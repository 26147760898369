import React, { Component } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import Sidebar from "../../../components/sidebar"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import VideoThumbnail from "react-video-thumbnail"
import _ from "lodash"
import Geocode from "react-geocode"
import { _Api } from "../../../services/Api"
import Url from "../../../services/BaseUrl"
import Loader from "../../../components/common/Loader"
Geocode.setApiKey("AIzaSyCDo6u0GLnkdPy9OHiS53-4yDhp_PEaCWA")
Geocode.enableDebug()
Geocode.setLanguage("en")

let videoHeight, videoWidth, videoPreview

class FarmEdit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: "",
      success: "",
      userInfo: [],
      isEdit: false,

      farmImagePreview: "",
      farmImageErrorMsg: "",
      farmImage: [],
      farmLogoPreview: "",
      farmLogo: "",
      farmLogoErrorMsg: "",
      farmVideoPreview: "",
      farmVideo: "",
      farmVideoErrorMsg: "",
      farmMediaErrorMsg: "",

      userId: this.props ? this.props.match.params.id : "",
      farmName: "",
      farmStatus: "",
      infoAboutFarm: "",
      infoAboutFarmer: "",
      farmNameErrorMsg: "",
      infoAboutFarmErrorMsg: "",
      infoAboutFarmerErrorMsg: "",

      farmId: "",
      farmInfo: [],
      media: [],
      loader: true,
      miniloader: false,
      miniloaderV: false,
      indexing: "",

      address: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      postalCode: "",
      city: "",
      lat: 0,
      shortInfo: "",
      lng: 0,
    }
  }

  componentDidMount() {
    this.getFarmDetails()
  }

  handleValidation = () => {
    let validate = true
    let { farmName, infoAboutFarm, infoAboutFarmer, farmLogo } = this.state

    if (farmName === "" || farmName === undefined) {
      validate = false
      this.setState({
        farmNameErrorMsg: "Please enter your farm name.",
      })
    } else {
      this.setState({
        farmNameErrorMsg: "",
      })
    }
    if (infoAboutFarm === "" || infoAboutFarm === undefined) {
      validate = false
      this.setState({
        infoAboutFarmErrorMsg: "Please enter information about farm.",
      })
    } else {
      this.setState({
        infoAboutFarmErrorMsg: "",
      })
    }
    if (infoAboutFarmer === "" || infoAboutFarmer === undefined) {
      validate = false
      this.setState({
        infoAboutFarmerErrorMsg: "Please enter information about you.",
      })
    } else {
      this.setState({
        infoAboutFarmerErrorMsg: "",
      })
    }

    if (farmLogo) {
      if (farmLogo === "" || farmLogo === undefined) {
        validate = false
        this.setState({
          farmLogoErrorMsg: "Please select farm logo",
        })
      } else if (farmLogo.size > 5000000) {
        validate = false
        this.setState({
          farmLogoErrorMsg: "Farm logo size should be less than 5Mb",
        })
      } else {
        this.setState({
          farmLogoErrorMsg: "",
        })
      }
    }
    return validate
  }

  handleChanged = (e) => {
    let self = this

    let { addressLine1, addressLine2, city, state, postalCode } = this.state
    if (e.target.name === "addressLine1") {
      addressLine1 = e.target.value
    }
    if (e.target.name === "addressLine2") {
      addressLine2 = e.target.value
    }

    if (e.target.name === "city") {
      city = e.target.value
    }
    if (e.target.name === "state") {
      state = e.target.value
    }
    if (e.target.name === "postalCode") {
      postalCode = e.target.value
    }

    let addr =
      addressLine1 +
      " " +
      addressLine2 +
      " " +
      city +
      " " +
      state +
      " " +
      postalCode

    if (
      e.target.name === "addressLine1" ||
      e.target.name === "addressLine2" ||
      e.target.name === "city" ||
      e.target.name === "state" ||
      e.target.name === "postalCode"
    ) {
      Geocode.fromAddress(addr).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location

          self.setState({ lat: lat, lng: lng })
        },
        (error) => {
          console.error(error)
        }
      )
    }

    this.setState({
      [e.target.name]: e.target.value,
      infoAboutFarmerErrorMsg: "",
      infoAboutFarmErrorMsg: "",
      farmNameErrorMsg: "",
      farmLogoErrorMsg: "",
      farmVideoErrorMsg: "",
      farmImageErrorMsg: "",
      addressLine1ErrorMsg: "",
    })
  }
  editMode = () => {
    this.setState({ isEdit: true })
  }

  viewMode = () => {
    this.setState({ isEdit: false })
  }

  handleLogoUpload = (event) => {
    console.log(event, "eventeventevent", event.target.files[0])
    let reader = new FileReader()
    let file = event.target.files[0]

    let { valiDate } = this.state

    reader.addEventListener(
      "load",
      () => {
        this.setState({ farmLogoPreview: reader.result }, function () {})
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/gif" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml"
      ) {
        if (file.size > 5000000) {
          valiDate = false
          this.setState({
            farmLogo: event.target.files[0],
            farmLogoErrorMsg: "Farm logo size should be less than 5Mb.",
          })
        } else {
          this.setState({
            farmLogo: event.target.files[0],
            farmLogoErrorMsg: "",
          })
        }
      } else {
        valiDate = false
        this.setState({ farmLogoErrorMsg: "File type not supported." })
      }
    }
  }

  handleEditSubmit = (e) => {
    let {
      shortInfo,
      userId,
      farmLogo,
      farmId,
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
      mapPosition,
      lat,
      lng,
      farmImage,
      farmVideo,
      farmName,
      farmStatus,
      infoAboutFarm,
      infoAboutFarmer,
      description,
    } = this.state
    e.preventDefault()
    // let {addressLine1, addressLine2, city, state, postalCode, mapPosition, lat, lng} = this.props.mapFarmReducer

    let self = this
    if (this.handleValidation()) {
      // this.setState({redirect: false, loader: true});

      let obj = new FormData()
      obj.append("userId", userId)
      obj.append("farmId", farmId)
      obj.append("farmLogo", farmLogo)
      obj.append("farmName", farmName)
      obj.append("farmStatus", 1)
      obj.append("infoAboutFarm", infoAboutFarm)
      obj.append("shortInfo", shortInfo)
      obj.append("infoAboutFarmer", infoAboutFarmer)
      if (lat) obj.append("lat", lat)
      if (lng) obj.append("long", lng)
      if (addressLine1) obj.append("addressLine1", addressLine1)
      if (addressLine2) obj.append("addressLine2", addressLine2)
      if (city) obj.append("city", city)
      if (state) obj.append("state", state)
      if (postalCode) obj.append("postalCode", postalCode)
      this.setState({ errors: "", loader: false })

      _Api(Url.EDIT_FARM.method, Url.EDIT_FARM.url, obj).then((data) => {
        self.setState({ loader: false })
        if (data.statusCode == 1) {
          this.props.history.push(`/farmermanagement/farm/${this.state.userId}`)
          self.setState({ isEdit: false })
        } else {
          self.setState({ errors: data.error.responseMessage })
        }
      })
    }
  }
  getFarmDetails = () => {
    let {
      userId,
      farmLogo,
      farmImage,
      farmVideo,
      farmLogoPreview,
      farmVideoPreview,
      farmName,
      farmStatus,
      infoAboutFarm,
      infoAboutFarmer,
      description,
    } = this.state

    let self = this
    let params = `?userId=${userId}&farmerId=${userId}`
    this.setState({ errors: "", loader: true })

    _Api(
      Url.GET_FARM_INFO.method,
      Url.GET_FARM_INFO.url,
      "",
      `?userId=${userId}&farmerId=${userId}`
    ).then((data) => {
      let farmInfo = data.responseData.result
      self.setState({ loader: false })

      console.log(
        "muuuuuuuuuuuuuuuuuuuuuu3333333",
        farmInfo.length > 0 ? farmInfo[0] : ""
      )
      if (data.statusCode == 1) {
        self.setState({
          farmInfo: farmInfo,
          farmLogo: farmInfo.length > 0 ? farmInfo[0].farmLogo : "",
          shortInfo: farmInfo.length > 0 ? farmInfo[0].shortInfo : "",
          farmId: farmInfo.length > 0 ? farmInfo[0]._id : "",
          farmLogoPreview: farmInfo.length > 0 ? farmInfo[0].farmLogo : "",
          media: farmInfo.length > 0 ? farmInfo[0].media : "",
          farmName: farmInfo.length > 0 ? farmInfo[0].farmName : "",
          infoAboutFarm: farmInfo.length > 0 ? farmInfo[0].infoAboutFarm : "",
          infoAboutFarmer:
            farmInfo.length > 0 ? farmInfo[0].infoAboutFarmer : "",
          addressLine1:
            farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
              ? farmInfo[0].farmAddress[0].addressLine1
              : "",
          addressLine2:
            farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
              ? farmInfo[0].farmAddress[0].addressLine2
              : "",
          city:
            farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
              ? farmInfo[0].farmAddress[0].city
              : "",
          state:
            farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
              ? farmInfo[0].farmAddress[0].state
              : "",
          postalCode:
            farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
              ? farmInfo[0].farmAddress[0].postalCode
              : "",

          lng: farmInfo.length > 0 ? farmInfo[0].geoLocation[0] : 0,
          lat: farmInfo.length > 0 ? farmInfo[0].geoLocation[1] : 0,
        })
      } else {
        self.setState({ errors: data.error.responseMessage })
      }
    })
  }

  editImage = (event, item, i) => {
    let reader = new FileReader()
    let file = event

    let { userId, farmId } = this.state
    let fileImage = event.target.files[0]
    if (fileImage) {
      if (event.target.files[0].size < 5000000) {
        let self = this

        let obj = new FormData()

        obj.append("farmId", farmId)
        if (fileImage) obj.append("media", fileImage)
        if (userId) obj.append("userId", userId)
        if (item) obj.append("mediaId", item._id)
        // this.setState({loader: true})
        this.setState({ miniloader: true, indexing: i })

        _Api(Url.EDIT_MEDIA.method, Url.EDIT_MEDIA.url, obj).then((data) => {
          self.setState({ miniloader: false })
          // self.setState({loader: false})
          if (data.statusCode == 1) {
            self.setState({
              edit: false,
              media: data.responseData.result.media,
            })
            // self.getFarmDetails()
          } else {
            self.setState({ errors: data.error.responseMessage })
          }
        })
      } else {
        // valiDate = false;
        this.setState({ farmMediaErrorMsg: "File size must be less than 5Mb." })
      }
    }
  }
  deleteImage = (event, item, i) => {
    console.log("ssssssssssssssss", item, i)

    let { userId, farmId } = this.state
    let self = this
    let obj = {
      farmId: farmId,
      userId: userId,
      mediaId: item._id,
    }
    console.log(obj, "ssssssssssssssss", item, i)
    // this.setState({loader: true})
    this.setState({ miniloader: true, indexing: i, farmMediaErrorMsg: "" })

    let { dispatch } = this.props
    _Api(Url.DELETE_MEDIA.method, Url.DELETE_MEDIA.url, obj)
      // dispatch(farmActions.deleteMedia(obj))
      .then((data) => {
        self.setState({ miniloader: false })

        if (data.statusCode == 1) {
          self.setState({ edit: false, media: data.responseData.result.media })
        } else {
          self.setState({ errors: data.error.responseMessage })
        }
      })
  }

  addImage = (event, item) => {
    let reader = new FileReader()
    let file = event
    let { userId, farmId } = this.state
    let fileImage = event.target.files[0]
    if (fileImage) {
      if (event.target.files[0].size < 5000000) {
        let self = this
        let obj = new FormData()

        obj.append("farmId", farmId)
        if (fileImage) obj.append("media", fileImage)
        if (userId) obj.append("userId", userId)
        if (userId) obj.append("mediaId", item._id)

        this.setState({ miniloader: true })

        _Api(Url.EDIT_MEDIA.method, Url.EDIT_MEDIA.url, obj).then((data) => {
          self.setState({ miniloader: false })
          if (data.statusCode == 1) {
            self.getFarmDetails()
          } else {
            self.setState({ errors: data.error.responseMessage })
          }
        })
      } else {
        // valiDate = false;
        this.setState({ farmMediaErrorMsg: "File size must be less than 5Mb." })
      }
    }
  }

  // handleDelete = (e, value) => {
  //     let {farmId, userId} = this.state
  //     let obj = {
  //         'farmId': farmId,
  //         'userId': value._id,
  //     }
  //     this.setState({errors: '', loader: true});
  //
  //     let {dispatch} = this.props;
  //
  //     dispatch(farmActions.deleteMedia(obj)).then(data => {
  //         this.setState({loader: false});
  //         let inventoryData = this.props.inventory
  //         if (inventoryData.statusCode == 1) {
  //
  //             this.getFarmDetails()
  //
  //         } else {
  //             // this.setState({errors: inventoryData.error.responseMessage})
  //         }
  //     });
  //
  //
  // }
  onKeyPress(e) {
    e = e || window.event
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which
    var charStr = String.fromCharCode(charCode)

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault()
  }

  render() {
    let {
      isEdit,
      farmInfo,
      media,
      loader,
      indexing,
      farmImagePreview,
      farmImageErrorMsg,
      farmImage,
      addressLine1ErrorMsg,
      farmLogoPreview,
      farmLogo,
      farmLogoErrorMsg,
      farmVideoPreview,
      farmVideo,
      farmVideoErrorMsg,
      userId,
      farmName,
      farmStatus,
      infoAboutFarm,
      infoAboutFarmer,
      farmNameErrorMsg,
      infoAboutFarmErrorMsg,
      infoAboutFarmerErrorMsg,
      shortInfo,
      farmMediaErrorMsg,
    } = this.state

    return (
      <>
        <Sidebar />

        <div>
          <div className="header_wrapper">
            <div className="header_logo">{/*<img src={require()}/>*/}</div>
            <div className="header_search"></div>
            <div className="bell_icon">
              <i className="fas fa-bell"></i>
            </div>
          </div>

          <div className="body_container">
            <div className="body_inner_wrapper">
              {this.state.loader ? (
                <Loader />
              ) : (
                <>
                  <header className="header_content">
                    {/*<p>Farm</p>*/}
                    <Link to={`/farmermanagement/farm/${this.state.userId}`}>
                      <span className="mr-3 d-inline-block text_light text_12">
                        <i class="fas fa-caret-left text_dark mr-2"></i> Back{" "}
                      </span>
                    </Link>
                  </header>

                  <div className="page_heading d-flex">
                    <Link
                      to={`/farmermanagement/editfrmermanagement/${userId}`}
                      className="mr-3"
                    >
                      {" "}
                      <h6>Edit Farmer</h6>
                    </Link>
                    <Link
                      to={`/farmermanagement/inventorymanagement/${userId}`}
                      className=" mr-3"
                    >
                      <h6>Inventory</h6>
                    </Link>
                    <h6 className=" activeheading mr-3 mr-3"> Farm</h6>

                    <Link
                      to={`/farmermanagement/distributionmanagement/${userId}`}
                      className=" mr-3"
                    >
                      <h6>Distribution</h6>
                    </Link>
                  </div>
                  <div className="main_content">
                    <form onSubmit={this.handleEditSubmit}>
                      <div className="farm_page">
                        <Row>
                          <Col md="2">
                            <p>Farm Logo:</p>
                          </Col>
                          <Col md="7">
                            <div
                              className="file_uploader bg_size mx-auto edit_dlt"
                              style={{
                                backgroundImage: `url(${farmLogoPreview})`,
                              }}
                            >
                              <div class="overlay"></div>
                              <i className="fas fa-pen text-light"></i>
                              <Form.File
                                label="Custom file input"
                                name="farmLogo"
                                accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                onChange={this.handleLogoUpload}
                                lang="en"
                                custom
                              />
                            </div>
                            <p className="text-center">
                              <small>Recommended size 5Mb </small>
                            </p>

                            {farmLogoErrorMsg ? (
                              <div className="error">{farmLogoErrorMsg}</div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="2">
                            <p>Farm Name:</p>
                          </Col>
                          <Col md="7">
                            <Form.Control
                              type="text"
                              onChange={this.handleChanged}
                              name="farmName"
                              value={farmName}
                              placeholder="Farm name "
                            />
                            {console.log(
                              farmNameErrorMsg,
                              "ssssssssssssssssssss"
                            )}
                            {farmNameErrorMsg ? (
                              <div className="error">{farmNameErrorMsg}</div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="2">
                            <p>Short Info About Farm:</p>
                          </Col>
                          <Col md="7">
                            <Form.Control
                              type="text"
                              onChange={this.handleChanged}
                              name="shortInfo"
                              value={shortInfo}
                              placeholder="Short farm info "
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="2">
                            <p>Info About Farm:</p>
                          </Col>
                          <Col md="7">
                            <textarea
                              placeholder="Enter here"
                              onChange={this.handleChanged}
                              name="infoAboutFarm"
                              value={infoAboutFarm}
                              className="form-control"
                              rows="4"
                              cols="50"
                            ></textarea>
                            {infoAboutFarmErrorMsg ? (
                              <div className="error">
                                {infoAboutFarmErrorMsg}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="2">
                            <p>Info About Farmer:</p>
                          </Col>
                          <Col md="7">
                            <textarea
                              placeholder="Enter here"
                              onChange={this.handleChanged}
                              name="infoAboutFarmer"
                              value={infoAboutFarmer}
                              className="form-control"
                              rows="4"
                              cols="50"
                            ></textarea>
                            {infoAboutFarmerErrorMsg ? (
                              <div className="error">
                                {infoAboutFarmerErrorMsg}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="2">
                            <p>Farm Image:</p>
                          </Col>
                          <Col md="7">
                            {farmInfo.length > 0 ? (
                              <div className="fileupload_wraper">
                                {media.map((value, i) => {
                                  return value.urlType === "I" ? (
                                    <div
                                      className="file_uploader bg_size edit-wrapper"
                                      style={{
                                        backgroundImage: `url(${value.thumbUrl})`,
                                      }}
                                    >
                                      <div className="overlay"></div>
                                      <div className="edit_dlt">
                                        <i className="fas fa-pen text-light"></i>
                                        <Form.File
                                          label="Custom file input"
                                          name="farmImage"
                                          accept="image/x-png,image/gif,image/jpeg"
                                          onChange={(event) =>
                                            this.editImage(event, value, i)
                                          }
                                          lang="en"
                                          custom
                                        />

                                        <span className="dltimg">
                                          <i
                                            onClick={(e) =>
                                              this.deleteImage(e, value)
                                            }
                                            className="fas fa-trash-alt deleteImage"
                                          ></i>
                                        </span>
                                      </div>
                                      {indexing === i
                                        ? this.state.miniloader && (
                                            <div className="mini_loader">
                                              <div
                                                class="spinner-border text-info"
                                                role="status"
                                              ></div>
                                            </div>
                                          )
                                        : ""}
                                    </div>
                                  ) : (
                                    ""
                                  )
                                })}
                                {media.length < 5 ? (
                                  <div className="file_uploader bg_size">
                                    <div className="edit_dlt">
                                      <i className="fas fa-plus-circle"></i>
                                      <Form.File
                                        label="Custom file input"
                                        name="farmImage"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={(event) =>
                                          this.editImage(event)
                                        }
                                        lang="en"
                                        custom
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            <p>
                              <small>Recommended size 5Mb </small>
                            </p>
                            {farmMediaErrorMsg ? (
                              <div className="error">{farmMediaErrorMsg}</div>
                            ) : null}
                          </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col md="2">*/}
                        {/*        <p>Farm Video:</p>*/}
                        {/*    </Col>*/}
                        {/*    {console.log(farmInfo, "farmInfofarmInfofarmInfo1",farmInfo.length > 0,media)}*/}
                        {/*    {media.length > 0 ?*/}
                        {/*        <Col md="7">*/}
                        {/*            <div className="fileupload_wraper">*/}
                        {/*                {media.map(value => {*/}
                        {/*                    console.log("farmInfofarmInfofarmInfo2", value.urlType === "V")*/}
                        {/*                    return (*/}
                        {/*                        value.urlType === "V" ?*/}
                        {/*                            <>*/}
                        {/*                                <div*/}
                        {/*                                    className=" file_uploader bg_size edit-wrapper max-w-100"*/}
                        {/*                                    style={{backgroundImage: `url(${value.thumbUrl})`}}>*/}
                        {/*                                    <div className="overlay"></div>*/}
                        {/*                                    <div className="edit_dlt">*/}
                        {/*                                        <i className="fas fa-pen"></i>*/}
                        {/*                                        <Form.File*/}
                        {/*                                            label="Custom file input"*/}
                        {/*                                            accept="video/mp4,video/x-m4v,video/*"*/}
                        {/*                                            name="farmVideo"*/}
                        {/*                                            onChange={(event) => this.editVideo(event, value)}*/}
                        {/*                                            lang="en" custom/>*/}
                        {/*                                        /!*           <span><i*!/*/}

                        {/*                                        /!*onClick={(e) => this.handleDelete(e, value)}*!/*/}
                        {/*                                        /!*className="fas fa-trash-alt"></i></span>*!/*/}
                        {/*                                        /!*<span><i onClick={(e) => this.handleDelete(e, value)} className="fas fa-trash-alt"></i></span>*!/*/}
                        {/*                                    </div>*/}
                        {/*                                    {this.state.miniloaderV &&*/}
                        {/*                                    <div className="mini_loader">*/}
                        {/*                                        <div*/}
                        {/*                                            class="spinner-border text-info"*/}
                        {/*                                            role="status">*/}
                        {/*                                        </div>*/}
                        {/*                                    </div>*/}
                        {/*                                    }*/}
                        {/*                                </div>*/}

                        {/*                                {farmVideoErrorMsg ?*/}
                        {/*                                    <div*/}
                        {/*                                        className="error">{farmVideoErrorMsg}</div> : null}*/}
                        {/*                            </> :*/}

                        {/*                            ''*/}

                        {/*                    )*/}

                        {/*                })}*/}
                        {/*            </div>*/}
                        {/*        </Col> :*/}

                        {/*        <Col md="7">*/}
                        {/*            <div className="fileupload_wraper">*/}
                        {/*                <>*/}
                        {/*                    <div*/}
                        {/*                        className="file_uploader bg_size bg_size max-w-100 video_upload videothumb">*/}
                        {/*                        {console.log(videoPreview, " ljhugyfhcgxchghjkgjfhc:::", videoPreview !== undefined, "ath this.state.videoPreview.path")}*/}
                        {/*                        {videoPreview !== undefined ?*/}
                        {/*                            <VideoThumbnail*/}
                        {/*                                renderThumbnail={true}*/}
                        {/*                                videoUrl={videoPreview != undefined ? videoPreview.path : ''}*/}
                        {/*                                thumbnailHandler={(thumbnail) => {*/}
                        {/*                                    console.log("ljhugyfhcgxchghjkgjfhc:::::;2")*/}
                        {/*                                    let img = new Image();*/}
                        {/*                                    img.src = thumbnail;*/}
                        {/*                                    img.onload = function () {*/}
                        {/*                                        console.log("ljhugyfhcgxchghjkgjfhc:::::;3")*/}
                        {/*                                        videoHeight = img.height;*/}
                        {/*                                        videoWidth = img.width;*/}
                        {/*                                    }*/}
                        {/*                                }}*/}
                        {/*                                width={videoWidth}*/}
                        {/*                                height={videoHeight}*/}
                        {/*                            />*/}

                        {/*                            :*/}

                        {/*                            <div><i*/}
                        {/*                                className="fas fa-plus-circle"></i>*/}
                        {/*                                <br/>*/}
                        {/*                                <span*/}
                        {/*                                    className="placeholder">Upload Video </span>*/}
                        {/*                            </div>}*/}

                        {/*                        <Form.File label="Custom file input"*/}
                        {/*                                   name="farmVideo"*/}
                        {/*                                   className="video_upload p-cm"*/}
                        {/*                                   accept="video/mp4,video/x-m4v,video/*"*/}
                        {/*                                   onChange={this.handleVideoUpload}*/}
                        {/*                                   lang="en" custom/>*/}

                        {/*                    </div>*/}

                        {/*                    {farmVideoErrorMsg ?*/}
                        {/*                        <div*/}
                        {/*                            className="error">{farmVideoErrorMsg}</div> : null}*/}

                        {/*                </>*/}
                        {/*            </div>*/}
                        {/*        </Col>*/}
                        {/*    }*/}

                        {/*</Row>*/}

                        {/*{this.state.lat ?*/}
                        <Form.Group>
                          {/*<p>Location On Map:</p>*/}
                          <div>
                            {console.log(
                              this.state.lat,
                              this.state.lng,
                              "xsdsddddddddddddddddd.lat"
                            )}
                            <Row>
                              <Col md="2">
                                {" "}
                                <label htmlFor="">Farm Address Line 1</label>
                              </Col>
                              <Col md="7">
                                <input
                                  type="text"
                                  name="addressLine1"
                                  className="form-control"
                                  placeholder="Farm Address Line 1"
                                  onChange={this.handleChanged}
                                  value={this.state.addressLine1}
                                />
                                {addressLine1ErrorMsg ? (
                                  <div className="error">
                                    {addressLine1ErrorMsg}
                                  </div>
                                ) : null}
                              </Col>
                            </Row>
                            {/*</div>*/}
                            <Row>
                              <Col md="2">
                                <label htmlFor="">Farm Address Line 2</label>
                              </Col>
                              <Col md="7">
                                {" "}
                                <input
                                  type="text"
                                  name="addressLine2"
                                  className="form-control"
                                  placeholder="Farm Address Line 2"
                                  onChange={this.handleChanged}
                                  value={this.state.addressLine2}
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col md="2">
                                <label htmlFor="">City</label>
                              </Col>
                              <Col md="7">
                                <input
                                  type="text"
                                  name="city"
                                  className="form-control"
                                  placeholder="City"
                                  onChange={this.handleChanged}
                                  value={this.state.city}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md="2">
                                <label htmlFor="">State</label>
                              </Col>
                              <Col md="7">
                                <input
                                  type="text"
                                  name="state"
                                  className="form-control"
                                  placeholder="State"
                                  onChange={this.handleChanged}
                                  value={this.state.state}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md="2">
                                <label htmlFor="">Postal Code </label>
                              </Col>
                              <Col md="7">
                                <input
                                  type="number"
                                  onKeyPress={this.onKeyPress.bind(this)}
                                  pattern="[0-9]*"
                                  min="0"
                                  name="postalCode"
                                  placeholder="Postal Code"
                                  className="form-control"
                                  onChange={this.handleChanged}
                                  value={this.state.postalCode}
                                />
                              </Col>
                            </Row>

                            {/*<Map1*/}

                            {/*    google={this.props.google}*/}
                            {/*    center={{*/}
                            {/*        lat: this.state.lat,*/}
                            {/*        lng: this.state.lng*/}
                            {/*    }}*/}
                            {/*    height='200px'*/}
                            {/*    zoom={15}*/}

                            {/*/>*/}

                            {/*12.5204, 75.8567*/}

                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d21784730.15225112!2d77.54500981874999!3d70.53342541794991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1588745436848!5m2!1sen!2sin" width="890" height="450" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                          </div>
                        </Form.Group>

                        {/*    : <Form.Group>*/}
                        {/*        <p>Location On Map:</p>*/}
                        {/*        <div>*/}

                        {/*            {console.log(this.state.lat, this.state.lng, "xsdsddddddddddddddddd.lat")}*/}

                        {/*            <Map1*/}

                        {/*                google={this.props.google}*/}
                        {/*                center={{*/}
                        {/*                    lat: 34.155834,*/}
                        {/*                    lng: -119.202789*/}
                        {/*                }}*/}
                        {/*                height='200px'*/}
                        {/*                zoom={15}*/}

                        {/*            />*/}

                        {/*            /!*12.5204, 75.8567*!/*/}

                        {/*            /!* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d21784730.15225112!2d77.54500981874999!3d70.53342541794991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1588745436848!5m2!1sen!2sin" width="890" height="450" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> *!/*/}
                        {/*        </div>*/}
                        {/*    </Form.Group>*/}
                        {/*}*/}

                        <Row>
                          <Col md="8">
                            <div className="text-right">
                              <Button type="submit" variant="warning">
                                Save
                              </Button>{" "}
                              &nbsp;
                              <Link
                                className="btn btn-dark"
                                variant="warning"
                                to={`/farmermanagement/farm/${this.state.userId}`}
                              >
                                Cancel
                              </Link>
                              {/*<Button variant="warning">Save</Button>*/}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default FarmEdit

// import React, { Component } from 'react'
// import { Accordion, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
//
// import { Link, NavLink } from 'react-router-dom'
// import _ from 'lodash';
// import { message } from 'antd'
// import { notify } from '../../components/common/Tooster'
// import ReactPhoneInput from 'react-phone-input-2'
// import { _Api } from '../../services/Api'
// import Url from '../../services/BaseUrl'
// import Map from '../map/Map'
// import Sidebar from "../../components/sidebar";

import "react-phone-input-2/lib/style.css"

import { Button, Col, Form, Row } from "react-bootstrap"
import React, { Component } from "react"

import Geocode from "react-geocode"
import { Link } from "react-router-dom"
import Loader from "../../components/common/Loader"
import PhoneInput from "react-phone-input-2"
import Sidebar from "../../components/sidebar"
import Url from "../../services/BaseUrl"
import _ from "lodash"
import { _Api } from "../../services/Api"
import { isValidEmail } from "../../utils/Validation"
import { notify } from "../../components/common/Toaster"

class AddCustomer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      profilePicErrorMsg: "",
      countryCode: "",
      phone: "",
      errors: [],
      messagePopup: 0,
      alertPopup: 0,
      emailPopup: 0,
      responseError: "",
      lat: "",
      long: "",
      address: "",
      profileImage: "",
      state: "",
      city: "",
      area: "",
      logout: false,
      postalCode: "",
      addressLine1: "",
      addressLine2: "",
    }

    this.handleCheck = this.handleCheck.bind(this)
    this.handleCheck1 = this.handleCheck1.bind(this)
    this.handleCheck2 = this.handleCheck2.bind(this)
  }

  handleCheck(e) {
    let { messagePopup } = this.state
    if (e == 0) {
      this.setState({ messagePopup: 1, agreeErrorMsg: "" })
    } else {
      this.setState({ messagePopup: 0 })
    }
  }

  handleCheck1(e) {
    let { alertPopup } = this.state
    if (e == 0) {
      this.setState({ alertPopup: 1, agreeErrorMsg: "" })
    } else {
      this.setState({ alertPopup: 0 })
    }
  }

  handleCheck2(e) {
    let { emailPopup } = this.state

    console.log(e, "erghjkl;poijkijhnmkjhbnmkjhbnmjhb")
    if (e == 0) {
      this.setState({ emailPopup: 1, agreeErrorMsg: "" })
    } else {
      this.setState({ emailPopup: 0 })
    }
  }

  handleOnChange = (e) => {
    let self = this
    let address1, address2, city1, state1, postalCode1
    if (e.target.name === "addressLine1") {
      address1 = e.target.value
    }
    if (e.target.name === "addressLine2") {
      address2 = e.target.value
    }

    if (e.target.name === "city") {
      city1 = e.target.value
    }
    if (e.target.name === "state") {
      state1 = e.target.value
    }
    if (e.target.name === "postalCode") {
      postalCode1 = e.target.value
    }
    let addr =
      address1 + " " + address1 + " " + city1 + " " + state1 + " " + postalCode1

    if (
      e.target.name === "addressLine1" ||
      e.target.name === "addressLine2" ||
      e.target.name === "city" ||
      e.target.name === "state" ||
      e.target.name === "postalCode"
    ) {
      Geocode.fromAddress(addr).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location

          self.setState({ lat: lat, long: lng })
        },
        (error) => {
          console.error(error)
        }
      )
    }

    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    })
  }
  handleValidate = () => {
    let validate = false
    let errors = {}
    let {
      profileImage,
      postalCode,
      firstName,
      lastName,
      email,
      phone,
      countryCode,
      address,
    } = this.state

    // if(profileImage){

    if (profileImage === "" || profileImage === undefined) {
      validate = true
      this.setState({
        profilePicErrorMsg: "Please select profile pic.",
      })
    } else {
      if (profileImage.size > 5000000) {
        validate = true
        this.setState({
          profilePicErrorMsg: "Profile pic size should be less than 5Mb.",
        })
      } else {
        this.setState({
          profilePicErrorMsg: "",
        })
      }
    }
    if (_.isEmpty(firstName)) {
      validate = true
      errors["firstName"] = "First name is required."
    }
    if (_.isEmpty(lastName)) {
      validate = true
      errors["lastName"] = "Last name is required."
    }
    if (_.isEmpty(email)) {
      validate = true
      errors["email"] = "Email is required."
    } else if (!isValidEmail(email)) {
      validate = true
      errors["email"] = "Enter vaild email"
    }
    if (postalCode) {
      if (postalCode < 0) {
        validate = true
        errors["postalCode"] = "Postal code must be greater than 0."
      }
    }

    if (phone) {
      if (phone.length > 3 && phone.length < 10) {
        validate = true
        errors["phone"] = "Please enter a valid mobile number."
      }
    }

    // if (_.isEmpty(address)) {
    //     validate = true;
    //     errors['address'] = 'Address is required.'
    // }

    this.setState({ errors })
    return validate
  }

  handleAddFramer = (e) => {
    if (!this.handleValidate()) {
      let {
        emailPopup,
        notificationAppAlert,
        notificationTextMassage,
        addressLine1,
        addressLine2,
        postalCode,
        finalNestedDeliveryWeekDaysTimeSlot,
        firstName,
        lastName,
        email,
        phone,
        countryCode,
        profileImage,
        lat,
        address,
        long,
        lng,
        state,
        city,
        area,
      } = this.state
      console.log("qwert", profileImage)

      // firstName: firstName,
      // lastName: lastName,
      // email: email,
      // phone: phone,
      // countryCode: countryCode,
      // lat: lat,
      // long: long,
      // address:address,
      // profileImage:profileImage

      let formData = new FormData()
      formData.append("firstName", firstName)
      formData.append("lastName", lastName)
      formData.append("email", email)
      formData.append("lat", lat ? lat : 0)
      formData.append("long", long ? long : 0)
      formData.append("phone", phone)

      formData.append("userType", 2)
      // formData.append("countryCode", countryCode)
      formData.append("profileImage", profileImage)
      formData.append("addressLine1", addressLine1)
      formData.append("addressLine2", addressLine2)
      formData.append("city", city)
      formData.append("state", state)
      formData.append("postalCode", postalCode ? postalCode : "")
      formData.append("notificationEmail", emailPopup ? emailPopup : 0)
      formData.append(
        "notificationTextMassage",
        notificationTextMassage ? notificationTextMassage : 0
      )
      formData.append(
        "notificationAppAlert",
        notificationAppAlert ? notificationAppAlert : 0
      )

      // Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "",
      _Api(
        Url.SUPER_ADMIN_ADD_USER.method,
        Url.SUPER_ADMIN_ADD_USER.url,
        formData
      )
        .then((resp) => {
          {
            console.log("resp", resp)
          }
          // this.setState({ driverInfo: resp.responseData.driverInfo, isLoader: false })
          notify("success", resp.responseData.message)
          this.props.history.push("/customer")
          // this.closeModal()
        })
        .catch((err) => {
          console.log("tTTTTTTTTT", err)

          notify("error", err.data.error.responseMessage)
        })
    }
  }
  handleAddressLatLong = (latValue, lngValue, address, area, city, state) => {
    console.log("latlong", latValue, lngValue, address)
    this.setState({
      lat: latValue,
      long: lngValue,
      address: address,
      area: area,
      city: city,
      state: state,
    })
  }
  handlePicUpload = (event) => {
    console.log(event, "eventeventevent", event.target.files[0])
    let reader = new FileReader()
    let file = event.target.files[0]

    let { valiDate } = this.state

    reader.addEventListener(
      "load",
      () => {
        this.setState({ profilePicPreview: reader.result }, function () {})
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }

    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/gif" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml"
      ) {
        if (file.size > 5000000) {
          console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")

          valiDate = false
          this.setState({
            profileImage: event.target.files[0],
            profilePicErrorMsg: "Profile pic size should be less than 5Mb.",
          })
        } else {
          this.setState({
            profileImage: event.target.files[0],
            profilePicErrorMsg: "",
          })
        }
        console.log("image", event.target.files[0])
      } else {
        valiDate = false
        this.setState({ profilePicErrorMsg: "File type not supported." })
      }
    }
  }

  onKeyPress(e) {
    e = e || window.event
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which
    var charStr = String.fromCharCode(charCode)

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault()
  }

  render() {
    let {
      profilePicErrorMsg,
      messagePopup,
      alertPopup,
      emailPopup,
      postalCode,
      firstName,
      lastName,
      email,
      profileImage,
      countryCode,
      logout,
      phone,
      errors,
      profilePicPreview,
      state,
      city,
      address,
      area,
    } = this.state
    return (
      <>
        <Sidebar />
        <div className="body_container">
          <div className="body_inner_wrapper">
            {this.state.isLoader ? (
              <Loader />
            ) : (
              <>
                {" "}
                <header className="header_content">
                  <div>
                    <Link to="/customer">
                      <span className="mr-3 d-inline-block text_light text_12">
                        <i class="fas fa-caret-left text_dark mr-2"></i> Back{" "}
                      </span>
                    </Link>
                  </div>
                </header>
                <div className="page_heading">
                  <h6>Add Customer</h6>
                </div>
                <div className="main_content farm_page ">
                  <Row>
                    <Col sm={3}>
                      <div className="u_img mb-5">
                        {profilePicPreview ? (
                          <span className="profile_icon_Admin ">
                            <img
                              src={profilePicPreview}
                              className="logoroundimg"
                            />
                            <span className="img_icon">
                              <span className="file_input">
                                <input
                                  type="file"
                                  className="cm_hide_input"
                                  accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                  onChange={this.handlePicUpload}
                                />
                              </span>
                              <img
                                src={require("../../assets/img/imgicon.png")}
                              />
                            </span>
                          </span>
                        ) : (
                          <span className="profile_icon_Admin ">
                            <img
                              src={require("../../assets/img/adminlogo.png")}
                              className="logoroundimg"
                            />
                            <span className="img_icon">
                              <span className="file_input">
                                <input
                                  type="file"
                                  className="cm_hide_input"
                                  accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                  onChange={this.handlePicUpload}
                                />
                              </span>
                              <img
                                src={require("../../assets/img/imgicon.png")}
                              />
                            </span>
                          </span>
                        )}
                      </div>
                      {profilePicErrorMsg ? (
                        <div className="error">{profilePicErrorMsg}</div>
                      ) : null}
                    </Col>
                    <Col sm={9}>
                      <Row>
                        <Col md="2">
                          <p>First Name:</p>
                        </Col>
                        <Col md="3">
                          <Form.Control
                            type="text"
                            placeholder="First name"
                            maxLength={30}
                            name="firstName"
                            value={firstName}
                            onChange={this.handleOnChange}
                          />
                          <span className="error_msg">
                            {errors["firstName"]}
                          </span>
                        </Col>
                        <Col md="2">
                          <p>Last Name:</p>
                        </Col>
                        <Col md="3">
                          <Form.Control
                            type="text"
                            placeholder="Last name"
                            maxLength={30}
                            name="lastName"
                            value={lastName}
                            onChange={this.handleOnChange}
                          />
                          <span className="error_msg">
                            {errors["lastName"]}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2">
                          <p>Email:</p>
                        </Col>
                        <Col md="8">
                          <Form.Control
                            type="email"
                            placeholder="Enter email"
                            maxLength={30}
                            name="email"
                            value={email}
                            onChange={this.handleOnChange}
                          />
                          <span className="error_msg">{errors["email"]}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2">
                          <p>Phone:</p>
                        </Col>
                        {/*<Col md="2">*/}
                        {/*    <Form.Control type="text"*/}
                        {/*        placeholder="Enter country code..."*/}
                        {/*        maxLength={30}*/}
                        {/*        name="countryCode"*/}
                        {/*        value={countryCode}*/}
                        {/*        onChange={this.handleOnChange}*/}
                        {/*    />*/}
                        {/*    <span className='error_msg'>{errors['countryCode']}</span>*/}
                        {/*</Col>*/}
                        <Col md="8">
                          <div className="cm_phnnumber">
                            <PhoneInput
                              country={"us"}
                              className="form-control custom-number"
                              value={phone}
                              placeholder="Please enter phone number"
                              onChange={(phone) => this.setState({ phone })}
                            />
                          </div>

                          <span className="error_msg">{errors["phone"]}</span>
                        </Col>
                      </Row>

                      <Form.Group>
                        {/*<p>Location On Map:</p>*/}
                        <div>
                          {console.log(
                            this.state.lat,
                            this.state.lng,
                            "xsdsddddddddddddddddd.lat"
                          )}
                          <Row>
                            <Col md="2">
                              {" "}
                              <label htmlFor="">Farm Address Line 1</label>
                            </Col>
                            <Col md="8">
                              <input
                                type="text"
                                name="addressLine1"
                                className="form-control"
                                placeholder="Farm Address Line 1"
                                onChange={this.handleOnChange}
                                value={this.state.addressLine1}
                              />
                            </Col>
                          </Row>
                          {/*</div>*/}
                          <Row>
                            <Col md="2">
                              <label htmlFor="">Farm Address Line 2</label>
                            </Col>
                            <Col md="8">
                              {" "}
                              <input
                                type="text"
                                name="addressLine2"
                                className="form-control"
                                placeholder="Farm Address Line 2"
                                onChange={this.handleOnChange}
                                value={this.state.addressLine2}
                              />
                            </Col>{" "}
                          </Row>

                          <Row>
                            <Col md="2">
                              <label htmlFor="">City</label>
                            </Col>
                            <Col md="8">
                              <input
                                type="text"
                                name="city"
                                className="form-control"
                                placeholder="City"
                                onChange={this.handleOnChange}
                                value={this.state.city}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="2">
                              <label htmlFor="">State</label>
                            </Col>
                            <Col md="8">
                              <input
                                type="text"
                                name="state"
                                className="form-control"
                                placeholder="State"
                                onChange={this.handleOnChange}
                                value={this.state.state}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="2">
                              <label htmlFor="">Postal Code </label>
                            </Col>
                            <Col md="8">
                              <input
                                type="number"
                                name="postalCode"
                                onKeyPress={this.onKeyPress.bind(this)}
                                pattern="[0-9]*"
                                min="0"
                                placeholder="Postal Code"
                                className="form-control"
                                onChange={this.handleOnChange}
                                value={this.state.postalCode}
                              />
                              <span className="error_msg">
                                {errors["postalCode"]}
                              </span>
                            </Col>
                          </Row>
                          {/*<Row>*/}
                          {/*    <Col md="2">*/}
                          {/*        <p>Notifications</p>*/}
                          {/*    </Col>*/}
                          {/*    <Col md="8">*/}
                          {/*        <Form.Group controlId="formBasicCheckbox">*/}
                          {/*            <Form.Check type="checkbox" label="Checked to receive text or email notifications" />*/}
                          {/*        </Form.Group>*/}
                          {/*    </Col>*/}
                          {/*</Row>*/}

                          <Row>
                            <Col md="2">
                              <p>Notifications:</p>
                            </Col>
                            <Col md="2">
                              {console.log(
                                emailPopup,
                                "emailPopupemailPopupemailPopupemailPopupemailPopup"
                              )}
                              <Form.Check
                                type="checkbox"
                                checked={emailPopup ? true : false}
                                value={this.state.emailPopup}
                                name="emailPopup"
                                onChange={this.handleCheck2.bind(
                                  this,
                                  emailPopup
                                )}
                                label="Email"
                              />
                            </Col>
                            <Col md="4">
                              <Form.Check
                                type="checkbox"
                                checked={messagePopup ? true : false}
                                value={this.state.messagePopup}
                                name="messagePopup"
                                onChange={this.handleCheck.bind(
                                  this,
                                  messagePopup
                                )}
                                label="Text Message"
                              />
                            </Col>
                            {/*<Col md="2">*/}
                            {/*    <Form.Check type="checkbox"*/}
                            {/*                checked={alertPopup ? true : false}*/}
                            {/*                value={this.state.alertPopup}*/}
                            {/*                name="alertPopup"*/}
                            {/*                onChange={this.handleCheck1.bind(this, alertPopup)}*/}
                            {/*                label="App Alert"/>*/}
                            {/*</Col>*/}
                          </Row>
                        </div>
                      </Form.Group>

                      <div>
                        <Button
                          variant="dark"
                          onClick={this.props.history.goBack}
                          className="mr-3"
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="warning"
                          onClick={this.handleAddFramer}
                        >
                          save
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}{" "}
          </div>
        </div>
      </>
    )
  }
}

export default AddCustomer

import React, { Component } from "react"
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom"

import AddCustomer from "./view/Customer/AddCustomer"
import AddDistribution from "./view/farmermanagement/distribution/AddDistribution"
import AddWholesaleBuyer from "./view/WholeSale/AddWholesaleBuyer"
import Addfarm from "./view/addfarm/addfarm"
import Addfarmer from "./view/farmermanagement/addfarmer"
import Addmarket from "./view/addmarket/addmarket"
import CustomerManagement from "./view/Customer/CustomerList"
import Distribution from "./view/farmermanagement/distribution/distribution"
import EditDistributionDetails from "./view/farmermanagement/distribution/EditDistributionDetails"
import EditMarket from "./view/addmarket/EditMarket"
import Editfrmermanagement from "./view/farmermanagement/Editfrmermanagement"
import FarmerInventory from "./view/farmermanagement/Inventory/Inventory"
import Farm from "./view/farmermanagement/farm/farm"
import FarmEdit from "./view/farmermanagement/farm/farmEdit"
import FarmerDetails from "./view/farmermanagement/FarmerDetails"
import FarmerManagement from "./view/farmermanagement/FarmerList"
import ForgotPassword from "./auth/ForgotPassword"
import Header from "./components/header"
import Inventory from "./view/Inventory/Inventory"
import Login from "./auth/Login"
import NewsLetter from "./view/newsLetter/newsLetter"
import Order from "./view/Order/order"
import Page404 from "./view/page404"
import Popupmanagement from "./view/addmarket/MarketList"
import RecipeManagement from "./view/Recipe/Recipe"
import ResetPassword from "./auth/ResetPassword"
import Settings from "./view/settings/settings"
import Sidebar from "./components/sidebar"
import ViewInvoice from "./view/Order/viewInvoice"
import WholeSaleList from "./view/WholeSale/WholeSaleList"
import { PickUpHostList } from "./view/PickUpHost/PickUpHostList"
import axios from "axios"
import { createBrowserHistory } from "history"

export const history = createBrowserHistory()

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("accessToken") ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
)

const PrivateOuterRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("accessToken") ? (
        <Redirect
          to={{ pathname: "/order", state: { from: props.location } }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
)
class Routes extends Component {
  constructor(props) {
    super(props)
    const { dispatch } = this.props
    history.listen((location, action) => {})
  }

  render() {
    let accessToken = localStorage.getItem("accessToken")
    if (accessToken) {
      axios.defaults.headers.common["accessToken"] = `${accessToken}`
    }
    axios.defaults.headers.common[
      "Authorization"
    ] = `${"Basic aGVhbHRoeVBsYW5ldF9hZG1pbl92WnBHaDQ6YWRtaW5AaGVhbHRoeVBsYW5ldF9GVGtWcFE="}`

    // const Routes = (props) => {
    return (
      <>
        <Router Router history={{ history }}>
          <Switch>
            <PrivateOuterRoute exact path="/" component={Login} />
            <PrivateOuterRoute
              path="/forgotpassword"
              component={ForgotPassword}
            />
            <PrivateOuterRoute
              path="/resetpassword/:id"
              component={ResetPassword}
            />
            <PrivateRoute
              exact
              path="/farmermanagement"
              component={FarmerManagement}
            />
            <PrivateRoute exact path="/wholesale" component={WholeSaleList} />
            <PrivateRoute
              exact
              path="/customer"
              component={CustomerManagement}
            />
            <PrivateRoute
              exact
              path="/farmermanagement"
              component={FarmerManagement}
            />
            <PrivateRoute
              exact
              path="/popupmanagement"
              component={Popupmanagement}
            />
            <PrivateRoute exact path="/pickups" component={PickUpHostList} />
            <PrivateRoute exact path="/inventory" component={Inventory} />
            <PrivateRoute path="/settings" component={Settings} />
            <PrivateRoute path="/recipe" component={RecipeManagement} />
            <PrivateRoute path="/newsletter" component={NewsLetter} />
            <PrivateRoute
              path="/addwholesalebuyer"
              component={AddWholesaleBuyer}
            />
            <PrivateRoute path="/addcustomer" component={AddCustomer} />
            <PrivateRoute path="/order" component={Order} />
            <PrivateRoute path="/vieworder/:id" component={ViewInvoice} />
            <PrivateRoute
              exact
              path="/farmermanagement/editfrmermanagement/:id"
              component={Editfrmermanagement}
            />
            <PrivateRoute
              exact
              path="/farmermanagement/inventorymanagement/:id"
              component={FarmerInventory}
            />
            <PrivateRoute
              exact
              path="/farmermanagement/farm/:id"
              component={Farm}
            />
            <PrivateRoute
              path="/farmermanagement/farmedit/:id"
              component={FarmEdit}
            />
            <PrivateRoute
              exact
              path="/farmermanagement/distributionmanagement/:id"
              component={Distribution}
            />
            <PrivateRoute
              exact
              path="/farmermanagement/distributionadd/:id"
              component={AddDistribution}
            />
            <PrivateRoute
              exact
              path="/farmermanagement/distributionedit/:id/:dId"
              component={EditDistributionDetails}
            />

            <PrivateRoute
              exact
              path="/farmermanagement/addfarmer"
              component={Addfarmer}
            />
            <PrivateRoute
              exact
              path="/farmermanagement/farmerdetails/:id"
              component={FarmerDetails}
            />
            <PrivateRoute
              exact
              path="/popupmanagement/addmarket"
              component={Addmarket}
            />
            <PrivateRoute
              exact
              path="/popupmanagement/addfarm"
              component={Addfarm}
            />
            <PrivateRoute
              exact
              path="/popupmanagement/editmarket/:id"
              component={EditMarket}
            />
            {/* <Route  component={Page404} />  */}

            <Route component={Page404} />
            <Redirect from="*" to="/404" />
          </Switch>
        </Router>
      </>
    )
  }
}
export default Routes

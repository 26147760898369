import Url from "../../services/BaseUrl"
import { _Api } from "../../services/Api"

export const ActionTypes = {
  LOAD_ITEM_REQUEST: "LOAD_ITEM_REQUEST",
  LOAD_ITEM_SUCCESS: "LOAD_ITEM_SUCCESS",
  LOAD_ITEM_FAILURE: "LOAD_ITEM_FAILURE",
}

export function loadItems(payload) {
  return function (dispatch) {
    const { userId } = payload

    // check here to see if it's already loaded

    dispatch({ type: "LOAD_INVENTORY_REQUEST", userId })

    const params = Object.entries({
      userId,
      itemStatus: 1, // Active Items
      count: 500,
    })
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&")

    _Api(
      Url.SUPER_ADMIN_ITEMS_LIST.method,
      Url.SUPER_ADMIN_ITEMS_LIST.url,
      "",
      `?${params}`
    )
      .then((response) => {
        dispatch({
          type: "LOAD_ITEM_SUCCESS",
          userId,
          payload: {
            list: response.responseData.result.itemList,
          },
        })
      })
      .catch((error) => {
        dispatch({
          type: "LOAD_ITEM_FAILURE",
          userId,
          error,
        })
      })
  }
}

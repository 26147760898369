import debugFactory from "debug"
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Accordion, Card } from "react-bootstrap"

import { _Api } from "../../services/Api"
import Url from "../../services/BaseUrl"
import Loader from "../../components/common/Loader"
import Sidebar from "../../components/sidebar"
import { PickUpHostOrders } from "healthyplaneat-common"
import siteSetting from "../../config/env/Index"

const debug = debugFactory("PickUpHostList")

export const PickUpHostList = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [markets, setMarkets] = useState([])
  // store a list of the opened indices, so we don't load them all at once
  const [marketsOpened, setMarketsOpened] = useState([])
  const apiDomain = siteSetting.api.url

  useEffect(() => {
    setIsLoading(true)
    _Api(
      Url.SUPER_ADMIN_GET_MARKET.method,
      Url.SUPER_ADMIN_GET_MARKET.url,
      "",
      ""
    )
      .then((resp) => {
        setIsLoading(false)
        if (resp.statusCode === 1) {
          setMarkets(resp.responseData.marketInfo)
        } else {
          debug("market list response not status code 1")
        }
      })
      .catch((error) => {
        setIsLoading(false)
        debug("market list response error")
      })
  }, [])

  const onMarketSelect = (marketEventKey) => {
    debug("onMarketSelect")
    debug(marketEventKey)
    if (marketsOpened.indexOf(marketEventKey) === -1) {
      setMarketsOpened(marketsOpened.concat([marketEventKey]))
    }
  }

  const handlePrint = () => {
    window.print()
  }

  return (
    <>
      <Sidebar />
      <div className="body_container">
        <div className="body_inner_wrapper">
          <div className="main_content">
            {isLoading && <Loader />}
            <div className="pick-up-host-title mb2 row">
              <div className="col-10">
                <h1 className="h3 font-main">Pick Up Host Orders</h1>
              </div>
              <div className="col-2 righted">
                <button
                  className="btn btn-info pick-up-host-print-button"
                  onClick={handlePrint}
                >
                  Print
                </button>
              </div>
            </div>
            {markets.map((market, marketIndex) => {
              const marketEventKey = marketIndex + 1
              const hasOpened = marketsOpened.indexOf(marketEventKey) !== -1
              return (
                <Accordion
                  className="pick-up-host-admin-outer-group"
                  onSelect={onMarketSelect}
                >
                  <Card
                    className="pick-up-host-admin-outer-group-card"
                    key={market._id}
                  >
                    <Accordion.Toggle
                      as={Card.Header}
                      className="pick-up-host-admin-outer-group-card-header"
                      eventKey={marketEventKey}
                      style={{
                        borderColor: market.marketBackgroundColor,
                      }}
                    >
                      <h2 className="h3 font-main pick-up-host-admin-outer-group-card-title mb0">
                        {market.marketName}
                      </h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={marketEventKey}>
                      <Card.Body>
                        {hasOpened && (
                          <PickUpHostOrders
                            apiDomain={apiDomain}
                            marketId={market._id}
                          />
                        )}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

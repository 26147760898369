import React, { Component } from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import _ from "lodash"
import Sidebar from "../../components/sidebar"
import Logo from "../../assets/img/OFFICIAL LOGO TM WITH HEALTHY PLANEAT BLOCK.png"
import * as jsPDF from "jspdf"
import Pagination from "react-js-pagination"
import "jspdf-autotable"
import * as html2canvas from "html2canvas"
import { Preview, print } from "react-html2pdf"
import { toast } from "react-toastify"
import moment from "moment-timezone"
import { _Api } from "../../services/Api"
import Url from "../../services/BaseUrl"

const Units = (type) => {
  switch (type) {
    // case 1:
    //     return 'wreath';
    //     break;
    // case 2:
    //     return 'bouquet';
    //     break;
    case 3:
      return "bunch"
      break
    // case 4:
    //     return 'lb';
    //     break;
    case 5:
      return "pint"
      break
    case 6:
      return "head"
      break

    case 7:
      return "bag "
      break
    case 8:
      return "half pint"
      break
    // case 9:
    //     return '1/2 lb bag'
    //     break;
    case 10:
      return "Pound"
      break

    case 11:
      return "Half Pound Bag"
      break

    case 12:
      return "Half Pound"
      break
    case 13:
      return "others"
      break

    default:
      return ""
      break
  }
}
const days = (type) => {
  switch (type) {
    case 0:
      return "Sun"
      break
    case 1:
      return "Mon"
      break
    case 2:
      return "Tue"
      break
    case 3:
      return "Wed"
      break
    case 4:
      return "Thur"
      break
    case 5:
      return "Fri"
      break
    case 6:
      return "Sat"
      break
  }
}

class ViewInvoice extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orderInfo: [],
      ItemList: [],
      reId: [],
      ItemId: [],
      multiValue: [],
      multiItemValue: [],
      reasonErMsg: "",
      show: false,
      farmName: "",
      showInvoice: false,
      messagePopup: 0,
      alertPopup: 0,
      emailPopup: 0,
      lat: 0,
      long: "",
      count: 10,
      totalCount: 0,
      filterHarvest: "",
      pageNo: 1,
      orderInfo1: [],
      profilePicPreview: "",
      userId: localStorage.getItem("userId"),
    }
  }

  componentDidMount() {
    // this.getUserInfo()
    this.getOrderList()
    // this.getFarmDetails()
  }

  // getFarmDetails = () => {
  //     let { userId, farmLogo, farmImage, farmVideo, farmLogoPreview, farmVideoPreview, farmName, farmStatus, infoAboutFarm, infoAboutFarmer, description } = this.state;
  //     this.setState({ errors: '' });
  //
  //     let self = this
  //     this.setState({ loader: true });
  //
  //     let params = `?userId=${userId}&farmerId=${userId}`
  //
  //     let { dispatch } = this.props;
  //     dispatch(farmActions.getFarmDetails(params)).then(data => {
  //         if (!_.isEmpty(data)) {
  //             let farmInfo = data.responseData.result
  //             self.setState({ loader: false })
  //
  //             if (data.statusCode == 1) {
  //
  //                 self.setState({
  //                     profilePicPreview: farmInfo.length > 0 ? farmInfo[0].farmLogo : '',
  //                     farmName: farmInfo.length > 0 ? farmInfo[0].farmName : '',
  //
  //                     addressLine1: farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress) ? farmInfo[0].farmAddress[0].addressLine1 : '',
  //                     addressLine2: farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress) ? farmInfo[0].farmAddress[0].addressLine2 : '',
  //                     city: farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress) ? farmInfo[0].farmAddress[0].city : '',
  //                     state: farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress) ? farmInfo[0].farmAddress[0].state : '',
  //                     postalCode: farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress) ? farmInfo[0].farmAddress[0].postalCode : '',
  //
  //                 })
  //             } else {
  //                 self.setState({ errors: data.error.responseMessage })
  //             }
  //         }
  //
  //     });
  //
  // }

  modalPrint = () => {
    let self = this

    html2canvas(document.getElementById("divIdToPrint"), {
      useCORS: true,
    }).then((canvas1) => {
      html2canvas(document.getElementById("divIdToPrint"), {
        useCORS: true,
      }).then((canvas) => {
        let w = window.innerWidth
        let h = window.innerHeight

        const imgData = canvas.toDataURL("image/png")
        const pdf = new jsPDF()
        console.log(
          canvas,
          "mlnjkhbjgvhcfgxdfs",
          canvas.width,
          canvas.height,
          window.innerWidth,
          window.innerHeight,
          imgData
        )
        pdf.addImage(imgData, "JPEG", 10, 5, 240, 190)
        pdf.save("download.pdf")
      })
    })
  }
  sum = (product) => {
    if (!_.isEmpty(product)) {
      console.log(product && product.length, "kjhygtfrdes")
      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        console.log(product[i], "c.productPrice")
        countPrice =
          (product[i].productPrice * product[i].productQuantity
            ? product[i].productQuantity
            : "") + countPrice
      }
      return countPrice
    }
  }

  sumOrder = (product) => {
    if (!_.isEmpty(product)) {
      // console.log(product,this.state.userId,"jhgfdsfghjgfd%%%%%%%%%%%%%%%%%%%%%%%%%")
      // if(product.farmUserId==this.state.userId) {

      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        console.log(
          product[i].farmUserId,
          this.state.userId,
          "kjhygtfrdes%%%%%%%%%%%%%%%%%%%%%%%%%"
        )

        console.log(product[i], "c.productPricePPPPPPPPPP")
        countPrice =
          product[i].productPrice * product[i].productQuantity + countPrice
      }

      console.log(countPrice, "countPriceaaaaaaaaaa")
      return countPrice
      // }
    }
  }
  sumOrderAndDelivery = (product) => {
    if (!_.isEmpty(product)) {
      // console.log(product,this.state.userId,"jhgfdsfghjgfd%%%%%%%%%%%%%%%%%%%%%%%%%")
      // if(product.farmUserId==this.state.userId) {

      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        console.log(
          product[i].farmUserId,
          this.state.userId,
          "kjhygtfrdes%%%%%%%%%%%%%%%%%%%%%%%%%"
        )

        console.log(product[i], "c.productPricePPPPPPPPPP")
        countPrice =
          product[i].deliveryFees +
          product[i].productPrice * product[i].productQuantity +
          countPrice
      }

      console.log(countPrice, "countPriceaaaaaaaaaa")
      return countPrice
      // }
    }
  }
  sumDelivery = (product) => {
    if (!_.isEmpty(product)) {
      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        console.log(
          product.farmUserId,
          this.state.userId,
          "kjhygtfrdes%%%%%%%%%%%%%%%%%%%%%%%%%"
        )

        console.log(product[i], "c.productPricePPPPPPPPPP")
        countPrice = product[i].deliveryFees + countPrice
      }

      console.log(countPrice, "countPriceaaaaaaaaaa")
      return countPrice
    }
  }

  // getUserInfo = () => {
  //     this.setState({ errors: '' });
  //
  //     let self = this
  //     this.setState({ redirect: false, loader: false });
  //
  //
  //     let { dispatch } = this.props;
  //     dispatch(usersActions.getUserInfo()).then(data => {
  //
  //
  //         this.setState({ loader: false })
  //         // let getData = this.props.user;
  //
  //         if (data.statusCode == 1) {
  //             let userInfo = data.responseData.userProfile
  //             localStorage.setItem('userId', userInfo._id);
  //             console.log(userInfo, "Data:::::::lkjhgfdsdfghj user")
  //
  //             this.setState({
  //                 userInfo: userInfo,
  //                 firstName: userInfo.firstName,
  //                 lastName: userInfo.lastName,
  //                 phone: userInfo.phone,
  //                 email: userInfo.email,
  //                 address: userInfo.address,
  //                 addressLine1: userInfo.address.length > 0 ? (userInfo.address[0].addressLine1 ? userInfo.address[0].addressLine1 : '') : '',
  //                 addressLine2: userInfo.address.length > 0 ? userInfo.address[0].addressLine2 ? userInfo.address[0].addressLine2 : '' : '',
  //                 state: userInfo.address.length > 0 ? userInfo.address[0].state ? userInfo.address[0].state : '' : '',
  //                 postalCode: userInfo.address.length > 0 ? userInfo.address[0].postalCode ? userInfo.address[0].postalCode : '' : '',
  //                 city: userInfo.address.length > 0 ? userInfo.address[0].city ? userInfo.address[0].city : '' : '',
  //                 addressId: userInfo.address.length > 0 ? userInfo.address[0]._id ? userInfo.address[0]._id : '' : '',
  //                 minimumOrderValue: userInfo.minimumOrderValue,
  //                 certificate: userInfo.certificateImage,
  //                 certificatePreview: userInfo.certificateImage,
  //                 insuranceDoc: userInfo.insuranceImage,
  //                 insurancePreview: userInfo.insuranceImage,
  //                 radius: userInfo.radius,
  //                 emailPopup: userInfo.notificationEmail,
  //                 messagePopup: userInfo.notificationTextMassage,
  //                 alertPopup: userInfo.notificationAppAlert,
  //                 lat: userInfo.geoLocation[0],
  //                 long: userInfo.geoLocation[1]
  //
  //
  //             })
  //         } else {
  //             this.setState({ errors: data.error.responseMessage })
  //         }
  //     });
  //
  // }
  getOrderList = () => {
    this.setState({ errors: "" })
    let { search, userId, count, filterHarvest } = this.state
    let self = this
    this.setState({ redirect: false, loader: true })
    let params = `?&userId=${userId ? userId : ""}&orderId=${
      this.props.match.params.id
    }`

    _Api(Url.VIEW_ORDER_ID.method, Url.VIEW_ORDER_ID.url, "", params).then(
      (data) => {
        this.setState({ loader: false })
        // let getData = this.props.user;

        if (data.statusCode == 1) {
          let orderInfo = data.responseData.OrderDetails[0]
          this.setState({
            ItemList: orderInfo,
          })
        } else {
          this.setState({ errors: data.error.responseMessage })
        }
      }
    )
  }

  ConvertDate = (created) => {
    var created = new Date(created)
    let data = moment(created)
      .tz("America/New_York")
      .format("dddd MMMM Do YYYY, h:mm:ss a")
    return data
  }

  OrderMpInvoice = (ItemList) => {
    console.log(ItemList, "ItemListItemList")
    let Json =
      ItemList.buyLists &&
      ItemList.buyLists.map((val, i) => (
        <tr>
          &nbsp;&nbsp;&nbsp;
          <td className="marGinOrderTwo">
            {" "}
            {val.productName} - {val.productQuantity}
          </td>
          <td>
            <span>
              ${val.productPrice} x {val.productQuantity} {val.unit}
            </span>
          </td>
          <td>
            <span>{val.address}</span>
          </td>
        </tr>
      ))

    return Json
  }

  OrderHarvest1 = (ItemList) => {
    console.log(ItemList, "ItemListItemListjhgfghgfghj")
    let Json =
      ItemList.buyLists &&
      ItemList.buyLists.map((val, i) => (
        <>
          {val.productName} <br />
        </>
      ))

    return Json
  }
  OrderHarvest2 = (ItemList) => {
    console.log(ItemList, "ItemListItemListjhgfghgfghj")
    let Json =
      ItemList.buyLists &&
      ItemList.buyLists.map((val, i) => (
        <>
          {val.productQuantity ? val.productQuantity : ""} <br />
        </>
      ))

    return Json
  }
  OrderHarvest3 = (ItemList) => {
    console.log(ItemList, "ItemListItemListjhgfghgfghj")
    let Json =
      ItemList.buyLists &&
      ItemList.buyLists.map((val, i) => (
        <>
          $ {val.productQuantity * val.productPrice}
          <br />
        </>
      ))

    return Json
  }

  render() {
    let {
      filterHarvest,
      farmName,
      reasonErMsg,
      orderInfo,
      count,
      pageNo,
      totalCount,
      dataCount,
      ItemList,
      profilePicPreview,
    } = this.state
    return (
      <>
        <Sidebar />
        <div className="body_container">
          <div className="body_inner_wrapper">
            {this.state.loader ? (
              <div className="loader_wrapper">
                <div class="spinner-border text-info" role="status"></div>
              </div>
            ) : (
              <>
                <div className="invoicewrapper">
                  <Preview id={"jsx-template"}>
                    <header className="header_content">
                      <p>Invoice</p>
                      <div className="d-flex text-nowrap header_btn">
                        <div className="print_button orderabs noabs">
                          <Button onClick={this.modalPrint} variant="light">
                            <i className="fas fa-print"></i> Print{" "}
                          </Button>
                        </div>
                      </div>
                    </header>
                    <div id={"divIdToPrint"} className="pdfwrapper">
                      <Row className="p-3">
                        <Col md="12">
                          {console.log(
                            ItemList,
                            "ItemListItemList",
                            profilePicPreview
                          )}

                          <p className="text-center ">
                            {profilePicPreview ? (
                              <img
                                src={profilePicPreview}
                                height="160px"
                                className="cm-mr"
                                width="160px"
                              />
                            ) : (
                              ""
                            )}
                          </p>

                          {ItemList.billingInfo &&
                          ItemList.billingInfo.firstName ? (
                            <p>
                              Customer:{" "}
                              <span>
                                {ItemList.billingInfo.firstName}{" "}
                                {ItemList.billingInfo.lastName}
                              </span>
                            </p>
                          ) : (
                            ""
                          )}

                          {ItemList.billingInfo &&
                          ItemList.billingInfo.phoneNumber ? (
                            <p>
                              Phone:{" "}
                              <span>{ItemList.billingInfo.phoneNumber} </span>
                            </p>
                          ) : (
                            ""
                          )}

                          {ItemList.billingInfo && ItemList.billingInfo ? (
                            <p>
                              Billing Address:{" "}
                              <span>
                                {ItemList.billingInfo.addressLine1}{" "}
                                {ItemList.billingInfo.addressLine2}{" "}
                                {ItemList.billingInfo.city}{" "}
                                {ItemList.billingInfo.state}{" "}
                                {ItemList.billingInfo.country}{" "}
                                {ItemList.billingInfo.postalCode}
                              </span>
                            </p>
                          ) : (
                            ""
                          )}

                          {
                            <p>
                              Placed On:
                              <span>{this.ConvertDate(ItemList.created)}</span>
                            </p>
                          }
                        </Col>
                      </Row>
                      <div className="order_items p-3">
                        <div className="table-responsive">
                          <table className="text-dark tablecenter">
                            <thead>
                              <th className="">Orders:</th>
                            </thead>
                            <tbody>{this.OrderMpInvoice(ItemList)}</tbody>
                          </table>
                        </div>
                      </div>
                      <div>
                        <br />
                        <br />

                        <table
                          cellPadding="8"
                          width="600"
                          className="tablecenter "
                        >
                          <tbody>
                            <tr>
                              <td>Order Total:</td>
                              <td></td>
                              <td>
                                <span>
                                  $
                                  {this.sumOrder(ItemList && ItemList.buyLists)}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>Delivery Charges:</td>
                              <td></td>
                              <td>
                                <span>
                                  $
                                  {this.sumDelivery(
                                    ItemList && ItemList.buyLists
                                  )}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>Total:</td>
                              <td></td>
                              <td>
                                <span>
                                  {" "}
                                  $
                                  {this.sumOrderAndDelivery(
                                    ItemList && ItemList.buyLists
                                  )}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <br />
                        <br />
                        <p className="text-center">
                          <img
                            src={Logo}
                            height="130px"
                            className="cm-mr"
                            width="160px"
                          />
                        </p>
                      </div>
                    </div>

                    <br />
                    <br />
                  </Preview>
                </div>
                <div className="footer_btn_wrapper"></div>
              </>
            )}{" "}
          </div>
        </div>
      </>
    )
  }
}

export default ViewInvoice
// export default Settings

import React, { Component } from "react"
import { DistributionForm } from "healthyplaneat-common"

import { Link } from "react-router-dom"
import Sidebar from "../../../components/sidebar"
import Url from "../../../services/BaseUrl"
import { isEmpty } from "lodash"
import { _Api } from "../../../services/Api"

import siteSetting from "../../../config/env/Index"

class EditDistributionDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      userId: this.props ? this.props.match.params.id : "",
      distribution: undefined,
      apiDomain: siteSetting.api.url,
    }
  }

  componentDidMount() {
    this.getDistibutionDetailsById()
  }

  redirectToDistributionList = () => {
    const { userId } = this.state
    this.props.history.push(
      `/farmermanagement/distributionmanagement/${userId}`
    )
  }

  handleSubmit = ({ distribution }) => {
    this.setState({ loader: true })
    _Api(
      Url.Edit_DISTRIBUTION.method,
      Url.Edit_DISTRIBUTION.url,
      distribution
    ).then((data) => {
      this.setState({ loader: false })
      if (data.statusCode === 1) {
        this.redirectToDistributionList()
      } else {
        console.log(data.error.responseMessage)
      }
    })
  }

  getDistibutionDetailsById = () => {
    let self = this
    this.setState({ loader: true })

    _Api(
      Url.GET_DISTRIBUTION_ID.method,
      Url.GET_DISTRIBUTION_ID.url,
      "",
      `?userId=${this.props.match.params.id}&distributionId=${this.props.match.params.dId}`
    ).then((data) => {
      this.setState({ loader: false })
      if (!isEmpty(data)) {
        if (data.statusCode === 1) {
          const distribution = data.responseData.result

          this.setState({ distribution })
        } else {
          this.redirectToDistributionList()
        }
      }
    })
  }

  render() {
    const { distribution, userId, apiDomain } = this.state

    return (
      <>
        <Sidebar />
        <div>
          <div className="header_wrapper">
            <div className="header_logo">
              <img src={require("../../../assets/img/OFFICIAL LOGO.png")} />
            </div>
            <div className="header_search">
              <div className="form-group has-search"></div>
            </div>
            <div className="bell_icon">
              <i className="fas fa-bell"></i>
            </div>
          </div>
          <div className="body_container">
            <div className="body_inner_wrapper">
              <header className="header_content">
                <p>
                  <Link
                    to={`/farmermanagement/distributionmanagement/${userId}`}
                  >
                    <span className="mr-3 d-inline-block text_light text_12">
                      <i class="fas fa-caret-left text_dark mr-2"></i> Back{" "}
                    </span>
                  </Link>
                </p>
              </header>
              <header className="header_content">
                <div className="d-flex text-nowrap header_btn">
                  Edit Distribution
                </div>
              </header>

              <div className="page_heading d-flex">
                <Link
                  to={`/farmermanagement/editfrmermanagement/${userId}`}
                  className="mr-3"
                >
                  {" "}
                  <h6>Edit Farmer</h6>
                </Link>
                <Link
                  to={`/farmermanagement/inventorymanagement/${userId}`}
                  className=" mr-3"
                >
                  <h6>Inventory</h6>
                </Link>
                <Link to={`/farmermanagement/farm/${userId}`} className=" mr-3">
                  <h6>Farm</h6>
                </Link>
                <h6 className=" activeheading mr-3 mr-3"> Distribution </h6>
              </div>

              <div className="main_content">
                {distribution && (
                  <DistributionForm
                    distribution={distribution}
                    userId={userId}
                    apiDomain={apiDomain}
                    handleSubmit={({ distribution }) => {
                      this.handleSubmit({ distribution })
                    }}
                    handleClose={() => {
                      this.redirectToDistributionList()
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default EditDistributionDetails

import "jspdf-autotable"

import { Button, Col, Modal, Row } from "react-bootstrap"
import React, { Component } from "react"

import Loader from "../../components/common/Loader"
import Pagination from "react-js-pagination"
import Sidebar from "../../components/sidebar"
import Url from "../../services/BaseUrl"
import _ from "lodash"
import { _Api } from "../../services/Api"
import moment from "moment-timezone"
import { notify } from "../../components/common/Toaster"

class Order extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ItemList: [],
      filterHarvest: "",
      orderInfo: [],
      show: false,
      messagePopup: 0,
      alertPopup: 0,
      emailPopup: 0,
      lat: 0,
      long: 0,
      count: 10,
      totalCount: 0,

      pageNo: 1,
      orderInfo1: [],
      sortField: "",
      sortType: -1,

      reId: [],
      excelFile: "",
      ItemId: [],
      multiValue: [],
      multiItemValue: [],
      reasonErMsg: "",
      farmName: "",
      showInvoice: false,
      profilePicPreview: "",
    }
  }

  componentDidMount() {
    this.getOrderList()
  }

  sumOrder = (product) => {
    if (!_.isEmpty(product)) {
      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        countPrice = product[i].totalPrice
      }

      let price = countPrice.toFixed(2)
      return price
    }
  }
  sumOrderAndDelivery = (productlist) => {
    if (!_.isEmpty(productlist)) {
      let countPrice = 0
      for (let i = 0; i < productlist.length; i++) {
        let num = productlist[i].totalPayPrice
        countPrice = num.toFixed(2)
      }

      return countPrice
    }
  }
  sumDelivery = (product) => {
    if (!_.isEmpty(product)) {
      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        countPrice = product[i].deliveryFees
      }

      return countPrice
    }
  }

  getCourierTypeLocation = (ItemList) => {

    if (!ItemList.buyList || !ItemList.buyList.length) {
      return null
    }

    const { buyList } = ItemList

    const { courierType, address, day } = buyList[0]

    const location = courierType === "Delivery" ? `${address}, ${day}` : address

    return (
      <>
        <p>
          {courierType} Location: <span>{location}</span>
        </p>
      </>
    )
  }

  ChangeHarvestStatus = (id, harvestStatus) => {
    let req = {
      orderId: id,
      harvestStatus,
    }
    this.setState({ loader: true })
    _Api(Url.UPDATE_HARVEST_STATUS.method, Url.UPDATE_HARVEST_STATUS.url, req)
      .then((resp) => {
        notify("success", resp.responseData.message)
        this.setState({ visible: false, show: false })
        this.getOrderList()
      })
      .catch((err) => {
        this.setState({ loader: false })

        notify("error", err.data?.error?.responseMessage)
      })
  }

  handleSort = (sortField) =>
    this.setState(
      {
        loader: true,
        sortField,
        sortType: this.state.sortType === 1 ? -1 : 1,
      },
      () => this.getOrderList()
    )

  handleFilter = (ele) =>
    this.setState(
      {
        loader: true,
        pageNo: 1,
        filterHarvest: ele,
        count: 10,
      },
      () => this.getOrderList()
    )

  handlePageChange = (pageNo) => {
    var self = this
    this.setState({ loader: true, pageNo: pageNo, count: 10 }, () => {
      self.getOrderList()
    })
  }
  getOrderList = () => {
    this.setState({ errors: "" })
    let { search, userId, count, pageNo, sortField, sortType, filterHarvest } =
      this.state
    let self = this
    this.setState({ redirect: false, loader: true })
    let params = `?&search=${search ? search : ""}&userId=${
      userId ? userId : ""
    }&count=${count}&pageNo=${
      pageNo - 1
    }&sortType=${sortType}&sortField=${sortField}&filterHarvest=${filterHarvest}`

    _Api(Url.GET_ORDER.method, Url.GET_ORDER.url, "", params)
      .then((data) => {
        this.setState({ loader: false })

        if (data.statusCode === 1) {
          let orderInfo = data.responseData.order.order

          console.log({ orderInfo })
          this.setState({
            orderInfo: orderInfo,
            totalCount: data.responseData.order.totalOrderCount,
            dataCount: data.responseData.order.getOrderCount,
          })
        } else {
          this.setState({ errors: data.error.responseMessage })
        }
      })
      .catch((err) => {
        this.setState({ loader: false })

        notify("error", err.data?.error?.responseMessage)
      })
  }

  handleClose = () => {
    this.setState({ show: false })
  }
  handleShow = (item) => {
    console.log({ item })
    this.setState({ show: true, ItemList: item })
  }

  ConvertDate = (created) => {
    var created = new Date(created)
    let data = moment(created)
      .tz("America/New_York")
      .format("dddd MMMM Do YYYY, h:mm:ss a")
    return data
  }
  //changes farmer done
  OrderBuyMp = (ItemList, itemColl) => {
    let Json =
      !_.isEmpty(ItemList) &&
      ItemList.map((val, i) => (
        <tr>
          <td>
            {val.productQuantity} {val.productName}
          </td>
          <td>{val.variety ? val.variety : ""}</td>

          <td>
            <span>
              ${val.productPrice} *{" "}
              {val.productQuantity ? val.productQuantity : ""} {val.unit}
            </span>
          </td>
          <td>
            {val.image ? (
              <img src={val.image} height="40px" width="40px" />
            ) : (
              ""
            )}
          </td>
          <td>
            <span className="inlinetext-align">
              <span className="cm-aligntext">
                {val.itemStatus === 1
                  ? "Harvested"
                  : val.itemStatus === 2
                  ? "Fulfilled"
                  : val.itemStatus === 3
                  ? "Out of Stock"
                  : "Pending"}
              </span>
            </span>
          </td>
        </tr>
      ))

    return Json
  }
  OrderPick = (product) => {
    if (!_.isEmpty(product)) {
      let cc = ""
      for (let i = 0; i < product.buyList.length; i++) {
        if (product.buyList[i].farmUserId === this.state.userId) {
          cc = product.buyList[i].courierType + ":" + product.buyList[i].address
        }
      }
      return cc
    }
  }

  OrderMp = (ItemList) => {
    if (_.isEmpty(ItemList.buyList)) {
      return null;
    }
  
    const Json = ItemList.buyList.map((val, i) => (
      <React.Fragment key={i}>
        {this.OrderBuyMp(val.productList, ItemList)}
        <p>
          Farm Name: {val.farmName}, 
        </p>
        <p>
         {val.courierType} Location: {val.address}
        </p>
        <p>Delivery Fee: ${val.deliveryFees}</p>
        <p>Total Price: ${val.totalPrice}</p>
        {val.trackingInfo && val.trackingInfo.length > 0 ? (
          val.trackingInfo.map((box, index) => (
            <div className="d-flex align-items-center mb-2" key={index}>
              <p className="mb-0 mr-2">USPS Package {index + 1}:</p>
              <a
                href={`https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${box.trackingNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="light" className="usps_order_button">
                  Track USPS Package
                </Button>
              </a>
            </div>
          ))
        ) : null}
      </React.Fragment>
    ));
  
    return Json;
  };
  
  getOrderExcel = () => {
    let self = this

    let { userId } = this.state
    let params = `?&userId=${userId ? userId : ""}`
    _Api(Url.GET_ORDER_EXCEL.method, Url.GET_ORDER_EXCEL.url, "", params).then(
      (data) => {
        let inventoryData = data

        self.setState({ loader: false })

        if (inventoryData.statusCode == 1) {
          window.open(data.responseData.file)
        } else {
          this.setState({ errors: inventoryData.error.responseMessage })
        }
      }
    )
  }
  getAllOrderExcel = () => {
    let self = this

    let { userId } = this.state
    let params = `?&userId=${userId ? userId : ""}`

    _Api(
      Url.GET_ALL_ORDER_EXCEL.method,
      Url.GET_ALL_ORDER_EXCEL.url,
      "",
      params
    ).then((data) => {
      let inventoryData = data

      self.setState({ loader: false })

      if (inventoryData.statusCode === 1) {
        window.open(data.responseData.file)
      } else {
        this.setState({ errors: inventoryData.error.responseMessage })
      }
    })
  }

  handleValidate = () => {
    let validate = true
    if (
      this.state.multiValue === "" ||
      this.state.multiValue.length < 0 ||
      this.state.multiValue.length === 0 ||
      this.state.multiValue === undefined
    ) {
      validate = false
      this.setState({
        reasonErMsg: "Please select check box.",
      })
    } else {
      this.setState({
        reasonErMsg: "",
      })
    }

    return validate
  }

  handleMaltiSelct = (e, val) => {
    let { orderInfo, multiValue } = this.state
    let self = this
    this.setState({ reasonErMsg: "" })
    multiValue =
      typeof this.state.multiValue === "object" ? this.state.multiValue : []
    let reIdval = []

    if (this.state.reId.includes(val)) {
      for (var i = 0; i < this.state.reId.length; i++) {
        if (this.state.reId[i] === val) {
          this.state.reId.splice(i, 1)
        }
      }
    } else {
      this.state.reId.push(val)
    }

    this.state.orderInfo.map(function (value, index) {
      if (value._id === val) {
        orderInfo[index].status = orderInfo[index].status === 2 ? 3 : 2

        if (orderInfo[index].status === 2) {
          multiValue.push(value._id)
        } else if (orderInfo[index].status === 3) {
          multiValue.splice(value._id)
        }
      }
    })
    this.setState({ multiValue })
  }
  handleAction = (e) => {
    let unique = [...new Set(this.state.multiValue)]
    let params = {
      orderId: unique.toString(),
      harvestStatus: e,
    }
    if (this.handleValidate()) {
      let self = this
      self.setState({ loader: true })
      self.setState({ loader: false, show: false })

      _Api(
        Url.CHANGE_ORDER_STATUS.method,
        Url.CHANGE_ORDER_STATUS.url,
        params
      ).then((data) => {
        if (data.statusCode === 1) {
          self.setState({
            loader: false,
            reId: [],
            multiValue: "",
            reasonErMsg: "",
          })

          notify("success", data.responseData.message)
          this.getOrderList()
        } else {
          self.setState({ loader: false })
          this.setState({
            reId: [],
            multiValue: "",
            reasonErMsg: "",
            errors: data.error.responseMessage,
          })
        }
      })
    } else {
    }
  }

  render() {
    let { orderInfo, count, pageNo, ItemList } = this.state
    return (
      <>
        <Sidebar />
        <div className="body_container">
          <div className="body_inner_wrapper">
            {this.state.loader ? (
              <Loader />
            ) : (
              <>
                {" "}
                <header className="header_content">
                  <p>Orders</p>
                  <div className="d-flex text-nowrap header_btn">
                    <Button
                      variant="primary"
                      disabled={_.isEmpty(this.state.orderInfo) ? true : false}
                      onClick={() => this.handleAction(1)}
                    >
                      {" "}
                      Add to Harvest List
                    </Button>
                    <Button
                      variant="info"
                      disabled={_.isEmpty(this.state.orderInfo) ? true : false}
                      onClick={() => this.handleAction(2)}
                    >
                      {" "}
                      Order Fulfilled
                    </Button>

                    <Button variant="secondary" onClick={this.getOrderExcel}>
                      {" "}
                      View Harvest List
                    </Button>
                    <Button
                      variant="warning"
                      onClick={() => this.handleFilter(2)}
                    >
                      {" "}
                      View Order Fulfilled
                    </Button>

                    <Button
                      variant="success"
                      onClick={() => this.handleFilter("")}
                    >
                      {" "}
                      All Orders
                    </Button>
                    <Button onClick={this.getAllOrderExcel} variant="info">
                      <i class="fas fa-upload"></i> Export as Excel
                    </Button>
                  </div>
                </header>
                <div className="main_content">
                  <div class="table-responsive">
                    <table class="table cm_bottom_space">
                      <thead>
                        <tr class="broder_top_0">
                          <th>Change Status</th>
                          <th scope="col" className="ws-nowrap">
                            Order
                          </th>
                          <th
                            scope="col"
                            onClick={() => this.handleSort("created")}
                          >
                            Placed On
                            <i className="fas fa-sort text_dark ml-2 d-inline-block"></i>
                          </th>
                          <th
                            scope="col"
                            className="ws-nowrap"
                            onClick={() => this.handleSort("firstName")}
                          >
                            Customer
                            <i className="fas fa-sort text_dark ml-2 d-inline-block"></i>
                          </th>
                          <th scope="col" className="ws-nowrap">
                            {" "}
                            Total
                          </th>
                          <th scope="col" className="ws-nowrap">
                            Status
                          </th>
                          <th scope="col" className="ws-nowrap">
                            Pick Up/Delivery Location
                          </th>

                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!_.isEmpty(orderInfo) &&
                          orderInfo.map((item, i) => (
                            <tr key={item._id}>
                              <td>
                                <div
                                  className={`cm_radio_3_wrapper`}
                                  style={{ background: "#fff" }}
                                >
                                  <input
                                    type="checkbox"
                                    indeterminate
                                    value={item.status}
                                    onChange={(e) =>
                                      this.handleMaltiSelct(
                                        e,
                                        item._id && item._id
                                      )
                                    }
                                  />
                                  <span className="cm_radio_3"></span>
                                </div>
                              </td>
                              <td>{count * (pageNo - 1) + (i + 1)}</td>
                              <td>{this.ConvertDate(item.created)}</td>
                              <td>
                                {item.billingInfo?.firstName +
                                  " " +
                                  item.billingInfo?.lastName}
                              </td>
                              <td>${item.amount}</td>
                              <td>
                                {item.status === "1" ? "Paid" : ""}{" "}
                                {item.status === "0" ? "Pending" : ""}{" "}
                                {item.status === "3" ? "cancel" : ""}
                              </td>
                              <td>
                                {this.getCourierTypeLocation(item)}
                              </td>
                              <td>
                                {" "}
                                {item.harvestStatus == "0"
                                  ? "Pending"
                                  : ""}{" "}
                                {item.harvestStatus == 1 ? " Harvested" : ""}{" "}
                                {item.harvestStatus == 2 ? "Fulfilled" : ""}
                              </td>
                              <td>
                                <div className="action_wrapper ws-nowrap">
                                  <span>
                                    <i
                                      className="fas fa-eye"
                                      onClick={() => this.handleShow(item)}
                                    ></i>
                                  </span>
                                  {item.harvestStatus === 1 ? (
                                    <span>
                                      <Button
                                        onClick={() =>
                                          this.ChangeHarvestStatus(item._id, 2)
                                        }
                                        variant="warning"
                                      >
                                        {" "}
                                        Order Fulfilled
                                      </Button>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item.harvestStatus === 2 ? (
                                    <span>
                                      <Button
                                        className="btn btn-secondary"
                                        onClick={() =>
                                          this.ChangeHarvestStatus(item._id, 1)
                                        }
                                      >
                                        Add to harvest
                                      </Button>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item.harvestStatus === 0 ? (
                                    <>
                                      <span>
                                        <button
                                          className="btn btn-secondary"
                                          onClick={() =>
                                            this.ChangeHarvestStatus(
                                              item._id,
                                              1
                                            )
                                          }
                                        >
                                          Add to harvest{" "}
                                        </button>
                                      </span>
                                      <Button
                                        onClick={() =>
                                          this.ChangeHarvestStatus(item._id, 2)
                                        }
                                        variant="warning"
                                      >
                                        {" "}
                                        Order Fulfilled
                                      </Button>
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {orderInfo && orderInfo.length ? (
                      <div className="d-flex justify-content-end cm-pagination">
                        <Pagination
                          itemClassPrev={"prevClass"}
                          itemClassNext={"nextClass"}
                          activePage={this.state.pageNo}
                          itemsCountPerPage={this.state.count}
                          totalItemsCount={this.state.totalCount}
                          pageRangeDisplayed={3}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {_.isEmpty(this.state.orderInfo) ? (
                      <h3 className="text-center ">No data found</h3>
                    ) : (
                      ""
                    )}
                  </div>
                </div>{" "}
              </>
            )}
          </div>
          <Modal
            show={this.state.show}
            onHide={this.handleClose}
            className="cm_order_view order-detailsmodal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <p>Order Details</p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md="12">
                  {ItemList.billingInfo && ItemList.billingInfo ? (
                    <p>
                      Name:{" "}
                      <span>
                        {ItemList.billingInfo.firstName}{" "}
                        {ItemList.billingInfo.lastName}
                      </span>
                    </p>
                  ) : (
                    ""
                  )}

                  {ItemList.billingInfo && ItemList.billingInfo.phoneNumber ? (
                    <p>
                      Phone: <span>{ItemList.billingInfo.phoneNumber} </span>
                    </p>
                  ) : (
                    ""
                  )}
                  {ItemList && ItemList.harvestStatus ? (
                    <p>
                      Status:{" "}
                      <span>
                        {ItemList.harvestStatus === "0" ? "Pending" : ""}{" "}
                        {ItemList.harvestStatus === 1 ? "Harvested" : ""}{" "}
                        {ItemList.harvestStatus === 2 ? "Fulfilled" : ""}
                        {ItemList.harvestStatus === 3 ? "Out of Stock" : ""}
                      </span>
                    </p>
                  ) : (
                    ""
                  )}

                  {ItemList.billingInfo && ItemList.billingInfo ? (
                    <p>
                      Billing Address:{" "}
                      <span>
                        {ItemList.billingInfo.addressLine1}{" "}
                        {ItemList.billingInfo.addressLine2}{" "}
                        {ItemList.billingInfo.city} {ItemList.billingInfo.state}{" "}
                        {ItemList.billingInfo.country}{" "}
                        {ItemList.billingInfo.postalCode}
                      </span>
                    </p>
                  ) : (
                    ""
                  )}

                  <p>{this.OrderPick(ItemList)}</p>
                </Col>
              </Row>
              <div className="order_items">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <th className="ws-nowrap">Product Name</th>
                      <th className="ws-nowrap">Variety</th>
                      <th className="ws-nowrap">Product Price</th>
                      <th className="ws-nowrap">Product Image</th>
                      <th className="ws-nowrap">Status</th>
                    </thead>
                    <tbody>{this.OrderMp(ItemList)}</tbody>
                  </table>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="footer_total">
                <p>
                  Order Total:
                  <span>${ItemList.amount}</span>
                </p>

                <p>
                  Delivery Charges:
                  <span>${ItemList.deliveryFees}</span>
                </p>

                <p>
                  Total:
                  <span> ${ItemList.totalPrice}</span>
                </p>
              </div>
              <div className="footer_btn_wrapper"></div>
              <div className="print_button orderabs"></div>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    )
  }
}

export default Order

/**
 * @about auth api file that contains login function
 * that get data from servers
 */

import axios from "axios"
import { history } from "../Routes"
import siteSetting from "../config/env/Index"

const url = siteSetting.api.url

const authKey =
  "Basic aGVhbHRoeVBsYW5ldF9hZG1pbl92WnBHaDQ6YWRtaW5AaGVhbHRoeVBsYW5ldF9GVGtWcFE="

//common for all api
export const _Api = (
  methodType,
  endPoint,
  request = "",
  query = "",
  { headers = {} } = {}
) => {
  try {
    return new Promise((resolve, reject) => {
      let Cmp_Url = `${url}${endPoint}`

      axios({
        method: methodType,
        url: `${Cmp_Url}${query}`,
        data: request,
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
          ...headers,
        },
      })
        .then((resp) => {
          if (resp.data.statusCode === 1) resolve(resp.data)
          else {
            reject(resp)

            if (resp.data.error && resp.data.error.errorCode === 2) {
              localStorage.clear()
              history.push("/")
            }
          }
        })
        .catch((err) => {
          if (err.error && err.error.errorCode === 2) {
            localStorage.clear()
            history.push("/")
          }
          reject(err)
        })
    })
  } catch (err) {}
}

import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap"
import React, { Component } from "react"

import { Empty } from "antd"
import FileViewer from "react-file-viewer"
import { Link } from "react-router-dom"
import Loader from "../../components/common/Loader"
import Pagination from "react-js-pagination"
import Sidebar from "../../components/sidebar"
import Url from "../../services/BaseUrl"
import { _Api } from "../../services/Api"
import { notify } from "../../components/common/Toaster"

const ViewDoc = (path) => {
  console.log(path, "kjhgfdsdfghjkjhgfhn")
  let extension
  if (path) {
    extension = path.substr(path.length - 3, path.length).toUpperCase()
  }

  switch (extension) {
    case "PDF":
      console.log("jkbhjvghcfgxdsxgfcghvhjbjn", path)
      return (
        <FileViewer
          fileType={"pdf"}
          filePath={path}
          // errorComponent={CustomErrorComponent}
          // onError={this.onError}
        />
      )

      break
    case "DOC":
      return (
        <div className="item_iframe">
          <iframe
            className={"doc"}
            width="100%"
            height="120"
            frameBorder="0"
            src={`https://docs.google.com/gview?url=${path}&embedded=true`}
          ></iframe>
        </div>
      )

    case "OCX":
      return <FileViewer fileType={"docx"} filePath={path} />

      break
    default:
      return (
        <div
          className="imgas_bg"
          style={{ backgroundImage: `url(${path})` }}
        ></div>
      )
  }
  return "lk"
}

class WholeSaleList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      farmerDetails: "",
      search: "",
      totalCount: 0,
      sortType: "",
      sortField: "",
      pageNo: 1,
      count: 10,
      loading: false,
      visible: false,
      isLoader: false,
      status: 1,
      logout: false,
      ItemListView: {},
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      profileImage: "",
      dataCount: "",
      consumerType: "",
      certificateImage: "",
    }
  }

  componentDidMount() {
    this.getwholeSalerListDetails()
  }

  handlePageChange = (pageNo) =>
    this.setState(
      {
        loader: true,
        pageNo,
        count: 10,
      },
      () => this.getwholeSalerListDetails()
    )

  handleSearch = (e) => {
    this.setState({ search: e.target.value })

    if (e.target.value.length > 2) {
      this.setState({ search: e.target.value }, () =>
        this.getwholeSalerListDetails()
      )
    }
    if (e.target.value === "") {
      this.setState({ search: e.target.value }, () =>
        this.getwholeSalerListDetails()
      )
    }
  }
  handleSort = (sortField) =>
    this.setState(
      {
        loader: true,
        sortField,
        sortType: this.state.sortType === 1 ? -1 : 1,
      },
      () => this.getwholeSalerListDetails()
    )

  handleFilter = (consumerType) => {
    console.log(consumerType, "consumerTypeconsumerTypeconsumerType")

    this.setState(
      {
        loader: true,
        consumerType: consumerType,
      },
      () => this.getwholeSalerListDetails()
    )
  }
  handleUserStatus = (e) =>
    this.setState(
      {
        loader: true,
        userStatus: this.state.userStatus === 1 ? 0 : 1,
      },
      () => this.getwholeSalerListDetails()
    )

  getwholeSalerListDetails = () => {
    let self = this
    let {
      search,
      count,
      pageNo,
      totalCount,
      sortField,
      sortType,
      consumerType,
    } = this.state
    this.setState({ isLoader: true })
    _Api(
      Url.GET_WHOLESALER.method,
      Url.GET_WHOLESALER.url,
      "",
      `?search=${search}&count=${count}&pageNo=${
        pageNo - 1
      }&sortType=${sortType}&sortField=${sortField}&filterType=${consumerType}`
    )
      .then((data) => {
        self.setState({
          isLoader: false,
        })
        if (data.statusCode === 1) {
          // notify('success', data.responseData.message)
          self.setState({
            wholeSalerList: data.responseData.userProfile.WholesellerList,
            totalCount: data.responseData.userProfile.totalAllWholeseller,
            dataCount: data.responseData.userProfile.totalWholesellerResult,

            isLoader: false,
          })
        } else {
        }
      })
      .catch((err) => {
        notify("error", err.error.data.responseMessage)
        self.setState({
          loader: false,
          isloader: false,
          errors: err.data.error.responseMessage,
        })
        this.setState({ isloader: false })
      })
  }

  // Status of user(0=active , 1=inactive , 2=delete, 3=verifyUser, 4=unVerifyUser)
  handleOnChangeStatus = (id, status) => {
    let self = this
    let req = {
      userId: id,
      status,
    }
    _Api(
      Url.SUPER_ADMIN_CHANGE_STATUS.method,
      Url.SUPER_ADMIN_CHANGE_STATUS.url,
      req
    )
      .then((resp) => {
        if (resp.statusCode === 1) {
          notify("success", resp.responseData.message)

          self.setState({ visible: false, filterType: "", status: "" }, () => {
            self.getwholeSalerListDetails()
          })
        } else {
        }
      })
      .catch((err) => {
        notify("error", err.data.error.responseMessage)
        self.setState({
          loader: false,
          isloader: false,
          errors: err.data.error.responseMessage,
        })
      })
  }

  //Show modal
  showModal = (id) => {
    this.setState({
      visible: true,
      selectedId: id,
    })
  }
  handleCancel = () => {
    this.setState({ visible: false })
  }
  handleClose = () => {
    this.setState({ viewWholeSaler: false, visible: false })
  }
  handleAciveInactiveStatus = (e, id) => {
    let self = this
    console.log("stsus", e.target, id)
    let req = {
      userId: id,
      status: e.target.checked ? 0 : 1,
    }
    this.setState({ isLoader: false })
    _Api(
      Url.SUPER_ADMIN_CHANGE_STATUS.method,
      Url.SUPER_ADMIN_CHANGE_STATUS.url,
      req
    )
      .then((resp) => {
        this.setState({ isLoader: false })
        if (resp.statusCode === 1) {
          notify("success", resp.responseData.message)
          this.setState({ visible: false })
          this.getwholeSalerListDetails()
        } else {
        }
      })
      .catch((err) => {
        notify("error", err.data.error.responseMessage)
        self.setState({
          loader: false,
          isloader: false,
          errors: err.data.error.responseMessage,
        })
      })
  }

  handleInvoiceStatus = (e, id) => {
    let self = this
    console.log("invoice status", e.target, id, e.target.checked)
    let req = {
      userId: id,
      invoiceStatus: e.target.checked ? 0 : 1,
    }
    this.setState({ isLoader: false })
    _Api(
      Url.SUPER_ADMIN_CHANGE_INVOICE_STATUS.method,
      Url.SUPER_ADMIN_CHANGE_INVOICE_STATUS.url,
      req
    )
      .then((resp) => {
        this.setState({ isLoader: false })
        if (resp.statusCode === 1) {
          notify("success", resp.responseData.message)
          this.setState({ visible: false })
          this.getwholeSalerListDetails()
        } else {
        }
      })
      .catch((err) => {
        notify("error", err.data.error.responseMessage)
        self.setState({
          loader: false,
          isloader: false,
          errors: err.data.error.responseMessage,
        })
      })
  }

  //admin verify User
  handleVerify = (e, id) => {
    console.log("stsus", e.target, id)
    let self = this
    let req = {
      userId: id,
      status: e.target.checked ? 3 : 4,
    }
    _Api(
      Url.SUPER_ADMIN_CHANGE_STATUS.method,
      Url.SUPER_ADMIN_CHANGE_STATUS.url,
      req
    )
      .then((resp) => {
        console.log("delete", resp.responseData.message)
        notify("success", resp.responseData.message)
        this.setState({ visible: false })
        this.getwholeSalerListDetails()
      })
      .catch((err) => {
        notify("error", err.data.error.responseMessage)
        self.setState({
          loader: false,
          isloader: false,
          errors: err.data.error.responseMessage,
        })
      })
  }

  openPopup = () => {
    this.setState({ logout: true })
  }

  openViewPopup = (e, item) => {
    console.log(item, "kjhghjjhghjh")

    this.setState({
      viewWholeSaler: true,
      firstName: item.firstName,
      lastName: item.lastName,
      email: item.email,
      phone: item.phone,
      address: item.address,
      profileImage: item.profileImage,
      consumerType: item.consumerType,
      certificateImage: item.certificateImage,
    })
  }

  isInvoiceStatusActive = (status) => {
    if (status === 0) {
      return true
    } else {
      return false
    }
  }

  render() {
    let {
      certificateImage,
      consumerType,
      firstName,
      lastName,
      email,
      phone,
      address,
      profileImage,
      wholeSalerList,
      viewWholeSaler,
      pageNo,
      count,
      search,
      totalCount,
      sortField,
      sortType,
      visible,
      loading,
      status,
      isLoader,
      logout,
    } = this.state

    return (
      <>
        <Sidebar />
        <div className="body_container">
          <div className="body_inner_wrapper">
            {this.state.isLoader ? (
              <Loader />
            ) : (
              <>
                <header className="header_content">
                  {/*{isLoader && <Loader/>}*/}
                  <p>Wholesale Management</p>

                  <div className="header_search ">
                    <div className="form-group has-search">
                      <span className="fa fa-search form-control-feedback"></span>
                      <Form.Control
                        type="text"
                        placeholder="Search..."
                        maxLength={30}
                        name="search"
                        value={search}
                        onChange={this.handleSearch}
                      />
                    </div>
                  </div>

                  <div className="d-flex text-nowrap header_btn">
                    <Link to="/addwholesalebuyer" className="text-light">
                      <Button variant="warning">
                        <i class="fas fa-plus-circle"></i>
                        Add Wholesale Buyer
                      </Button>
                    </Link>
                  </div>
                </header>
                <div className="main_content">
                  <div class="table-responsive">
                    <table class="table cm_bottom_space">
                      <thead>
                        <tr class="broder_top_0">
                          <th scope="col">S.No.</th>
                          <th
                            scope="col"
                            className="ws-nowrap"
                            onClick={() => this.handleSort("firstName")}
                          >
                            Wholesaler Name
                            <i class="fas fa-sort text_dark ml-2 d-inline-block"></i>
                          </th>

                          {/*<th scope="col" className="ws-nowrap">Total Orders</th>*/}
                          <th>
                            <div className="tableheading-category">
                              <Dropdown>
                                <Dropdown.Toggle variant="success">
                                  Category
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => this.handleFilter(1)}
                                  >
                                    {" "}
                                    Restaurant
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => this.handleFilter(2)}
                                  >
                                    {" "}
                                    School
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => this.handleFilter(3)}
                                  >
                                    University
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => this.handleFilter(4)}
                                  >
                                    Other
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </th>
                          {/*<th scope="col" width="100">Contact Name</th>*/}
                          <th scope="col">Phone</th>
                          <th scope="col">Email</th>
                          <th scope="col">Address</th>
                          {/*<th scope="col">Verify</th>*/}
                          <th scope="col">Pay by invoice</th>
                          <th scope="col">Inactive/Active</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wholeSalerList &&
                          wholeSalerList.map((item, i) => (
                            <tr>
                              <td>{count * (pageNo - 1) + (i + 1)}</td>
                              <td>
                                {item.firstName} {item.lastName}
                              </td>
                              {/*<td> {item.totalOrder ? item.totalOrder : '0'}</td>*/}
                              {/*<td>*/}
                              {/*    {item.profileImage ? item.profileImage : '--'}*/}
                              {/*</td>*/}
                              <td>
                                {item.consumerType === 1 ? "Restaurant" : ""}
                                {item.consumerType === 2 ? "School" : ""}
                                {item.consumerType === 3 ? "University" : ""}
                                {item.consumerType === 4 ? "Other" : ""}
                              </td>
                              <td>{item.phone ? item.phone : "--"}</td>
                              <td>{item.email ? item.email : item.email}</td>
                              <td>
                                {" "}
                                {item.address.length > 0
                                  ? item.address[0].addressLine1
                                  : "--"}{" "}
                                &nbsp;
                                {item.address.length > 0
                                  ? item.address[0].addressLine2
                                  : ""}{" "}
                                &nbsp;
                                {item.address.length > 0
                                  ? item.address[0].city
                                  : ""}{" "}
                                &nbsp;
                                {item.address.length > 0
                                  ? item.address[0].state
                                  : ""}{" "}
                                &nbsp;
                                {item.address.length > 0
                                  ? item.address[0].postalCode
                                  : ""}{" "}
                              </td>
                              {/*<td>*/}
                              {/*    <div class="switch_wrapper">*/}
                              {/*        <input type="checkbox"*/}
                              {/*               onChange={(e) => this.handleVerify(e, item._id)}*/}
                              {/*        />*/}
                              {/*        <span class="checkbox_swich"></span>*/}
                              {/*    </div>*/}
                              {/*</td>*/}
                              <td>
                                <div class="switch_wrapper">
                                  <input
                                    type="checkbox"
                                    checked={this.isInvoiceStatusActive(
                                      item.invoiceStatus
                                    )}
                                    onChange={(e) =>
                                      this.handleInvoiceStatus(e, item._id)
                                    }
                                  />
                                  <span class="checkbox_swich"></span>
                                </div>
                              </td>
                              <td>
                                <div class="switch_wrapper">
                                  <input
                                    type="checkbox"
                                    checked={item.status === 1 ? false : true}
                                    onChange={(e) =>
                                      this.handleAciveInactiveStatus(
                                        e,
                                        item._id
                                      )
                                    }
                                  />
                                  <span class="checkbox_swich"></span>
                                </div>
                              </td>
                              <td className="ws-nowrap">
                                <div className="action_wrapper">
                                  <span>
                                    <Link>
                                      <i
                                        onClick={(e) =>
                                          this.openViewPopup(e, item)
                                        }
                                        class="fas fa-eye"
                                      ></i>
                                    </Link>
                                  </span>

                                  <span
                                    onClick={() => this.showModal(item._id)}
                                  >
                                    <i className="fas fa-trash-alt text-danger"></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {wholeSalerList && wholeSalerList.length ? (
                      ""
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </div>
                  {wholeSalerList && wholeSalerList.length ? (
                    <div className="d-flex justify-content-end cm-pagination">
                      <Pagination
                        itemClassPrev={"prevClass"}
                        itemClassNext={"nextClass"}
                        activePage={this.state.pageNo}
                        itemsCountPerPage={this.state.count}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={3}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <Modal
          show={visible}
          onHide={this.handleCancel}
          className="cm_order_view dlt_item"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img src={require("../../assets/img/Delete-Email.png")} />
            <br />
            <br />
            <p>Are you sure you want to delete this wholesaler?</p>
          </Modal.Body>
          <Modal.Footer>
            <div className="footer_btn_wrapper btn_nospace">
              <Button
                variant="warning"
                loading={loading}
                onClick={() =>
                  this.handleOnChangeStatus(this.state.selectedId, 2)
                }
              >
                Yes
              </Button>
              <Button variant="dark" onClick={this.handleCancel}>
                No
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={viewWholeSaler}
          onHide={this.handleClose}
          className="cm_order_view edit_item inventry"
        >
          <Modal.Header closeButton>
            <Modal.Title> Wholesaler Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {profileImage ? (
              <Row>
                <Col md="4">
                  <p>Profile Image</p>
                </Col>
                <Col md="8">
                  <div
                    className="file_uploader"
                    style={{ backgroundImage: `url(${profileImage})` }}
                  >
                    <>
                      <div className="overlay"></div>
                    </>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
            {firstName ? (
              <Row>
                <Col md="4">
                  <p>Name</p>
                </Col>
                <Col md="8">
                  <div className="form-control">
                    {firstName} {lastName}
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}

            {email ? (
              <Row>
                <Col md="4">
                  <p> Email</p>
                </Col>
                <Col md="8">
                  <Form.Control
                    type="text"
                    onChange={this.handleChanged}
                    disabled
                    name="email"
                    value={email}
                    placeholder=" "
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}

            {phone ? (
              <Row>
                <Col md="4">
                  <p> Phone Number</p>
                </Col>
                <Col md="8">
                  <Form.Control
                    type="text"
                    onChange={this.handleChanged}
                    disabled
                    name="phone"
                    value={phone}
                    placeholder="Recipe name"
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}

            {consumerType ? (
              <Row>
                <Col md="4">
                  <p> Categories</p>
                </Col>
                <Col md="8">
                  <div className="form-control">
                    {consumerType === 1 ? "Restaurant" : ""}
                    {consumerType === 2 ? "School" : ""}
                    {consumerType === 3 ? "University" : ""}
                    {consumerType === 4 ? "Other" : ""}
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}

            {address ? (
              <Row>
                <Col md="4">
                  <p> Address</p>
                </Col>
                <Col md="8">
                  <p className="form-control">
                    {address.length > 0 ? address[0].addressLine1 : ""} &nbsp;
                    {address.length > 0 ? address[0].addressLine2 : ""} &nbsp;
                    {address.length > 0 ? address[0].city : ""} &nbsp;
                    {address.length > 0 ? address[0].state : ""} &nbsp;
                    {address.length > 0 ? address[0].postalCode : ""}
                  </p>
                </Col>
              </Row>
            ) : (
              ""
            )}

            {certificateImage ? (
              // <Row>

              <Row>
                <Col md="4">
                  <p>Certification or Growers Pledge:</p>
                </Col>
                <Col md="8">
                  <div
                    className="file_uploader bg_size superadmin documentpdf"
                    // style={{ backgroundImage: `url(${this.state.certificatePreview1})` }}
                  >
                    {ViewDoc(certificateImage)}
                    <>
                      {/* <div class="overlay"></div> */}

                      <span className="dwnload-icon">
                        <a href={certificateImage} download>
                          <i className="fas fa-download"></i>
                        </a>
                      </span>
                    </>
                  </div>
                </Col>

                {/*</Row>*/}

                {/*<Col md="4">*/}
                {/*    <p> certificateImage</p>*/}
                {/*</Col>*/}
                {/*<Col md="8">*/}

                {/*{ViewDoc(certificateImage)}</Col>*/}
              </Row>
            ) : (
              ""
            )}
          </Modal.Body>

          <Modal.Footer>
            <div className="footer_btn_wrapper">
              <Button variant="dark" onClick={this.handleClose}>
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default WholeSaleList

import React, { Component } from "react"
import { Button, Modal, Tab, Tabs } from "react-bootstrap"
import Sidebar from "../../../components/sidebar"
import { Link } from "react-router-dom"
import _ from "lodash"
import Pagination from "react-js-pagination"
import { _Api } from "../../../services/Api"
import Url from "../../../services/BaseUrl"
import Loader from "../../../components/common/Loader"

import {
  distributionTypeStringForInt,
  distributionClientStringForInt,
} from "../../../_constants/distribution.constants"

const days = (type) => {
  switch (type) {
    case 0:
      return "Sunday"
      break
    case 1:
      return "Monday"
      break
    case 2:
      return "Tuesday"
      break
    case 3:
      return "Wednesday"
      break
    case 4:
      return "Thursday"
      break
    case 5:
      return "Friday"
      break
    case 6:
      return "Saturday"
      break
  }
}

function ListTime(props) {
  let Jsx = []

  props.data.time.map((value, i) =>
    Jsx.push(
      <span className="cm_time_wrapper">
        {value.starttime}-{value.endTime}&nbsp;&nbsp;
      </span>
    )
  )
  return Jsx
}

class Distribution extends Component {
  constructor(props) {
    super(props)

    this.state = {
      distributionId: "",
      dlt: false,
      errors: "",
      success: "",
      isEdit: false,
      address: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      postalCode: "",
      city: "",
      lat: 0,
      lng: 0,
      distInfo: [],
      userId: this.props ? this.props.match.params.id : "",
      viewPopUp: false,
      distId: "",
      totalCount: "",
      search: "",
      sortField: "",
      sortType: "",
      pageNo: 1,
      count: 10,
      dataCount: 0,
      category: "",
      notes: "",
    }
  }

  componentDidMount() {
    this.getDistibutionDetails()
  }

  openViewPopup = (item) => {
    this.setState({
      viewPopUp: true,

      distId: item._id,
    })
  }

  handleClose = (item) => {
    this.setState({
      viewPopUp: false,
      distId: "",
      dlt: false,
    })
  }

  DeleteItem = (e, item) => {
    this.setState({ dlt: true, distributionId: item._id })
  }

  handleDelete = (e, item) => {
    let { userId, distributionId } = this.state
    let self = this

    this.setState({ redirect: false, show: false, errors: "", loader: true })

    let obj = {
      distributionId: distributionId,
      userId: userId,
    }

    _Api(Url.DELETE_DISTRIBUTION.method, Url.DELETE_DISTRIBUTION.url, obj).then(
      (data) => {
        if (data.statusCode === 1) {
          self.setState({ dlt: false })
          self.getDistibutionDetails()
          // self.setState({ loader: false, })
        } else {
          self.setState({ loader: false })
          self.setState({ errors: data.error.responseMessage })
        }
      }
    )
  }

  getDistibutionDetails = () => {
    let { userId, category, pageNo, count, sortField, sortType } = this.state
    this.setState({ errors: "" })

    let self = this
    this.setState({ loader: true })

    _Api(
      Url.GET_DISTRIBUTION.method,
      Url.GET_DISTRIBUTION.url,
      "",
      `?userId=${userId}&farmerId=${userId}&pageNo=${
        pageNo - 1
      }&count=${count}&sortField=${sortField ? sortField : ""}&sortType=${
        sortType ? sortType : ""
      }&category=${category ? category : ""}`
    ).then((data) => {
      self.setState({ loader: false })

      if (data.statusCode === 1) {
        let distInfo = data.responseData.result

        self.setState({
          distInfo: distInfo.distributionList,
          dataCount: distInfo.totalDistributionResult,
          totalCount: distInfo.totalAllDistribution,
        })
      } else {
        self.setState({ errors: data.error.responseMessage })
      }
    })
    self.setState({ loader: false })
  }

  handlePageChange = (pageNo) => {
    var self = this
    this.setState({ loader: true, pageNo: pageNo, count: 10 }, () => {
      self.getDistibutionDetails()
    })
  }

  handleFilter = (e) => {
    this.setState({ category: e, pageNo: 1 }, () =>
      this.getDistibutionDetails()
    )
  }

  toBack = () => {
    this.props.history.push(
      `/farmermanagement/editfrmermanagement/${this.props.match.params.id}`
    )
  }

  render() {
    let { distInfo, dlt, count, pageNo, userId } = this.state

    return (
      <>
        <Sidebar />
        <div>
          <div className="header_wrapper">
            <div className="header_logo">
              <img
                src={require("../../../assets/img/OFFICIAL LOGO.png")}
                alt=""
              />
            </div>
            <div className="header_search">
              <div className="form-group has-search"></div>
            </div>
            <div className="bell_icon">
              <i className="fas fa-bell"></i>
            </div>
          </div>

          <div className="body_container">
            <div className="body_inner_wrapper">
              {this.state.loader ? (
                <Loader />
              ) : (
                <>
                  <header className="header_content">
                    <Link onClick={this.toBack}>
                      <span className="mr-3 d-inline-block text_light text_12">
                        <i class="fas fa-caret-left text_dark mr-2"></i> Back{" "}
                      </span>
                    </Link>
                    <div className="d-flex text-nowrap header_btn">
                      <Link
                        to={`/farmermanagement/distributionadd/${userId}`}
                        className="text-light"
                      >
                        <Button variant="warning">
                          <i class="fas fa-plus-circle"></i>
                          Add Distribution
                        </Button>
                      </Link>
                    </div>
                  </header>
                  <div className="page_heading d-flex">
                    <Link
                      to={`/farmermanagement/editfrmermanagement/${userId}`}
                      className="mr-3"
                    >
                      {" "}
                      <h6>Edit Farmer</h6>
                    </Link>
                    <Link
                      to={`/farmermanagement/inventorymanagement/${userId}`}
                      className=" mr-3"
                    >
                      <h6>Inventory</h6>
                    </Link>
                    <Link
                      to={`/farmermanagement/farm/${userId}`}
                      className=" mr-3"
                    >
                      <h6>Farm</h6>
                    </Link>
                    <h6 className=" activeheading mr-3 mr-3"> Distribution </h6>
                  </div>

                  <div className="main_content">
                    <Tabs
                      defaultActiveKey="1"
                      activeKey={1}
                      id="uncontrolled-tab-example"
                      className="border-0"
                    >
                      <Tab eventKey="1">
                        <div className="cm-inventory_gridview">
                          <div className="table-responsive overflow_remove">
                            <table className="table cm_bottom_space">
                              <thead>
                                <tr className="broder_top_0">
                                  <th scope="col" className="s_no ws-nowrap">
                                    S. No.
                                  </th>
                                  <th scope="col" className="ws-nowrap">
                                    Orders Open
                                  </th>
                                  <th scope="col" className="ws-nowrap">
                                    Orders Close
                                  </th>
                                  <th scope="col" className="ws-nowrap">
                                    Type
                                  </th>
                                  <th scope="col" className="ws-nowrap">
                                    Pick Up / Delivery Day
                                  </th>
                                  <th scope="col" className="ws-nowrap">
                                    {" "}
                                    Pick Up Location
                                  </th>
                                  <th scope="col">Fee/mile</th>
                                  <th scope="col" width="300">
                                    {" "}
                                    Delivery For
                                  </th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!_.isEmpty(distInfo) &&
                                  distInfo.map((item, i) => (
                                    <tr key={item._id}>
                                      <td className="s_no">
                                        {count * (pageNo - 1) + (i + 1)}
                                      </td>
                                      {item.placeOrderOpen ? (
                                        <td>
                                          {days(item.placeOrderOpen.day)}{" "}
                                          {item.placeOrderOpen.time}{" "}
                                          {item.placeOrderOpen.meridiem}
                                        </td>
                                      ) : (
                                        ""
                                      )}

                                      {item.placeOrderClose ? (
                                        <td width="140">
                                          {days(item.placeOrderClose.day)}{" "}
                                          {item.placeOrderClose.time}{" "}
                                          {item.placeOrderClose.meridiem}
                                        </td>
                                      ) : (
                                        ""
                                      )}

                                      {item.selectPickDel ? (
                                        <td width="140">
                                          {" "}
                                          {distributionTypeStringForInt({
                                            distributionType:
                                              item.selectPickDel,
                                          })}
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                      {item && item.selectPickDel === 2 ? (
                                        <td>
                                          {item.delivery.map((value, i) =>
                                            value ? (
                                              <span className="px-1 d-block">
                                                {days(value.day)}{" "}
                                                {value.time ? (
                                                  <i>
                                                    {" "}
                                                    {<ListTime data={value} />}
                                                  </i>
                                                ) : (
                                                  ""
                                                )}{" "}
                                              </span>
                                            ) : (
                                              ""
                                            )
                                          )}
                                        </td>
                                      ) : (
                                        <td width="140">
                                          {" "}
                                          {item
                                            ? item.pickup.map((value, i) =>
                                                value ? (
                                                  <span className="px-1 d-block">
                                                    {days(value.day)}{" "}
                                                    {value.time ? (
                                                      <i>
                                                        {
                                                          <ListTime
                                                            data={value}
                                                          />
                                                        }
                                                      </i>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                ) : (
                                                  ""
                                                )
                                              )
                                            : ""}
                                        </td>
                                      )}

                                      <td>
                                        {item
                                          ? item.distributionLocation.address
                                          : ""}
                                      </td>

                                      <td>
                                        ${" "}
                                        {item.deliveryFees
                                          ? item.deliveryFees
                                          : item.flatFee
                                          ? item.flatFee
                                          : ""}
                                      </td>
                                      <td>
                                        {" "}
                                        {item.deliveryFor === 1
                                          ? "Customer"
                                          : ""}{" "}
                                        {item.deliveryFor === 2
                                          ? "Wholesaler"
                                          : ""}{" "}
                                        {item.deliveryFor === 3 ? "Both" : ""}
                                      </td>

                                      <td align="center">
                                        <div className="action_wrapper">
                                          <Link
                                            to={`/farmermanagement/distributionedit/${userId}/${item._id}`}
                                            className="font_14"
                                          >
                                            <i class="fas fa-pen"></i>
                                          </Link>
                                          <span className="font_14">
                                            <i
                                              style={{ color: "red" }}
                                              onClick={(e) =>
                                                this.DeleteItem(e, item)
                                              }
                                              className="fas fa-trash-alt"
                                            ></i>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            {distInfo && distInfo.length ? (
                              <div className="d-flex justify-content-end cm-pagination">
                                <Pagination
                                  itemClassPrev={"prevClass"}
                                  itemClassNext={"nextClass"}
                                  activePage={this.state.pageNo}
                                  itemsCountPerPage={this.state.count}
                                  totalItemsCount={this.state.totalCount}
                                  pageRangeDisplayed={3}
                                  onChange={this.handlePageChange}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Tab>
                    </Tabs>

                    {_.isEmpty(distInfo) ? (
                      <h3 className="text-center">No data found</h3>
                    ) : (
                      ""
                    )}

                    <Modal
                      show={dlt}
                      onHide={this.handleClose}
                      className="cm_order_view dlt_item"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <img
                          src={require("../../../assets/img/Delete-Email.png")}
                        />
                        <br />
                        <br />
                        <p>
                          Are you sure you want to delete this distribution?
                        </p>
                      </Modal.Body>
                      <Modal.Footer>
                        <div className="footer_btn_wrapper">
                          <Button
                            variant="warning"
                            onClick={(e) => this.handleDelete()}
                          >
                            Delete
                          </Button>
                          <Button variant="dark" onClick={this.handleClose}>
                            Cancel
                          </Button>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>{" "}
      </>
    )
  }
}

export default Distribution

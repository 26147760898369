import { CardDeck, Col, Tab, Tabs } from "react-bootstrap"
import React, { Component } from "react"

import Item from "./Item"
import ItemAdd from "./ItemAdd"
import ItemDelete from "./ItemDelete"
import ItemDetails from "./ItemDetails"
import _ from "lodash"
import { connect } from "react-redux"
import { loadInventory } from "../../../redux/action/inventory"
import Loader from "../../../components/common/Loader"

class InventoryList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      search: "",
      pageNo: 1,
      count: 500,
      view: 1,
      inventoryType: 1,
      status: "",
      sortType: "1",
    }
  }

  getInventoryList = (type) => {
    let { inventoryType } = this.state
    if (type) {
      inventoryType = _.toInteger(type)
      this.setState({ inventoryType })
    }

    this.props.dispatch(
      loadInventory({
        ...this.state,
        inventoryType,
        userId: this.props.match.params.id,
      })
    )
  }

  componentDidMount() {
    this.getInventoryList(1)
  }

  render() {
    const { inventoryType } = this.state
    const { itemList, isLoading } = this.props

    const tabs = [
      "Vegetables",
      "Seedings",
      "CSA Shares",
      "Subscription Boxes",
      "Others",
      "Fruits",
      "Meat and Eggs",
      "Dairy",
    ]

    return (
      <div className="main_content cm-inventory">
        <div className="cm-inventory">
          {isLoading && <Loader />}
          <Tabs
            defaultActiveKey="1"
            onSelect={(type) => this.getInventoryList(type)}
            activeKey={inventoryType}
            id="uncontrolled-tab-example"
          >
            {_.map(tabs, (tab, index) => {
              return (
                <Tab key={index} eventKey={index + 1} title={tab}>
                  <CardDeck>
                    {!_.isEmpty(itemList) &&
                      itemList.map((value, key) => {
                        return (
                          <Item
                            key={key}
                            item={value}
                            itemList={itemList}
                            inventoryType={inventoryType}
                            userId={this.props.match.params.id}
                            {...this.props}
                          />
                        )
                      })}
                  </CardDeck>
                </Tab>
              )
            })}
          </Tabs>
        </div>
        {_.isEmpty(itemList) && <h3 className="text-center">No data found</h3>}

        {!_.isEmpty(itemList) && (
          <ItemDetails userId={this.props.match.params.id} />
        )}

        {
          <ItemAdd
            itemList={itemList}
            userId={this.props.match.params.id}
            inventoryType={inventoryType}
          />
        }

        {!_.isEmpty(itemList) && (
          <ItemDelete userId={this.props.match.params.id} />
        )}
      </div>
    )
  }
}

export default connect((state) => ({
  itemList: state.inventory.list,
  isLoading: state.loading.isLoading,
}))(InventoryList)

import React, { Component } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import Sidebar from "../../../components/sidebar"
import { Link } from "react-router-dom"
import _ from "lodash"
import VideoThumbnail from "react-video-thumbnail"
import Map1 from "../../map/CommonMap"
import { _Api } from "../../../services/Api"
import Url from "../../../services/BaseUrl"
import Loader from "../../../components/common/Loader"
import Geocode from "react-geocode"
Geocode.setApiKey("AIzaSyCDo6u0GLnkdPy9OHiS53-4yDhp_PEaCWA")
Geocode.enableDebug()
Geocode.setLanguage("en")

let videoHeight, videoWidth, videoPreview

class Farm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      userId: this.props ? this.props.match.params.id : "",
      errors: "",
      success: "",
      userInfo: [],
      postalErrorMsg: "",
      isEdit: false,
      farmImagePreview: "",
      farmImageErrorMsg: "",
      farmMediaErrorMsg: "",
      farmImage: [],
      farmLogoPreview: "",
      farmLogo: "",
      farmLogoErrorMsg: "",
      farmVideoPreview: "",
      farmVideo: "",
      farmVideoErrorMsg: "",

      farmName: "",
      farmStatus: "",
      shortInfo: "",
      infoAboutFarm: "",
      infoAboutFarmer: "",
      description: "",
      farmId: "",
      farmInfo: [],
      farmMedia: [],
      farmImage1Preview: [],
      media: [],
      valiDate: true,
      loader: false,

      address: "",
      addressLine1: "",
      addressLine1ErrorMsg: "",
      addressLine2: "",
      state: "",
      postalCode: "",
      city: "",
      lat: 0,
      lng: 0,
    }
  }

  componentDidMount() {
    this.getFarmDetails()
  }

  handleChanged = (e) => {
    console.log("onchange names", e)
    let self = this
    let address1, address2, city1, state1, postalCode1
    if (e.target.name === "addressLine1") {
      address1 = e.target.value
    }
    if (e.target.name === "addressLine2") {
      address2 = e.target.value
    }

    if (e.target.name === "city") {
      city1 = e.target.value
    }
    if (e.target.name === "state") {
      state1 = e.target.value
    }
    if (e.target.name === "postalCode") {
      postalCode1 = e.target.value
    }
    let addr =
      address1 + " " + address1 + " " + city1 + " " + state1 + " " + postalCode1

    if (
      e.target.name === "addressLine1" ||
      e.target.name === "addressLine2" ||
      e.target.name === "city" ||
      e.target.name === "state" ||
      e.target.name === "postalCode"
    ) {
      Geocode.fromAddress(addr).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location

          self.setState({ lat: lat, lng: lng })
        },
        (error) => {
          console.error(error)
        }
      )
    }

    this.setState({
      [e.target.name]: e.target.value,
      infoAboutFarmErrorMsg: "",
      infoAboutFarmerErrorMsg: "",
      addressLine1ErrorMsg: "",
      farmNameErrorMsg: "",
    })
  }

  viewMode = () => {
    this.setState({ isEdit: false })
  }

  handleVideoUpload = (event) => {
    let doc = {
      path: URL.createObjectURL(event.target.files[0]),
      type: event.target.files[0].type,
      preview: URL.createObjectURL(event.target.files[0]),
      fileName: event.target.files[0].name,
    }

    videoPreview = doc

    let { valiDate } = this.state
    let file = event.target.files[0]

    if (
      file.type === "video/mp4" ||
      file.type === "video/webm" ||
      file.type === "video/ogg"
    ) {
      this.setState({
        farmVideo: file,
        farmVideoErrorMsg: "",
      })
    } else {
      valiDate = false
      this.setState({ farmVideoErrorMsg: "File type not supported." })
    }
  }

  handleLogoUpload = (event) => {
    console.log(event, "eventeventevent", event.target.files[0])
    let reader = new FileReader()
    let file = event.target.files[0]

    let { valiDate } = this.state

    reader.addEventListener(
      "load",
      () => {
        this.setState({ farmLogoPreview: reader.result }, function () {})
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/gif" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml"
      ) {
        if (file.size > 5000000) {
          valiDate = false
          this.setState({
            farmLogo: event.target.files[0],
            farmLogoErrorMsg: "Farm logo size should be less than 5Mb.",
          })
        } else {
          this.setState({
            farmLogo: event.target.files[0],
            farmLogoErrorMsg: "",
          })
        }
      } else {
        valiDate = false
        this.setState({
          farmLogoErrorMsg: "File type not supported.",
          farmLogo: "",
        })
      }
    }
  }

  handleImageUpload = (event) => {
    let { valiDate } = this.state
    if (event.target.files) {
      if (event.target.files[0].size < 5000000) {
        console.log(
          event.target,
          "adnbhahgvdahbsdha",
          Number(event.target.name)
        )
        let index = Number(event.target.name)
        console.log(event, "eventeventevent", event.target.files[0])
        let reader = new FileReader()
        let file = event.target.files[0]
        let farmMedia = this.state.farmMedia
        let farmImage1Preview = this.state.farmImage1Preview

        reader.addEventListener(
          "load",
          () => {
            farmImage1Preview[index] = reader.result

            this.setState(
              { farmImage1Preview: farmImage1Preview },
              function () {}
            )
          },
          false
        )
        if (file) {
          reader.readAsDataURL(file)
        }

        // if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/svg+xml') {

        farmMedia[index] = event.target.files[0]

        this.setState({
          farmMedia,
          farmMediaErrorMsg: "",
        })
      } // }
      else {
        valiDate = false
        this.setState({ farmMediaErrorMsg: "File size must be less than 5Mb." })
      }
    }
  }

  handleAddValidation = () => {
    let validate = true
    let {
      farmName,
      postalCode,
      infoAboutFarm,
      infoAboutFarmer,
      farmMedia,
      farmLogo,
      farmVideo,
      addressLine1,
    } = this.state

    if (farmName === "" || farmName === undefined) {
      validate = false
      this.setState({
        farmNameErrorMsg: "Please enter your farm name.",
      })
    } else {
      this.setState({
        farmNameErrorMsg: "",
      })
    }
    console.log(addressLine1, "sssssssssssssss")
    if (addressLine1 === "" || addressLine1 === undefined) {
      validate = false
      this.setState({
        addressLine1ErrorMsg: "Please enter address.",
      })
    } else {
      this.setState({
        addressLine1ErrorMsg: "",
      })
    }

    if (postalCode) {
      if (postalCode < 0) {
        validate = true
        this.setState({ postalErrorMsg: "Postal code must be greater than 0." })
      } else {
        this.setState({
          postalErrorMsg: "",
        })
      }
    }

    if (infoAboutFarm === "" || infoAboutFarm === undefined) {
      validate = false
      this.setState({
        infoAboutFarmErrorMsg: "Please enter information about farm.",
      })
    } else {
      this.setState({
        infoAboutFarmErrorMsg: "",
      })
    }
    if (infoAboutFarmer === "" || infoAboutFarmer === undefined) {
      validate = false
      this.setState({
        infoAboutFarmerErrorMsg: "Please enter information about you.",
      })
    } else {
      this.setState({
        infoAboutFarmerErrorMsg: "",
      })
    }
    console.log(farmMedia, "mediaaaaaaaaaaaaaaa")
    if (_.isEmpty(farmMedia) || farmMedia === undefined) {
      validate = false
      this.setState({
        farmMediaErrorMsg: "Please select farm image.",
      })
    } else {
      this.setState({
        farmMediaErrorMsg: "",
      })
    }
    // if (farmLogo) {

    if (farmLogo === "" || farmLogo === undefined) {
      validate = false
      this.setState({
        farmLogoErrorMsg: "Please select farm logo",
      })
    } else {
      if (farmLogo.size > 5000000) {
        validate = false
        this.setState({
          farmLogoErrorMsg: "Farm logo size should be less than 5Mb",
        })
      } else {
        this.setState({
          farmLogoErrorMsg: "",
        })
      }
    }

    return validate
  }

  handleAddSubmit = (e) => {
    let {
      userId,
      farmLogo,
      farmImage,
      farmVideo,
      farmMedia,
      farmName,
      farmStatus,
      shortInfo,
      infoAboutFarm,
      infoAboutFarmer,
      description,
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
      mapPosition,
      lat,
      lng,
    } = this.state
    this.setState({ errors: "" })
    // let {addressLine1, addressLine2, city, state, postalCode, mapPosition,  lat, lng} = this.props.mapFarmReducer
    // this.setState({addressLine1, addressLine2, city, state, postalCode})

    e.preventDefault()

    let self = this
    if (this.handleAddValidation()) {
      let obj = new FormData()
      obj.append("userId", userId)
      obj.append("farmLogo", farmLogo)

      for (let i = 0; i < 5; i++) {
        if (typeof farmMedia[i] !== "undefined") {
          console.log("xrdctfvyiiigbuhij", typeof farmMedia[i])

          obj.append("farmImage", farmMedia[i])

          console.log(
            farmVideo,
            farmName,
            farmMedia[i],
            infoAboutFarm,
            farmLogo,
            infoAboutFarmer,
            userId,
            "onjjjjjjjjjjjjjjjjjjjjj"
          )
        }
      }

      // if (farmVideo) obj.append('farmVideo', farmVideo);
      obj.append("farmName", farmName)
      obj.append("farmStatus", 1)
      obj.append("infoAboutFarm", infoAboutFarm)
      obj.append("shortInfo", shortInfo)
      obj.append("infoAboutFarmer", infoAboutFarmer)
      if (lat) obj.append("lat", lat)
      if (lng) obj.append("long", lng)

      if (addressLine1) obj.append("addressLine1", addressLine1)
      if (addressLine2) obj.append("addressLine2", addressLine2)
      if (city) obj.append("city", city)
      if (state) obj.append("state", state)
      if (postalCode) obj.append("postalCode", postalCode)
      console.log(
        addressLine1,
        addressLine2,
        city,
        state,
        postalCode,
        mapPosition,
        lat,
        lng,
        "addressLine1, addressLine2, city, state, postalCode, lat, lng add"
      )

      this.setState({ loader: true })

      // let {dispatch} = this.props;
      // dispatch(farmActions.ADD_FARM(obj))
      _Api(Url.ADD_FARM.method, Url.ADD_FARM.url, obj).then((data) => {
        self.setState({ loader: false })
        console.log(data, "aaaaaaaaaaaaaaaaaaaa")
        if (data.statusCode == 1) {
          // console.log(data.statusCode)
          self.setState({ isEdit: false })
          self.getFarmDetails()
        } else {
          self.setState({ errors: data.error.responseMessage })
        }
      })
    }
  }

  getFarmDetails = () => {
    let {
      userId,
      farmLogo,
      farmImage,
      farmVideo,
      farmLogoPreview,
      farmVideoPreview,
      farmName,
      farmStatus,
      infoAboutFarm,
      infoAboutFarmer,
      description,
    } = this.state
    this.setState({ errors: "" })

    let self = this
    this.setState({ loader: true })

    // let params = `?userId=${userId}&farmerId=${userId}`
    _Api(
      Url.GET_FARM_INFO.method,
      Url.GET_FARM_INFO.url,
      "",
      `?userId=${userId}&farmerId=${userId}`
    ).then((data) => {
      self.setState({ loader: false })

      if (!_.isEmpty(data)) {
        if (data.statusCode == 1) {
          let farmInfo = data.responseData.result

          console.log("muuuuuuuuuuuuuuuuuuuuuu", farmInfo)

          self.setState({
            farmInfo: farmInfo,
            farmLogo: farmInfo.length > 0 ? farmInfo[0].farmLogo : "",
            farmId: farmInfo.length > 0 ? farmInfo[0]._id : "",
            farmLogoPreview: farmInfo.length > 0 ? farmInfo[0].farmLogo : "",
            media: farmInfo.length > 0 ? farmInfo[0].media : "",
            farmName: farmInfo.length > 0 ? farmInfo[0].farmName : "",
            infoAboutFarm: farmInfo.length > 0 ? farmInfo[0].infoAboutFarm : "",
            infoAboutFarmer:
              farmInfo.length > 0 ? farmInfo[0].infoAboutFarmer : "",
            lng:
              farmInfo.length > 0 && farmInfo[0].geoLocation
                ? farmInfo[0].geoLocation[0]
                : "",
            lat:
              farmInfo.length > 0 && farmInfo[0].geoLocation
                ? farmInfo[0].geoLocation[1]
                : "",

            addressLine1:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].addressLine1
                : "",
            addressLine2:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].addressLine2
                : "",
            city:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].city
                : "",
            state:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].state
                : "",
            postalCode:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].postalCode
                : "",
          })
        } else {
          self.setState({ errors: data.error.responseMessage })
        }
      }

      // });
    })
  }
  onKeyPress(e) {
    e = e || window.event
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which
    var charStr = String.fromCharCode(charCode)

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault()
  }
  toBack = () => {
    console.log("LLLLLLLLLLLLLLLLLLLLLLLLLLLLL", this.props.match.params.id)
    this.props.history.push(
      `/farmermanagement/editfrmermanagement/${this.props.match.params.id}`
    )
  }

  render() {
    let {
      isEdit,
      farmInfo,
      media,
      loader,
      postalErrorMsg,
      farmImagePreview,
      farmMediaErrorMsg,
      farmImage,
      farmImage1Preview0,
      farmImage1Preview,
      farmLogoPreview,
      farmLogo,
      farmLogoErrorMsg,
      farmNameErrorMsg,
      infoAboutFarmErrorMsg,
      infoAboutFarmerErrorMsg,
      addressLine1ErrorMsg,
      farmVideoPreview,
      farmVideo,
      farmVideoErrorMsg,
      userId,
      farmName,
      farmStatus,
      infoAboutFarm,
      infoAboutFarmer,
      description,
      shortInfo,
    } = this.state

    return (
      <>
        <Sidebar />

        <div>
          <div className="header_wrapper">
            <div className="header_logo">{/*<img src={require()}/>*/}</div>
            <div className="header_search"></div>
            <div className="bell_icon">
              <i className="fas fa-bell"></i>
            </div>
          </div>

          <div className="body_container">
            <div className="body_inner_wrapper">
              {this.state.loader ? (
                <Loader />
              ) : (
                <>
                  <header className="header_content">
                    {/*<p>Farm</p>*/}
                    <Link onClick={this.toBack}>
                      <span className="mr-3 d-inline-block text_light text_12">
                        <i class="fas fa-caret-left text_dark mr-2"></i> Back{" "}
                      </span>
                    </Link>
                  </header>

                  <div className="page_heading d-flex">
                    <Link
                      to={`/farmermanagement/editfrmermanagement/${userId}`}
                      className="mr-3"
                    >
                      {" "}
                      <h6>Edit Farmer</h6>
                    </Link>
                    <Link
                      to={`/farmermanagement/inventorymanagement/${userId}`}
                      className=" mr-3"
                    >
                      <h6>Inventory</h6>
                    </Link>
                    <h6 className=" activeheading mr-3 mr-3"> Farm</h6>

                    <Link
                      to={`/farmermanagement/distributionmanagement/${userId}`}
                      className=" mr-3"
                    >
                      <h6>Distribution</h6>
                    </Link>
                  </div>

                  {_.isEmpty(farmInfo) ? (
                    <div className="main_content">
                      <form onSubmit={this.handleAddSubmit}>
                        <div className="farm_page">
                          <Row>
                            <Col md="2">
                              <p>Farm Logo:</p>
                            </Col>
                            <Col md="7">
                              <div
                                className="file_uploader mx-auto"
                                style={{
                                  backgroundImage: `url(${farmLogoPreview})`,
                                }}
                              >
                                {farmLogoPreview ? (
                                  <>
                                    <div className="overlay"></div>{" "}
                                    <i class="fas fa-pen text-light"></i>
                                  </>
                                ) : (
                                  <i className="fas fa-plus-circle"></i>
                                )}

                                <Form.File
                                  label="Custom file input"
                                  name="farmLogo"
                                  onChange={this.handleLogoUpload}
                                  accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                  lang="en"
                                  custom
                                />
                              </div>
                              <p className="text-center">
                                {" "}
                                <small>Recommended size 5Mb </small>
                              </p>

                              {farmLogoErrorMsg ? (
                                <div className="error text-center">
                                  {farmLogoErrorMsg}
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                          <Row>
                            <Col md="2">
                              <p>Farm Name:</p>
                            </Col>
                            <Col md="7">
                              <Form.Control
                                type="text"
                                onChange={this.handleChanged}
                                name="farmName"
                                value={farmName}
                                placeholder="Farm name "
                              />
                              {farmNameErrorMsg ? (
                                <div className="error">{farmNameErrorMsg}</div>
                              ) : null}
                            </Col>
                          </Row>
                          <Row>
                            <Col md="2">
                              <p>Info About Farm:</p>
                            </Col>
                            <Col md="7">
                              <textarea
                                placeholder="Enter here"
                                onChange={this.handleChanged}
                                name="infoAboutFarm"
                                value={infoAboutFarm}
                                className="form-control"
                                rows="4"
                                cols="50"
                              ></textarea>
                              {infoAboutFarmErrorMsg ? (
                                <div className="error">
                                  {infoAboutFarmErrorMsg}
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                          <Row>
                            <Col md="2">
                              <p>Short Farm Info:</p>
                            </Col>
                            <Col md="7">
                              <Form.Control
                                type="text"
                                onChange={this.handleChanged}
                                name="shortInfo"
                                value={shortInfo}
                                placeholder="Short Farm Info"
                              />
                              {/*{farmNameErrorMsg ?*/}
                              {/*    <div className="error">{farmNameErrorMsg}</div> : null}*/}
                            </Col>
                          </Row>
                          <Row>
                            <Col md="2">
                              <p>Info About Farmer:</p>
                            </Col>
                            <Col md="7">
                              <textarea
                                placeholder="Enter here"
                                onChange={this.handleChanged}
                                name="infoAboutFarmer"
                                value={infoAboutFarmer}
                                className="form-control"
                                rows="4"
                                cols="50"
                              ></textarea>
                              {infoAboutFarmerErrorMsg ? (
                                <div className="error">
                                  {infoAboutFarmerErrorMsg}
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                          <Row>
                            <Col md="2">
                              <p>Farm Image:</p>
                            </Col>
                            <Col md="7">
                              <div className="fileupload_wraper">
                                {/*<div className="file_uploader">*/}
                                <div
                                  className="file_uploader bg_size"
                                  style={{
                                    backgroundImage: `url(${farmImage1Preview[0]})`,
                                  }}
                                >
                                  {farmImage1Preview[0] ? (
                                    <>
                                      <div className="overlay"></div>
                                      <i class="fas fa-pen text-light"></i>
                                    </>
                                  ) : (
                                    <i class="fas fa-plus-circle"></i>
                                  )}
                                  <Form.File
                                    label="Custom file input"
                                    name="0"
                                    accept="image/x-png,image/gif,image/jpeg"
                                    onChange={this.handleImageUpload}
                                    lang="en"
                                    custom
                                  />
                                </div>
                                {/*</div>*/}
                                <div
                                  className="file_uploader bg_size"
                                  style={{
                                    backgroundImage: `url(${farmImage1Preview[1]})`,
                                  }}
                                >
                                  {farmImage1Preview[1] ? (
                                    <>
                                      <div className="overlay"></div>
                                      <i class="fas fa-pen text-light"></i>
                                    </>
                                  ) : (
                                    <i class="fas fa-plus-circle"></i>
                                  )}
                                  <Form.File
                                    label="Custom file input"
                                    name="1"
                                    accept="image/x-png,image/gif,image/jpeg"
                                    onChange={this.handleImageUpload}
                                    lang="en"
                                    custom
                                  />
                                </div>
                                <div
                                  className="file_uploader bg_size"
                                  style={{
                                    backgroundImage: `url(${farmImage1Preview[2]})`,
                                  }}
                                >
                                  {farmImage1Preview[2] ? (
                                    <>
                                      <div className="overlay"></div>
                                      <i class="fas fa-pen text-light"></i>
                                    </>
                                  ) : (
                                    <i class="fas fa-plus-circle"></i>
                                  )}
                                  <Form.File
                                    label="Custom file input"
                                    name="2"
                                    accept="image/x-png,image/gif,image/jpeg"
                                    onChange={this.handleImageUpload}
                                    lang="en"
                                    custom
                                  />
                                </div>
                                <div
                                  className="file_uploader bg_size"
                                  style={{
                                    backgroundImage: `url(${farmImage1Preview[3]})`,
                                  }}
                                >
                                  {farmImage1Preview[3] ? (
                                    <>
                                      <div className="overlay"></div>
                                      <i class="fas fa-pen text-light"></i>
                                    </>
                                  ) : (
                                    <i class="fas fa-plus-circle"></i>
                                  )}
                                  <Form.File
                                    label="Custom file input"
                                    name="3"
                                    accept="image/x-png,image/gif,image/jpeg"
                                    onChange={this.handleImageUpload}
                                    lang="en"
                                    custom
                                  />
                                </div>
                                <div
                                  className="file_uploader bg_size"
                                  style={{
                                    backgroundImage: `url(${farmImage1Preview[4]})`,
                                  }}
                                >
                                  {farmImage1Preview[4] ? (
                                    <>
                                      <div className="overlay"></div>
                                      <i class="fas fa-pen text-light"></i>
                                    </>
                                  ) : (
                                    <i class="fas fa-plus-circle"></i>
                                  )}
                                  <Form.File
                                    label="Custom file input"
                                    name="4"
                                    accept="image/x-png,image/gif,image/jpeg"
                                    onChange={this.handleImageUpload}
                                    lang="en"
                                    custom
                                  />
                                </div>
                              </div>

                              <p>
                                {" "}
                                <small>Recommended size 5Mb </small>
                              </p>
                              {farmMediaErrorMsg ? (
                                <div className="error">{farmMediaErrorMsg}</div>
                              ) : null}
                            </Col>
                          </Row>

                          <Form.Group>
                            {/*<p>Location On Map:</p>*/}
                            <div>
                              <Row>
                                <Col md="2">
                                  {" "}
                                  <label htmlFor="">Farm Address Line 1</label>
                                </Col>
                                <Col md="7">
                                  <input
                                    type="text"
                                    name="addressLine1"
                                    className="form-control"
                                    placeholder="Farm Address Line 1"
                                    onChange={this.handleChanged}
                                    value={this.state.addressLine1}
                                  />
                                  {addressLine1ErrorMsg ? (
                                    <div className="error">
                                      {addressLine1ErrorMsg}
                                    </div>
                                  ) : null}
                                </Col>
                              </Row>
                              {/*</div>*/}
                              <Row>
                                <Col md="2">
                                  <label htmlFor="">Farm Address Line 2</label>
                                </Col>
                                <Col md="7">
                                  {" "}
                                  <input
                                    type="text"
                                    name="addressLine2"
                                    className="form-control"
                                    placeholder="Farm Address Line 2"
                                    onChange={this.handleChanged}
                                    value={this.state.addressLine2}
                                  />
                                </Col>{" "}
                              </Row>

                              <Row>
                                <Col md="2">
                                  <label htmlFor="">City</label>
                                </Col>
                                <Col md="7">
                                  <input
                                    type="text"
                                    name="city"
                                    className="form-control"
                                    placeholder="City"
                                    onChange={this.handleChanged}
                                    value={this.state.city}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md="2">
                                  <label htmlFor="">State</label>
                                </Col>
                                <Col md="7">
                                  <input
                                    type="text"
                                    name="state"
                                    className="form-control"
                                    placeholder="State"
                                    onChange={this.handleChanged}
                                    value={this.state.state}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md="2">
                                  <label htmlFor="">Postal Code </label>
                                </Col>
                                <Col md="7">
                                  <input
                                    type="number"
                                    name="postalCode"
                                    placeholder="Postal Code"
                                    className="form-control"
                                    onKeyPress={this.onKeyPress.bind(this)}
                                    pattern="[0-9]*"
                                    min="0"
                                    onChange={this.handleChanged}
                                    value={this.state.postalCode}
                                  />
                                  {postalErrorMsg ? (
                                    <div className="error">
                                      {postalErrorMsg}
                                    </div>
                                  ) : null}
                                </Col>
                              </Row>

                              {console.log(
                                this.state.lat,
                                this.state.long,
                                "this.state.latthis.state.lat"
                              )}
                            </div>
                          </Form.Group>
                          <Row>
                            <Col md="2"></Col>
                            <Col md="7">
                              <div className="text-right">
                                <Button
                                  type="submit"
                                  className="text-light"
                                  variant="warning"
                                >
                                  Submit
                                </Button>{" "}
                                &nbsp;
                                {/*<Button variant="warning">Save</Button>*/}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <div className="main_content">
                      {/*<form onSubmit={this.handleAddSubmit}>*/}

                      <div className="farm_page">
                        <Row>
                          <Col md="2">
                            <p>Farm Logo:</p>
                          </Col>
                          <Col md="7">
                            <div
                              className="file_uploader bg_size mx-auto"
                              style={{
                                backgroundImage: `url(${
                                  farmInfo.length > 0
                                    ? farmInfo[0].farmLogo
                                    : ""
                                })`,
                              }}
                            ></div>
                          </Col>
                          {/*<small>Recommended size 160*170 </small>*/}
                        </Row>
                        <Row>
                          <Col md="2">
                            <p>Farm Name:</p>
                          </Col>
                          <Col md="7">
                            <Form.Control
                              disabled
                              type="text"
                              name="farmName"
                              value={
                                farmInfo.length > 0 ? farmInfo[0].farmName : ""
                              }
                              placeholder="Farm name "
                            />
                          </Col>
                        </Row>{" "}
                        <Row>
                          <Col md="2">
                            <p>Short Farm Info:</p>
                          </Col>
                          <Col md="7">
                            <Form.Control
                              disabled
                              type="text"
                              name="shortInfo"
                              value={
                                farmInfo.length > 0 ? farmInfo[0].shortInfo : ""
                              }
                              placeholder="Short Farm Info "
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="2">
                            <p>Info About Farm:</p>
                          </Col>
                          <Col md="7">
                            <textarea
                              placeholder="Enter here"
                              disabled
                              name="infoAboutFarm"
                              value={
                                farmInfo.length > 0
                                  ? farmInfo[0].infoAboutFarm
                                  : ""
                              }
                              className="form-control"
                              rows="4"
                              cols="50"
                            ></textarea>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="2">
                            <p>Info About Farmer:</p>
                          </Col>
                          <Col md="7">
                            <textarea
                              placeholder="Enter here"
                              disabled
                              onChange={this.handleChanged}
                              name="infoAboutFarmer"
                              value={
                                farmInfo.length > 0
                                  ? farmInfo[0].infoAboutFarmer
                                  : ""
                              }
                              className="form-control"
                              rows="4"
                              cols="50"
                            ></textarea>
                          </Col>
                        </Row>
                        <Row>
                          {media.length > 0 ? (
                            <Col md="2">
                              <p>Farm Image:</p>
                            </Col>
                          ) : (
                            ""
                          )}

                          <Col md="7">
                            {media.length > 0 ? (
                              <div className="fileupload_wraper">
                                {media.map((value) => {
                                  return value.urlType === "I" ? (
                                    <div
                                      className="file_uploader bg_size"
                                      style={{
                                        backgroundImage: `url(${value.url})`,
                                      }}
                                    ></div>
                                  ) : (
                                    ""
                                  )
                                })}
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                        {/*    <Row>*/}
                        {/*    <Col md="2">*/}
                        {/*        <p>Farm Video:</p>*/}
                        {/*    </Col>*/}
                        {/*    <Col md="6">*/}
                        {/*        {farmInfo.length > 0 ? <div className=" ">*/}
                        {/*            {media.map(value => {*/}
                        {/*                return (*/}
                        {/*                    value.urlType === "V" ?*/}
                        {/*                        <Row>*/}
                        {/*                            <Col md="2">*/}
                        {/*                                <p>Farm Video:</p>*/}
                        {/*                            </Col>*/}
                        {/*                            <Col md="7">*/}
                        {/*                                <div className="fileupload_wraper ">*/}
                        {/*                            <div className="file_uploader bg_size max-w-100"*/}
                        {/*                             style={{backgroundImage: `url(${value.thumbUrl})`}}>*/}
                        {/*                        </div>*/}
                        {/*                                </div>*/}
                        {/*                            </Col>*/}
                        {/*                        </Row>: ''*/}
                        {/*                )*/}
                        {/*            })}*/}
                        {/*        </div> : ''}*/}
                        {/*    </Col>*/}
                        {/*</Row> */}
                        {/*{this.state.lat ||this.state.lng   ?*/}
                        <Form.Group>
                          {/*<p>Location On Map:</p>*/}
                          <div>
                            <Row>
                              <Col md="2">
                                {" "}
                                <label htmlFor="">Farm Address Line 1</label>
                              </Col>
                              <Col md="7">
                                <input
                                  type="text"
                                  name="addressLine1"
                                  className="form-control"
                                  placeholder="Farm Address Line 1"
                                  onChange={this.onChange}
                                  readOnly="readOnly"
                                  value={this.state.addressLine1}
                                />
                              </Col>
                            </Row>
                            {/*</div>*/}
                            <Row>
                              <Col md="2">
                                <label htmlFor="">Farm Address Line 2</label>
                              </Col>
                              <Col md="7">
                                {" "}
                                <input
                                  type="text"
                                  name="addressLine2"
                                  className="form-control"
                                  placeholder="Farm Address Line 2"
                                  onChange={this.handleChanged}
                                  readOnly="readOnly"
                                  value={this.state.addressLine2}
                                />
                              </Col>{" "}
                            </Row>

                            <Row>
                              <Col md="2">
                                <label htmlFor="">City</label>
                              </Col>
                              <Col md="7">
                                <input
                                  type="text"
                                  name="city"
                                  className="form-control"
                                  placeholder="City"
                                  onChange={this.handleChanged}
                                  readOnly="readOnly"
                                  value={this.state.city}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md="2">
                                <label htmlFor="">State</label>
                              </Col>
                              <Col md="7">
                                <input
                                  type="text"
                                  name="state"
                                  className="form-control"
                                  placeholder="State"
                                  onChange={this.handleChanged}
                                  readOnly="readOnly"
                                  value={this.state.state}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md="2">
                                <label htmlFor="">Postal Code </label>
                              </Col>
                              <Col md="7">
                                <input
                                  type="number"
                                  name="postalCode"
                                  placeholder="Postal Code"
                                  className="form-control"
                                  onChange={this.handleChanged}
                                  readOnly="readOnly"
                                  value={this.state.postalCode}
                                />
                              </Col>
                            </Row>

                            {console.log(
                              this.state.lat,
                              this.state.long,
                              "this.state.latthis.state.lat"
                            )}
                            {/*<Map1*/}

                            {/*    google={this.props.google}*/}
                            {/*    center={{lat: this.state.lat, lng:this.state.lng}}*/}
                            {/*    height='200px'*/}
                            {/*    zoom={15}*/}

                            {/*/>*/}
                            {/*12.5204, 75.8567*/}

                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d21784730.15225112!2d77.54500981874999!3d70.53342541794991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1588745436848!5m2!1sen!2sin" width="890" height="450" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                          </div>
                        </Form.Group>
                        {/*:''}*/}
                        <Row>
                          <Col md="8">
                            <div className="text-right">
                              <Link
                                className="btn btn-warning text-light"
                                variant="warning"
                                to={`/farmermanagement/farmedit/${this.state.userId}`}
                              >
                                Edit
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      {/*</form>*/}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Farm

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"

import rootReducer from "./reducers"

export default function configureAppStore(preloadedState) {
  const immutableMiddleware =
    process.env.NODE_ENV !== "prod"
      ? [require("redux-immutable-state-invariant").default()]
      : []

  const store = configureStore({
    reducer: rootReducer,
    middleware: [...immutableMiddleware, ...getDefaultMiddleware()],
    preloadedState,
  })

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(rootReducer))
  }

  return store
}

import React from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Tabs,
  Tab,
  Card,
  CardDeck,
  Col,
  Form,
  Modal,
  Row,
} from "react-bootstrap"

function Addfarm() {
  return (
    <div className="body_container">
      <div className="body_inner_wrapper">
        <header className="header_content">
          <div>
            <Link to="/popupmanagement/editpopupmanagement">
              <span className="mr-3 d-inline-block text_light text_12">
                <i class="fas fa-caret-left text_dark mr-2"></i> Back{" "}
              </span>
            </Link>
          </div>
        </header>
        <div className="main_content">
          <div className="farm_page">
            <Row>
              <Col md="2">
                <p>Farm Logo:</p>
              </Col>
              <Col md="7">
                <div className="file_uploader">
                  <i class="fas fa-plus-circle"></i>
                  <Form.File label="Custom file input" lang="en" custom />
                </div>
              </Col>
              <small>Recommended size 160*170 </small>
            </Row>
            <Row>
              <Col md="2">
                <p>Farm Name:</p>
              </Col>
              <Col md="7">
                <Form.Control type="text" placeholder="Enter email" />
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <p>Info About Farm:</p>
              </Col>
              <Col md="7">
                <textarea
                  placeholder="Enter here"
                  className="form-control"
                  rows="4"
                  cols="50"
                ></textarea>
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <p>Info About Farmer:</p>
              </Col>
              <Col md="7">
                <textarea
                  placeholder="Enter here"
                  className="form-control"
                  rows="4"
                  cols="50"
                ></textarea>
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <p>Farm Image:</p>
              </Col>
              <Col md="7">
                <div className="fileupload_wraper">
                  <div className="file_uploader">
                    <i class="fas fa-plus-circle"></i>
                    <Form.File label="Custom file input" lang="en" custom />
                  </div>
                  <div className="file_uploader">
                    <i class="fas fa-plus-circle"></i>
                    <Form.File label="Custom file input" lang="en" custom />
                  </div>
                  <div className="file_uploader">
                    <i class="fas fa-plus-circle"></i>
                    <Form.File label="Custom file input" lang="en" custom />
                  </div>
                  <div className="file_uploader">
                    <i class="fas fa-plus-circle"></i>
                    <Form.File label="Custom file input" lang="en" custom />
                  </div>
                  <div className="file_uploader">
                    <i class="fas fa-plus-circle"></i>
                    <Form.File label="Custom file input" lang="en" custom />
                  </div>
                </div>
              </Col>
              <small>Recommended size 160*170 </small>
            </Row>
            <Row>
              <Col md="2">
                <p>Farm Video:</p>
              </Col>
              <Col md="7">
                <div className="video_uploader video_upload videothumb">
                  <div className="file_uploader">
                    <i class="fas fa-plus-circle"></i>
                    <p>Upload Video</p>
                    <Form.File
                      label="Custom file input"
                      className="video_upload p-cm"
                      lang="en"
                      custom
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <div className="text-right">
                  <Button variant="warning">Save</Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Addfarm

export const validateImage = (image) => {
  const errors = {}
  if (image === "" || image === undefined) {
    errors["image"] = "Please select item image"
  }

  if (typeof image === "string" && image.startsWith("http")) {
    return errors
  }

  if (
    !(
      image.type === "image/jpeg" ||
      image.type === "image/gif" ||
      image.type === "image/png" ||
      image.type === "image/svg+xml"
    )
  ) {
    errors["image"] = "File type not supported."
  }

  if (image.size > 5000000) {
    errors["image"] = "Image size should be less than 5Mb."
  }
  return errors
}

export const validate = (item) => {
  const {
    image,
    organic,
    pledge,
    consumerPrice,
    consumerUnit,
    inventoryType,
    itemName,
    maximumConsumerQuantity,
    wholeselerUnit,
    wholeselerPrice,
    maximumWholeselerQuantity,
    otherItemName,
    otherWholeSalerUnit,
    otherConsumerUnit,
  } = item
  let errors = {}

  const imageErrors = validateImage(image)
  errors = {
    ...errors,
    ...imageErrors,
  }

  if (image === "" || image === undefined) {
    errors["image"] = "Please select item image"
  }

  if (image.size > 5000000) {
    errors["image"] = "Image size should be less than 5Mb."
  }

  if (consumerPrice === "" && wholeselerUnit === "") {
    errors["consumerPrice"] =
      "Please enter either consumer price or wholesale price."
  }

  if (consumerUnit === "" && wholeselerUnit === "") {
    errors["consumerUnit"] =
      "Please enter either consumer unit or wholesale unit."
  }

  if (
    consumerUnit === "13" &&
    (otherConsumerUnit === "" || otherConsumerUnit === undefined)
  ) {
    errors["consumerUnit"] = "Please enter other consumer unit."
  }

  if (
    wholeselerUnit === "13" &&
    (otherWholeSalerUnit === "" || otherWholeSalerUnit === undefined)
  ) {
    errors["wholeselerUnit"] = "Please enter other wholesale unit."
  }

  if (maximumConsumerQuantity === "" && maximumWholeselerQuantity === "") {
    errors["maximumConsumerQuantity"] =
      "Please enter either maximum consumer quantity or maximum wholesaler quantity."
  }

  if (consumerUnit && consumerPrice === "") {
    errors["consumerPrice"] = "Please enter consumer price."
  }

  if (maximumConsumerQuantity === "") {
    errors["maximumConsumerQuantity"] =
      "Please enter maximum consumer quantity."
  }

  if (maximumConsumerQuantity && consumerPrice === "") {
    errors["consumerPrice"] = "Please enter consumer price."
  }

  if (maximumConsumerQuantity && consumerUnit === "") {
    errors["consumerUnit"] = "Please enter other consumer unit."
  }

  if (consumerPrice && consumerUnit === "") {
    errors["consumerUnit"] = "Please enter consumer unit."
  }

  if (
    consumerPrice &&
    consumerUnit === "13" &&
    (otherConsumerUnit === "" || otherConsumerUnit === undefined)
  ) {
    errors["consumerUnit"] = "Please enter other consumer unit."
  }

  if (maximumConsumerQuantity === "") {
    errors["maximumConsumerQuantity"] =
      "Please enter maximum consumer quantity."
  }

  if (wholeselerUnit && wholeselerPrice === "") {
    errors["wholeselerPrice"] = "Please enter wholesale price."
  }

  if (wholeselerUnit && maximumWholeselerQuantity === "") {
    errors["maximumWholeselerQuantity"] =
      "Please enter maximum wholesale quantity."
  }

  if (wholeselerPrice && wholeselerUnit === "") {
    errors["wholeselerUnit"] = "Please enter maximum wholesale unit."
  }

  if (
    wholeselerPrice &&
    wholeselerUnit === "13" &&
    (otherWholeSalerUnit === "" || otherWholeSalerUnit === undefined)
  ) {
    errors["wholeselerUnit"] = "Please enter other wholesale unit."
  }

  if (maximumWholeselerQuantity && wholeselerPrice === "") {
    errors["wholeselerPrice"] = "Please enter wholesale price."
  }

  if (maximumWholeselerQuantity && wholeselerUnit === "") {
    errors["wholeselerUnit"] = "Please enter maximum wholesale unit."
  }

  if (
    maximumWholeselerQuantity &&
    wholeselerUnit === "13" &&
    (otherWholeSalerUnit === "" || otherWholeSalerUnit === undefined)
  ) {
    errors["wholeselerUnit"] = "Please enter other wholesale unit."
  }

  if (inventoryType === "" || inventoryType === undefined) {
    errors["inventoryType"] = "Please enter inventory type."
  }

  if (itemName === "" || itemName === undefined) {
    errors["itemName"] = "Please enter item name."
  }

  if (
    itemName === "others" &&
    (otherItemName === "" || otherItemName === undefined)
  ) {
    errors["itemName"] = "Please enter other item name."
  }

  if (organic === "" || organic === undefined) {
    errors["organic"] = "Please enter organic type."
  }

  if (pledge === "" || pledge === undefined) {
    errors["pledge"] = "Please enter pledge."
  }

  if (maximumConsumerQuantity === "" && maximumWholeselerQuantity === "") {
    errors["maximumConsumerQuantity"] =
      "Please enter either maximum consumer quantity or maximum wholesaler quantity."
  }

  return errors
}

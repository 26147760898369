import React, { useState, useEffect } from "react"
import { Select } from "antd"

import Url from "../../services/BaseUrl"
import { _Api } from "../../services/Api"

const debug = require("debug")("SelectHost")

const { Option } = Select

const findUiHostKey = (pickUpHostUiKey) => (host) =>
  host.pickUpHostUiKey === pickUpHostUiKey

export const SelectHost = ({ pickUpHostUiKey, setSelectedHostKey }) => {
  const [_pickUpHostUiKey, _setPickUpHostUiKey] = useState(pickUpHostUiKey)
  const [hosts, setHosts] = useState([])

  const onChange = (pickUpHostUiKey) => {
    _setPickUpHostUiKey(pickUpHostUiKey)
    setSelectedHostKey(pickUpHostUiKey)
  }

  useEffect(() => {
    _Api(
      Url.PICK_UP_HOST_GET_ELIGIBLE.method,
      Url.PICK_UP_HOST_GET_ELIGIBLE.url
    ).then((response) => {
      const responseHosts = response.responseData
      setHosts(responseHosts)
      // set default
      debug("pickUpHostUiKey")
      debug(pickUpHostUiKey)
      const hostToSet = responseHosts.find(findUiHostKey(pickUpHostUiKey))
      if (hostToSet && hostToSet.pickUpHostUiKey) {
        onChange(hostToSet.pickUpHostUiKey)
      } else if (
        responseHosts.length > 0 &&
        responseHosts[0] &&
        responseHosts[0].pickUpHostUiKey
      ) {
        onChange(responseHosts[0].pickUpHostUiKey)
      }
    })
  }, [])

  if (hosts.length === 0) return null

  if (!_pickUpHostUiKey) return null

  return (
    <Select
      mode="single"
      name="marketPickupHostIds"
      className="form-control"
      defaultValue={_pickUpHostUiKey}
      onChange={onChange}
    >
      {hosts.map((host) => {
        return (
          <Option value={host.pickUpHostUiKey} key={host.pickUpHostUiKey}>
            {host.pickUpHostUiDisplay}
          </Option>
        )
      })}
    </Select>
  )
}

import { Button, Col, Container, Form, Navbar, Row } from "react-bootstrap"
import React, { Component } from "react"

import Url from "../services/BaseUrl"
import _ from "lodash"
import { _Api } from "../services/Api"
import { isValidEmail } from "../utils/Validation"
import { notify } from "../components/common/Toaster"

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      errors: [],
      responseError: "",
      err: "",
    }
  }

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    })
  }

  handleValidate = () => {
    let validate = false
    let errors = {}
    let { email, desc, imageLocation, phone, countryCode } = this.state

    if (_.isEmpty(email)) {
      validate = true
      errors["email"] = "email is required."
    } else if (!isValidEmail(email)) {
      validate = true
      errors["email"] = "Enter a valid email."
    }

    this.setState({ errors })
    return validate
  }

  // Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "",
  handleForgotSubmit = (e) => {
    e.preventDefault()
    if (!this.handleValidate()) {
      let { email } = this.state
      let req = {
        email: email,
      }
      _Api(
        Url.SUPER_ADMIN_FORGOT_PASSWORD.method,
        Url.SUPER_ADMIN_FORGOT_PASSWORD.url,
        req
      )
        .then((resp) => {
          //  console.log('respuuuuuuuuuuuu', resp.error.message)
          if (resp.statusCode == 1) {
            notify("success", resp.responseData.message)
            this.props.history.goBack()
          }
          // this.setState({ driverInfo: resp.responseData.driverInfo, isLoader: false })
          // notify('success', resp.responseData.message)
          // this.props.history.goBack()
          // this.closeModal()
        })
        .catch((err) => notify("error", err.error.responseMessage))
    }
  }

  //sign in
  // handleForgotSubmit = e => {
  //     let { email,long, lat,password,userType} = this.state;
  //     this.setState({redirect: false, errors: ''});
  //     e.preventDefault();

  //     let self=this
  //     if (this.handleValidation()) {
  //         this.setState({redirect: false,loader: true});

  // let obj = {
  //     'email': email.toLowerCase(),
  // }

  // let {dispatch} = this.props;
  // dispatch(usersActions.forgot(obj)).then(data => {

  //     console.log(data,"Data:::::::lkjhgfdsdfghj")

  //     this.setState({loader: false})
  // let getData = this.props.user;

  //             if (data.data.statusCode == 1) {
  //                 let userInfo = data.data.responseData.userProfile
  //                 this.setState({success: data.data.responseData.message,errors:''})

  //                 // console.log(self.props.history, " this.props.history");
  //                 // self.props.history.push('/order');
  //             }
  //             else {
  //                 this.setState({errors: data.data.error.responseMessage})
  //             }
  //         });
  //     }
  // }

  // handleCloseModal = () => {
  //     this.setState({ rs:'',success:'',firstNameErrorMsg:'', errors:'',emailErrorMsg:'',passwordErrorMsg:'',confirmPasswordErrorMsg:'', })
  // }

  render() {
    let { email, password, passwordErrorMsg, emailErrorMsg, errors, success } =
      this.state
    return (
      <div className="body_container_wrapper login_page">
        <Container>
          <h1>
            <Navbar.Brand href="#home">
              <img
                src={require(".././assets/img/OFFICIAL LOGO TM WITH HEALTHY PLANEAT BLOCK.png")}
                alt="Logo"
              />
            </Navbar.Brand>
          </h1>
          <div className="login_wrapper">
            <Row>
              <Col md="4" className="mx-auto text-center">
                <div>
                  <img src={require(".././assets/img/OFFICIAL LOGO.png")} />
                </div>
                <h6>Forgot Password</h6>

                {/* <div className="error"> {errors} </div>
                                <div className="success"> {success} </div> */}

                <Form.Group>
                  <Form.Control
                    type="email"
                    onChange={this.handleOnChange}
                    name="email"
                    value={email}
                    placeholder="Email Address"
                  />
                  <span className="error_msg">{errors["email"]}</span>
                  {/* {emailErrorMsg ? <div className="error">{emailErrorMsg}</div> : null} */}
                </Form.Group>

                <Button
                  type="submit"
                  onClick={this.handleForgotSubmit}
                  variant="dark"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    )
  }
}

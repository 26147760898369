import * as debugFactory from "debug"
import Url from "../../services/BaseUrl"
import { _Api } from "../../services/Api"
import { inventoryAlphaSort } from "healthyplaneat-common"
import { IS_LOADING_START, IS_LOADING_STOP } from "./Type"

const debug = debugFactory("inventory").extend("action")

export const ActionTypes = {
  ADD_INVENTORY_ITEM_SHOW: "ADD_INVENTORY_ITEM_SHOW",
  ADD_INVENTORY_ITEM_DISMISS: "ADD_INVENTORY_ITEM_DISMISS",
  ADD_INVENTORY_ITEM_REQUEST: "ADD_INVENTORY_ITEM_REQUEST",
  ADD_INVENTORY_ITEM_SUCCESS: "ADD_INVENTORY_ITEM_SUCCESS",
  ADD_INVENTORY_ITEM_FAILURE: "ADD_INVENTORY_ITEM_FAILURE",
  LOAD_INVENTORY_REQUEST: "LOAD_INVENTORY_REQUEST",
  LOAD_INVENTORY_SUCCESS: "LOAD_INVENTORY_SUCCESS",
  LOAD_INVENTORY_FAILURE: "LOAD_INVENTORY_FAILURE",
  SHOW_INVENTORY_ITEM: "SHOW_INVENTORY_ITEM",
  HIDE_INVENTORY_ITEM: "HIDE_INVENTORY_ITEM",
  DELETE_INVENTORY_ITEM: "DELETE_INVENTORY_ITEM",
  DELETE_INVENTORY_ITEM_SHOW: "DELETE_INVENTORY_ITEM_SHOW",
  DELETE_INVENTORY_ITEM_DISMISS: "DELETE_INVENTORY_ITEM_DISMISS",
  DELETE_INVENTORY_ITEM_REQUEST: "DELETE_INVENTORY_ITEM_REQUEST",
  DELETE_INVENTORY_ITEM_SUCCESS: "DELETE_INVENTORY_ITEM_SUCCESS",
  DELETE_INVENTORY_ITEM_FAILURE: "DELETE_INVENTORY_ITEM_FAILURE",
  UPDATE_INVENTORY_ITEM_REQUEST: "UPDATE_INVENTORY_ITEM_REQUEST",
  UPDATE_INVENTORY_ITEM_SUCCESS: "UPDATE_INVENTORY_ITEM_SUCCESS",
  UPDATE_INVENTORY_ITEM_FAILURE: "UPDATE_INVENTORY_ITEM_FAILURE",
  UPDATE_INVENTORY_ITEM_STATUS_REQUEST: "UPDATE_INVENTORY_ITEM_STATUS_REQUEST",
  UPDATE_INVENTORY_ITEM_STATUS_SUCCESS: "UPDATE_INVENTORY_ITEM_STATUS_SUCCESS",
  UPDATE_INVENTORY_ITEM_STATUS_FAILURE: "UPDATE_INVENTORY_ITEM_STATUS_FAILURE",
  RESET_INVENTORY_STATE: "RESET_INVENTORY_STATE",
}

export function loadInventory(payload) {
  return function (dispatch) {
    debug("loadInventory")
    const { userId, inventoryType } = payload
    // check here to see if it's already loaded

    dispatch({ type: ActionTypes.LOAD_INVENTORY_REQUEST, userId })
    dispatch({ type: IS_LOADING_START })

    const parsedPayload = {
      ...payload,
      filterInv: inventoryType,
      pageNo: payload.pageNo - 1,
    }

    const params = Object.entries(parsedPayload)
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&")

    return _Api(
      Url.GET_INVENTORY.method,
      Url.GET_INVENTORY.url,
      "",
      `?${params}`
    )
      .then((response) => {
        dispatch({ type: IS_LOADING_STOP })
        response.responseData.result.inventoryList.sort(inventoryAlphaSort)
        dispatch({
          type: ActionTypes.LOAD_INVENTORY_SUCCESS,
          userId,
          payload: {
            list: response.responseData.result.inventoryList,
            inventoryType,
          },
        })
        return response.responseData?.result?.inventoryList
      })
      .catch((error) => {
        dispatch({ type: IS_LOADING_STOP })
        dispatch({
          type: ActionTypes.LOAD_INVENTORY_FAILURE,
          userId,
          error,
        })
        return error
      })
  }
}

export function deleteInventoryItem(payload) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.DELETE_INVENTORY_ITEM_REQUEST })

    _Api(
      Url.DELETE_INV_FARMER_ITEM.method,
      Url.DELETE_INV_FARMER_ITEM.url,
      payload
    ).then((data) => {
      if (data.statusCode === 1) {
        dispatch({
          type: ActionTypes.DELETE_INVENTORY_ITEM_SUCCESS,
          payload,
        })
      } else {
        dispatch({
          type: ActionTypes.DELETE_INVENTORY_ITEM_FAILURE,
          error: data.error,
        })
      }
    })
  }
}

export function updateInventoryItemStatus(payload) {
  return function (dispatch) {
    dispatch({ type: IS_LOADING_START })
    dispatch({ type: ActionTypes.UPDATE_INVENTORY_ITEM_STATUS_REQUEST })

    return _Api(
      Url.CHANGE_INV_FARMER_STATUS.method,
      Url.CHANGE_INV_FARMER_STATUS.url,
      payload
    )
      .then((data) => {
        dispatch({ type: IS_LOADING_STOP })
        if (data.statusCode === 1) {
          dispatch({
            type: ActionTypes.UPDATE_INVENTORY_ITEM_STATUS_SUCCESS,
            payload,
          })
        } else {
          dispatch({
            type: ActionTypes.UPDATE_INVENTORY_ITEM_STATUS_FAILURE,
            error: data.error,
          })
        }
      })
      .catch(() => {
        dispatch({ type: IS_LOADING_STOP })
      })
  }
}

export function updateInventoryItem({ inventoryItemForm }) {
  return function (dispatch) {
    dispatch({ type: "UPDATE_INVENTORY_ITEM_REQUEST" })

    return _Api(
      Url.EDIT_INV_FARMER_ITEM.method,
      Url.EDIT_INV_FARMER_ITEM.url,
      inventoryItemForm
    ).then((data) => {
      if (data.statusCode === 1) {
        dispatch({
          type: ActionTypes.UPDATE_INVENTORY_ITEM_SUCCESS,
        })
        return true
      } else {
        dispatch({
          type: ActionTypes.UPDATE_INVENTORY_ITEM_FAILURE,
          error: data.error,
        })
        return false
      }
    })
  }
}

export function addInventoryItem({ inventoryItemForm }) {
  return function (dispatch) {
    debug("addInventoryItem")
    dispatch({ type: ActionTypes.ADD_INVENTORY_ITEM_REQUEST })

    return _Api(
      Url.ADD_INV_FARMER_ITEM.method,
      Url.ADD_INV_FARMER_ITEM.url,
      inventoryItemForm
    )
      .then((data) => {
        if (data.statusCode === 1) {
          dispatch({
            type: ActionTypes.ADD_INVENTORY_ITEM_SUCCESS,
            payload: {
              ...data.requestParams,
              status: data.statusCode,
            },
          })
          return Promise.resolve(true)
        } else {
          dispatch({
            type: ActionTypes.ADD_INVENTORY_ITEM_FAILURE,
            error: data.error,
          })
          return Promise.resolve(false)
        }
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.ADD_INVENTORY_ITEM_FAILURE,
          error: error,
        })
        return Promise.reject(error)
      })
  }
}

import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import React, { Component } from "react"
import { InventoryItemForm } from "healthyplaneat-common"

import _ from "lodash"
import { connect } from "react-redux"

class ItemDetails extends Component {
  close = () => {
    this.props.dispatch({
      type: "HIDE_INVENTORY_ITEM",
    })
  }

  render() {
    const { item, isShow, itemList } = this.props

    if (_.isEmpty(item)) return null

    return (
      <Modal
        show={isShow}
        onHide={this.close}
        className="cm_order_view edit_item inventry view_sec"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Inventory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InventoryItemForm
            itemList={itemList}
            readOnly={true}
            inventoryItem={item}
            handleSubmit={() => {}}
            handleClose={() => {
              this.close()
            }}
          />
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    )
  }
}

export default connect((state) => ({
  itemList: state.inventory.list,
  item: state.inventory.item.details,
  isShow: state.inventory.item.isShow,
}))(ItemDetails)

import {
  Button,
  Card,
  CardDeck,
  Col,
  Form,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap"
import React, { Component } from "react"

import Loader from "../../components/common/Loader"
import Pagination from "react-js-pagination"
import Sidebar from "../../components/sidebar"
import Url from "../../services/BaseUrl"
import _ from "lodash"
import { _Api } from "../../services/Api"
import { connect } from "react-redux"
import { notify } from "../../components/common/Toaster"

class Inventory extends Component {
  constructor(props) {
    super(props)

    this.state = {
      custPrice: "",
      custUnit: "",
      invType: "",
      invName: "",
      maxConsumerQuantity: "",
      wholeselUnit: "",
      wholeselPrice: "",
      maxWholeselerQuantity: "",
      descript: "",

      errors: "",
      success: "",
      isEdit: false,
      show: false,
      dlt: false,
      edit: false,
      viewInventory: false,
      inventoryInfo: [],
      search: "",
      pageNo: 1,
      count: 12,
      view: 1,
      inventoryId: "",
      image: "",
      imagePreview: "",
      fileImage: "",
      fileImagePreview: "",
      imageErrorMsg: "",
      fileImageErrorMsg: "",
      itemName: "",
      inventoryType: 1,
      inventoryTypeEdit: 1,
      consumerUnit: "",
      consumerPrice: 0,
      maximumConsumerQuantity: "",
      wholeselerUnit: "",
      wholeselerPrice: "",
      maximumWholeselerQuantity: "",
      description: "",
      status: "",
      userId: localStorage.getItem("userId"),
      custUnitErrorMsg: "",
      custPriceErrorMsg: "",
      invTypeErrorMsg: "",
      invNameErrorMsg: "",
      maxConsumerQuantityErrorMsg: "",
      wholeselUnitErrorMsg: "",
      wholeselPriceErrorMsg: "",
      maxWholeselerQuantityErrorMsg: "",
      descriptErrorMsg: "",
      loader: false,
      organic: 1,
      editOrganic: 1,
      itemStatus: 1,
      variety: "",
      editVariety: "",
      organicEditErrorMsg: "",
      varietyErrorMsg: "",

      totalCount: 0,
      dataCountCount: 12,
      active: 0,
      inactive: 0,

      // totalItemResult: 0,
      // totalAllItem: 0,
      // totalActive: 0,
      // totalInActive:0
    }
  }

  componentDidMount() {
    this.getInventoryList()
  }

  handleAddValidation = () => {
    let validate = true
    let { image, custUnit, invName } = this.state

    if (image === "" || image === undefined) {
      validate = false
      this.setState({
        imageErrorMsg: "Please select item image.",
      })
    } else {
      if (image.size > 5000000) {
        validate = false
        this.setState({
          imageErrorMsg: "Image size should be less than 5Mb.",
        })
      } else {
        this.setState({
          imageErrorMsg: "",
        })
      }
    }

    if (custUnit === "" || custUnit === undefined) {
      validate = false
      this.setState({
        custUnitErrorMsg: "Please enter consumer unit.",
      })
    } else {
      this.setState({
        custUnitErrorMsg: "",
      })
    }

    if (invName === "" || invName === undefined) {
      validate = false
      this.setState({
        invNameErrorMsg: "Please enter item name.",
      })
    } else {
      this.setState({
        invNameErrorMsg: "",
      })
    }

    return validate
  }

  handleEditValidation = () => {
    let validate = true
    let { fileImage, itemName, consumerUnit } = this.state

    if (fileImage) {
      if (fileImage === "" || fileImage === undefined) {
        validate = false
        this.setState({
          fileImageErrorMsg: "Please select item image.",
        })
      } else if (fileImage.size > 5000000) {
        validate = false
        this.setState({
          fileImageErrorMsg: "Image size should be less than 5Mb.",
        })
      } else {
        this.setState({
          fileImageErrorMsg: "",
        })
      }
    }
    if (consumerUnit === "" || consumerUnit === undefined) {
      validate = false
      this.setState({
        custUnitErrorMsg: "Please enter consumer unit.",
      })
    } else {
      this.setState({
        custUnitErrorMsg: "",
      })
    }
    if (itemName === "" || itemName === undefined) {
      validate = false
      this.setState({
        invNameErrorMsg: "Please enter item name.",
      })
    } else {
      this.setState({
        invNameErrorMsg: "",
      })
    }

    return validate
  }

  handleAddSubmit = (e) => {
    let { image, invName, custUnit } = this.state
    this.setState({ errors: "" })
    e.preventDefault()

    let self = this
    if (this.handleAddValidation()) {
      this.setState({ redirect: false, show: false })
      let obj = new FormData()

      obj.append("fileData", image)
      obj.append("itemName", invName)
      obj.append("status", 1)
      obj.append("consumerUnit", custUnit)

      self.setState({ loader: true })

      _Api(Url.ADD_INVENTORY.method, Url.ADD_INVENTORY.url, obj)
        .then((resp) => {
          if (resp.statusCode === 1) {
            this.setState({
              loader: false,
              invName: "",
              imagePreview: "",
              image: "",
              custUnit: "",
            })
            this.getInventoryList()
            notify("success", resp.responseData.message)
          } else {
            notify("error", resp.data.error.responseMessage)
            this.setState({ loader: false })
          }
        })
        .catch((err) => {
          this.setState({ loader: false })

          notify("error", err.data.error.responseMessage)
        })
    }
  }

  handleEditSubmit = (e, item) => {
    let { inventoryId, fileImage, itemName, consumerUnit, status } = this.state
    this.setState({ errors: "" })
    e.preventDefault()

    let self = this
    if (this.handleEditValidation()) {
      this.setState({ edit: false })
      let obj = new FormData()

      obj.append("itemId", inventoryId)
      if (fileImage) obj.append("fileData", fileImage)
      if (itemName) obj.append("itemName", itemName)
      if (status) obj.append("status", 1)
      if (consumerUnit)
        obj.append("consumerUnit", consumerUnit ? consumerUnit : "")

      self.setState({ loader: true })

      _Api(Url.EDIT_INVENTORY.method, Url.EDIT_INVENTORY.url, obj)
        .then((resp) => {
          if (resp.statusCode === 1) {
            this.getInventoryList()
            this.setState({ loader: false })
            notify("success", resp.responseData.message)
          } else {
            notify("error", resp.data.error.responseMessage)
            this.setState({ loader: false })
          }
        })
        .catch((err) => {
          this.setState({ loader: false })

          notify("error", err.data.error.responseMessage)
        })
    }
  }

  getInventoryList = () => {
    let { search, pageNo, count, sortField, sortType, itemStatus } = this.state
    let req = { search, pageNo, count, sortField, sortType, itemStatus }

    this.setState({ loader: true })
    _Api(
      Url.SUPER_ADMIN_ITEMS_LIST.method,
      Url.SUPER_ADMIN_ITEMS_LIST.url,
      "",
      `?search=${req.search}&count=${req.count}&pageNo=${
        req.pageNo - 1
      }&sortType=${req.sortType ? req.sortType : ""}&sortField=${
        req.sortField ? req.sortField : ""
      }&itemStatus=${req.itemStatus}`
    )
      .then((resp) => {
        let inventoryInfo = resp.responseData.result.itemList
        this.setState({
          inventoryInfo: resp.responseData.result.itemList,
          totalCount: resp.responseData.result.totalAllItem,
          dataCountCount: resp.responseData.result.totalItemResult,
          active: resp.responseData.result.totalActive,
          inactive: resp.responseData.result.totalInActive,
          fileImagePreview: inventoryInfo.image,
          itemName: inventoryInfo.itemName,

          loader: false,
        })
      })
      .catch((err) => {
        this.setState({ loader: false })

        notify("error", err.data.error.responseMessage)
      })
  }

  handleClose = () => {
    this.setState({
      show: false,
      viewInventory: false,
      imageErrorMsg: "",
      custPriceErrorMsg: "",
      custUnitErrorMsg: "",
      invTypeErrorMsg: "",
      varietyErrorMsg: "",
      invNameErrorMsg: "",
      organicEditErrorMsg: "",
      maxConsumerQuantityErrorMsg: "",
      wholeselPriceErrorMsg: "",
      maxWholeselerQuantityErrorMsg: "",
      wholeselUnitErrorMsg: "",
      descriptErrorMsg: "",
      imagePreview: "",
    })
  }
  handleShow = () => {
    this.setState({ show: true })
  }

  openViewPopup = (e, item) => {
    this.setState({
      viewInventory: true,
      consumerUnit: item.consumerUnit,
      consumerPrice: item.consumerPrice,
      maximumConsumerQuantity: item.maximumConsumerQuantity,
      wholeselerUnit: item.wholeselerUnit,
      wholeselerPrice: item.wholeselerPrice,
      maximumWholeselerQuantity: item.maximumWholeselerQuantity,
      fileImage: item.image,
      fileImagePreview: item.image,
      itemName: item.itemName,
      description: item.description,
      inventoryTypeEdit: item.inventoryType,
      inventoryType: item.inventoryType,
      editVariety: item.variety,
      editOrganic: item.organic,
    })
  }
  CloseDeleteScreen = () => {
    this.setState({ dlt: false })
  }
  DeleteItem = (e, item) => {
    this.setState({ dlt: true, inventoryId: item._id })
  }
  CloseEdit = () => {
    this.setState({ edit: false })
  }
  EditItem = (e, item) => {
    this.setState({
      edit: true,
      inventoryId: item._id,
      consumerUnit: item.consumerUnit,
      consumerPrice: item.consumerPrice,
      maximumConsumerQuantity: item.maximumConsumerQuantity,
      wholeselerUnit: item.wholeselerUnit,
      wholeselerPrice: item.wholeselerPrice,
      maximumWholeselerQuantity: item.maximumWholeselerQuantity,
      fileImage: item.image,
      fileImagePreview: item.image,
      itemName: item.itemName,
      description: item.description,
      inventoryTypeEdit: item.inventoryType,
      editVariety: item.variety,
      editOrganic: item.organic,
    })
  }

  handleChanged = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      custUnitErrorMsg: "",
      custPriceErrorMsg: "",
      invTypeErrorMsg: "",
      invNameErrorMsg: "",
      maxConsumerQuantityErrorMsg: "",

      wholeselPriceErrorMsg: "",
      maxWholeselerQuantityErrorMsg: "",
      descriptErrorMsg: "",
      imageErrorMsg: "",
      varietyErrorMsg: "",
      organicEditErrorMsg: "",
      wholeselUnitErrorMsg: "",
    })
  }
  editMode = () => {
    this.setState({ isEdit: true })
  }

  viewMode = () => {
    this.setState({ isEdit: false })
  }
  inventoryType = (e) => {
    if (e === "") {
      this.setState({ itemStatus: "", inventoryType: "", pageNo: 1 }, () =>
        this.getInventoryList()
      )
    } else if (e === "1") {
      this.setState({ itemStatus: 1, inventoryType: 1, pageNo: 1 }, () =>
        this.getInventoryList()
      )
    } else if (e === "2") {
      this.setState({ itemStatus: 2, inventoryType: 2, pageNo: 1 }, () =>
        this.getInventoryList()
      )
    }
  }
  changeView = (e) => {
    this.setState({ view: e }, () => {})
  }
  handleStatusChange = (ac, status) => {
    let { userId } = this.state
    let obj = {
      itemId: status._id,
      status: status.status === 2 ? 1 : 2,
      userId: userId,
    }
    this.setState({ loader: true })

    _Api(Url.CHANGE_INVENTORY.method, Url.CHANGE_INVENTORY.url, obj)
      .then((resp) => {
        notify("success", resp.responseData.message)
        this.setState({ visible: false, loader: false })
        this.getInventoryList()
      })
      .catch((err) => {
        this.setState({ loader: false })

        notify("error", err.data.error.responseMessage)
      })
  }

  handleDelete = (ac, status) => {
    let { inventoryId } = this.state

    let obj = {
      itemId: inventoryId,
    }
    this.setState({ loader: false, dlt: false })

    _Api(Url.DELETE_INVENTORY.method, Url.DELETE_INVENTORY.url, obj)
      .then((resp) => {
        console.log("delete", resp.responseData.message)
        notify("success", resp.responseData.message)
        this.setState({ visible: false, loader: false })
        this.getInventoryList()
      })
      .catch((err) => {
        this.setState({ loader: false })

        notify("error", err.data.error.responseMessage)
      })
  }

  handleAddImageUpload = (event) => {
    let reader = new FileReader()
    let file = event.target.files[0]

    reader.addEventListener(
      "load",
      () => {
        this.setState({ imagePreview: reader.result }, function () {})
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/gif" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml"
      ) {
        if (file.size > 5000000) {
          this.setState({
            image: event.target.files[0],
            imageErrorMsg: "File size should be less than 5Mb.",
            valiDate: false,
          })
        } else {
          this.setState({
            image: event.target.files[0],
            imageErrorMsg: "",
          })
        }
      } else {
        this.setState({
          imageErrorMsg: "File type not supported.",
          valiDate: false,
        })
      }
    }
  }
  handleEditImageUpload = (event) => {
    let reader = new FileReader()
    let file = event.target.files[0]

    reader.addEventListener(
      "load",
      () => {
        this.setState({ fileImagePreview: reader.result }, function () {})
      },
      false
    )

    if (file) {
      reader.readAsDataURL(file)
    }

    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/gif" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml"
      ) {
        if (file.size > 5000000) {
          this.setState({
            fileImage: event.target.files[0],
            fileImageErrorMsg: "File size should be less than 5Mb.",
            valiDate: false,
          })
        } else {
          this.setState({
            fileImage: event.target.files[0],
            fileImageErrorMsg: "",
          })
        }
      } else {
        this.setState({
          fileImageErrorMsg: "File type not supported.",
          valiDate: false,
        })
      }
    }
  }
  handlePageChange = (pageNo) => {
    var self = this
    this.setState({ loader: true, pageNo: pageNo, count: 12 }, () => {
      self.getInventoryList()
    })
  }

  handleSearch = (e) => {
    this.setState({ search: e.target.value })

    this.setState({ search: e.target.value })
    if (e.target.value.length > 2) {
      this.setState({ search: e.target.value }, () => this.getInventoryList())
    }
    if (e.target.value === "") {
      this.setState({ search: e.target.value }, () => this.getInventoryList())
    }
  }

  render() {
    let {
      search,
      itemStatus,
      edit,
      dlt,
      show,
      inventoryInfo,
      itemName,
      consumerUnit,
      inventoryType,
      imagePreview,
      imageErrorMsg,
      viewInventory,
      fileImagePreview,
      fileImageErrorMsg,
      invName,
      custUnitErrorMsg,
      invNameErrorMsg,
      totalCount,
      dataCountCount,
      active,
      custUnit,
      inactive,
      view,
    } = this.state

    return (
      <>
        <Sidebar />

        <div>
          <div className="header_wrapper">
            <div className="header_logo">
              <img src={require("../../assets/img/OFFICIAL LOGO.png")} />
            </div>
            <div className="header_search">
              <div className="form-group has-search">
                <span className="fa fa-search form-control-feedback"></span>
                <Form.Control
                  type="search"
                  placeholder="Search..."
                  name="search"
                  value={search}
                  onChange={this.handleSearch}
                />
              </div>
            </div>
            <div className="bell_icon">
              <i className="fas fa-bell"></i>
            </div>
          </div>

          <div className="body_container">
            <div className="body_inner_wrapper">
              {this.state.loader ? (
                <Loader />
              ) : (
                <>
                  <header className="header_content">
                    <p>Items </p>
                    <div className="d-flex text-nowrap header_btn">
                      <Button
                        onClick={(e) => this.changeView(1)}
                        variant="light"
                      >
                        <i className="fas fa-th-large"></i>
                      </Button>
                      <Button
                        onClick={(e) => this.changeView(2)}
                        variant="light"
                      >
                        <i className="fas fa-bars"></i>
                      </Button>
                      <Button
                        variant="warning"
                        className="text-light"
                        onClick={this.handleShow}
                      >
                        <i className="fas fa-plus-circle"></i> Add Items
                      </Button>
                    </div>
                  </header>
                  <div className="main_content">
                    {view === 1 ? (
                      <div className="cm-inventory">
                        <Tabs
                          defaultActiveKey=""
                          onSelect={(e) => this.inventoryType(e)}
                          activeKey={inventoryType}
                          id="uncontrolled-tab-example"
                        >
                          <Tab
                            eventKey=""
                            title={`Total Items (${totalCount}) `}
                          >
                            <CardDeck>
                              {!_.isEmpty(inventoryInfo) &&
                                inventoryInfo.map((value) => {
                                  return (
                                    <Col md="itemwidth_inventry">
                                      <Card className="mb-3">
                                        <div className="inventry_img_wrapper">
                                          <Card.Img
                                            variant="top"
                                            src={
                                              inventoryInfo
                                                ? value.media[0].url
                                                : ""
                                            }
                                          />
                                          <div className="edit_dlt">
                                            <span>
                                              <i
                                                className="fas fa-eye"
                                                onClick={(e) =>
                                                  this.openViewPopup(e, value)
                                                }
                                              ></i>
                                            </span>

                                            <span>
                                              <i
                                                className="fas fa-pen"
                                                onClick={(e) =>
                                                  this.EditItem(e, value)
                                                }
                                              ></i>
                                            </span>
                                            <span>
                                              <i
                                                onClick={(e) =>
                                                  this.DeleteItem(e, value)
                                                }
                                                className="fas fa-trash-alt"
                                              ></i>
                                            </span>
                                          </div>
                                        </div>

                                        <Card.Body>
                                          <Card.Title>
                                            <div>{value.itemName}</div>
                                            <div>
                                              <Form.Check
                                                type="switch"
                                                id={value._id}
                                                style={{ cursor: "pointer" }}
                                                checked={
                                                  value.status === 1
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  this.handleStatusChange(
                                                    e,
                                                    value
                                                  )
                                                }
                                                label="."
                                              />
                                            </div>
                                          </Card.Title>
                                          <Card.Text></Card.Text>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  )
                                })}
                            </CardDeck>
                          </Tab>
                          <Tab eventKey="1" title={`Active Items (${active}) `}>
                            <CardDeck>
                              {inventoryInfo.map((value) => {
                                return (
                                  <Col md="itemwidth_inventry">
                                    <Card className="mb-3">
                                      <div className="inventry_img_wrapper">
                                        <Card.Img
                                          variant="top"
                                          src={
                                            inventoryInfo
                                              ? value.media[0].url
                                              : ""
                                          }
                                        />
                                        <div className="edit_dlt">
                                          <span>
                                            <i
                                              className="fas fa-eye"
                                              onClick={(e) =>
                                                this.openViewPopup(e, value)
                                              }
                                            ></i>
                                          </span>
                                          <span>
                                            <i
                                              className="fas fa-pen"
                                              onClick={(e) =>
                                                this.EditItem(e, value)
                                              }
                                            ></i>
                                          </span>
                                          <span>
                                            <i
                                              onClick={(e) =>
                                                this.DeleteItem(e, value)
                                              }
                                              className="fas fa-trash-alt"
                                            ></i>
                                          </span>
                                        </div>
                                      </div>

                                      <Card.Body>
                                        <Card.Title>
                                          <div>{value.itemName}</div>
                                          <div>
                                            <Form.Check
                                              type="switch"
                                              id={value._id}
                                              checked={
                                                value.status === 1
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                this.handleStatusChange(
                                                  e,
                                                  value
                                                )
                                              }
                                              label="."
                                            />
                                          </div>
                                        </Card.Title>
                                        <Card.Text></Card.Text>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                )
                              })}
                            </CardDeck>
                          </Tab>
                          <Tab
                            eventKey="2"
                            title={`Inactive Items (${inactive}) `}
                          >
                            <CardDeck>
                              {inventoryInfo.map((value) => {
                                return (
                                  <Col md="itemwidth_inventry">
                                    <Card className="mb-3">
                                      <div className="inventry_img_wrapper">
                                        <Card.Img
                                          variant="top"
                                          src={
                                            inventoryInfo
                                              ? value.media[0].url
                                              : ""
                                          }
                                        />
                                        <div className="edit_dlt">
                                          <span>
                                            <i
                                              className="fas fa-eye"
                                              onClick={(e) =>
                                                this.openViewPopup(e, value)
                                              }
                                            ></i>
                                          </span>
                                          <span>
                                            <i
                                              className="fas fa-pen"
                                              onClick={(e) =>
                                                this.EditItem(e, value)
                                              }
                                            ></i>
                                          </span>
                                          <span>
                                            <i
                                              onClick={(e) =>
                                                this.DeleteItem(e, value)
                                              }
                                              className="fas fa-trash-alt"
                                            ></i>
                                          </span>
                                        </div>
                                      </div>

                                      <Card.Body>
                                        <Card.Title>
                                          <div>{value.itemName}</div>
                                          <div>
                                            <Form.Check
                                              type="switch"
                                              id={value._id}
                                              checked={
                                                value.status === 1
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                this.handleStatusChange(
                                                  e,
                                                  value
                                                )
                                              }
                                              label="."
                                            />
                                          </div>
                                        </Card.Title>
                                        <Card.Text></Card.Text>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                )
                              })}
                            </CardDeck>
                          </Tab>
                        </Tabs>
                      </div>
                    ) : (
                      <div className="cm-inventory">
                        <Tabs
                          defaultActiveKey=""
                          onSelect={(e) => this.inventoryType(e)}
                          activeKey={inventoryType}
                          id="uncontrolled-tab-example"
                        >
                          <Tab
                            eventKey=""
                            title={`Total Items (${totalCount}) `}
                          >
                            <div className="cm-inventory_gridview">
                              <div className="table-responsive">
                                <table className="table cm_bottom_space">
                                  <thead>
                                    <tr className="broder_top_0">
                                      <th scope="col" width="100">
                                        S.No.
                                      </th>
                                      <th scope="col">Image</th>
                                      <th scope="col">Name</th>

                                      <th scope="col">Active/Inactive</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {console.log(
                                      inventoryInfo,
                                      "inventoryInfoinventoryInfo"
                                    )}
                                    {!_.isEmpty(inventoryInfo) &&
                                      inventoryInfo.map((item, i) => (
                                        <tr key={item._id}>
                                          <th>{i + 1}</th>
                                          <td>
                                            <img
                                              height="20px"
                                              width="20px"
                                              src={
                                                inventoryInfo
                                                  ? item.media[0].url
                                                  : ""
                                              }
                                            />
                                          </td>
                                          <td>
                                            <div
                                              onClick={(e) =>
                                                this.openViewPopup(e, item)
                                              }
                                            >
                                              {item.itemName}
                                            </div>
                                          </td>
                                          <td>
                                            <div
                                              onClick={(e) =>
                                                this.openViewPopup(e, item)
                                              }
                                            >
                                              {item.status === 1
                                                ? "Active"
                                                : "Inactive"}
                                            </div>
                                          </td>
                                          <td>
                                            <Form.Check
                                              type="switch"
                                              id={item._id}
                                              checked={
                                                item.status === 1 ? true : false
                                              }
                                              onChange={(e) =>
                                                this.handleStatusChange(e, item)
                                              }
                                              label=""
                                            />
                                          </td>
                                          <td width="100">
                                            <div className="action_wrapper">
                                              <span>
                                                <i
                                                  className="fas fa-eye"
                                                  onClick={(e) =>
                                                    this.openViewPopup(e, item)
                                                  }
                                                ></i>
                                              </span>
                                              <span>
                                                <i
                                                  style={{ color: "#F78F1E" }}
                                                  className="fas fa-pen"
                                                  onClick={(e) =>
                                                    this.EditItem(e, item)
                                                  }
                                                ></i>
                                              </span>
                                              <span>
                                                <i
                                                  style={{ color: "red" }}
                                                  onClick={(e) =>
                                                    this.DeleteItem(e, item)
                                                  }
                                                  className="fas fa-trash-alt"
                                                ></i>
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}{" "}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="1" title={`Active Items (${active}) `}>
                            <div className="cm-inventory_gridview">
                              <div className="table-responsive">
                                <table className="table cm_bottom_space">
                                  <thead>
                                    <tr className="broder_top_0">
                                      <th scope="col" width="100">
                                        S.No.
                                      </th>
                                      <th scope="col">Image</th>
                                      <th scope="col">Name</th>
                                      <th scope="col">Active/Inactive</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {console.log(
                                      inventoryInfo,
                                      "inventoryInfoinventoryInfo"
                                    )}
                                    {!_.isEmpty(inventoryInfo) &&
                                      inventoryInfo.map((item, i) => (
                                        <tr key={item._id}>
                                          <th>{i + 1}</th>
                                          <td>
                                            <img
                                              height="20px"
                                              width="20px"
                                              src={
                                                inventoryInfo
                                                  ? item.media[0].url
                                                  : ""
                                              }
                                            />
                                          </td>
                                          <td>
                                            <div
                                              onClick={(e) =>
                                                this.openViewPopup(e, item)
                                              }
                                            >
                                              {item.itemName}
                                            </div>
                                          </td>

                                          <td>
                                            <div
                                              onClick={(e) =>
                                                this.openViewPopup(e, item)
                                              }
                                            >
                                              {item.status === 1
                                                ? "Active"
                                                : "Inactive"}
                                            </div>
                                          </td>
                                          <td width="100">
                                            <div className="action_wrapper">
                                              <span>
                                                <i
                                                  className="fas fa-eye"
                                                  onClick={(e) =>
                                                    this.openViewPopup(e, item)
                                                  }
                                                ></i>
                                              </span>
                                              <span>
                                                <i
                                                  style={{ color: "#F78F1E" }}
                                                  className="fas fa-pen"
                                                  onClick={(e) =>
                                                    this.EditItem(e, item)
                                                  }
                                                ></i>
                                              </span>
                                              <span>
                                                <i
                                                  style={{ color: "red" }}
                                                  onClick={(e) =>
                                                    this.DeleteItem(e, item)
                                                  }
                                                  className="fas fa-trash-alt"
                                                ></i>
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}{" "}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Tab>
                          <Tab
                            eventKey="2"
                            title={`Inactive Items (${inactive}) `}
                          >
                            <div className="cm-inventory_gridview">
                              <div className="table-responsive">
                                <table className="table cm_bottom_space">
                                  <thead>
                                    <tr className="broder_top_0">
                                      <th scope="col" width="100">
                                        S.No.
                                      </th>
                                      <th scope="col">Image</th>
                                      <th scope="col">Name</th>
                                      <th scope="col">Active/Inactive</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!_.isEmpty(inventoryInfo) &&
                                      inventoryInfo.map((item, i) => (
                                        <tr key={item._id}>
                                          <th>{i + 1}</th>
                                          <td>
                                            <img
                                              height="20px"
                                              width="20px"
                                              src={
                                                inventoryInfo
                                                  ? item.media[0].url
                                                  : ""
                                              }
                                            />
                                          </td>
                                          <td>
                                            <div
                                              onClick={(e) =>
                                                this.openViewPopup(e, item)
                                              }
                                            >
                                              {item.itemName}
                                            </div>
                                          </td>

                                          <td>
                                            <div
                                              onClick={(e) =>
                                                this.openViewPopup(e, item)
                                              }
                                            >
                                              {item.status === 1
                                                ? "Active"
                                                : "Inactive"}
                                            </div>
                                          </td>
                                          <td>
                                            <Form.Check
                                              type="switch"
                                              id={item._id}
                                              checked={
                                                item.status === 1 ? true : false
                                              }
                                              onChange={(e) =>
                                                this.handleStatusChange(e, item)
                                              }
                                              label=""
                                            />
                                          </td>
                                          <td width="100">
                                            <div className="action_wrapper">
                                              <span>
                                                <i
                                                  className="fas fa-eye"
                                                  onClick={(e) =>
                                                    this.openViewPopup(e, item)
                                                  }
                                                ></i>
                                              </span>
                                              <span>
                                                <i
                                                  style={{ color: "#F78F1E" }}
                                                  className="fas fa-pen"
                                                  onClick={(e) =>
                                                    this.EditItem(e, item)
                                                  }
                                                ></i>
                                              </span>
                                              <span>
                                                <i
                                                  style={{ color: "red" }}
                                                  onClick={(e) =>
                                                    this.DeleteItem(e, item)
                                                  }
                                                  className="fas fa-trash-alt"
                                                ></i>
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}{" "}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    )}
                    {_.isEmpty(inventoryInfo) ? (
                      <h3 className="text-center">No data found</h3>
                    ) : (
                      ""
                    )}
                    {
                      //proper

                      dataCountCount && dataCountCount ? (
                        <div className="d-flex justify-content-end cm-pagination">
                          <Pagination
                            itemClassPrev={"prevClass"}
                            itemClassNext={"nextClass"}
                            activePage={this.state.pageNo}
                            itemsCountPerPage={this.state.count}
                            totalItemsCount={
                              itemStatus === 1
                                ? this.state.active
                                : itemStatus === 2
                                ? this.state.inactive
                                : this.state.totalCount
                            }
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      ) : (
                        ""
                      )
                    }
                  </div>
                </>
              )}
            </div>

            <Modal
              show={edit}
              onHide={this.CloseEdit}
              className="cm_order_view edit_item inventry"
            >
              <form onSubmit={this.handleEditSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Items</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <Row>
                    <Col md="4">
                      <p>Item</p>
                    </Col>
                    <Col md="8">
                      <div
                        className="file_uploader"
                        style={{ backgroundImage: `url(${fileImagePreview})` }}
                      >
                        <div className="overlay"></div>
                        <i className="fas fa-pen text-light"></i>
                        <Form.File
                          label="Custom file input"
                          name="fileImage"
                          lang="en"
                          onChange={this.handleEditImageUpload}
                          custom
                        />
                      </div>
                      {fileImageErrorMsg ? (
                        <div className="error">{fileImageErrorMsg}</div>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <p> Item Name</p>
                    </Col>
                    <Col md="8">
                      <Form.Control
                        type="text"
                        onChange={this.handleChanged}
                        name="itemName"
                        value={itemName}
                        placeholder="Item name"
                      />
                      {invNameErrorMsg ? (
                        <div className="error">{invNameErrorMsg}</div>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <p>Consumer Unit:</p>
                    </Col>
                    <Col md="8">
                      <select
                        name="consumerUnit"
                        value={consumerUnit}
                        onChange={this.handleChanged}
                        className="form-control"
                      >
                        <option value="">Select</option>
                        <option value="3">bunch</option>
                        <option value="5">pint</option>
                        <option value="6">head</option>
                        <option value="7">bag</option>
                        <option value="8">half pint</option>
                        <option value="10">Pound</option>
                        <option value="11">Half Pound Bag</option>
                        <option value="12">Half Pound</option>
                        <option value="13">others</option>
                      </select>

                      {custUnitErrorMsg ? (
                        <div className="error">{custUnitErrorMsg}</div>
                      ) : null}
                    </Col>
                  </Row>
                </Modal.Body>

                <Modal.Footer>
                  <div className="footer_btn_wrapper">
                    <Button variant="warning" type="submit">
                      Save
                    </Button>
                    <Button variant="dark" onClick={this.CloseEdit}>
                      Cancel
                    </Button>
                  </div>
                </Modal.Footer>
              </form>
            </Modal>

            <Modal
              show={dlt}
              onHide={this.CloseDeleteScreen}
              className="cm_order_view dlt_item"
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img src={require("../../assets/img/Delete-Email.png")} />
                <br />
                <br />
                <p>Are you sure you want to delete this item?</p>
              </Modal.Body>
              <Modal.Footer>
                <div className="footer_btn_wrapper btn_nospace">
                  <Button
                    variant="warning"
                    onClick={(e) => this.handleDelete()}
                  >
                    Delete
                  </Button>

                  <Button variant="dark" onClick={this.CloseDeleteScreen}>
                    Cancel
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>

            <Modal
              show={show}
              onHide={this.handleClose}
              className="cm_order_view inventry"
            >
              <form onSubmit={this.handleAddSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Items</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col md="4">
                      <p>Item</p>
                    </Col>
                    <Col md="8">
                      <div
                        className="file_uploader"
                        style={{ backgroundImage: `url(${imagePreview})` }}
                      >
                        {imagePreview ? (
                          <>
                            <div className="overlay"></div>
                            <i className="fas fa-pen text-light"></i>
                          </>
                        ) : (
                          <i className="fas fa-plus-circle"></i>
                        )}

                        <Form.File label="Custom file input" lang="en" custom />
                        <Form.File
                          label="Custom file input"
                          name="image"
                          lang="en"
                          accept="image/x-png,image/gif,image/jpeg,image/jpg"
                          onChange={this.handleAddImageUpload}
                          custom
                        />
                      </div>
                      {imageErrorMsg ? (
                        <div className="error">{imageErrorMsg}</div>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      {/*<p>Select Item:</p>*/}
                      <p> Item Name</p>
                    </Col>
                    <Col md="8">
                      <Form.Control
                        type="text"
                        onChange={this.handleChanged}
                        name="invName"
                        value={invName}
                        placeholder="Item name"
                      />
                      {invNameErrorMsg ? (
                        <div className="error">{invNameErrorMsg}</div>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <p>Consumer Unit:</p>
                    </Col>
                    <Col md="8">
                      <select
                        name="custUnit"
                        value={custUnit}
                        onChange={this.handleChanged}
                        className="form-control"
                      >
                        <option value="">Select</option>
                        <option value="3">bunch</option>
                        <option value="5">pint</option>
                        <option value="6">head</option>
                        <option value="7">bag</option>
                        <option value="8">half pint</option>
                        <option value="10">Pound</option>
                        <option value="11">Half Pound Bag</option>
                        <option value="12">Half Pound</option>
                        <option value="13">others</option>
                      </select>
                      {custUnitErrorMsg ? (
                        <div className="error">{custUnitErrorMsg}</div>
                      ) : null}
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <div className="footer_btn_wrapper">
                    <Button
                      variant="warning"
                      className="text-light"
                      type="submit"
                    >
                      Submit
                    </Button>
                    <Button variant="dark" onClick={this.handleClose}>
                      Cancel
                    </Button>
                  </div>
                </Modal.Footer>
              </form>
            </Modal>

            <Modal
              show={viewInventory}
              onHide={this.handleClose}
              className="cm_order_view edit_item inventry"
            >
              <Modal.Header closeButton>
                <Modal.Title>View Items</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md="4">
                    <p>Item</p>
                  </Col>
                  <Col md="8">
                    <div
                      className="file_uploader"
                      style={{ backgroundImage: `url(${fileImagePreview})` }}
                    ></div>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <p> Item Name</p>
                  </Col>
                  <Col md="8">
                    <Form.Control
                      type="text"
                      onChange={this.handleChanged}
                      disabled
                      name="itemName"
                      value={itemName}
                      placeholder="Item name"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <p>Consumer Unit:</p>
                  </Col>
                  <Col md="8">
                    <select
                      disabled
                      name="consumerUnit"
                      value={consumerUnit}
                      onChange={this.handleChanged}
                      className="form-control"
                    >
                      <option value="">Select</option>
                      <option value="3">bunch</option>
                      <option value="5">pint</option>
                      <option value="6">head</option>
                      <option value="7">bag</option>
                      <option value="8">half pint</option>
                      <option value="10">Pound</option>
                      <option value="11">Half Pound Bag</option>
                      <option value="12">Half Pound</option>
                      <option value="13">others</option>
                    </select>
                  </Col>
                </Row>
              </Modal.Body>

              <Modal.Footer>
                <div className="footer_btn_wrapper">
                  <Button variant="dark" onClick={this.handleClose}>
                    Cancel
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { props, login, user, inventory } = state
  return {
    props,
    login,
    user,
    inventory,
  }
}

export default connect(mapStateToProps)(Inventory)

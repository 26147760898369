import * as debugFactory from "debug"
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap"
import React, { Component } from "react"
import { InventoryItemForm, InventoryItemCard } from "healthyplaneat-common"

import {
  updateInventoryItem,
  updateInventoryItemStatus,
  loadInventory,
} from "../../../redux/action/inventory"

import _ from "lodash"
import { connect } from "react-redux"
import { loadItems } from "../../../redux/action/item"
import { validate } from "../../../utils/inventory/validation"

const debug = debugFactory("inventory").extend("item")

class Item extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEdit: false,
      userId: this.props ? this.props.match.params.id : "",
    }
  }

  componentDidMount() {
    if (!this.props.itemList.length) {
      const { userId } = this.props
      this.props.dispatch(loadItems({ userId }))
    }
  }

  view = ({ inventoryItem }) => {
    this.props.dispatch({
      type: "SHOW_INVENTORY_ITEM",
      payload: inventoryItem,
    })
  }

  delete = ({ inventoryItem }) => {
    this.props.dispatch({
      type: "DELETE_INVENTORY_ITEM_SHOW",
      payload: inventoryItem,
    })
  }

  showEdit = () => {
    this.setState({ isEdit: true })
  }

  closeEdit = () => {
    this.setState({ isEdit: false })
  }

  saveChanges = ({ inventoryItemForm }) => {
    this.closeEdit()
    const { inventoryType, userId } = this.props

    this.props.dispatch(updateInventoryItem({ inventoryItemForm })).then(() => {
      this.props.dispatch(loadInventory({ userId, inventoryType }))
    })
  }

  changeStatus = ({ inventoryItem }) => {
    const { userId } = this.state
    const payload = {
      inventoryId: inventoryItem._id,
      status: inventoryItem.status === 2 ? 1 : 2,
      userId: userId,
    }
    const { inventoryType } = this.props

    this.props.dispatch(updateInventoryItemStatus(payload)).then(() => {
      this.props.dispatch(loadInventory({ userId, inventoryType }))
    })
  }

  render() {
    const { userId } = this.state
    const { item, itemList } = this.props

    return (
      <>
        <InventoryItemCard
          inventoryItem={item}
          onDetails={({ inventoryItem }) => {
            this.view({ inventoryItem })
          }}
          onEdit={({ inventoryItem }) => {
            this.showEdit({ inventoryItem })
          }}
          onDelete={({ inventoryItem }) => {
            this.delete({ inventoryItem })
          }}
          onStatusChange={({ inventoryItem }) => {
            this.changeStatus({ inventoryItem })
          }}
        />

        <Modal
          show={this.state.isEdit}
          onHide={this.closeEdit}
          className="cm_order_view edit_item inventry view_sec"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Inventory</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InventoryItemForm
              itemList={itemList}
              readOnly={false}
              inventoryItem={item}
              userId={userId}
              handleSubmit={({ inventoryItemForm }) => {
                debug("handleSubmit")
                this.saveChanges({ inventoryItemForm })
              }}
              handleClose={() => {
                this.closeEdit()
              }}
            />
          </Modal.Body>

          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default connect((state) => ({
  itemList: state.item.list,
}))(Item)

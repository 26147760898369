import * as debugFactory from "debug"
import { Button, Col, Form, Modal, Spinner } from "react-bootstrap"
import React, { Component } from "react"

import _ from "lodash"
import {
  addInventoryItem,
  loadInventory,
} from "../../../redux/action/inventory"
import { connect } from "react-redux"
import { loadItems } from "../../../redux/action/item"
import { ActionTypes as inventoryActions } from "../../../redux/action/inventory"
import { validate, validateImage } from "../../../utils/inventory/validation"

import { InventoryItemForm } from "healthyplaneat-common"

const debug = debugFactory("ItemAdd")

class ItemAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    if (!this.props.itemList.length) {
      const { userId } = this.props
      this.props.dispatch(loadItems({ userId }))
    }
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: inventoryActions.ADD_INVENTORY_ITEM_DISMISS,
    })
  }

  close = () => {
    this.props.dispatch({
      type: inventoryActions.ADD_INVENTORY_ITEM_DISMISS,
    })
  }

  saveChanges = ({ inventoryItemForm }) => {
    this.close()
    const { userId, inventoryType } = this.props
    this.props
      .dispatch(addInventoryItem({ inventoryItemForm }))
      .then((wasAdded) => {
        debug("post-add")
        if (!wasAdded) return
        return this.props.dispatch(loadInventory({ userId, inventoryType }))
      })
      .catch((error) => {
        debug("error")
        debug(error)
      })
  }

  render() {
    const { itemList, userId } = this.props
    return (
      <Modal
        show={this.props.show}
        onHide={this.close}
        className="cm_order_view edit_item inventry view_sec"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Inventory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InventoryItemForm
            itemList={itemList}
            userId={userId}
            handleSubmit={({ inventoryItemForm }) => {
              this.saveChanges({ inventoryItemForm })
            }}
            handleClose={() => {
              this.close()
            }}
          />
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    )
  }
}

export default connect((state) => ({
  show: state.inventory.item.isAdd,
  itemList: state.item.list,
}))(ItemAdd)

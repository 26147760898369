// TODO pick up host mods - mirror edit functionality

import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  InputGroup,
} from "react-bootstrap"

import React, { Component } from "react"

import Geocode from "react-geocode"
import { Link } from "react-router-dom"
import Loader from "../../components/common/Loader"
import { Select } from "antd"
import Sidebar from "../../components/sidebar"
import Url from "../../services/BaseUrl"
import _ from "lodash"
import { _Api } from "../../services/Api"
import { notify } from "../../components/common/Toaster"
import { SelectHost } from "./SelectHost"
import { floatOrDefault } from "../../utils/default-values"
import { sortAscendingFarmName } from "../../utils/sort-values"

const debug = require("debug")("AddMarket")

const { Option } = Select

const days = (type) => {
  switch (type) {
    case 0:
      return "Sunday"
      break
    case 1:
      return "Monday"
      break
    case 2:
      return "Tuesday"
      break
    case 3:
      return "Wednesday"
      break
    case 4:
      return "Thursday"
      break
    case 5:
      return "Friday"
      break
    case 6:
      return "Saturday"
      break
  }
}

const DeliveryChildComponent = (props) => {
  let i = props.index
  console.log(
    "dataIn:::::########ChildComponentzzzzz",
    props.nestedDeliveryWeekDaysTimeSlot,
    i
  )
  console.log(props, "kjuyhgtfrdfghjklkjhygfdsxcvbhjk")

  let length = props.nestedDeliveryWeekDaysTime[i]
  console.log(
    "dataIn:::::########ChildComponent::2",
    length,
    "::::",
    props.nestedDeliveryWeekDaysTime,
    ":::::",
    i,
    ":::::::",
    props.nestedDeliveryWeekDaysTime[i]
  )
  let jsx = []

  for (let j = 1; j < length; j++) {
    console.log(
      j,
      i,
      "lhkgyftdgsexgrdcfhfvjgykuhlihugyvft",
      props.nestedDeliveryWeekDaysTimeSlot[i][j] ? 1 : 2,
      "########",
      props.nestedDeliveryWeekDaysTimeSlot[i][j],
      props.deliveryTimeStart
    )

    let startTime = props.nestedDeliveryWeekDaysTimeSlot[i][j]
      ? props.nestedDeliveryWeekDaysTimeSlot[i][j].time.starttime
      : props.deliveryTimeStart
    let endtTime = props.nestedDeliveryWeekDaysTimeSlot[i][j]
      ? props.nestedDeliveryWeekDaysTimeSlot[i][j].time.endTime
      : props.deliveryTimeEnd
    // if (props.nestedWeekDaysTimeSlot[i][j] !== null) {
    jsx.push(
      <div className="time_itemwrapper">
        <div className="accordian_wrapper" id={props.number}>
          <select
            className="form-control"
            value={startTime}
            name="deliveryTimeStart"
            id={props.number}
            onChange={(e) => props.handleDeliveryTimeChange(e, i, "start", j)}
          >
            <option>Select</option>
            <option
              id="1"
              disabled={props.endTimeId[i][j] <= 1 ? false : false}
              value="10:00"
            >
              10:00 AM
            </option>
            <option
              id="2"
              disabled={props.endTimeId[i][j] <= 2 ? false : false}
              value="10:30"
            >
              10:30 AM
            </option>
            <option
              id="3"
              disabled={props.endTimeId[i][j] <= 3 ? false : false}
              value="11:00"
            >
              11:00 AM
            </option>
            <option
              id="4"
              disabled={props.endTimeId[i][j] <= 4 ? false : false}
              value="11:30"
            >
              11:30 AM
            </option>
            <option
              id="5"
              disabled={props.endTimeId[i][j] <= 5 ? false : false}
              value="12:00"
            >
              12:00 PM
            </option>
            <option
              id="6"
              disabled={props.endTimeId[i][j] <= 6 ? false : false}
              value="12:30"
            >
              12:30 PM
            </option>
            <option
              id="7"
              disabled={props.endTimeId[i][j] <= 7 ? false : false}
              value="13:00"
            >
              13:00 PM
            </option>
            <option
              id="8"
              disabled={props.endTimeId[i][j] <= 8 ? false : false}
              value="13:30"
            >
              13:30 PM
            </option>
            <option
              id="9"
              disabled={props.endTimeId[i][j] <= 9 ? false : false}
              value="14:00"
            >
              14:00 PM
            </option>
            <option
              id="10"
              disabled={props.endTimeId[i][j] <= 10 ? false : false}
              value="14:30"
            >
              14:30 PM
            </option>
            <option
              id="11"
              disabled={props.endTimeId[i][j] <= 11 ? false : false}
              value="15:00"
            >
              15:00 PM
            </option>
            <option
              id="12"
              disabled={props.endTimeId[i][j] <= 12 ? false : false}
              value="15:30"
            >
              15:30 PM
            </option>
            <option
              id="13"
              disabled={props.endTimeId[i][j] <= 14 ? false : false}
              value="16:00"
            >
              16:00 PM
            </option>
            <option
              id="14"
              disabled={props.endTimeId[i][j] <= 15 ? false : false}
              value="16:30"
            >
              16:30 PM
            </option>
            <option
              id="15"
              disabled={props.endTimeId[i][j] <= 16 ? false : false}
              value="17:00"
            >
              17:00 PM
            </option>
            <option
              id="16"
              disabled={props.endTimeId[i][j] <= 17 ? false : false}
              value="17:30"
            >
              17:30 PM
            </option>
            <option
              id="17"
              disabled={props.endTimeId[i][j] <= 18 ? false : false}
              value="18:00"
            >
              18:00 PM
            </option>
            <option
              id="18"
              disabled={props.endTimeId[i][j] <= 19 ? false : false}
              value="18:30"
            >
              18:30 PM
            </option>
            <option
              id="19"
              disabled={props.endTimeId[i][j] <= 20 ? false : false}
              value="19:00"
            >
              19:00 PM
            </option>
            <option
              id="20"
              disabled={props.endTimeId[i][j] <= 21 ? false : false}
              value="19:30"
            >
              19:30 PM
            </option>
            <option
              id="21"
              disabled={props.endTimeId[i][j] <= 22 ? false : false}
              value="20:00"
            >
              20:00 PM
            </option>
            <option
              id="22"
              disabled={props.endTimeId[i][j] <= 23 ? false : false}
              value="20:30"
            >
              20:30 PM
            </option>
            <option
              id="23"
              disabled={props.endTimeId[i][j] <= 24 ? false : false}
              value="21:00"
            >
              21:00 PM
            </option>
            <option
              id="24"
              disabled={props.endTimeId[i][j] <= 25 ? false : false}
              value="21:30"
            >
              21:30 PM
            </option>
            <option
              id="25"
              disabled={props.endTimeId[i][j] <= 26 ? false : false}
              value="22:00"
            >
              22:00 PM
            </option>
          </select>
          <span className="mx-5">—</span>
          <select
            className="form-control"
            value={endtTime}
            name="deliveryTimeEnd"
            id={props.number}
            onChange={(e) => props.handleDeliveryTimeChange(e, i, "end", j)}
          >
            <option>Select</option>
            <option
              id="1"
              disabled={props.startTimeId[i][j] >= 1 ? false : false}
              value="10:00"
            >
              10:00 AM
            </option>
            <option
              id="2"
              disabled={props.startTimeId[i][j] >= 2 ? false : false}
              value="10:30"
            >
              10:30 AM
            </option>
            <option
              id="3"
              disabled={props.startTimeId[i][j] >= 3 ? false : false}
              value="11:00"
            >
              11:00 AM
            </option>
            <option
              id="4"
              disabled={props.startTimeId[i][j] >= 4 ? false : false}
              value="11:30"
            >
              11:30 AM
            </option>
            <option
              id="5"
              disabled={props.startTimeId[i][j] >= 5 ? false : false}
              value="12:00"
            >
              12:00 PM
            </option>
            <option
              id="6"
              disabled={props.startTimeId[i][j] >= 6 ? false : false}
              value="12:30"
            >
              12:30 PM
            </option>
            <option
              id="7"
              disabled={props.startTimeId[i][j] >= 7 ? false : false}
              value="13:00"
            >
              13:00 PM
            </option>
            <option
              id="8"
              disabled={props.startTimeId[i][j] >= 8 ? false : false}
              value="13:30"
            >
              13:30 PM
            </option>
            <option
              id="9"
              disabled={props.startTimeId[i][j] >= 9 ? false : false}
              value="14:00"
            >
              14:00 PM
            </option>
            <option
              id="10"
              disabled={props.startTimeId[i][j] >= 10 ? false : false}
              value="14:30"
            >
              14:30 PM
            </option>
            <option
              id="11"
              disabled={props.startTimeId[i][j] >= 11 ? false : false}
              value="15:00"
            >
              15:00 PM
            </option>
            <option
              id="12"
              disabled={props.startTimeId[i][j] >= 12 ? false : false}
              value="15:30"
            >
              15:30 PM
            </option>
            <option
              id="13"
              disabled={props.startTimeId[i][j] >= 13 ? false : false}
              value="16:00"
            >
              16:00 PM
            </option>
            <option
              id="14"
              disabled={props.startTimeId[i][j] >= 14 ? false : false}
              value="16:30"
            >
              16:30 PM
            </option>
            <option
              id="15"
              disabled={props.startTimeId[i][j] >= 15 ? false : false}
              value="17:00"
            >
              17:00 PM
            </option>
            <option
              id="16"
              disabled={props.startTimeId[i][j] >= 16 ? false : false}
              value="17:30"
            >
              17:30 PM
            </option>
            <option
              id="17"
              disabled={props.startTimeId[i][j] >= 17 ? false : false}
              value="18:00"
            >
              18:00 PM
            </option>
            <option
              id="18"
              disabled={props.startTimeId[i][j] >= 18 ? false : false}
              value="18:30"
            >
              18:30 PM
            </option>
            <option
              id="19"
              disabled={props.startTimeId[i][j] >= 19 ? false : false}
              value="19:00"
            >
              19:00 PM
            </option>
            <option
              id="20"
              disabled={props.startTimeId[i][j] >= 20 ? false : false}
              value="19:30"
            >
              19:30 PM
            </option>
            <option
              id="21"
              disabled={props.startTimeId[i][j] >= 21 ? false : false}
              value="20:00"
            >
              20:00 PM
            </option>
            <option
              id="22"
              disabled={props.startTimeId[i][j] >= 22 ? false : false}
              value="20:30"
            >
              20:30 PM
            </option>
            <option
              id="23"
              disabled={props.startTimeId[i][j] >= 23 ? false : false}
              value="21:00"
            >
              21:00 PM
            </option>
            <option
              id="24"
              disabled={props.startTimeId[i][j] >= 24 ? false : false}
              value="21:30"
            >
              21:30 PM
            </option>
            <option
              id="25"
              disabled={props.startTimeId[i][j] >= 25 ? false : false}
              value="22:00"
            >
              22:00 PM
            </option>
          </select>
          <span className="pl-4 py-2 d-inline-block ">
            <i
              onClick={() => props.minusDeliveryTime(i, j, props)}
              className="fas fa-minus-circle c-pnt"
            ></i>
          </span>
        </div>
      </div>
    )
  }

  return jsx
}

function ListTime(props) {
  console.log(props.data.time, "dddddddddddddddddddddddsxz")
  let Jsx = []

  props.data.time.map((value, i) =>
    // console.log(value,"lkjhbvcvbnm,,kjhgf")
    Jsx.push(
      <span className="cm_time_wrapper">
        {value.starttime}-{value.endTime}&nbsp;&nbsp;
      </span>
    )
  )
  console.log(Jsx, "dddddddddddddddddddddddd")
  return Jsx
}

function CardDeliveryView(props) {
  console.log(props, "prrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
  let Jsx = []

  for (let i = 0; i < 7; i++) {
    Jsx.push(
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={i.toString()}>
            <div className="cm_time_wrapper">
              <span>
                <label class="cm_checkbox">
                  <input
                    type="checkbox"
                    value={props.deliveryCheck}
                    name="deliveryCheck"
                    onChange={(e) => props.handleDeliveryChanged(e, i)}
                    name="Sunday"
                  />
                  <span></span>
                </label>
                <label htmlFor="Sunday">{days(i)}</label>
              </span>
              <span>10 AM–10 PM</span>
            </div>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={i.toString()}>
          <Card.Body>
            <div className="time_itemwrapper">
              <div className="accordian_wrapper">
                <select
                  className="form-control"
                  value={props.deliveryTimeStart}
                  name="deliveryTimeStart"
                  onChange={(e) =>
                    props.handleDeliveryTimeChange(e, i, "start", 0)
                  }
                >
                  <option>Select</option>
                  <option
                    id="1"
                    disabled={props.endTimeId[i][0] <= 1 ? false : false}
                    value="10:00"
                  >
                    10:00 AM
                  </option>
                  <option
                    id="2"
                    disabled={props.endTimeId[i][0] <= 2 ? false : false}
                    value="10:30"
                  >
                    10:30 AM
                  </option>
                  <option
                    id="3"
                    disabled={props.endTimeId[i][0] <= 3 ? false : false}
                    value="11:00"
                  >
                    11:00 AM
                  </option>
                  <option
                    id="4"
                    disabled={props.endTimeId[i][0] <= 4 ? false : false}
                    value="11:30"
                  >
                    11:30 AM
                  </option>
                  <option
                    id="5"
                    disabled={props.endTimeId[i][0] <= 5 ? false : false}
                    value="12:00"
                  >
                    12:00 PM
                  </option>
                  <option
                    id="6"
                    disabled={props.endTimeId[i][0] <= 6 ? false : false}
                    value="12:30"
                  >
                    12:30 PM
                  </option>
                  <option
                    id="7"
                    disabled={props.endTimeId[i][0] <= 7 ? false : false}
                    value="13:00"
                  >
                    13:00 PM
                  </option>
                  <option
                    id="8"
                    disabled={props.endTimeId[i][0] <= 8 ? false : false}
                    value="13:30"
                  >
                    13:30 PM
                  </option>
                  <option
                    id="9"
                    disabled={props.endTimeId[i][0] <= 9 ? false : false}
                    value="14:00"
                  >
                    14:00 PM
                  </option>
                  <option
                    id="10"
                    disabled={props.endTimeId[i][0] <= 10 ? false : false}
                    value="14:30"
                  >
                    14:30 PM
                  </option>
                  <option
                    id="11"
                    disabled={props.endTimeId[i][0] <= 11 ? false : false}
                    value="15:00"
                  >
                    15:00 PM
                  </option>
                  <option
                    id="12"
                    disabled={props.endTimeId[i][0] <= 12 ? false : false}
                    value="15:30"
                  >
                    15:30 PM
                  </option>
                  <option
                    id="13"
                    disabled={props.endTimeId[i][0] <= 14 ? false : false}
                    value="16:00"
                  >
                    16:00 PM
                  </option>
                  <option
                    id="14"
                    disabled={props.endTimeId[i][0] <= 15 ? false : false}
                    value="16:30"
                  >
                    16:30 PM
                  </option>
                  <option
                    id="15"
                    disabled={props.endTimeId[i][0] <= 16 ? false : false}
                    value="17:00"
                  >
                    17:00 PM
                  </option>
                  <option
                    id="16"
                    disabled={props.endTimeId[i][0] <= 17 ? false : false}
                    value="17:30"
                  >
                    17:30 PM
                  </option>
                  <option
                    id="17"
                    disabled={props.endTimeId[i][0] <= 18 ? false : false}
                    value="18:00"
                  >
                    18:00 PM
                  </option>
                  <option
                    id="18"
                    disabled={props.endTimeId[i][0] <= 19 ? false : false}
                    value="18:30"
                  >
                    18:30 PM
                  </option>
                  <option
                    id="19"
                    disabled={props.endTimeId[i][0] <= 20 ? false : false}
                    value="19:00"
                  >
                    19:00 PM
                  </option>
                  <option
                    id="20"
                    disabled={props.endTimeId[i][0] <= 21 ? false : false}
                    value="19:30"
                  >
                    19:30 PM
                  </option>
                  <option
                    id="21"
                    disabled={props.endTimeId[i][0] <= 22 ? false : false}
                    value="20:00"
                  >
                    20:00 PM
                  </option>
                  <option
                    id="22"
                    disabled={props.endTimeId[i][0] <= 23 ? false : false}
                    value="20:30"
                  >
                    20:30 PM
                  </option>
                  <option
                    id="23"
                    disabled={props.endTimeId[i][0] <= 24 ? false : false}
                    value="21:00"
                  >
                    21:00 PM
                  </option>
                  <option
                    id="24"
                    disabled={props.endTimeId[i][0] <= 25 ? false : false}
                    value="21:30"
                  >
                    21:30 PM
                  </option>
                  <option
                    id="25"
                    disabled={props.endTimeId[i][0] <= 26 ? false : false}
                    value="22:00"
                  >
                    22:00 PM
                  </option>
                </select>
                <span className="mx-5">—</span>
                <select
                  className="form-control"
                  value={props.deliveryTimeEnd}
                  name="deliveryTimeEnd"
                  onChange={(e) =>
                    props.handleDeliveryTimeChange(e, i, "end", 0)
                  }
                >
                  <option>Select</option>
                  <option
                    id="1"
                    disabled={props.startTimeId[i][0] >= 1 ? false : false}
                    value="10:00"
                  >
                    10:00 AM
                  </option>
                  <option
                    id="2"
                    disabled={props.startTimeId[i][0] >= 2 ? false : false}
                    value="10:30"
                  >
                    10:30 AM
                  </option>
                  <option
                    id="3"
                    disabled={props.startTimeId[i][0] >= 3 ? false : false}
                    value="11:00"
                  >
                    11:00 AM
                  </option>
                  <option
                    id="4"
                    disabled={props.startTimeId[i][0] >= 4 ? false : false}
                    value="11:30"
                  >
                    11:30 AM
                  </option>
                  <option
                    id="5"
                    disabled={props.startTimeId[i][0] >= 5 ? false : false}
                    value="12:00"
                  >
                    12:00 PM
                  </option>
                  <option
                    id="6"
                    disabled={props.startTimeId[i][0] >= 6 ? false : false}
                    value="12:30"
                  >
                    12:30 PM
                  </option>
                  <option
                    id="7"
                    disabled={props.startTimeId[i][0] >= 7 ? false : false}
                    value="13:00"
                  >
                    13:00 PM
                  </option>
                  <option
                    id="8"
                    disabled={props.startTimeId[i][0] >= 8 ? false : false}
                    value="13:30"
                  >
                    13:30 PM
                  </option>
                  <option
                    id="9"
                    disabled={props.startTimeId[i][0] >= 9 ? false : false}
                    value="14:00"
                  >
                    14:00 PM
                  </option>
                  <option
                    id="10"
                    disabled={props.startTimeId[i][0] >= 10 ? false : false}
                    value="14:30"
                  >
                    14:30 PM
                  </option>
                  <option
                    id="11"
                    disabled={props.startTimeId[i][0] >= 11 ? false : false}
                    value="15:00"
                  >
                    15:00 PM
                  </option>
                  <option
                    id="12"
                    disabled={props.startTimeId[i][0] >= 12 ? false : false}
                    value="15:30"
                  >
                    15:30 PM
                  </option>
                  <option
                    id="13"
                    disabled={props.startTimeId[i][0] >= 13 ? false : false}
                    value="16:00"
                  >
                    16:00 PM
                  </option>
                  <option
                    id="14"
                    disabled={props.startTimeId[i][0] >= 14 ? false : false}
                    value="16:30"
                  >
                    16:30 PM
                  </option>
                  <option
                    id="15"
                    disabled={props.startTimeId[i][0] >= 15 ? false : false}
                    value="17:00"
                  >
                    17:00 PM
                  </option>
                  <option
                    id="16"
                    disabled={props.startTimeId[i][0] >= 16 ? false : false}
                    value="17:30"
                  >
                    17:30 PM
                  </option>
                  <option
                    id="17"
                    disabled={props.startTimeId[i][0] >= 17 ? false : false}
                    value="18:00"
                  >
                    18:00 PM
                  </option>
                  <option
                    id="18"
                    disabled={props.startTimeId[i][0] >= 18 ? false : false}
                    value="18:30"
                  >
                    18:30 PM
                  </option>
                  <option
                    id="19"
                    disabled={props.startTimeId[i][0] >= 19 ? false : false}
                    value="19:00"
                  >
                    19:00 PM
                  </option>
                  <option
                    id="20"
                    disabled={props.startTimeId[i][0] >= 20 ? false : false}
                    value="19:30"
                  >
                    19:30 PM
                  </option>
                  <option
                    id="21"
                    disabled={props.startTimeId[i][0] >= 21 ? false : false}
                    value="20:00"
                  >
                    20:00 PM
                  </option>
                  <option
                    id="22"
                    disabled={props.startTimeId[i][0] >= 22 ? false : false}
                    value="20:30"
                  >
                    20:30 PM
                  </option>
                  <option
                    id="23"
                    disabled={props.startTimeId[i][0] >= 23 ? false : false}
                    value="21:00"
                  >
                    21:00 PM
                  </option>
                  <option
                    id="24"
                    disabled={props.startTimeId[i][0] >= 24 ? false : false}
                    value="21:30"
                  >
                    21:30 PM
                  </option>
                  <option
                    id="25"
                    disabled={props.startTimeId[i][0] >= 25 ? false : false}
                    value="22:00"
                  >
                    22:00 PM
                  </option>
                </select>
                <span className="pl-4 py-2 d-inline-block ">
                  <i
                    onClick={() => props.addDeliveryChild(i, props)}
                    className="fas fa-plus-circle c-pnt"
                  ></i>
                </span>
              </div>
              <DeliveryChildComponent {...props} index={i} />
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  }
  console.log(Jsx, "dddddddddddddddddddddddd")
  return Jsx
}

class addmarket extends Component {
  constructor(props) {
    super(props)
    this.farmIds = []
    this.state = {
      marketName: "",
      desc: "",
      lat: "",
      long: "",
      farmIds: "",
      imageLocation: "",
      response: "",
      errors: [],
      responseError: "",
      fileName: "",
      farmdata: "",
      profilePicErrorMsg: "",
      state: "",
      city: "",
      postalCode: "",
      address: "",
      area: "",
      category: "",
      deliverydays: false,
      deliveryloc: false,
      deliveryCheck: "",
      deliveryFees: "",
      deliveryTimeStart: "",
      deliveryTimeEnd: "",
      deliveryStartTime: [],
      deliveryEndndTime: [],
      deliveryOrderDay: [],
      deliveryErrorMsg: "",
      deliveryLocErrorMsg: "",
      nestedDeliveryWeekDaysTime: [],
      nestedDeliveryWeekDaysTimeSlot: [],
      finalNestedDeliveryWeekDaysTimeSlot: [],
      startTimeId: [],
      endTimeId: [],
      addressLine1: "",
      addressLine2: "",
      marketBackgroundColor: "",
      marketIcon: "",
      marketIconPreview: "",
      marketIconErrorMsg: "",
      marketOrderWindow: "",
      marketOrderWindowError: "",
      marketPickupWindows: [],
      newMarketPickupWindow: "",
      pickUpHostCommission: 0,
      pickUpHostUiKey: undefined,
      pickUpHostUiDisplay: undefined,
    }
  }

  setSelectedHostKey(key) {
    this.setState({ pickUpHostUiKey: key })
  }

  onImageUpload = (e) => {
    let file = e.target.files[0]

    this.setState({
      imageLocation: file,
      fileName: file.name,
      fileUploadFileError: "",
    })
  }

  handleOnChange = (e) => {
    let self = this
    let address1, address2, city1, state1, postalCode1
    if (e.target.name === "addressLine1") {
      address1 = e.target.value
    }
    if (e.target.name === "addressLine2") {
      address2 = e.target.value
    }

    if (e.target.name === "city") {
      city1 = e.target.value
    }
    if (e.target.name === "state") {
      state1 = e.target.value
    }
    if (e.target.name === "postalCode") {
      postalCode1 = e.target.value
    }
    let addr =
      address1 + " " + address1 + " " + city1 + " " + state1 + " " + postalCode1

    if (
      e.target.name === "addressLine1" ||
      e.target.name === "addressLine2" ||
      e.target.name === "city" ||
      e.target.name === "state" ||
      e.target.name === "postalCode"
    ) {
      Geocode.fromAddress(addr).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location

          self.setState({ lat: lat, long: lng })
        },
        (error) => {
          console.error(error)
        }
      )
    }

    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      imageErrorMsg: "",
      responseError: "",
    })
  }

  handleValidate = () => {
    let validate = false
    let errors = {}
    let {
      category,
      postalCode,
      marketName,
      desc,
      finalNestedDeliveryWeekDaysTimeSlot,
      imageLocation,
      phone,
      countryCode,
    } = this.state
    console.log(marketName, desc, "desc desc")

    if (imageLocation === "" || imageLocation === undefined) {
      validate = false
      this.setState({
        imageErrorMsg: "Please select item image.",
      })
    } else {
      if (imageLocation.size > 5000000) {
        validate = false
        this.setState({
          imageErrorMsg: "Image size should be less than 5Mb.",
        })
      } else {
        this.setState({
          imageErrorMsg: "",
        })
      }
    }

    if (marketName == "" || marketName == undefined) {
      validate = true
      errors["marketName"] = "Market name is required."
    }

    if (desc == "" || desc == undefined) {
      validate = true
      errors["desc"] = "Description is required."
    }
    if (category == "" || category == undefined) {
      validate = true
      errors["category"] = "category is required."
    }

    if (postalCode) {
      if (postalCode < 0) {
        validate = true
        errors["postalCode"] = "Postal code must be greater than 0."
      }
    }

    this.setState({ errors })
    return validate
  }

  handleAddMarket = (e) => {
    if (!this.handleValidate()) {
      let self = this
      // if (!this.handleValidate()) {
      let {
        category,
        postalCode,
        addressLine1,
        addressLine2,
        finalNestedDeliveryWeekDaysTimeSlot,
        marketName,
        desc,
        imageLocation,
        city,
        area,
        address,
        state,
        lat,
        long,
        farmIds,
        profilePicPreview,
        marketIcon,
        marketBackgroundColor,
        marketOrderWindow,
        marketPickupWindows,
        pickUpHostCommission,
        pickUpHostUiKey,
      } = this.state
      console.log(
        finalNestedDeliveryWeekDaysTimeSlot,
        "finalNestedDeliveryWeekDaysTimeSlot",
        typeof finalNestedDeliveryWeekDaysTimeSlot
      )
      let formData = new FormData()

      debug("marketIcon")
      debug(marketIcon)
      formData.append("marketName", marketName ? marketName : "")
      formData.append("farmIds", JSON.stringify(this.farmIds))
      formData.append("desc", desc)
      formData.append("lat", lat ? lat : 0)
      formData.append("long", long ? long : 0)
      formData.append("category", category ? category : "")
      formData.append("marketImage", imageLocation ? imageLocation : "")
      formData.append("addressLine1", addressLine1)
      formData.append("addressLine2", addressLine2)
      formData.append("city", city)
      formData.append("state", state)
      formData.append("postalCode", postalCode ? postalCode : "")
      formData.append(
        "openTime",
        JSON.stringify(finalNestedDeliveryWeekDaysTimeSlot)
      )
      formData.append("marketIcon", marketIcon ? marketIcon : "")
      formData.append("marketBackgroundColor", marketBackgroundColor)
      formData.append("marketOrderWindow", marketOrderWindow)
      formData.append(
        "marketPickupWindows",
        JSON.stringify(marketPickupWindows)
      )

      formData.append(
        "pickUpHostCommission",
        floatOrDefault(pickUpHostCommission, 0)
      )
      formData.append("pickUpHostUiKey", pickUpHostUiKey)

      this.setState({ isloader: true, loader: true })

      _Api(
        Url.SUPER_ADMIN_ADD_MARKET.method,
        Url.SUPER_ADMIN_ADD_MARKET.url,
        formData
      )
        .then((resp) => {
          this.setState({ isloader: false, loader: false })

          if (resp.statusCode == 1) {
            notify("success", resp.responseData.message)
            self.props.history.goBack()
          } else {
          }
        })
        .catch((err) => {
          debug("add error")
          debug(err)
          notify("error", err.data.error.responseMessage)
          self.setState({ loader: false, isloader: false })
          this.setState({ isloader: false })
          // notify('error', err.error.responseMessage)
        })
    }
  }
  handleOnChangeSelect = (id) => {
    this.farmIds = id
  }

  handleAddToSet = ({ setPropKey, addToSetPropKey }) => {
    const set = this.state[setPropKey]
    const addValue = this.state[addToSetPropKey]
    const validate = (val) => val.length > 0
    if (!validate(addValue)) return
    this.setState({
      [setPropKey]: _.uniq(set.concat(addValue)),
    })
  }
  handleRemoveFromSet = ({ setPropKey, removeValue }) => {
    const set = this.state[setPropKey]
    this.setState({
      [setPropKey]: set.filter((value) => value !== removeValue),
    })
  }

  componentDidMount() {
    this.getFarm()

    let {
      placeOrderday,
      startTime,
      endTime,
      nestedWeekDaysTime,
      nestedWeekDaysTimeSlot,
      startTimeId,
      endTimeId,
    } = this.state
    startTimeId = [...Array(7)].map((item) => Array(5).fill(0))
    endTimeId = [...Array(7)].map((item) => Array(5).fill(26))
    this.setState({
      startTimeId,
      endTimeId,
    })

    let {
      deliveryOrderDay,
      deliveryStartTime,
      deliveryEndndTime,
      nestedDeliveryWeekDaysTime,
      nestedDeliveryWeekDaysTimeSlot,
    } = this.state
    deliveryOrderDay.length = 7
    deliveryStartTime.length = 7
    deliveryEndndTime.length = 7
    nestedDeliveryWeekDaysTimeSlot = [...Array(7)].map((item) =>
      Array(5).fill(0)
    )
    let lengthDelivery = 7
    while (lengthDelivery--) {
      nestedDeliveryWeekDaysTime.push(0)
    }
    this.setState({
      deliveryOrderDay,
      deliveryStartTime,
      deliveryEndndTime,
      nestedDeliveryWeekDaysTime,
      nestedDeliveryWeekDaysTimeSlot,
    })
  }

  getFarm = () => {
    let req = {}
    let self = this

    _Api(Url.SUPER_ADMIN_GET_FARM.method, Url.SUPER_ADMIN_GET_FARM.url, req)
      .then((resp) => {
        if (resp.statusCode == 1) {
          // notify('success', resp.responseData.message)

          this.setState({
            farmdata: resp.responseData.result.sort(sortAscendingFarmName),
          })
        } else {
        }
      })
      .catch((err) => {
        notify("error", err.data.error.responseMessage)
        self.setState({ loader: false, isloader: false })
      })
  }

  handleAddressLatLong = (
    latValue,
    lngValue,
    address,
    area,
    city,
    state,
    postalCode
  ) => {
    console.log("latlong", latValue, lngValue, address)
    this.setState({
      lat: latValue,
      long: lngValue,
      address: address,
      area: area,
      city: city,
      postalCode: postalCode,
      state: state,
    })
  }

  handlePicUpload = (
    { stateImage, statePreview, stateErrorMessage },
    event
  ) => {
    console.log(event, "eventeventevent", event.target.files[0])
    let reader = new FileReader()
    let file = event.target.files[0]

    let { valiDate } = this.state

    reader.addEventListener(
      "load",
      () => {
        this.setState({ [statePreview]: reader.result }, function () {})
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }
    if (file) {
      // console.log(file, 'fileeeeeeeeeee');
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/gif" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml"
      ) {
        if (file.size > 5000000) {
          valiDate = false
          this.setState({
            [stateImage]: event.target.files[0],
            [stateErrorMessage]: "File size should be less than 5Mb.",
          })
        } else {
          debug("processed successfully", event.target.files[0])

          this.setState({
            [stateImage]: event.target.files[0],
            [stateErrorMessage]: "",
          })
        }
      } else {
        valiDate = false
        this.setState({ [stateErrorMessage]: "File type not supported." })
      }
    }
  }

  handleDeliveryChanged = (e, i) => {
    let { deliveryOrderDay } = this.state

    let obj = {
      day: i.toString(),
      time: [{ starttime: "10:00", endTime: "22:00" }],
    }

    if (!_.isEmpty(deliveryOrderDay[i])) {
      deliveryOrderDay[i] = null
    } else {
      deliveryOrderDay[i] = obj
    }

    this.setState({
      deliveryOrderDay: deliveryOrderDay,
    })
  }
  handleDeliveryTimeChange = (e, i, time, j) => {
    // Delivery
    if (this.state.tempDeliveryIndex) {
      i = this.state.tempDeliveryIndex
    }
    let {
      deliveryStartTime,
      deliveryEndndTime,
      deliveryOrderDay,
      nestedDeliveryWeekDaysTimeSlot,
    } = this.state
    let { placeorderday } = this.state

    let obj = { day: i.toString(), starttime: e.target.value, endTime: "77" }

    this.setState({
      [e.target.name]: e.target.value,
    })

    if (time === "start") {
      deliveryStartTime[i] = e.target.value.toString()
    } else if (time === "end") {
      deliveryEndndTime[i] = e.target.value.toString()
    }

    this.setState({ deliveryStartTime, deliveryEndndTime })

    if (!_.isEmpty(deliveryStartTime[i]) && !_.isEmpty(deliveryEndndTime[i])) {
      deliveryOrderDay[i] = {
        day: i.toString(),
        time: [
          { starttime: deliveryStartTime[i], endTime: deliveryEndndTime[i] },
        ],
      }

      nestedDeliveryWeekDaysTimeSlot[i][j] = {
        day: i.toString(),
        time: [
          { starttime: deliveryStartTime[i], endTime: deliveryEndndTime[i] },
        ],
      }
    }
    this.setState({
      deliveryOrderDay,
      tempDeliveryIndex: null,
      nestedDeliveryWeekDaysTimeSlot,
    })
    console.log(
      nestedDeliveryWeekDaysTimeSlot,
      "nestedWeekDaysTimeSlotDEl@@@@@@@@@@@@@"
    )
  }
  handleDeliveryOrderSubmit = () => {
    this.setState({ deliverydays: false })
    let { nestedDeliveryWeekDaysTimeSlot } = this.state
    console.log("ljlhkgjftdrsetdrftgyjkhul", nestedDeliveryWeekDaysTimeSlot)

    let finalNestedDeliveryWeekDaysTimeSlot = []
    for (let x in nestedDeliveryWeekDaysTimeSlot) {
      let time = []
      if (nestedDeliveryWeekDaysTimeSlot.hasOwnProperty(x))
        for (let y in nestedDeliveryWeekDaysTimeSlot[x]) {
          if (nestedDeliveryWeekDaysTimeSlot[x].hasOwnProperty(y)) {
            if (nestedDeliveryWeekDaysTimeSlot[x][y].time) {
              console.log(
                "ljlhkgjftdrsetdrftgyjkhul###::",
                nestedDeliveryWeekDaysTimeSlot[x][y]
              )
              console.log(
                "ljlhkgjftdrsetdrftgyjkhul###::2",
                nestedDeliveryWeekDaysTimeSlot[x][y].time[0].starttime
              )
              time.push({
                starttime:
                  nestedDeliveryWeekDaysTimeSlot[x][y].time[0].starttime,
                endTime: nestedDeliveryWeekDaysTimeSlot[x][y].time[0].endTime,
              })
            }
          }
        }
      if (!_.isEmpty(time)) {
        finalNestedDeliveryWeekDaysTimeSlot.push({
          day: x.toString(),
          time: time,
        })
      }
    }
    this.setState({ finalNestedDeliveryWeekDaysTimeSlot })
    console.log(
      "ljlhkgjftdrsetdrftgyjkhulDel###::final",
      finalNestedDeliveryWeekDaysTimeSlot
    )
  }
  handledeliverydays = () => {
    this.setState({
      deliverydays: true,
      deliveryErrorMsg: "",
    })
  }
  addDeliveryChild = (i, val) => {
    console.log(
      "dataIn:::::########addDeliveyChild",
      i,
      val.nestedDeliveryWeekDaysTime
    )

    let currentDeliveryValueInIndex = 0
    let { nestedDeliveryWeekDaysTime, nestedDeliveryWeekDaysTimeSlot } =
      this.state
    currentDeliveryValueInIndex = val.nestedDeliveryWeekDaysTime[i]
    console.log(
      "dataIn:::::########addDeliveryChild::::::::::::::::##########",
      currentDeliveryValueInIndex,
      "::::::::",
      nestedDeliveryWeekDaysTimeSlot[i][currentDeliveryValueInIndex]
    )
    if (currentDeliveryValueInIndex < 5) {
      val.nestedDeliveryWeekDaysTime[i] = currentDeliveryValueInIndex + 1
      nestedDeliveryWeekDaysTime = val.nestedDeliveryWeekDaysTime
      this.setState({
        nestedDeliveryWeekDaysTime,
        numDeliveryChildren: this.state.numDeliveryChildren + 1,
        tempDeliveryIndex: i,
      })
    }
  }
  minusDeliveryTime = (i, j, val) => {
    console.log(
      "dataIn:::::########minusTime",
      i,
      j,
      val.nestedDeliveryWeekDaysTime
    )
    let currentDeliveryValueInIndex = 0
    let { nestedDeliveryWeekDaysTime, nestedDeliveryWeekDaysTimeSlot } =
      this.state
    currentDeliveryValueInIndex = val.nestedDeliveryWeekDaysTime[i]
    console.log("nkbhjvghfcgdxf", nestedDeliveryWeekDaysTimeSlot)
    nestedDeliveryWeekDaysTimeSlot[i][j] = null
    val.nestedDeliveryWeekDaysTime[i] = currentDeliveryValueInIndex - 1

    nestedDeliveryWeekDaysTime = val.nestedDeliveryWeekDaysTime
    this.setState({
      nestedDeliveryWeekDaysTime,
      nestedDeliveryWeekDaysTimeSlot,
    })
  }

  onKeyPress(e) {
    e = e || window.event
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which
    var charStr = String.fromCharCode(charCode)

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault()
  }

  render() {
    let {
      addressLine1,
      category,
      imageErrorMsg,
      addressLine2,
      postalCode,
      deliverydays,
      finalNestedDeliveryWeekDaysTimeSlot,
      deliveryErrorMsg,
      marketName,
      desc,
      errors,
      fileName,
      farmdata,
      profilePicPreview,
      logout,
      address,
      state,
      city,
      area,
      marketIcon,
      marketIconPreview,
      marketIconErrorMsg,
      marketBackgroundColor,
      marketOrderWindow,
      marketPickupWindows,
      newMarketPickupWindow,
      imageLocation,
      pickUpHostCommission,
      pickUpHostUiKey,
    } = this.state
    return (
      <>
        <Sidebar />
        <div className="body_container">
          <div className="body_inner_wrapper">
            {this.state.isLoader ? (
              <Loader />
            ) : (
              <>
                <header className="header_content">
                  <div>
                    <Link to="/popupmanagement">
                      <span className="mr-3 d-inline-block text_light text_12">
                        <i class="fas fa-caret-left text_dark mr-2"></i> Back{" "}
                      </span>
                    </Link>
                  </div>
                </header>
                <div className="page_heading">
                  <h6>Add Market / Pop-Up</h6>
                </div>
                <div className="main_content">
                  <div className="farm_page">
                    <Row>
                      <Col xl="2" lg="3">
                        <p> Market / Pop-Up Name:</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="text"
                          placeholder="Market / Pop-Up Name"
                          maxLength={55}
                          name="marketName"
                          value={marketName}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">
                          {errors["marketName"]}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="2" lg="3">
                        <p> Category:</p>
                      </Col>
                      <Col md="6">
                        <select
                          name="category"
                          value={category}
                          onChange={this.handleOnChange}
                          className="form-control"
                        >
                          <option>Select</option>
                          <option value="1">Popup market</option>
                          <option value="2">Farmer market</option>
                        </select>
                        <span className="error_msg">{errors["category"]}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="2" lg="3">
                        <p> Market / Pop-Up Image:</p>
                      </Col>
                      <Col md="6">
                        <div className="file_uploader cm_imgupload">
                          {imageLocation ? (
                            <img src={profilePicPreview} className="pos_abs" />
                          ) : (
                            <i class="fas fa-plus-circle"></i>
                          )}

                          <div className="custom custom-file">
                            <div className="file_input">
                              <input
                                type="file"
                                className="custom-file-input"
                                onChange={this.handlePicUpload.bind(this, {
                                  stateImage: "imageLocation",
                                  statePreview: "profilePicPreview",
                                  stateErrorMessage: "imageErrorMsg",
                                })}
                              />
                              <span className="error_msg">{imageErrorMsg}</span>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Market Icon</p>
                      </Col>
                      <Col md="6">
                        <div className="file_uploader cm_imgupload">
                          {marketIcon ? (
                            <img
                              src={marketIconPreview}
                              className="pos_abs"
                              alt=""
                            />
                          ) : (
                            <i class="fas fa-plus-circle"></i>
                          )}

                          <div className="custom custom-file">
                            <div className="file_input">
                              <input
                                type="file"
                                className="custom-file-input"
                                onChange={this.handlePicUpload.bind(this, {
                                  stateImage: "marketIcon",
                                  statePreview: "marketIconPreview",
                                  stateErrorMessage: "marketIconErrorMsg",
                                })}
                              />
                              <span className="error_msg">
                                {marketIconErrorMsg}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Market Background Color</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="text"
                          placeholder="Enter a hex color, including #"
                          name="marketBackgroundColor"
                          value={marketBackgroundColor}
                          onChange={this.handleOnChange}
                        />
                        <span>
                          <a
                            href="https://g.co/kgs/Rg6Jsj"
                            style={{ textDecoration: "underline" }}
                          >
                            Use Google's color picker to find color hex values
                          </a>
                        </span>
                        <span className="error_msg">
                          {errors["marketBackgroundColor"]}
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      {" "}
                      <Col xl="2" lg="3">
                        <p> Market / Pop-Up Location:</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="2" lg="3">
                        <p>Address Line 1</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="text"
                          placeholder="Enter Address Line 1..."
                          maxLength={30}
                          name="addressLine1"
                          value={addressLine1}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">{errors["address"]}</span>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="2" lg="3">
                        <p>Address Line 2</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="text"
                          placeholder="Enter Address Line 2..."
                          maxLength={30}
                          name="addressLine2"
                          value={addressLine2}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">{errors["area"]}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="2" lg="3">
                        <p>City</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="text"
                          placeholder="Enter city..."
                          maxLength={30}
                          name="city"
                          value={city}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">{errors["city"]}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="2" lg="3">
                        <p>State</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="text"
                          placeholder="Enter State"
                          maxLength={30}
                          name="state"
                          value={state}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">{errors["state"]}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="2" lg="3">
                        <p>Postal Code</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="number"
                          placeholder="Enter Postal Code"
                          name="postalCode"
                          maxLength={30}
                          onKeyPress={this.onKeyPress.bind(this)}
                          pattern="[0-9]*"
                          min="0"
                          value={postalCode}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">
                          {errors["postalCode"]}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="2" lg="3">
                        <p> Market / Pop-Up Description:</p>
                      </Col>
                      <Col md="6">
                        <textarea
                          placeholder="Market / Pop-Up Description"
                          className="form-control"
                          rows="4"
                          cols="50"
                          maxLength={256}
                          name="desc"
                          value={desc}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">{errors["desc"]}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Order Window</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="text"
                          placeholder="Enter order window"
                          name="marketOrderWindow"
                          value={marketOrderWindow}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">
                          {errors["marketOrderWindow"]}
                        </span>
                        <p>Example: Monday 9 am - Thursday 12 pm</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Pick Up Windows</p>
                      </Col>
                      <Col md="6">
                        <InputGroup>
                          <Form.Control
                            type="text"
                            onChange={this.handleOnChange}
                            value={newMarketPickupWindow}
                            name="newMarketPickupWindow"
                            placeholder="Enter pick up window"
                          />
                          <InputGroup.Append>
                            <Button
                              variant="light"
                              onClick={() => {
                                this.handleAddToSet({
                                  setPropKey: "marketPickupWindows",
                                  addToSetPropKey: "newMarketPickupWindow",
                                })
                              }}
                            >
                              <i className="fas fa-plus-circle"></i>
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <span className="helper_msg">
                          Example: Wednesdays 1 pm - 2 pm <br />
                          Markets without pick up windows will not be displayed
                          on the home page
                        </span>
                        <span className="error_msg">
                          {errors["marketPickupWindows"]}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <div className="col-md-6 offset-md-2">
                        <Row>
                          {marketPickupWindows.map((pickupWindow) => {
                            return (
                              <Col sm="4" className="delivery-postal-codes">
                                <Button
                                  variant="warning"
                                  onClick={() =>
                                    this.handleRemoveFromSet({
                                      setPropKey: "marketPickupWindows",
                                      removeValue: pickupWindow,
                                    })
                                  }
                                >
                                  {pickupWindow}{" "}
                                  <i class="fas fa-times font_15"></i>
                                </Button>
                              </Col>
                            )
                          })}
                        </Row>
                      </div>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Farms</p>
                      </Col>
                      <Col md="6">
                        <Select
                          mode="multiple"
                          name="farmIds"
                          onChange={this.handleOnChangeSelect}
                          className="form-control"
                        >
                          {farmdata &&
                            farmdata.map((item, i) => {
                              return (
                                <Option value={item._id}>
                                  {item.farmName}
                                </Option>
                              )
                            })}
                        </Select>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Pick Up Host</p>
                      </Col>
                      <Col md="6">
                        <SelectHost
                          setSelectedHostKey={(host) =>
                            this.setSelectedHostKey(host)
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Pick Up Host Commission Percentage</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="number"
                          step="0.01"
                          min="0"
                          max="90"
                          name="pickUpHostCommission"
                          value={pickUpHostCommission}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg"></span>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="8">
                        <div className="text-right">
                          <Button
                            variant="dark"
                            onClick={this.props.history.goBack}
                            className="mr-3"
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="warning"
                            onClick={this.handleAddMarket}
                          >
                            Save
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            )}{" "}
          </div>
        </div>
      </>
    )
  }
}

export default addmarket

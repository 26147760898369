import {
  IS_LOADING_START,
  IS_LOADING_STOP,
  LOGIN_SUCCESS,
  LOG_OUT_SUCCESS,
} from "./Type"

import Url from "../../services/BaseUrl"
import { _Api } from "../../services/Api"
import { notify } from "../../components/common/Toaster"

// action for login
export const login = (req) => (dispatch) => {
  try {
    dispatch({ type: IS_LOADING_START })

    _Api(Url.SUPER_ADMIN_LOGIN.method, Url.SUPER_ADMIN_LOGIN.url, req)
      .then((resp) => {
        if (resp.statusCode === 1) {
          let payload = {
            userProfile: resp.responseData.userProfile,
          }

          localStorage.setItem("accessToken", resp.responseData.accessToken)
          localStorage.setItem("isLoggedIn", "true")

          dispatch({ type: LOGIN_SUCCESS, payload })
          dispatch({ type: IS_LOADING_STOP })

          notify("success", resp.responseData.message)
        } else {
          dispatch({ type: IS_LOADING_STOP })
          notify("success", resp.responseData.message)
        }
      })
      .catch((err) => {
        dispatch({ type: IS_LOADING_STOP })

        if (err.error) notify("err", err.data.error.responseMessage)
        else if (err) notify("err", err.data.error.responseMessage)

        if (err.errorCode === 2) dispatch({ type: LOG_OUT_SUCCESS })
      })
  } catch (error) {
    notify("err", error.message)
  }
}

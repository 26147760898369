import { Col, Form, Row } from "react-bootstrap"
import React, { Component } from "react"

import FileViewer from "react-file-viewer"
import { Link } from "react-router-dom"
import PhoneInput from "react-phone-input-2"
import Sidebar from "../../components/sidebar"
import Url from "../../services/BaseUrl"
import _ from "lodash"
import { _Api } from "../../services/Api"
import { isValidEmail } from "../../utils/Validation"
import { message } from "antd"
import { notify } from "../../components/common/Toaster"

const ViewDoc = (path) => {
  console.log(path, "kjhgfdsdfghjkjhgfhn")
  let extension
  if (path) {
    extension = path.substr(path.length - 3, path.length).toUpperCase()
  }

  switch (extension) {
    case "PDF":
      console.log("jkbhjvghcfgxdsxgfcghvhjbjn", path)
      return (
        <FileViewer
          fileType={"pdf"}
          filePath={path}
          // errorComponent={CustomErrorComponent}
          // onError={this.onError}
        />
      )

      break
    case "DOC":
      return (
        <div className="item_iframe">
          <iframe
            className={"doc"}
            width="100%"
            height="120"
            frameBorder="0"
            src={`https://docs.google.com/gview?url=${path}&embedded=true`}
          ></iframe>
        </div>
      )

    case "OCX":
      return <FileViewer fileType={"docx"} filePath={path} />

      break
    default:
      return (
        <div
          className="imgas_bg"
          style={{ backgroundImage: `url(${path})` }}
        ></div>
      )
  }
  return "lk"
}

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result))
  reader.readAsDataURL(img)
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg"
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!")
  }
  // const isLt2M = file.size / 1024 / 1024 < 2;
  // if (!isLt2M) {
  //     message.error('Image must smaller than 2MB!');
  // }
  return isJpgOrPng
}

class FarmerDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      phone: "",
      errors: [],
      responseError: "",
      lat: "",
      long: "",
      userId: "",
      farmerDetails: "",
      profileImage: "",
      state: "",
      city: "",
      area: "",
      postalCode: "",

      address: "",
      addressLine1: "",
      addressLine2: "",
      logout: false,
      insurancePreview: "",
      insuranceDocErrorMsg: "",
      insuranceDoc: "",
      certificatePreview1: "",
      insurancePreview1: "",
      certificatePreview: "",
      certificate: "",
    }
  }

  //Image OnChange
  handleFileChange = (info) => {
    let reader = new FileReader()
    if (info.file.status === "uploading") {
      this.setState({ loading: true })
      return
    }
    if (info.file.status === "done") {
      // Get this url from response in real world
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          uploadMedia: info.fileList[0].originFileObj,
          loading: false,
        })
      )
    }
  }

  componentDidMount() {
    this.getFarmerDetails()
  }

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    })
  }
  getFarmerDetails = () => {
    let { search, count, pageNo, totalCount, sortField, sortType, userId } =
      this.state
    if (this.props.match.params) {
      let req = { userId: this.props.match.params.id }
      _Api(
        Url.SUPER_ADMIN_VIEW_FARMER.method,
        Url.SUPER_ADMIN_VIEW_FARMER.url,
        "",
        `?userId=${req.userId}`
      ).then((resp) => {
        console.log("resppp", resp)
        this.setState({
          farmerDetails: resp.responseData.farmersInfo,
          firstName: resp.responseData.farmersInfo.firstName,
          lastName: resp.responseData.farmersInfo.lastName,
          countryCode: resp.responseData.farmersInfo.countryCode,
          phone: resp.responseData.farmersInfo.phone,
          email: resp.responseData.farmersInfo.email,
          commission: resp.responseData.farmersInfo.commission,
          profileImage: resp.responseData.farmersInfo.profileImage,

          addressLine1: !_.isEmpty(resp.responseData.farmersInfo.address)
            ? resp.responseData.farmersInfo.address[0].addressLine1
              ? resp.responseData.farmersInfo.address[0].addressLine1
              : ""
            : "",
          addressLine2: !_.isEmpty(resp.responseData.farmersInfo.address)
            ? resp.responseData.farmersInfo.address[0].addressLine2
              ? resp.responseData.farmersInfo.address[0].addressLine2
              : ""
            : "",
          city: !_.isEmpty(resp.responseData.farmersInfo.address)
            ? resp.responseData.farmersInfo.address[0].city
              ? resp.responseData.farmersInfo.address[0].city
              : ""
            : "",
          state: !_.isEmpty(resp.responseData.farmersInfo.address)
            ? resp.responseData.farmersInfo.address[0].state
              ? resp.responseData.farmersInfo.address[0].state
              : ""
            : "",
          postalCode: !_.isEmpty(resp.responseData.farmersInfo.address)
            ? resp.responseData.farmersInfo.address[0].postalCode
              ? resp.responseData.farmersInfo.address[0].postalCode
              : ""
            : "",
          certificate: resp.responseData.farmersInfo.certificateImage,
          certificatePreview: resp.responseData.farmersInfo.certificateImage,
          certificatePreview1: resp.responseData.farmersInfo.certificateImage,
          insuranceDoc: resp.responseData.farmersInfo.insuranceImage,
          insurancePreview: resp.responseData.farmersInfo.insuranceImage,
        })
      })
    }
  }

  handleValidate = () => {
    let validate = false
    let errors = {}
    let { firstName, lastName, email, phone, countryCode, commission } =
      this.state

    if (_.isEmpty(firstName)) {
      validate = true
      errors["firstName"] = "First Name is required."
    }
    if (_.isEmpty(lastName)) {
      validate = true
      errors["lastName"] = "Last Name is required."
    }
    if (_.isEmpty(email)) {
      validate = true
      errors["email"] = "Email is required."
    } else if (!isValidEmail(email)) {
      validate = true
      errors["email"] = "Enter vaild email"
    }
    // if (_.isEmpty(countryCode)) {
    //     validate = true;
    //     errors['countryCode'] = 'Country code is required.'
    // }
    if (_.isEmpty(commission)) {
      validate = true
      errors["commission"] = "Phone number is required."
    }

    this.setState({ errors })
    return validate
  }

  handleEditFramer = (e) => {
    this.handleValidate()
    if (!this.handleValidate()) {
      let {
        firstName,
        lastName,
        addressLine1,
        addressLine2,
        postalCode,
        email,
        state,
        city,
        phone,
        profileImage,
        countryCode,
        lat,
        long,
        commission,
      } = this.state
      let formData = new FormData()
      formData.append("firstName", firstName)
      formData.append("lastName", lastName)
      formData.append("email", email)
      formData.append("lat", lat)
      formData.append("long", long)
      formData.append("phone", phone)
      formData.append("commission", commission)
      formData.append("countryCode", countryCode)
      formData.append("profileImage", profileImage)
      formData.append("addressLine1", addressLine1)
      formData.append("addressLine2", addressLine2)
      formData.append("postalCode", postalCode)
      formData.append("state", state)
      formData.append("city", city)
      // Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "",
      _Api(
        Url.SUPER_ADMIN_EDIT_FARMER.method,
        Url.SUPER_ADMIN_EDIT_FARMER.url,
        formData
      ).then((resp) => {
        {
          console.log("resp", resp)
        }
        notify("success", resp.responseData.message)
        this.props.history.goBack()
      })
    }
  }
  handlePicUpload = (event) => {
    console.log(event, "eventeventevent", event.target.files[0])
    let reader = new FileReader()
    let file = event.target.files[0]

    let { valiDate } = this.state

    reader.addEventListener(
      "load",
      () => {
        this.setState({ profilePicPreview: reader.result }, function () {})
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }

    if (
      file.type === "image/jpeg" ||
      file.type === "image/gif" ||
      file.type === "image/png" ||
      file.type === "image/svg+xml"
    ) {
      this.setState({
        profileImage: event.target.files[0],
        profilePicErrorMsg: "",
      })
    } else {
      valiDate = false
      this.setState({ profilePicErrorMsg: "File type not supported." })
    }
  }

  Closelogout = () => {
    this.setState({ logout: false })
  }
  handleLogoutAdmin = () => {
    this.setState({ logoutdeletePopup: true })
    localStorage.removeItem("accessToken")
    window.location.href = "/"
  }

  openPopup = () => {
    this.setState({ logout: true })
  }

  render() {
    let {
      insurancePreview,
      insuranceDocErrorMsg,
      insuranceDoc,
      certificatePreview1,
      certificatePreview,
      certificate,
      firstName,
      lastName,
      email,
      addressLine1,
      postalCode,
      addressLine2,
      countryCode,
      profileImage,
      phone,
      errors,
      logout,
      commission,
      address,
      area,
      state,
      city,
    } = this.state
    return (
      <>
        <Sidebar />
        <div className="body_container">
          <div className="body_inner_wrapper">
            <header className="header_content">
              <div>
                <Link to="/farmermanagement">
                  <span className="mr-3 d-inline-block text_light text_12">
                    <i class="fas fa-caret-left text_dark mr-2"></i> Back{" "}
                  </span>
                </Link>
              </div>
            </header>
            <div className="page_heading">
              <h6>Farmer view</h6>
            </div>
            <div className="main_content farm_page ">
              <Row>
                <Col sm={3}>
                  <div className="u_img mb-5">
                    {profileImage ? (
                      <img src={profileImage} />
                    ) : (
                      <span className="profile_icon_Admin ">
                        <img src={require("../../assets/img/adminlogo.png")} />
                        {/*<span className="img_icon">*/}
                        {/*    /!*<span className="file_input">*!/*/}
                        {/*    /!*    <input type="file" className="cm_hide_input"*!/*/}
                        {/*    /!*           accept="image/x-png,image/gif,image/jpeg,image/jpg"*!/*/}
                        {/*    /!*           onChange={this.handlePicUpload}/>*!/*/}
                        {/*    /!*</span>*!/*/}
                        {/*        <img src={require('../../assets/img/imgicon.png')}/>*/}
                        {/*    </span>*/}
                      </span>
                    )}
                  </div>
                </Col>
                <Col sm={9}>
                  <Row>
                    <Col md="2">
                      <p>First Name:</p>
                    </Col>
                    <Col md="3">
                      <Form.Control
                        type="text"
                        placeholder="Enter first name"
                        maxLength={30}
                        disabled
                        readOnly="readOnly"
                        name="firstName"
                        value={firstName}
                        onChange={this.handleOnChange}
                      />
                      <span className="error_msg">{errors["firstName"]}</span>
                    </Col>
                    <Col md="2">
                      <p>Last Name:</p>
                    </Col>
                    <Col md="3">
                      <Form.Control
                        type="text"
                        placeholder="Enter last name"
                        maxLength={30}
                        readOnly="readOnly"
                        disabled
                        name="lastName"
                        value={lastName}
                        onChange={this.handleOnChange}
                      />
                      <span className="error_msg">{errors["lastName"]}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <p>Email:</p>
                    </Col>
                    <Col md="8">
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        maxLength={30}
                        readOnly="readOnly"
                        disabled
                        name="email"
                        value={email}
                        onChange={this.handleOnChange}
                      />
                      <span className="error_msg">{errors["email"]}</span>
                    </Col>
                  </Row>
                  {phone ? (
                    <Row>
                      <Col md="2">
                        <p>Phone:</p>
                      </Col>
                      <Col md="8">
                        <div className="cm_phnnumber">
                          <PhoneInput
                            country={"us"}
                            disabled
                            className="form-control custom-number"
                            value={phone}
                            placeholder="Enter phone number"
                            onChange={(phone) => this.setState({ phone })}
                          />
                        </div>

                        <span className="error_msg">{errors["phone"]}</span>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {commission ? (
                    <Row>
                      <Col md="2">
                        <p>Commission:</p>
                      </Col>
                      <Col md="8">
                        <Form.Control
                          type="text"
                          disabled
                          placeholder="Enter commission in %..."
                          maxLength={30}
                          readOnly="readOnly"
                          name="commission "
                          value={commission}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">
                          {errors["commission "]}
                        </span>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {addressLine1 ? (
                    <Row>
                      <Col md="2">
                        <p>Address Line 1</p>
                      </Col>
                      <Col md="8">
                        <Form.Control
                          type="text"
                          placeholder="Enter Address Line 1..."
                          maxLength={30}
                          disabled
                          readOnly="readOnly"
                          name="addressLine1"
                          value={addressLine1}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">
                          {errors["addressLine1"]}
                        </span>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {addressLine2 ? (
                    <Row>
                      <Col md="2">
                        <p>Address Line 2</p>
                      </Col>
                      <Col md="8">
                        <Form.Control
                          type="text"
                          placeholder="Enter Address Line 2..."
                          maxLength={30}
                          readOnly="readOnly"
                          disabled
                          name="addressLine2"
                          value={addressLine2}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">
                          {errors["addressLine2"]}
                        </span>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {city ? (
                    <Row>
                      <Col md="2">
                        <p>City</p>
                      </Col>
                      <Col md="8">
                        <Form.Control
                          type="text"
                          placeholder="Enter city"
                          maxLength={30}
                          readOnly="readOnly"
                          disabled
                          name="city"
                          value={city}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">{errors["city"]}</span>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {state ? (
                    <Row>
                      <Col md="2">
                        <p>State</p>
                      </Col>
                      <Col md="8">
                        <Form.Control
                          type="text"
                          placeholder="Enter state"
                          maxLength={30}
                          readOnly="readOnly"
                          disabled
                          name="state"
                          value={state}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">{errors["state"]}</span>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {postalCode ? (
                    <Row>
                      <Col md="2">
                        <p>Postal Code</p>
                      </Col>
                      <Col md="8">
                        <Form.Control
                          type="text"
                          placeholder="Enter postal code"
                          maxLength={30}
                          disabled
                          readOnly="readOnly"
                          name="postalCode"
                          value={postalCode}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">
                          {errors["postalCode"]}
                        </span>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {insurancePreview ? (
                    <Row>
                      <Col lg="2" md="2">
                        <p>Insurance Document:</p>
                      </Col>
                      <Col lg="4" md="6">
                        <div
                          className="file_uploader bg_size documentpdf"
                          // style={{ backgroundImage: `url(${this.state.insurancePreview1})` }}
                        >
                          {ViewDoc(insurancePreview)}
                          {/* <div class="overlay"></div> */}

                          <span className="dwnload-icon">
                            <a href={insuranceDoc} download>
                              <i className="fas fa-download"></i>
                            </a>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row>
                    <Col md="2"></Col>
                    <Col md="4">
                      {/*<small>Recommended size 350*170 </small>*/}
                    </Col>
                  </Row>
                  {certificatePreview1 ? (
                    <Row>
                      <Col lg="2" md="2">
                        <p>Certification or Growers Pledge:</p>
                      </Col>
                      <Col lg="4" md="6">
                        <div
                          className="file_uploader bg_size documentpdf"
                          // style={{ backgroundImage: `url(${this.state.certificatePreview1})` }}
                        >
                          {ViewDoc(certificatePreview1)}
                          {/* <div class="overlay"></div> */}
                          <>
                            {/* <div class="overlay"></div> */}

                            <span className="dwnload-icon">
                              <a href={certificate} target="_blank" download>
                                <i className="fas fa-download"></i>
                              </a>
                            </span>
                          </>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default FarmerDetails

// TODO pick up host mods
// - add the ability to select a pick up host model that has stripeAccountId
// - add the ability to set a commission for the pick up host when a single valid
// account is set as the pickup host

import { Button, Col, Form, Row, InputGroup } from "react-bootstrap"
import React, { Component } from "react"

import Geocode from "react-geocode"
import { Link } from "react-router-dom"
import Loader from "../../components/common/Loader"
import { Select } from "antd"
import Sidebar from "../../components/sidebar"
import Url from "../../services/BaseUrl"
import _ from "lodash"
import { _Api } from "../../services/Api"
import { notify } from "../../components/common/Toaster"
import { SelectHost } from "./SelectHost"
import { floatOrDefault } from "../../utils/default-values"
import { sortAscendingFarmName } from "../../utils/sort-values"

const { Option } = Select
const debug = require("debug")("edit-market")

class EditMarket extends Component {
  constructor(props) {
    super(props)
    this.state = {
      marketName: "",
      desc: "",
      lat: 0,
      long: 0,
      profilePicPreview: "",
      category: "",
      imageLocation: "",
      response: "",
      errors: [],
      responseError: "",
      fileName: "",
      farmdata: "",
      marketImage: "",
      state: "",
      city: "",
      address: "",
      addressLine1: "",
      addressLine2: "",
      postalCode: "",
      area: "",
      farmId: "",
      farmName: "",
      farmIds: "",
      marketBackgroundColor: "",
      marketIcon: "",
      marketIconPreview: "",
      marketIconErrorMsg: "",
      marketOrderWindow: "",
      marketOrderWindowError: "",
      marketPickupWindows: [],
      newMarketPickupWindow: "",
      pickUpHostCommission: 0,
      pickUpHostUiKey: undefined,
      pickUpHostUiDisplay: undefined,
    }
  }

  setSelectedHostKey(key) {
    this.setState({ pickUpHostUiKey: key })
  }

  // on image upload
  onImageUpload = (e) => {
    let file = e.target.files[0]

    this.setState({
      imageLocation: file,
      fileName: file.name,
      fileUploadFileError: "",
    })
  }

  onKeyPress(e) {
    e = e || window.event
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which
    var charStr = String.fromCharCode(charCode)

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault()
  }

  handleOnChange = (e) => {
    let self = this

    let { addressLine1, addressLine2, city, state, postalCode } = this.state
    if (e.target.name === "addressLine1") {
      addressLine1 = e.target.value
    }
    if (e.target.name === "addressLine2") {
      addressLine2 = e.target.value
    }

    if (e.target.name === "city") {
      city = e.target.value
    }
    if (e.target.name === "state") {
      state = e.target.value
    }
    if (e.target.name === "postalCode") {
      postalCode = e.target.value
    }

    let addr =
      addressLine1 +
      " " +
      addressLine2 +
      " " +
      city +
      " " +
      state +
      " " +
      postalCode

    if (
      e.target.name === "addressLine1" ||
      e.target.name === "addressLine2" ||
      e.target.name === "city" ||
      e.target.name === "state" ||
      e.target.name === "postalCode"
    ) {
      Geocode.fromAddress(addr).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location

          self.setState({ lat: lat, long: lng })
        },
        (error) => {
          console.error(error)
        }
      )
    }

    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    })
  }

  handleValidate = () => {
    let validate = true
    let errors = {}
    let {
      postalCode,
      category,
      marketName,
      desc,
      imageLocation,
      phone,
      countryCode,
      marketBackgroundColor,
      marketOrderWindow,
      marketPickupWindows,
    } = this.state
    if (imageLocation === "" || imageLocation === undefined) {
      validate = false
      this.setState({
        imageErrorMsg: "Please select item image.",
      })
    } else {
      if (imageLocation.size > 5000000) {
        validate = false
        this.setState({
          imageErrorMsg: "Image size should be less than 5Mb.",
        })
      } else {
        this.setState({
          imageErrorMsg: "",
        })
      }
    }
    if (_.isEmpty(marketName)) {
      validate = false
      errors["marketName"] = "Market name is required."
    }
    if (postalCode) {
      if (postalCode < 0) {
        validate = false
        errors["postalCode"] = "Postal code must be greater than 0."
      }
    }

    if (category == null || category == "" || category == undefined) {
      validate = false
      errors["category"] = "category is required."
    }
    if (_.isEmpty(desc)) {
      validate = false
      errors["desc"] = "Description is required."
    }

    // if (!marketBackgroundColor) {
    //   validate = false
    //   errors["marketBackgroundColor"] = "Background color is required."
    // }
    // not required
    // if (!marketOrderWindow) {
    //   validate = false
    //   errors["marketOrderWindow"] = "Order window is required."
    // }
    // if (
    //   !Array.isArray(marketPickupWindows) ||
    //   (Array.isArray(marketPickupWindows) && marketPickupWindows.length === 0)
    // ) {
    //   validate = false
    //   errors["marketPickupWindows"] = "Pick up window is required."
    // }

    this.setState({ errors })
    return validate
  }

  componentDidMount() {
    this.getMarketDetails()
    this.getFarm()
  }

  getMarketDetails = () => {
    let self = this
    this.setState({ isLoader: true })
    if (this.props.match.params) {
      let req = { marketId: this.props.match.params.id }
      _Api(
        Url.SUPER_ADMIN_VIEW_MARKET.method,
        Url.SUPER_ADMIN_VIEW_MARKET.url,
        "",
        `?marketId=${req.marketId}`
      )
        .then((resp) => {
          const market = resp.responseData.marketInfo
          self.setState(
            {
              marketName: market.marketName,
              desc: market.desc,
              imageLocation: market.marketImage,
              profilePicPreview: market.marketImage,
              addressLine1: market.address.addressLine1,
              addressLine2: market.address.addressLine2,
              category: market.category ? market.category : "",
              city: market.address.city,
              postalCode: market.address.postalCode,
              state: market.address.state,
              farmIds: market.farmIds,
              lat: market.length > 0 ? market.geoLocation[0] : 0,
              long: market.length > 0 ? market.geoLocation[1] : 0,
              marketBackgroundColor: market.marketBackgroundColor,
              marketIcon: market.marketIcon,
              marketIconPreview: market.marketIcon,
              marketOrderWindow: market.marketOrderWindow,
              marketPickupWindows: market.marketPickupWindows,
              isLoader: false,
              pickUpHostCommission: floatOrDefault(
                market.pickUpHostCommission,
                0
              ),
              pickUpHostUiKey: market.pickUpHostUiKey,
              pickUpHostUiDisplay: market.pickUpHostUiDisplay,
            },
            () => {
              // this.getFarm()
            }
          )
        })
        .catch((err) => {
          notify("error", err.data?.error?.responseMessage || "error")
          self.setState({ loader: false, isloader: false })
        })
    }
  }

  getFarm = () => {
    let req = {}
    _Api(
      Url.SUPER_ADMIN_GET_FARM.method,
      Url.SUPER_ADMIN_GET_FARM.url,
      req
    ).then((resp) => {
      this.setState({
        farmdata: resp.responseData.result.sort(sortAscendingFarmName),
      })
    })
  }

  handleOnChangeSelect = (id) => {
    this.farmIds = id
    this.setState({ farmIds: this.farmIds })
  }

  handleAddToSet = ({ setPropKey, addToSetPropKey }) => {
    const set = this.state[setPropKey]
    const addValue = this.state[addToSetPropKey]
    const validate = (val) => val.length > 0
    if (!validate(addValue)) return
    this.setState({
      [setPropKey]: _.uniq(set.concat(addValue)),
    })
  }
  handleRemoveFromSet = ({ setPropKey, removeValue }) => {
    const set = this.state[setPropKey]
    this.setState({
      [setPropKey]: set.filter((value) => value !== removeValue),
    })
  }

  // submit
  handleEditMarket = (e) => {
    let self = this
    if (this.handleValidate()) {
      let {
        addressLine1,
        category,
        addressLine2,
        postalCode,
        marketName,
        desc,
        imageLocation,
        phone,
        countryCode,
        lat,
        long,
        state,
        city,
        address,
        area,
        marketIcon,
        marketBackgroundColor,
        marketOrderWindow,
        marketPickupWindows,
        pickUpHostCommission,
        pickUpHostUiKey,
      } = this.state
      let formData = new FormData()
      formData.append("marketId", this.props.match.params.id)
      formData.append("marketName", marketName)
      formData.append("category", category)

      formData.append("desc", desc)
      formData.append(
        "farmIds",
        JSON.stringify(this.farmIds) ? JSON.stringify(this.farmIds) : ""
      )
      formData.append("lat", lat ? lat : 0)
      formData.append("long", long ? long : 0)
      formData.append("marketImage", imageLocation ? imageLocation : "")
      formData.append("addressLine1", addressLine1)
      formData.append("addressLine2", addressLine2)
      formData.append("postalCode", postalCode ? postalCode : "")
      formData.append("city", city)
      formData.append("state", state)
      formData.append("marketIcon", marketIcon ? marketIcon : "")
      formData.append("marketBackgroundColor", marketBackgroundColor)
      formData.append("marketOrderWindow", marketOrderWindow)
      formData.append(
        "marketPickupWindows",
        JSON.stringify(marketPickupWindows)
      )
      formData.append("pickUpHostCommission", pickUpHostCommission)
      formData.append("pickUpHostUiKey", pickUpHostUiKey)

      this.setState({ isloader: true, loader: true })
      _Api(
        Url.SUPER_ADMIN_EDIT_MARKET.method,
        Url.SUPER_ADMIN_EDIT_MARKET.url,
        formData
      )
        .then((resp) => {
          {
            // console.log('resp', resp);
          }
          if (resp.statusCode == 1) {
            notify("success", resp.responseData.message)
            this.props.history.push("/popupmanagement")
          } else {
            notify("error", resp.data.error.responseMessage)
            self.setState({ loader: false, isloader: false })
          }
        })
        .catch((err) => {
          console.log("edit-market-api-error")
          console.log(err)
          notify("error", err.data.error.responseMessage)
          self.setState({ loader: false, isloader: false })
        })
    }
  }

  handlePicUpload = (
    { stateImage, statePreview, stateErrorMessage },
    event
  ) => {
    console.log(event, "eventeventevent", event.target.files[0])
    let reader = new FileReader()
    let file = event.target.files[0]

    let { valiDate } = this.state

    reader.addEventListener(
      "load",
      () => {
        this.setState({ [statePreview]: reader.result }, function () {})
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }
    if (file) {
      // console.log(file, 'fileeeeeeeeeee');
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/gif" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml"
      ) {
        if (file.size > 5000000) {
          valiDate = false
          this.setState({
            [stateImage]: event.target.files[0],
            [stateErrorMessage]: "File size should be less than 5Mb.",
          })
        } else {
          debug("processed successfully", event.target.files[0])

          this.setState({
            [stateImage]: event.target.files[0],
            [stateErrorMessage]: "",
          })
        }
      } else {
        valiDate = false
        this.setState({ [stateErrorMessage]: "File type not supported." })
      }
    }
  }

  // handleAddressLatLong = (latValue, lngValue, address, area, city, state,postalCode) => {
  //     console.log('latlong', latValue, lngValue, address)
  //     this.setState({ lat: latValue, long: lngValue, address: address, area: area, city: city,postalCode:postalCode, state: state })
  // }

  render() {
    let {
      long,
      lat,
      imageErrorMsg,
      addressLine1,
      category,
      addressLine2,
      marketName,
      postalCode,
      desc,
      errors,
      fileName,
      farmdata,
      profilePicPreview,
      imageLocation,
      state,
      city,
      address,
      area,
      marketIcon,
      marketIconPreview,
      marketIconErrorMsg,
      marketBackgroundColor,
      marketOrderWindow,
      marketPickupWindows,
      newMarketPickupWindow,
      pickUpHostCommission,
      pickUpHostUiKey,
    } = this.state

    return (
      <>
        <Sidebar />
        <div className="body_container">
          <div className="body_inner_wrapper">
            {this.state.isLoader ? (
              <Loader />
            ) : (
              <>
                <header className="header_content">
                  <div>
                    <Link to="/popupmanagement">
                      <span className="mr-3 d-inline-block text_light text_12">
                        <i class="fas fa-caret-left text_dark mr-2"></i> Back{" "}
                      </span>
                    </Link>
                  </div>
                </header>
                <div className="page_heading">
                  <h6>Edit Market/Pop-Up</h6>
                </div>
                <div className="main_content">
                  <div className="farm_page">
                    <Row>
                      <Col md="2">
                        <p>Market Name</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="text"
                          placeholder="Enter Enter here"
                          maxLength={55}
                          name="marketName"
                          value={marketName}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">
                          {errors["marketName"]}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p> Category</p>
                      </Col>
                      <Col md="6">
                        <select
                          name="category"
                          value={category}
                          onChange={this.handleOnChange}
                          className="form-control"
                        >
                          <option value="">Select</option>
                          <option value="1">Pop up market</option>
                          <option value="2">Farmer market</option>
                        </select>
                        <span className="error_msg">{errors["category"]}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Market Image</p>
                      </Col>
                      <Col md="6">
                        <div className="file_uploader cm_imgupload">
                          {imageLocation ? (
                            <img
                              src={profilePicPreview}
                              className="pos_abs"
                              alt=""
                            />
                          ) : (
                            <i class="fas fa-plus-circle"></i>
                          )}

                          <div className="custom custom-file">
                            <div className="file_input">
                              <input
                                type="file"
                                className="custom-file-input"
                                onChange={this.handlePicUpload.bind(this, {
                                  stateImage: "imageLocation",
                                  statePreview: "profilePicPreview",
                                  stateErrorMessage: "imageErrorMsg",
                                })}
                              />
                              <span className="error_msg">{imageErrorMsg}</span>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Market Icon</p>
                      </Col>
                      <Col md="6">
                        <div className="file_uploader cm_imgupload">
                          {marketIcon ? (
                            <img
                              src={marketIconPreview}
                              className="pos_abs"
                              alt=""
                            />
                          ) : (
                            <i class="fas fa-plus-circle"></i>
                          )}

                          <div className="custom custom-file">
                            <div className="file_input">
                              <input
                                type="file"
                                className="custom-file-input"
                                onChange={this.handlePicUpload.bind(this, {
                                  stateImage: "marketIcon",
                                  statePreview: "marketIconPreview",
                                  stateErrorMessage: "marketIconErrorMsg",
                                })}
                              />
                              <span className="error_msg">
                                {marketIconErrorMsg}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Market Background Color</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="text"
                          placeholder="Enter a hex color, including #"
                          name="marketBackgroundColor"
                          value={marketBackgroundColor}
                          onChange={this.handleOnChange}
                        />
                        <span>
                          <a
                            href="https://g.co/kgs/Rg6Jsj"
                            style={{ textDecoration: "underline" }}
                          >
                            Use Google's color picker to find color hex values
                          </a>
                        </span>
                        <span className="error_msg">
                          {errors["marketBackgroundColor"]}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Address Line 1</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="text"
                          placeholder="Enter Address Line 1"
                          // maxLength={30}
                          name="addressLine1"
                          value={addressLine1}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">
                          {errors["addressLine1"]}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Address Line 2</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="text"
                          placeholder="Enter Address Line 2"
                          maxLength={30}
                          name="addressLine2"
                          value={addressLine2}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">
                          {errors["addressLine2"]}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>City</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="text"
                          placeholder="Enter city"
                          maxLength={30}
                          name="city"
                          value={city}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">{errors["city"]}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>State</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="text"
                          placeholder="Enter state"
                          maxLength={30}
                          name="state"
                          value={state}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">{errors["state"]}</span>
                      </Col>
                    </Row>{" "}
                    <Row>
                      <Col md="2">
                        <p>Postal Code</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="number"
                          placeholder="Enter Postal Code"
                          maxLength={30}
                          onKeyPress={this.onKeyPress.bind(this)}
                          pattern="[0-9]*"
                          min="0"
                          name="postalCode"
                          value={postalCode}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">
                          {errors["postalCode"]}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Description</p>
                      </Col>
                      <Col md="6">
                        <textarea
                          placeholder="Enter here"
                          className="form-control"
                          rows="4"
                          cols="50"
                          maxLength={256}
                          name="desc"
                          value={desc}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">{errors["desc"]}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Order Window</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="text"
                          placeholder="Enter order window"
                          name="marketOrderWindow"
                          value={marketOrderWindow}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg">
                          {errors["marketOrderWindow"]}
                        </span>
                        <p>Example: Monday 9 am - Thursday 12 pm</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Pick Up Windows</p>
                      </Col>
                      <Col md="6">
                        <InputGroup>
                          <Form.Control
                            type="text"
                            onChange={this.handleOnChange}
                            value={newMarketPickupWindow}
                            name="newMarketPickupWindow"
                            placeholder="Enter pick up window"
                          />
                          <InputGroup.Append>
                            <Button
                              variant="light"
                              onClick={() => {
                                this.handleAddToSet({
                                  setPropKey: "marketPickupWindows",
                                  addToSetPropKey: "newMarketPickupWindow",
                                })
                              }}
                            >
                              <i className="fas fa-plus-circle"></i>
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <span className="helper_msg">
                          Example: Wednesdays 1 pm - 2 pm <br />
                          Markets without pick up windows will not be displayed
                          on the home page
                        </span>
                        <span className="error_msg">
                          {errors["marketPickupWindows"]}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <div className="col-md-6 offset-md-2">
                        <Row>
                          {marketPickupWindows.map((pickupWindow) => {
                            return (
                              <Col sm="4" className="delivery-postal-codes">
                                <Button
                                  variant="warning"
                                  onClick={() =>
                                    this.handleRemoveFromSet({
                                      setPropKey: "marketPickupWindows",
                                      removeValue: pickupWindow,
                                    })
                                  }
                                >
                                  {pickupWindow}{" "}
                                  <i class="fas fa-times font_15"></i>
                                </Button>
                              </Col>
                            )
                          })}
                        </Row>
                      </div>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Farms</p>
                      </Col>
                      <Col md="6">
                        {/*<Select*/}
                        {/*    mode="multiple"*/}
                        {/*    name="farmIds"*/}
                        {/*    onChange={this.handleOnChangeSelect}*/}
                        {/*    className="form-control"*/}
                        {/*>*/}

                        {/*    {*/}

                        {/*        farmdata && farmdata.map((item, i) => {*/}
                        {/*            console.log('item', item)*/}
                        {/*            return (*/}
                        {/*                <Option value={item._id}>{item.farmName}</Option>*/}
                        {/*            )*/}
                        {/*        })*/}
                        {/*    }*/}

                        <Select
                          mode="tags"
                          // name="farmIds"
                          value={this.state.farmIds}
                          onChange={this.handleOnChangeSelect}
                          className="form-control"
                        >
                          {farmdata &&
                            farmdata.map((item, i) => {
                              return (
                                <Option key={item._id} value={item._id}>
                                  {item.farmName}
                                </Option>
                              )
                            })}
                        </Select>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Pick Up Host</p>
                      </Col>
                      <Col md="6">
                        {pickUpHostUiKey && (
                          <SelectHost
                            pickUpHostUiKey={pickUpHostUiKey}
                            setSelectedHostKey={(host) =>
                              this.setSelectedHostKey(host)
                            }
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <p>Pick Up Host Commission Percentage</p>
                      </Col>
                      <Col md="6">
                        <Form.Control
                          type="number"
                          step="0.01"
                          min="0"
                          max="90"
                          name="pickUpHostCommission"
                          value={pickUpHostCommission}
                          onChange={this.handleOnChange}
                        />
                        <span className="error_msg"></span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <div className="text-right">
                          <Button
                            variant="dark"
                            onClick={this.props.history.goBack}
                            className="mr-3"
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="warning"
                            onClick={this.handleEditMarket}
                          >
                            Save
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default EditMarket

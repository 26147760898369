import * as jsPDF from "jspdf"

import { Button, Form } from "react-bootstrap"
import React, { Component } from "react"

import InventoryList from "./InventoryList"
import { Link } from "react-router-dom"
import Loader from "../../../components/common/Loader"
import Sidebar from "../../../components/sidebar"
import Url from "../../../services/BaseUrl"
import { _Api } from "../../../services/Api"
import { connect } from "react-redux"
import { ActionTypes as inventoryActions } from "../../../redux/action/inventory"

const Units = (type) => {
  switch (type) {
    case 3:
      return "bunch"
    case 5:
      return "pint"
    case 6:
      return "head"
    case 7:
      return "bag "
    case 8:
      return "half pint"
    case 10:
      return "Pound"
    case 11:
      return "Half Pound Bag"
    case 12:
      return "Half Pound"
    case 13:
      return "others"
    default:
      return ""
  }
}

class FarmerInventory extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inventoryInfo: [],
      search: "",
      pageNo: 1,
      count: 500,
      view: 1,
      sortField: "itemName",
      sortType: "1",
      userId: this.props.match.params.id,
    }
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: inventoryActions.RESET_INVENTORY_STATE,
    })
  }

  onKeyPress(e) {
    e = e || window.event
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which
    var charStr = String.fromCharCode(charCode)

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault()
  }

  addInventory = () => {
    this.props.dispatch({
      type: inventoryActions.ADD_INVENTORY_ITEM_SHOW,
    })
  }

  changeView = (e) => {
    this.setState({ view: e }, () => {})
  }

  handleSearch = (e) => {
    this.setState({ search: e.target.value })
  }

  printDocument = () => {
    let self = this

    let { search } = this.state
    let params = `?&search=${search}&userId=${
      this.props.match.params.id
    }&count=${500}`
    if (this.props.match.params) {
      _Api(Url.GET_INVENTORY.method, Url.GET_INVENTORY.url, "", params).then(
        (data) => {
          let inventoryData = data

          self.setState({ loader: false })

          if (inventoryData.statusCode === 1) {
            let inventoryInfo1 = inventoryData.responseData.result.inventoryList
            var doc = new jsPDF()

            if (this.state.inventoryInfo) {
              // let self = this.props
              let len = this.state.inventoryInfo.length - 1
              let arr = []

              doc.setFontSize(12)

              doc.autoTable({ html: "#divToPrint1" })
              let table1 = inventoryInfo1.map((item, index) => [
                index + 1,
                item.itemName === "others" ? item.otherItemName : item.itemName,
                item.variety ? item.variety : "",
                item.wholesalerPrice && item.wholesalerUnit
                  ? "$" +
                    item.wholesalerPrice +
                    " " +
                    "/" +
                    " " +
                    (item.wholesalerUnit === 13
                      ? item.otherWholesalerUnit
                      : Units(item.wholesalerUnit))
                  : "",
                item.consumerPrice && item.consumerUnit
                  ? "$" +
                    item.consumerPrice +
                    " " +
                    "/" +
                    " " +
                    (item.consumerUnit === 13
                      ? item.otherConsumerUnit
                      : Units(item.consumerUnit))
                  : "",
                item.description ? item.description : "",
              ])

              doc.autoTable({
                head: [
                  [
                    "#",
                    "Item",
                    "Variety",
                    "Wholesale Price Per Unit",
                    "Consumer Price Per Unit",
                    "Description",
                  ],
                ],
                body: table1.length === 0 ? [["no data available "]] : table1,
              })
              setTimeout(() => doc.save("Inventory.pdf"), 5000)
            }
          } else {
            this.setState({ errors: inventoryData.error.responseMessage })
          }
        }
      )
    }
  }

  toBack = () => {
    this.props.history.push(
      `/farmermanagement/editfrmermanagement/${this.props.match.params.id}`
    )
  }

  render() {
    let { userId, search } = this.state

    return (
      <>
        <Sidebar />

        <div>
          <div className="header_wrapper">
            <div className="header_logo">
              <img src={require("../../../assets/img/OFFICIAL LOGO.png")} />
            </div>
            <div className="header_search">
              <div className="form-group has-search">
                <span className="fa fa-search form-control-feedback"></span>
                <Form.Control
                  type="search"
                  placeholder="Search..."
                  name="search"
                  value={search}
                  onChange={this.handleSearch}
                />
              </div>
            </div>
            <div className="bell_icon">
              <i className="fas fa-bell"></i>
            </div>
          </div>

          <div className="body_container">
            <div className="body_inner_wrapper">
              {this.state.loader ? (
                <Loader />
              ) : (
                <>
                  <header className="header_content">
                    <Link onClick={this.toBack}>
                      <span className="mr-3 d-inline-block text_light text_12">
                        <i className="fas fa-caret-left text_dark mr-2"></i>{" "}
                        Back{" "}
                      </span>
                    </Link>
                    <div className="d-flex text-nowrap header_btn">
                      <div className="print_button">
                        <Button variant="light" onClick={this.printDocument}>
                          <i className="fas fa-print"></i> Print Inventory{" "}
                        </Button>
                      </div>
                      <Button
                        onClick={(e) => this.changeView(1)}
                        variant="light"
                      >
                        <i className="fas fa-th-large"></i>
                      </Button>
                      <Button
                        onClick={(e) => this.changeView(2)}
                        variant="light"
                      >
                        <i className="fas fa-bars"></i>
                      </Button>
                      <Button
                        variant="warning"
                        className="text-light"
                        onClick={this.addInventory}
                      >
                        <i className="fas fa-plus-circle"></i> Add Inventory
                      </Button>
                    </div>
                  </header>
                  <div className="page_heading d-flex">
                    <Link
                      to={`/farmermanagement/editfrmermanagement/${userId}`}
                      className="mr-3"
                    >
                      <h6>Edit Farmer</h6>
                    </Link>
                    <h6 className=" activeheading mr-3 mr-3"> Inventory</h6>

                    <Link
                      to={`/farmermanagement/farm/${userId}`}
                      className=" mr-3"
                    >
                      <h6>Farm</h6>
                    </Link>
                    <Link
                      to={`/farmermanagement/distributionmanagement/${userId}`}
                      className=" mr-3"
                    >
                      <h6>Distribution</h6>
                    </Link>
                  </div>
                  <InventoryList {...this.props} />
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

// export default FarmerInventory;
export default connect((state) => ({
  search: state.search,
  pageNo: state.pageNo,
  count: state.count,
  sortField: state.sortField,
  sortType: state.sortType,
  itemStatus: state.itemStatus,
}))(FarmerInventory)

import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import React, { Component } from "react"

import { Empty } from "antd"
import { Link } from "react-router-dom"
import Loader from "../../components/common/Loader"
import Pagination from "react-js-pagination"
import Sidebar from "../../components/sidebar"
import Url from "../../services/BaseUrl"
import { _Api } from "../../services/Api"
import moment from "moment-timezone"

const days = (type) => {
  switch (type) {
    case 0:
      return "Sun"
      break
    case 1:
      return "Mon"
      break
    case 2:
      return "Tue"
      break
    case 3:
      return "Wed"
      break
    case 4:
      return "Thur"
      break
    case 5:
      return "Fri"
      break
    case 6:
      return "Sat"
      break
  }
}

class NewsLetter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newsList: [],
      search: "",
      totalCount: 0,
      sortType: "",
      sortField: "",
      pageNo: 1,
      count: 10,
      email: "",
      name: "",
      loading: false,
      del: false,
      isLoader: false,
      status: 1,
      recipeName: "",
      zip: "",
      description: "",
      descript: "",
      image: "",
      imageData: "",
      imagePreview: "",
      imageErrorMsg: "",
      recipeNameErrorMsg: "",
      descriptionErrorMsg: "",
      totalNewsResult: "",
      totalAllNews: "",
    }
  }

  componentDidMount() {
    this.getNewsLetters()
  }

  handleChanged = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      // image: '',
      descriptionErrorMsg: "",
      imageErrorMsg: "",
      recipeNameErrorMsg: "",
    })
  }

  handleSearch = (e) => {
    this.setState({ search: e.target.value })
    if (e.target.value.length > 3) {
      this.setState({ search: e.target.value }, () => this.getNewsLetters())
    }
    if (e.target.value === "") {
      this.setState({ search: e.target.value }, () => this.getNewsLetters())
    }
  }
  handleSort = (sortField) =>
    this.setState(
      {
        loader: true,
        sortField,
        sortType: this.state.sortType === 1 ? -1 : 1,
      },
      () => this.getNewsLetters()
    )
  // handleUserStatus = e => this.setState({
  //     loader: true,
  //     userStatus: this.state.userStatus === 1 ? 0 : 1
  // }, () => this.getNewsLetters());

  getAllNewsExcel = () => {
    let self = this

    let {
      search,
      pageNo,
      count,
      sortField,
      sortType,
      inventoryType,
      userId,
      status,
    } = this.state
    let params = `?&userId=${userId ? userId : ""}`

    _Api(
      Url.GET_ALL_NEWS_EXCEL.method,
      Url.GET_ALL_NEWS_EXCEL.url,
      "",
      params
    ).then((data) => {
      let inventoryData = data

      self.setState({ loader: false })

      if (inventoryData.statusCode == 1) {
        console.log("sdfsdfsfds", data.responseData.file)

        window.open(data.responseData.file)
        // window.location.href = data.responseData.file;
      } else {
        this.setState({ errors: inventoryData.error.responseMessage })
      }
    })
  }

  getNewsLetters = () => {
    let { search, count, pageNo, totalCount, sortField, sortType } = this.state
    this.setState({ isLoader: true })
    _Api(
      Url.GET_NEWS.method,
      Url.GET_NEWS.url,
      "",
      `?search=${search}&count=${count}&pageNo=${
        pageNo - 1
      }&sortType=${sortType}&sortField=${sortField}`
    )
      .then((resp) => {
        console.log("resppp", resp.responseData)
        this.setState({
          newsList: resp.responseData.result.totalNewsletterList,
          totalNewsResult: resp.responseData.result.totalNewsletterResult,
          totalAllNews: resp.responseData.result.totalNewsletter,
          // totalActive: resp.responseData.result.totalActive,
          // totalInActive: resp.responseData.result.totalInActive,
          isLoader: false,
        })
        //  notify('success', resp.responseData.)
      })
      .catch((err) => {
        this.setState({ loader: false })

        // notify('error', err.data.error.responseMessage)
      })
  }

  // handleDeleteStatus = (id, status) => {
  //     let req = {
  //         recipesId: id,
  //         status,
  //
  //     }
  //     _Api(Url.DELETE_RECIPE.method, Url.DELETE_RECIPE.url, req)
  //         .then(resp => {
  //             console.log('delete', resp.responseData.message)
  //             notify('success', resp.responseData.message)
  //             this.setState({ del: false })
  //             this.getRecipeDetails()
  //         }).catch(err => {
  //
  //         this.setState({ loader: false })
  //
  //         notify('error', err.data.error.responseMessage)
  //     })
  //
  //
  // }

  //Show modal
  // showModal = (id) => {
  //     this.setState({
  //         del: true,
  //         selectedId: id
  //     });
  //
  // };

  // handleAciveInactiveStatus = (e, id) => {
  //     let req = {
  //         recipesId: id,
  //         status: e.target.checked ? 1 : 2,
  //     }
  //     _Api(Url.UPDATE_STATUS_RECIPE.method, Url.UPDATE_STATUS_RECIPE.url, req)
  //         .then(resp => {
  //             notify('success', resp.responseData.message)
  //             this.getRecipeDetails()
  //         }).catch(err => {
  //
  //         this.setState({ loader: false })
  //
  //         notify('error', err.data.error.responseMessage)
  //     })
  //
  //
  // }

  openAddPopup = () => {
    this.setState({ open: true })
  }
  handleClose = () => {
    this.setState({
      open: false,
      viewRecipes: false,
      del: false,
      imagePreview: "",
      data1: "",
      data2: "",
      imageErrorMsg: "",
      descriptionErrorMsg: "",
      recipeNameErrorMsg: "",
      recipeName: "",
      edit: false,
      data: "",
    })
  }

  // handleAddValidation = () => {
  //     console.log(this.state, "pppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppss")
  //     let validate = true;
  //     let {
  //         image, recipeName, description, data
  //     } = this.state
  //
  //
  //     console.log(data, "datadatadatadatadatadatas")
  //     // if(image) {
  //     if (image === '' || image === undefined) {
  //         validate = false;
  //         this.setState({
  //             imageErrorMsg: 'Please select item image.'
  //         })
  //     } else {
  //         if (image.size > 5000000) {
  //             validate = false;
  //             this.setState({
  //                 imageErrorMsg: 'Image size should be less than 5Mb.'
  //             })
  //         } else {
  //             this.setState({
  //                 imageErrorMsg: ''
  //             })
  //         }
  //     }
  //
  //     // if (description === '' || description === undefined) {
  //     //     validate = false;
  //     //     this.setState({
  //     //         descriptionErrorMsg: 'Please enter description.'
  //     //     })
  //     // } else {
  //     //     this.setState({
  //     //         descriptionErrorMsg: ''
  //     //     })
  //     // }
  //
  //
  //     if (recipeName === '' || recipeName === undefined) {
  //         validate = false;
  //         this.setState({
  //             recipeNameErrorMsg: 'Please enter recipe name.'
  //         })
  //     } else {
  //         this.setState({
  //             recipeNameErrorMsg: ''
  //         })
  //     }
  //
  //
  //     return validate
  // }
  // handleEditValidation = () => {
  //     console.log(this.state, "pppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppss")
  //     let validate = true;
  //     let {
  //         imageData, recName, descript
  //     } = this.state
  //     if (imageData) {
  //         if (imageData === '' || imageData === undefined) {
  //             validate = false;
  //             this.setState({
  //                 imageErrorMsg: 'Please select recipe image.'
  //             })
  //         } else if (imageData.size > 5000000) {
  //             validate = false;
  //             this.setState({
  //                 imageErrorMsg: 'Image size should be less than 5Mb.'
  //             })
  //         } else {
  //             this.setState({
  //                 imageErrorMsg: ''
  //             })
  //         }
  //     }
  //
  //     // if (descript === '' || descript === undefined) {
  //     //     validate = false;
  //     //     this.setState({
  //     //         descriptionErrorMsg: 'Please enter description.'
  //     //     })
  //     // } else {
  //     //     this.setState({
  //     //         descriptionErrorMsg: ''
  //     //     })
  //     // }
  //
  //
  //     if (recName === '' || recName === undefined) {
  //         validate = false;
  //         this.setState({
  //             recipeNameErrorMsg: 'Please enter recipe name.'
  //         })
  //     } else {
  //         this.setState({
  //             recipeNameErrorMsg: ''
  //         })
  //     }
  //
  //
  //     return validate
  // }

  // handleAddSubmit = (e) => {
  //     let { data,
  //         recipeName,
  //         description,
  //         image,
  //     } = this.state;
  //     this.setState({ errors: '', });
  //     e.preventDefault()
  //
  //     let self = this
  //     if (this.handleAddValidation()) {
  //
  //         this.setState({ redirect: false, open: false });
  //         let obj = new FormData()
  //
  //         obj.append('fileData', image);
  //         obj.append('recipesName', recipeName);
  //         obj.append('description', data);
  //         obj.append('status', 1);
  //
  //         self.setState({ isLoader: true })
  //
  //         let { dispatch } = this.props;
  //         _Api(Url.CREATE_RECIPE.method, Url.CREATE_RECIPE.url, obj)
  //             .then((resp) => {
  //                 if (resp.statusCode == 1) {
  //                     this.setState({ loader: false, image: '',data:'', imagePreview: '', recipeName: '', description: '' })
  //                     this.getRecipeDetails()
  //
  //                     notify('success', resp.responseData.message)
  //                 } else {
  //
  //                     this.setState({ isLoader: false, loader: false })
  //
  //                 }
  //             }).catch(err => {
  //
  //             this.setState({ isLoader: false, loader: false })
  //
  //             notify('error', err.data.error.responseMessage)
  //         })
  //
  //
  //     }
  // }
  // handleAddImageUpload = (event) => {
  //
  //
  //     console.log(event, "eventeventevent", event.target.files[0])
  //     let reader = new FileReader();
  //     let file = event.target.files[0];
  //     console.log(file, "fileeeeeeeeeeeeeeeeeeeeeeeeeee add")
  //     let { valiDate } = this.state;
  //
  //     reader.addEventListener("load", () => {
  //         this.setState({ imagePreview: reader.result }, function () {
  //         })
  //     }, false);
  //     if (file) {
  //         reader.readAsDataURL(file)
  //     }
  //
  //
  //     if (file) {
  //         if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/svg+xml') {
  //
  //             if (file.size > 5000000) {
  //                 console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")
  //
  //                 valiDate = false;
  //                 this.setState({ image: event.target.files[0], imageErrorMsg: 'File size should be less than 5Mb.' })
  //
  //             } else {
  //                 this.setState({
  //                     image: event.target.files[0],
  //                     imageErrorMsg: ''
  //
  //                 });
  //             }
  //
  //
  //         } else {
  //             valiDate = false;
  //             this.setState({ imageErrorMsg: 'File type not supported.' })
  //
  //         }
  //
  //     }
  //
  // }
  // handleEditImageUpload = (event) => {
  //
  //
  //     console.log(event, "eventeventevent", event.target.files[0])
  //     let reader = new FileReader();
  //     let file = event.target.files[0];
  //     console.log(file, "fileeeeeeeeeeeeeeeeeeeeeeeeeee add")
  //     let { valiDate } = this.state;
  //
  //     reader.addEventListener("load", () => {
  //         this.setState({ fileImagePreview: reader.result }, function () {
  //         })
  //     }, false);
  //     if (file) {
  //         reader.readAsDataURL(file)
  //     }
  //     if (file) {
  //         if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/svg+xml') {
  //
  //
  //             if (file.size > 5000000) {
  //                 valiDate = false;
  //                 this.setState({
  //                     imageData: event.target.files[0],
  //                     imageErrorMsg: 'File size should be less than 5Mb.'
  //
  //                 })
  //             } else {
  //                 this.setState({
  //                     imageData: event.target.files[0],
  //                     imageErrorMsg: ''
  //
  //                 });
  //             }
  //         } else {
  //             valiDate = false;
  //             this.setState({ imageErrorMsg: 'File type not supported.' })
  //
  //         }
  //
  //
  //     }
  //
  //
  // }

  // handleEditSubmit = (e, item) => {
  //
  //
  //     let {
  //         recipeId, descript, recName, imageData, data2,
  //     } = this.state;
  //     console.log(data2, "POPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP")
  //     this.setState({ errors: '' });
  //     e.preventDefault()
  //
  //     let self = this
  //     if (this.handleEditValidation()) {
  //         this.setState({ edit: false });
  //         console.log(recipeId, imageData, recName, descript, "consumerPrice,maximumConsumerQuantity,wholeselerUnit,wholeselerPrice,maximumWholeselerQuantity, description,status")
  //         let obj = new FormData()
  //
  //         obj.append('recipesId', recipeId);
  //         if (imageData) obj.append('fileData', imageData);
  //         if (recName) obj.append('recipesName', recName);
  //         if (data2) obj.append('description', data2);
  //         obj.append('status', 1)
  //
  //
  //         self.setState({ isLoader: true })
  //
  //         _Api(Url.UPDATE_RECIPE.method, Url.UPDATE_RECIPE.url, obj)
  //             .then((resp) => {
  //                 self.setState({ isLoader: false })
  //
  //                 if (resp.statusCode === 1) {
  //                     // notify('success', data.responseData.message)
  //                     this.getRecipeDetails()
  //                     this.setState({ isLoader: false })
  //                     notify('success', resp.responseData.message)
  //                     // this.props.history.goBack()
  //                     // this.closeModal()
  //                 } else {
  //
  //                 }
  //             }).catch(err => {
  //
  //             this.setState({ loader: false })
  //
  //             notify('error', err.data.error.responseMessage)
  //         })
  //
  //     }
  // }

  openViewPopup = (e, item) => {
    this.setState({
      viewRecipes: true,
      name: item.name,
      email: item.email,
      zip: item.zipCode,
      // fileImagePreview: item.image,
    })
  }
  // EditItem = (e, item) => {
  //
  //     console.log(item, "itemitemitemitemitemitemitemitemitem")
  //
  //
  //     this.setState({
  //         edit: true, recipeId: item._id,
  //         recName: item.recipesName,
  //         descript: item.description,
  //         data2: item.description,
  //         fileImagePreview: item.image,
  //
  //     })
  // }
  handlePageChange = (pageNo) => {
    var self = this
    this.setState({ loader: true, pageNo: pageNo, count: 10 }, () => {
      self.getNewsLetters()
    })
  }

  ConvertDate = (created) => {
    var created = new Date(created)
    let data = moment(created)
      .tz("America/New_York")
      .format("dddd MMMM Do YYYY, h:mm:ss a")
    return data
  }

  render() {
    let {
      name,
      email,
      zip,
      data2,
      data,
      totalNewsResult,
      totalAllNews,
      recName,
      descript,
      recipeName,
      imagePreview,
      descriptionErrorMsg,
      recipeNameErrorMsg,
      imageErrorMsg,
      description,
      image,
      open,
      newsList,
      fileImagePreview,
      viewRecipes,
      fileImageErrorMsg,
      edit,
      pageNo,
      count,
      search,
      totalCount,
      sortField,
      sortType,
      del,
      loading,
      status,
      isLoader,
      logout,
    } = this.state
    return (
      <>
        <Sidebar />
        <div className="body_container">
          <div className="body_inner_wrapper">
            {this.state.isLoader ? (
              <Loader />
            ) : (
              <>
                <header className="header_content">
                  {isLoader && <Loader />}
                  <p>News Letter Management</p>
                  <Button
                    onClick={this.getAllNewsExcel}
                    variant="info"
                    // download={excelFile?excelFile:''}
                  >
                    <i class="fas fa-upload"></i> Export as Excel
                  </Button>

                  <div className="header_search ">
                    <div className="form-group has-search">
                      <span className="fa fa-search form-control-feedback"></span>
                      <Form.Control
                        type="text"
                        placeholder="Search..."
                        maxLength={30}
                        name="search"
                        value={search}
                        onChange={this.handleSearch}
                      />
                    </div>
                  </div>

                  {/*<div className="d-flex text-nowrap header_btn">*/}
                  {/*    <Link onClick={this.openAddPopup} className="text-light"><Button*/}
                  {/*        variant="warning"><i class="fas fa-plus-circle"></i>*/}
                  {/*        Add Recipe*/}
                  {/*    </Button>*/}
                  {/*    </Link>*/}
                  {/*</div>*/}
                </header>
                <div className="main_content">
                  <div class="table-responsive">
                    <table class="table cm_bottom_space">
                      <thead>
                        <tr class="broder_top_0">
                          <th scope="col">
                            {" "}
                            Number
                            {/*<i class="fas fa-sort text_dark ml-2 d-inline-block"></i>*/}
                          </th>

                          <th
                            scope="col"
                            onClick={() => this.handleSort("name")}
                          >
                            Name
                            <i class="fas fa-sort text_dark ml-2 d-inline-block"></i>
                          </th>
                          <th scope="col">Email</th>
                          <th scope="col">Zip Code</th>
                          <th
                            scope="col"
                            onClick={() => this.handleSort("created")}
                          >
                            Date Posted{" "}
                            <i className="fas fa-sort text_dark ml-2 d-inline-block"></i>
                          </th>

                          {/*<th scope="col">Active/Inactive</th>*/}
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {console.log(newsList, "nbnkknbnknkn")}
                        {newsList &&
                          newsList.map((item, i) => (
                            <tr>
                              <td>{count * (pageNo - 1) + (i + 1)}</td>

                              <td>{item.name}</td>

                              <td>{item.email}</td>
                              <td>{item.zipCode}</td>

                              <td>{this.ConvertDate(item.created)}</td>

                              {/*<td>*/}
                              {/*    <div class="switch_wrapper">*/}
                              {/*        <input type="checkbox"*/}
                              {/*               checked={item.status === 1 ? true : false}*/}
                              {/*               onChange={(e) => this.handleAciveInactiveStatus(e, item._id)}*/}
                              {/*        />*/}
                              {/*        <span class="checkbox_swich"></span>*/}
                              {/*    </div>*/}
                              {/*</td>*/}
                              <td>
                                <div className="action_wrapper">
                                  <span>
                                    <Link>
                                      <i
                                        onClick={(e) =>
                                          this.openViewPopup(e, item)
                                        }
                                        class="fas fa-eye"
                                      ></i>
                                    </Link>
                                  </span>

                                  {/*<span>*/}
                                  {/*        /!*        <Link><i onClick={(e) => this.EditItem(e, item)}*!/*/}
                                  {/*        /!*                 class="fas fa-pen text-warning "></i></Link>*!/*/}
                                  {/*        /!*    </span>*!/*/}
                                  {/*        <span onClick={() => this.showModal(item._id)}><i*/}
                                  {/*            className="fas fa-trash-alt text-danger"></i></span>*/}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {newsList && newsList.length ? (
                      ""
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </div>
                  {
                    // totalRecipesResult ?
                    //
                    //     <div className="d-flex justify-content-end cm-pagination">
                    //         {/*proper*/}
                    //         <Pagination
                    //                         itemClassPrev={'prevClass'}
                    //                         itemClassNext={'nextClass'}
                    //                         activePage={this.state.pageNo}
                    //                         itemsCountPerPage={totalRecipesResult}
                    //                         totalItemsCount={totalAllRecipes}
                    //                         pageRangeDisplayed={3}
                    //                         onChange={this.handlePageChange}
                    //                     />
                    //                 </div>
                    //     :''}

                    <div className="d-flex justify-content-end cm-pagination">
                      <Pagination
                        itemClassPrev={"prevClass"}
                        itemClassNext={"nextClass"}
                        activePage={this.state.pageNo}
                        itemsCountPerPage={count}
                        totalItemsCount={totalAllNews}
                        pageRangeDisplayed={3}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  }
                </div>
              </>
            )}
          </div>
        </div>

        {/*<Modal show={del} onHide={this.handleClose} className="cm_order_view dlt_item">*/}
        {/*    <Modal.Header closeButton>*/}
        {/*    </Modal.Header>*/}
        {/*    <Modal.Body>*/}
        {/*        <img src={require('../../assets/img/Delete-Email.png')} />*/}
        {/*        <br />*/}
        {/*        <br />*/}
        {/*        <p>Are you sure you want to delete this recipe?</p>*/}
        {/*    </Modal.Body>*/}
        {/*    <Modal.Footer>*/}
        {/*        <div className="footer_btn_wrapper btn_nospace">*/}
        {/*            <Button variant="warning" loading={loading}*/}
        {/*                    onClick={() => this.handleDeleteStatus(this.state.selectedId)}>*/}
        {/*                Yes*/}
        {/*            </Button>*/}
        {/*            <Button variant="dark" onClick={this.handleClose}>*/}
        {/*                No*/}
        {/*            </Button>*/}
        {/*        </div>*/}
        {/*    </Modal.Footer>*/}
        {/*</Modal>*/}
        {/*<Modal show={open} onHide={this.handleClose} className="cm_order_view inventry recipe">*/}
        {/*    <form onSubmit={this.handleAddSubmit}>*/}
        {/*        <Modal.Header closeButton>*/}
        {/*            <Modal.Title>Add Recipes</Modal.Title>*/}
        {/*        </Modal.Header>*/}
        {/*        <Modal.Body>*/}
        {/*            <Row>*/}
        {/*                <Col md="4">*/}
        {/*                    <p>Recipe Image</p>*/}
        {/*                </Col>*/}
        {/*                <Col md="8">*/}

        {/*                    <div className="file_uploader"*/}
        {/*                         style={{ backgroundImage: `url(${imagePreview})` }}>*/}
        {/*                        {imagePreview ? <>*/}
        {/*                                <div className="overlay"></div>*/}
        {/*                                <i className="fas fa-pen text-light"></i> </> :*/}
        {/*                            <i className="fas fa-plus-circle"></i>}*/}

        {/*                        <Form.File label="Custom file input" lang="en" custom />*/}
        {/*                        <Form.File label="Custom file input" name="image"*/}
        {/*                                   lang="en"*/}
        {/*                                   accept="image/x-png,image/gif,image/jpeg,image/jpg"*/}
        {/*                                   onChange={this.handleAddImageUpload}*/}
        {/*                                   custom />*/}
        {/*                    </div>*/}
        {/*                    {imageErrorMsg ? <div className="error">{imageErrorMsg}</div> : null}*/}

        {/*                </Col>*/}
        {/*            </Row>*/}
        {/*            <Row>*/}
        {/*                <Col md="4">*/}
        {/*                    /!*<p>Select Item:</p>*!/*/}
        {/*                    <p> Recipe Name</p>*/}
        {/*                </Col>*/}
        {/*                <Col md="8">*/}
        {/*                    <Form.Control type="text" onChange={this.handleChanged}*/}
        {/*                                  name="recipeName" value={recipeName}*/}
        {/*                                  placeholder="Recipe Name" />*/}
        {/*                    {recipeNameErrorMsg ? <div className="error">{recipeNameErrorMsg}</div> : null}*/}

        {/*                </Col>*/}
        {/*            </Row>*/}

        {/*            <Row>*/}
        {/*                <Col md="4">*/}
        {/*                    <p> Description </p>*/}
        {/*                </Col>*/}
        {/*                <Col md="8">*/}
        {/*                    /!*<EditorClass/>*!/*/}

        {/*                    <CKEditor*/}
        {/*                        data={data}*/}
        {/*                        onChange={({ editor }) => this.setState({ data: editor.getData() })}*/}

        {/*                    />*/}

        {/*                    /!*<Form.Control as="textarea" rows="5"*!/*/}
        {/*                    /!*              onChange={this.handleChanged}*!/*/}
        {/*                    /!*              name="description" value={description}*!/*/}
        {/*                    /!*              placeholder="Description"/>*!/*/}
        {/*                    /!*{descriptionErrorMsg ? <div className="error">{descriptionErrorMsg}</div> : null}*!/*/}

        {/*                </Col>*/}
        {/*            </Row>*/}

        {/*        </Modal.Body>*/}
        {/*        <Modal.Footer>*/}
        {/*            <div className="footer_btn_wrapper">*/}
        {/*                <Button variant="warning" className="text-light"*/}
        {/*                        type="submit"*/}
        {/*                >*/}
        {/*                    Submit*/}
        {/*                </Button>*/}
        {/*                <Button variant="dark" onClick={this.handleClose}>*/}
        {/*                    Cancel*/}
        {/*                </Button>*/}
        {/*            </div>*/}
        {/*        </Modal.Footer>*/}
        {/*    </form>*/}
        {/*</Modal>*/}
        <Modal
          show={viewRecipes}
          onHide={this.handleClose}
          className="cm_order_view edit_item inventry recipe"
        >
          <Modal.Header closeButton>
            <Modal.Title> View Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/*<Row>*/}
            {/*    <Col md="4">*/}
            {/*        <p>Item</p>*/}
            {/*    </Col>*/}
            {/*    <Col md="8">*/}
            {/*        <div className="file_uploader"*/}
            {/*             style={{ backgroundImage: `url(${fileImagePreview})` }}>*/}

            {/*        </div>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Row>
              <Col md="4">
                <p> Name</p>
              </Col>
              <Col md="8">
                <Form.Control
                  type="text"
                  as="textarea"
                  rows="2"
                  onChange={this.handleChanged}
                  disabled
                  name="recName"
                  value={name}
                  placeholder="Recipe name"
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <p> Email</p>
              </Col>
              <Col md="8">
                <Form.Control
                  type="text"
                  as="textarea"
                  rows="2"
                  onChange={this.handleChanged}
                  disabled
                  name="recName"
                  value={email}
                  placeholder="Recipe name"
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <p> Zip Code</p>
              </Col>
              <Col md="8">
                <Form.Control
                  type="text"
                  as="textarea"
                  rows="2"
                  onChange={this.handleChanged}
                  disabled
                  name="recName"
                  value={zip}
                  placeholder="Recipe name"
                />
              </Col>
            </Row>
            {/*{description?<Row>*/}
            {/*    <Col md="4">*/}
            {/*        <p>Email:</p>*/}
            {/*    </Col>*/}
            {/*    <Col md="8">*/}
            {/*        <div className="form-control discriptionwrapper ">{description}</div>*/}
            {/*        /!*<Form.Control type="text" as="textarea" rows="5" onChange={this.handleChanged} disabled*!/*/}
            {/*        /!*              name="descript" value={descript}*!/*/}
            {/*        /!*              placeholder=" Description"/>*!/*/}

            {/*    </Col>*/}
            {/*</Row>:''}*/}
          </Modal.Body>

          <Modal.Footer>
            <div className="footer_btn_wrapper">
              <Button variant="dark" onClick={this.handleClose}>
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default NewsLetter

import { Button, Form, Modal } from "react-bootstrap"
import React, { Component } from "react"

import { Empty } from "antd"
import { Link } from "react-router-dom"
import Loader from "../../components/common/Loader"
import Pagination from "react-js-pagination"
import Sidebar from "../../components/sidebar"
import Url from "../../services/BaseUrl"
import _ from "lodash"
import { _Api } from "../../services/Api"
import { notify } from "../../components/common/Toaster"
import siteSetting from "../../config/env/Index"

var farmmarketmarkers = []

class FarmerManagement extends Component {
  googleMapRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      lat: null,
      long: null,
      type: 2,
      GOOGLE_MAP_API_KEY: null,
      googlemap: "",
      farmmarketicon: "",

      farmerDetails: "",
      search: "",
      totalCount: 0,
      sortType: "",
      sortField: "",
      pageNo: 1,
      count: 10,
      loading: false,
      visible: false,
      isLoader: false,
      status: 1,
      logout: false,
    }
  }

  componentDidMount() {
    this.setState(
      {
        GOOGLE_MAP_API_KEY: siteSetting.google_map_key,
        lat: siteSetting.lat,
        long: siteSetting.long,
      },
      function () {
        let { GOOGLE_MAP_API_KEY } = this.state
        const googleScript = document.createElement("script")
        googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`
        googleScript.async = true
        googleScript.onload = this.initGoogleMap
        window.document.body.appendChild(googleScript)
      }
    )

    this.getFarmerDetails()
  }

  initGoogleMap = () => {
    let self = this
    let { lat, long } = this.state

    const googlemap = new window.google.maps.Map(this.googleMapRef.current, {
      zoom: 9,
      center: {
        lat: lat,
        lng: long,
      },
      // disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: false,
      linksControl: false,
      // mapTypeId: 'terrain',
      styles: [
        {
          featureType: "administrative", // selects all administrative areas
          // stylers: [{"visibility": "off"}]
        },
        {
          featureType: "poi", // selects all points of interest
          // stylers: [{"visibility": "off"}]
        },
        {
          featureType: "landscape",
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "transit", // selects all transit stations and lines
          stylers: [{ visibility: "on" }],
        },
      ],
    })

    this.setState({ googlemap: googlemap }, function () {
      // get current lat lng
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState(
            { lat: position.coords.latitude, long: position.coords.longitude },
            function () {
              self.setGoogleMapLatLng()
            }
          )
        },
        (error) => {
          // set default lat lng if user deny access location
          this.setState({ lat: lat, long: long }, function () {
            self.setGoogleMapLatLng()
          })
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 1000,
        }
      )
    })
  }

  setGoogleMapLatLng = () => {
    let { googlemap, lat, long } = this.state
    googlemap.setCenter({ lat: lat, lng: long })
    this.getLocations()
  }

  showMarker(latlng, markericon) {
    let { googlemap } = this.state
    var marker = new window.google.maps.Marker({
      map: googlemap,
      position: latlng,
      draggable: false,
      anchorPoint: new window.google.maps.Point(10, 0),
      icon: markericon,
      zIndex: 1000,
    })
    return marker
  }

  get_address(address) {
    var setAddress = '<div class="address"> Address: '
    if (address.addressLine1) {
      setAddress += address.addressLine1
      if (address.city || address.postalCode || address.state) {
        setAddress += ", "
      }
    }
    if (address.city) {
      setAddress += address.city
      if (address.postalCode || address.state) {
        setAddress += ", "
      }
    }
    if (address.state) {
      setAddress += address.state
      if (address.postalCode) {
        setAddress += ", "
      }
    }
    if (address.postalCode) {
      setAddress += address.postalCode
    }
    setAddress += "</div>"
    return setAddress
  }

  get_info(name, about, address, call_address) {
    var infobox = '<div class="infoWindowContentWrapper">'
    if (name) {
      infobox += `<div class="name">${name}</div>`
    }
    if (about) {
      infobox += `<div class="about">${about}</div>`
    }
    if (address) {
      if (call_address == 1) {
        var setAddress = this.get_address(address)
      } else {
        var setAddress = address
      }
      infobox += setAddress
    }
    infobox += "</div>"
    return infobox
  }

  getLocations = () => {
    let self = this
    let { googlemap, lat, long, type } = this.state

    let farmmarketicon = {
      url: require("../../assets/img/mapimage/farm.png"),
      scaledSize: new window.google.maps.Size(20, 20), // scaled size
      origin: new window.google.maps.Point(0, 0), // origin
      anchor: new window.google.maps.Point(0, 0), // anchor
    }
    this.setState({ farmmarketicon: farmmarketicon })

    _Api(Url.USER_MAP_EXPLORE.method, Url.USER_MAP_EXPLORE.url).then((resp) => {
      var infowindow = new window.google.maps.InfoWindow({
        size: new window.google.maps.Size(130, 0),
      })

      if (resp && resp.statusCode === 1) {
        var farmar_market_location = resp.responseData.result.result

        farmar_market_location.map((farmMarket) => {
          if (
            farmMarket.geoLocation &&
            farmMarket.geoLocation &&
            farmMarket.geoLocation &&
            farmMarket.geoLocation
          ) {
            var latlng = new window.google.maps.LatLng(
              parseFloat(farmMarket.geoLocation[1]),
              parseFloat(farmMarket.geoLocation[0])
            )

            var marker = this.showMarker(latlng, farmmarketicon)
            farmmarketmarkers.push(marker)

            var address = null

            if (farmMarket.address) {
              address =
                !_.isEmpty(farmMarket.address) &&
                JSON.stringify(farmMarket.address[0].addressLine1)
            }
            let name = farmMarket.firstName
              ? `<span className="link"> <a href="/farmermanagement/editfrmermanagement/${
                  farmMarket._id
                }"> <strong className="text-dark">${
                  farmMarket.firstName + " " + farmMarket.lastName
                }</strong> </a></span>`
              : ""
            let farmName = farmMarket.farmName
              ? `<span className="link"> Farm Name:<a > ${farmMarket.farmName} </a></span>`
              : ""
            let addressName =
              farmMarket.address.length > 0
                ? `<span className="link"> Address:<a > ${
                    (farmMarket.address[0].addressLine1
                      ? farmMarket.address[0].addressLine1
                      : "") +
                    " " +
                    (farmMarket.address[0].addressLine2
                      ? farmMarket.address[0].addressLine2
                      : "") +
                    " " +
                    (farmMarket.address[0].city
                      ? farmMarket.address[0].city
                      : "") +
                    " " +
                    (farmMarket.address[0].state
                      ? farmMarket.address[0].state
                      : "") +
                    " " +
                    (farmMarket.address[0].country
                      ? farmMarket.address[0].country
                      : "") +
                    " " +
                    (farmMarket.address[0].postalCode
                      ? farmMarket.address[0].postalCode
                      : "")
                  } </a></span>`
                : ""

            var infobox = this.get_info(name, farmName, addressName)
            window.google.maps.event.addListener(
              marker,
              "mouseover",
              (function (marker) {
                return function () {
                  infowindow.setContent(infobox)
                  infowindow.open(googlemap, marker)
                }
              })(marker)
            )
          }
        })
      }
    })
  }

  handlePageChange = (pageNo) =>
    this.setState({ loader: false, pageNo }, () => this.getFarmerDetails())

  handleSearch = (e) => {
    this.setState({ search: e.target.value })
    if (e.target.value.length > 2) {
      this.setState({ search: e.target.value }, () => this.getFarmerDetails())
    }
    if (e.target.value === "") {
      this.setState({ search: e.target.value }, () => this.getFarmerDetails())
    }
  }
  handleSort = (sortField) =>
    this.setState(
      {
        loader: false,
        sortField,
        sortType: this.state.sortType === 1 ? -1 : 1,
      },
      () => this.getFarmerDetails()
    )
  handleUserStatus = (e) =>
    this.setState(
      {
        loader: false,
        userStatus: this.state.userStatus === 0 ? 1 : 0,
      },
      () => this.getFarmerDetails()
    )

  getFarmerDetails = () => {
    let { search, count, pageNo, totalCount, sortField, sortType } = this.state
    let req = {
      search,
      sortType,
      sortField,
      pageNo,
      count,
    }

    this.setState({ isLoader: true })
    _Api(
      Url.SUPER_ADMIN_GET_USER.method,
      Url.SUPER_ADMIN_GET_USER.url,
      "",
      `?search=${req.search}&count=${req.count}&pageNo=${req.pageNo}&sortType=${req.sortType}&sortField=${req.sortField}`
    ).then((resp) => {
      this.setState({
        farmerDetails: resp.responseData.farmersInfo.driverInfo,
        totalCount: resp.responseData.farmersInfo.dataCount,
        isLoader: false,
      })
    })
  }

  handleOnChangeStatus = (id, status) => {
    let req = {
      userId: id,
      status,
    }
    _Api(
      Url.SUPER_ADMIN_CHANGE_STATUS.method,
      Url.SUPER_ADMIN_CHANGE_STATUS.url,
      req
    ).then((resp) => {
      notify("success", resp.responseData.message)
      this.setState({ visible: false })
      this.getFarmerDetails()
    })
  }

  //Show modal
  showModal = (id) => {
    this.setState({
      visible: true,
      selectedId: id,
    })
  }
  //Cancel modal
  handleCancel = () => {
    this.setState({ visible: false })
  }
  //admin active and inactive user
  handleAciveInactiveStatus = (e, id) => {
    let req = {
      userId: id,
      status: e.target.checked ? 0 : 1,
    }
    _Api(
      Url.SUPER_ADMIN_CHANGE_STATUS.method,
      Url.SUPER_ADMIN_CHANGE_STATUS.url,
      req
    )
      .then((resp) => {
        this.setState({ visible: false })

        if (resp.statusCode === 1) {
          notify("success", resp.responseData.message)

          this.getFarmerDetails()
        } else {
          this.setState({ loader: false })

          notify("error", resp.error.responseMessage)
        }
      })
      .catch((err) => {
        this.setState({ loader: false })

        notify("error", err.data.error.responseMessage)
      })
  }

  handleVerify = (e, id) => {
    let req = {
      userId: id,
      status: e.target.checked ? 3 : 4,
    }
    _Api(
      Url.SUPER_ADMIN_CHANGE_STATUS.method,
      Url.SUPER_ADMIN_CHANGE_STATUS.url,
      req
    )
      .then((resp) => {
        this.setState({ loader: false })
        this.setState({ visible: false })

        if (resp.statusCode === 1) {
          notify("success", resp.responseData.message)
          this.getFarmerDetails()
        }
      })
      .catch((err) => {
        this.setState({ loader: false })
        notify("error", err.data.error.responseMessage)
      })
  }

  render() {
    let {
      farmerDetails,
      pageNo,
      count,
      search,
      totalCount,
      sortField,
      sortType,
      visible,
      loading,
      status,
      isLoader,
    } = this.state
    return (
      <>
        <Sidebar />
        <div className="body_container">
          <div className="body_inner_wrapper">
            {/* {isLoader && <Loader />} */}
            <header className="header_content">
              {/*{isLoader && <Loader/>}*/}
              <p>Farmer Management</p>

              <div className="header_search ">
                <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback"></span>
                  <Form.Control
                    type="text"
                    placeholder="Search..."
                    maxLength={30}
                    name="search"
                    value={search}
                    onChange={this.handleSearch}
                  />
                </div>
              </div>
              <div className="d-flex text-nowrap header_btn">
                <Link to="/farmermanagement/addfarmer" className="text-light">
                  <Button variant="warning">
                    <i class="fas fa-plus-circle"></i>
                    Add Farmer
                  </Button>
                </Link>
              </div>
            </header>

            <div className="main_content">
              <div className="map w-100">
                <div
                  id="GoogleMap"
                  ref={this.googleMapRef}
                  style={{ width: "100%", height: "500px" }}
                ></div>
              </div>

              <div class="table-responsive">
                <table class="table cm_bottom_space">
                  <thead>
                    <tr class="broder_top_0">
                      <th scope="col">S.No.</th>
                      <th
                        scope="col"
                        onClick={() => this.handleSort("firstName")}
                      >
                        Farmer Name
                        <i class="fas fa-sort text_dark ml-2 d-inline-block"></i>
                      </th>
                      {/*<th scope="col">Email</th>*/}
                      <th scope="col">Farm Name</th>
                      {/*<th scope="col">Total Order</th>*/}
                      <th scope="col">Commission(%)</th>
                      <th scope="col">Account Id</th>
                      <th scope="col">Verify</th>
                      <th scope="col">Inactive/Active</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {farmerDetails &&
                      farmerDetails.map((item, i) => (
                        <tr>
                          {/*<td>{i + 1}</td>*/}
                          <td>{count * (pageNo - 1) + (i + 1)}</td>

                          <td>
                            {item.firstName} {item.lastName}
                          </td>
                          {/*<td>{item.email}</td>*/}
                          <td>
                            {item.farmName == null ? "N/A" : item.farmName}
                          </td>
                          {/*<td>---</td>*/}
                          <td>
                            {item.commission == null ? "N/A" : item.commission}
                          </td>
                          <td>{item.accountId ? item.accountId : "N/A"}</td>
                          <td>
                            <div class="switch_wrapper">
                              <input
                                type="checkbox"
                                checked={item.isVarified === 1 ? false : true}
                                onChange={(e) => this.handleVerify(e, item._id)}
                              />
                              <span class="checkbox_swich"></span>
                            </div>
                          </td>
                          <td>
                            <div class="switch_wrapper">
                              <input
                                type="checkbox"
                                checked={item.status === 1 ? false : true}
                                onChange={(e) =>
                                  this.handleAciveInactiveStatus(e, item._id)
                                }
                              />
                              <span class="checkbox_swich"></span>
                            </div>
                          </td>
                          <td>
                            <div className="action_wrapper">
                              <span>
                                <Link
                                  to={`/farmermanagement/farmerdetails/${item._id}`}
                                >
                                  <i class="fas fa-eye"></i>
                                </Link>
                              </span>
                              <span>
                                <Link
                                  to={`/farmermanagement/editfrmermanagement/${item._id}`}
                                >
                                  <i class="fas fa-pen text-warning "></i>
                                </Link>
                              </span>
                              <span onClick={() => this.showModal(item._id)}>
                                <i className="fas fa-trash-alt text-danger"></i>
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {farmerDetails && farmerDetails.length ? (
                  ""
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </div>
              {farmerDetails && farmerDetails.length ? (
                <div className="d-flex justify-content-end cm-pagination">
                  <Pagination
                    itemClassPrev={"prevClass"}
                    itemClassNext={"nextClass"}
                    activePage={this.state.pageNo}
                    itemsCountPerPage={this.state.count}
                    totalItemsCount={this.state.totalCount}
                    pageRangeDisplayed={3}
                    onChange={this.handlePageChange}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Modal
          show={visible}
          onHide={this.handleCancel}
          className="cm_order_view dlt_item"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img src={require("../../assets/img/Delete-Email.png")} />
            <br />
            <br />
            <p>Are you sure you want to delete this farmer?</p>
          </Modal.Body>
          <Modal.Footer>
            <div className="footer_btn_wrapper btn_nospace">
              <Button
                variant="warning"
                loading={loading}
                onClick={() =>
                  this.handleOnChangeStatus(this.state.selectedId, 2)
                }
              >
                Yes
              </Button>
              <Button variant="dark" onClick={this.handleCancel}>
                No
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default FarmerManagement

import { Button, Col, Form, Nav, Row, Tab } from "react-bootstrap"
import React, { Component } from "react"

import Loader from "../../components/common/Loader"
import ReactPhoneInput from "react-phone-input-2"
import Sidebar from "../../components/sidebar"
import Url from "../../services/BaseUrl"
import { _Api } from "../../services/Api"
import { connect } from "react-redux"
import { notify } from "../../components/common/Toaster"

class Settings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      phone: "",
      changePic: false,

      name: "",
      emailErrorMsg: "",
      errors: "",
      success: "",
      userInfo: [],
      isEdit: false,
      profilePicPreview: "",
      profilePic: "",
      profilePicErrorMsg: "",
      userId: localStorage.getItem("userId"),
      address: [],
      firstNameErrorMsg: "",
      phoneErrorMsg: "",
      loader: false,
      isChangePass: false,
      oldPassword: "",
      oldPasswordErrorMsg: "",
      newPassword: "",
      newPasswordErrorMsg: "",
      confirmPassword: "",
      confirmPasswordErrorMsg: "",
      activeType: "Profile",
      profileImage: "",
      pic: "",
    }
  }

  componentDidMount() {
    this.getUserInfo()
  }

  getUserInfo = () => {
    this.setState({ errors: "" })

    let self = this
    this.setState({ redirect: false, loader: true })

    _Api(
      Url.SUPER_ADMIN_VIEW_PROFILE.method,
      Url.SUPER_ADMIN_VIEW_PROFILE.url,
      ""
    ).then((data) => {
      this.setState({ loader: false })
      if (data.statusCode == 1) {
        let userInfo = data.responseData.adminProfile
        this.setState({
          userInfo: userInfo,

          name: userInfo.name,
          phone: userInfo.phone,
          email: userInfo.email,
          address: userInfo.address,
          profilePic: userInfo.profileImage,
          pic: userInfo.profileImage,
          profilePicPreview: userInfo.profileImage,
        })
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }

  handleProfileValidation = () => {
    console.log(
      this.state,
      "pppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppss"
    )
    let validate = true
    let {
      email,
      profilePic,
      addressLine1,
      city,
      postalCode,
      state,
      name,
      phone,
      password,
      userType,
    } = this.state

    if (profilePic) {
      if (profilePic.size > 5000000) {
        validate = false
        this.setState({
          profilePicErrorMsg: "Profile pic size should be less than 5Mb.",
        })
      } else {
        this.setState({
          profilePicErrorMsg: "",
        })
      }
    }
    if (name === "" || name === undefined) {
      validate = false
      console.log(this.state, "2")

      this.setState({
        firstNameErrorMsg: "Please enter first name.",
      })
    } else {
      this.setState({
        firstNameErrorMsg: "",
      })
    }
    console.log(phone, "pppppppppppppppccccccccccccccccccccccccccccccc")
    if (phone) {
      if (phone === "" || phone === undefined) {
        validate = false
        console.log(this.state, "3")

        this.setState({
          phoneErrorMsg: "Please enter phone number.",
        })
      } else if (phone.length > 3 && phone.length < 10) {
        validate = false
        this.setState({ phoneErrorMsg: "Please enter a valid mobile number." })
      } else {
        this.setState({
          phoneErrorMsg: "",
        })
      }
    }

    return validate
  }

  handleChanged = (e) => {
    console.log("onchange names", e)
    this.setState({
      errors: "",
      oldPasswordErrorMsg: "",
      newPasswordErrorMsg: "",
      confirmPasswordErrorMsg: "",

      [e.target.name]: e.target.value,
    })
  }
  editMode = () => {
    this.setState({ isEdit: true })
  }

  viewMode = () => {
    this.setState({ isEdit: false, isChangePass: false })
  }

  handleEditSubmit = (e) => {
    console.log(this.props, "dsffffffffffffffff3432sd")
    let {
      radius,
      profilePic,
      messagePopup,
      alertPopup,
      emailPopup,
      email,
      name,
      lastName,
      address,
      phone,
      minimumOrderValue,
      addressId,
      userId,
    } = this.state
    this.setState({ errors: "" })
    e.preventDefault()

    let self = this
    console.log(phone, "phonephonephonephonephone")
    if (this.handleProfileValidation()) {
      let obj = new FormData()
      obj.append("profileImage", profilePic)
      obj.append("name", name)
      obj.append("phone", phone)
      self.setState({ loader: true })

      _Api(
        Url.SUPER_ADMIN_EDIT_PROFILE.method,
        Url.SUPER_ADMIN_EDIT_PROFILE.url,
        obj
      )
        .then((data) => {
          if (data.statusCode == 1) {
            notify("success", data.responseData.responseMessage)
            console.log(data, "ggggggggggg")
            self.setState({
              loader: false,
              isEdit: false,
              changePic: true,
              profileImage: data.responseData.userProfile.profileImage,
            })
            self.getUserInfo()
          } else {
            self.setState({ loader: false })
            self.setState({ errors: data.data.error.responseMessage })
          }
        })
        .catch((err) => {
          console.log(err, "dsffffffffffffffff")
          self.setState({ loader: false })
          this.setState({ isloader: false })
          notify("error", err.data.error.responseMessage)
        })
    }
  }

  handlePicUpload = (event) => {
    console.log(event, "eventeventevent", event.target.files[0])
    let reader = new FileReader()
    let file = event.target.files[0]

    let { valiDate } = this.state

    reader.addEventListener(
      "load",
      () => {
        this.setState({ profilePicPreview: reader.result }, function () {})
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/gif" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml"
      ) {
        if (file.size > 5000000) {
          console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")

          valiDate = false
          this.setState({
            profilePic: event.target.files[0],
            profilePicErrorMsg: "Profile pic size should be less than 5Mb.",
          })
        } else {
          this.setState({
            profilePic: event.target.files[0],
            profilePicErrorMsg: "",
          })
        }
      } else {
        valiDate = false
        this.setState({ profilePicErrorMsg: "File type not supported." })
      }
    }
  }
  changePass = () => {
    console.log("Ssssssssssss")
    this.setState({
      isChangePass: true,
      newPassword: "",
      confirmPassword: "",
      success: "",
      oldPassword: "",
    })
  }
  handleValidation = () => {
    let validate = true
    let { oldPassword, newPassword, confirmPassword } = this.state
    if (oldPassword === "" || oldPassword === undefined) {
      validate = false
      this.setState({
        oldPasswordErrorMsg: "Old password is required.",
      })
    } else if (oldPassword.length < 5) {
      validate = false
      this.setState({
        oldPasswordErrorMsg: "Old password must contain at least 6 characters.",
      })
    } else {
      this.setState({
        oldPasswordErrorMsg: "",
      })
    }

    if (newPassword === "" || newPassword === undefined) {
      validate = false
      this.setState({
        newPasswordErrorMsg: "New password is required.",
      })
    } else if (newPassword.length < 5) {
      validate = false
      this.setState({
        newPasswordErrorMsg: "New password must contain at least 6 characters.",
      })
    } else {
      this.setState({
        newPasswordErrorMsg: "",
      })
    }

    if (confirmPassword === "" || confirmPassword === undefined) {
      validate = false
      this.setState({
        confirmPasswordErrorMsg: "Confirm password is required.",
      })
    } else if (confirmPassword !== newPassword) {
      validate = false
      this.setState({
        confirmPasswordErrorMsg:
          "New password and Confirm password does not match.",
      })
    } else if (oldPassword === newPassword) {
      validate = false
      this.setState({
        confirmPasswordErrorMsg:
          "Old Password & New Password should not be same!",
      })
    } else {
      this.setState({
        confirmPasswordErrorMsg: "",
      })
    }

    return validate
  }
  // changePassword() {

  changePassword = (event) => {
    event.preventDefault()
    let self = this

    if (this.handleValidation()) {
      let { newPassword, oldPassword } = self.state

      console.log(
        self.state.newPassword,
        self.state.confirmPassword,
        "self.state.confirmPassword"
      )
      let params = {
        newPassword: newPassword,
        oldPassword: oldPassword,
      }
      this.setState({ loader: true, errors: "", success: "" })

      _Api(Url.CHANGE_PASSWORD.method, Url.CHANGE_PASSWORD.url, params)
        .then((data) => {
          console.log("LLLLLLLLLLLLLLLLLLLLLLL")
          this.setState({ loader: false })
          if (data.statusCode == 1) {
            notify("success", data.responseData.message)

            self.setState({
              isChangePass: false,
              newPassword: "",
              oldPassword: "",
              confirmPassword: "",
            })
          } else {
            self.setState({ errors: data.error.responseMessage })
            notify("error", data.error.responseMessage)
          }
        })
        .catch((err) => {
          self.setState({ loader: false })
          this.setState({ loader: false })
          notify("error", err.data.error.responseMessage)
        })
    }
  }
  clickedView = (e) => {
    if (e == "Profile") {
      this.setState({ activeType: "Profile" }, () => this.getUserInfo())
    } else {
      this.setState({ activeType: "changepassword" }, () => this.getUserInfo())
    }

    // console.log("changepasswordchangepasswordchangepassword",item)
    // changepassword
  }
  viewProfile = () => {
    this.setState({ activeType: "Profile" }, () => this.getUserInfo())
  }
  refreshEditProfile = () => {
    this.setState({ activeType: "Profile" }, () => this.getUserInfo())
  }

  render() {
    let {
      changePic,
      pic,
      activeType,
      oldPassword,
      oldPasswordErrorMsg,
      newPassword,
      newPasswordErrorMsg,
      confirmPassword,
      confirmPasswordErrorMsg,
      radius,
      isChangePass,
      email,
      name,
      lastName,
      password,
      phone,
      passwordErrorMsg,
      errors,
      success,
      userInfo,
      addressLine1,
      addressLine2,
      state,
      minimumOrderValue,
      postalCode,
      city,
      isEdit,
      insurancePreview,
      insuranceDocErrorMsg,
      insuranceDoc,
      certificatePreview,
      certificate,
      certificateErrorMsg,
      profilePicPreview,
      profilePic,

      profilePicErrorMsg,
      emailErrorMsg,
      firstNameErrorMsg,
      phoneErrorMsg,
      addressLine1ErrorMsg,
      cityErrorMsg,
      stateErrorMsg,
      postalCodeErrorMsg,
      loader,
      messagePopup,
      alertPopup,
      emailPopup,
      profileImage,
    } = this.state
    console.log(
      "njbkhvjgchxgzfdxgfchgvjhbjknlklm;::::::::::::::::::::::1",
      profilePic
    )
    return (
      <>
        <Sidebar profilePicPreview={profileImage ? profileImage : ""} />
        {/*<EditProfile onRefreshEditProfile={this.refreshEditProfile} userId={profileInfo._id}   />*/}

        <div>
          <div className="header_wrapper">
            <div className="header_logo">
              <img src={require("../../assets/img/OFFICIAL LOGO.png")} />
            </div>
            <div className="header_search">
              {/* <div className="form-group has-search">
                                <span className="fa fa-search form-control-feedback"></span>
                                <Form.Control type="search" placeholder="search"/>
                            </div> */}
            </div>
            <div className="bell_icon">
              <i className="fas fa-bell"></i>
            </div>
          </div>

          <div className="body_container">
            <div className="body_inner_wrapper">
              {this.state.loader ? (
                <Loader />
              ) : (
                <>
                  <header className="header_content">
                    <p>Settings</p>
                  </header>
                  <div className="main_content">
                    <div className="farm_page setting_wrapper">
                      <Tab.Container
                        defaultActiveKey="Profile"
                        onSelect={(e) => this.clickedView(e)}
                        activeKey={activeType}
                      >
                        <Row>
                          <Col sm={3}>
                            <Nav variant="pills" className="flex-column">
                              <Nav.Item>
                                <Nav.Link eventKey="Profile">Profile</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="changepassword">
                                  <Button
                                    onClick={this.changePass}
                                    variant="warning"
                                    className="changepassword"
                                  >
                                    Change Password
                                  </Button>
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col sm={9}>
                            <Tab.Content>
                              {isEdit ? (
                                <Tab.Pane eventKey="Profile">
                                  <form onSubmit={this.handleEditSubmit}>
                                    <Row>
                                      <Col md="8">
                                        <div className="u_img mb-5">
                                          {profilePicPreview ? (
                                            <img
                                              className="rounded-circle1"
                                              src={profilePicPreview}
                                              className="logoroundimg"
                                            />
                                          ) : (
                                            <span className="profile_icon_Admin ">
                                              <img
                                                src={require("../../assets/img/adminlogo.png")}
                                                className="logoroundimg"
                                              />
                                            </span>
                                          )}
                                          <span className="img_icon">
                                            <span className="file_input">
                                              <input
                                                type="file"
                                                className="cm_hide_input"
                                                accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                                onChange={this.handlePicUpload}
                                              />
                                            </span>
                                            <img
                                              src={require("../../assets/img/imgicon.png")}
                                            />
                                          </span>
                                        </div>
                                        {profilePicErrorMsg ? (
                                          <div className="error">
                                            {profilePicErrorMsg}
                                          </div>
                                        ) : null}
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md="2">
                                        <p>First Name:</p>

                                        {console.log(userInfo ? userInfo : "")}
                                      </Col>
                                      <Col md="6">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleChanged}
                                          name="name"
                                          value={name}
                                          placeholder="First Name "
                                        />
                                        {firstNameErrorMsg ? (
                                          <div className="error">
                                            {firstNameErrorMsg}
                                          </div>
                                        ) : null}
                                        {/*<Form.Control type="text" placeholder="Star Light"/>*/}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="2">
                                        <p>Email:</p>
                                      </Col>
                                      <Col md="6">
                                        <Form.Control
                                          type="email"
                                          onChange={this.handleChanged}
                                          name="email"
                                          disabled
                                          value={email}
                                          placeholder="Email Address"
                                        />
                                        {emailErrorMsg ? (
                                          <div className="error">
                                            {emailErrorMsg}
                                          </div>
                                        ) : null}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="2">
                                        <p>Phone:</p>
                                      </Col>
                                      <Col md="6">
                                        <div className="cm_phnnumber">
                                          <ReactPhoneInput
                                            value={phone}
                                            placeholder="Please enter phone number"
                                            onChange={(phone) =>
                                              this.setState({ phone: phone })
                                            }
                                          />
                                        </div>
                                        {phoneErrorMsg ? (
                                          <div className="error">
                                            {phoneErrorMsg}
                                          </div>
                                        ) : null}
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md="8" className="text-right">
                                        <Button
                                          type="submit"
                                          variant="warning"
                                          className="mr-2"
                                        >
                                          Save
                                        </Button>
                                        &nbsp;
                                        <Button
                                          onClick={this.viewMode}
                                          variant="dark"
                                        >
                                          Cancel
                                        </Button>
                                      </Col>
                                    </Row>
                                  </form>
                                </Tab.Pane>
                              ) : (
                                <Tab.Pane eventKey="Profile">
                                  <Row>
                                    <Col md="8">
                                      <div className="u_img mb-5">
                                        {profilePic ? (
                                          <img
                                            className="rounded-circle1 text-center logoroundimg"
                                            src={pic}
                                          />
                                        ) : (
                                          <img
                                            src={require("../../assets/img/adminlogo.png")}
                                          />
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="2">
                                      <p>First Name:</p>

                                      {console.log(userInfo ? userInfo : "")}
                                    </Col>
                                    <Col md="6">
                                      <Form.Control
                                        type="text"
                                        disabled
                                        name="name"
                                        value={name}
                                        placeholder="First Name "
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="2">
                                      <p>Email:</p>
                                    </Col>
                                    <Col md="6">
                                      <Form.Control
                                        type="email"
                                        onChange={this.handleChanged}
                                        name="email"
                                        disabled
                                        value={email}
                                        placeholder="Email Address"
                                      />
                                    </Col>
                                  </Row>
                                  {console.log("phhhhhh", phone.length)}
                                  {phone.length > 3 ? (
                                    <Row>
                                      <Col md="2">
                                        <p>Phone:</p>
                                      </Col>
                                      <Col md="6">
                                        <div className="cm_phnnumber">
                                          <ReactPhoneInput
                                            value={phone}
                                            disabled
                                            placeholder="Please enter phone number"
                                            onChange={(phone) =>
                                              this.setState({ phone: phone })
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  ) : (
                                    ""
                                  )}

                                  <Row>
                                    <Col className="text-right " md="2"></Col>

                                    <Col className="text-right" md="6">
                                      <Button
                                        onClick={this.editMode}
                                        variant="warning"
                                      >
                                        Edit
                                      </Button>
                                    </Col>
                                  </Row>
                                </Tab.Pane>
                              )}
                              <Tab.Pane eventKey="changepassword">
                                <div className="error">
                                  {errors ? errors : ""}
                                </div>
                                <div className="success">
                                  {success ? success : ""}
                                </div>
                                <form onSubmit={this.changePassword}>
                                  <div className="u_img mb-5"></div>

                                  <Row>
                                    <Col md="2">
                                      <p>Old Password:</p>
                                    </Col>
                                    <Col md="6">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleChanged}
                                        name="oldPassword"
                                        value={oldPassword}
                                        maxLength="30"
                                        placeholder="Old Password "
                                      />
                                      {oldPasswordErrorMsg ? (
                                        <div className="error">
                                          {oldPasswordErrorMsg}
                                        </div>
                                      ) : null}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="2">
                                      <p>New Password:</p>
                                    </Col>
                                    <Col md="6">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleChanged}
                                        name="newPassword"
                                        value={newPassword}
                                        maxLength="30"
                                        placeholder="New Password  "
                                      />
                                      {newPasswordErrorMsg ? (
                                        <div className="error">
                                          {newPasswordErrorMsg}
                                        </div>
                                      ) : null}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="2">
                                      <p>Confirm Password:</p>
                                    </Col>
                                    <Col md="6">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleChanged}
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        maxLength="30"
                                        placeholder="Confirm Password "
                                      />
                                      {confirmPasswordErrorMsg ? (
                                        <div className="error">
                                          {confirmPasswordErrorMsg}
                                        </div>
                                      ) : null}
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md="8" className="text-right">
                                      <Button
                                        type="submit"
                                        variant="warning"
                                        className="mr-2"
                                      >
                                        Save
                                      </Button>
                                      &nbsp;
                                      <Button
                                        onClick={this.viewProfile}
                                        variant="dark"
                                      >
                                        Cancel
                                      </Button>
                                      {/* <Button onClick={this.viewMode}
                                                                                variant="dark">Cancel</Button> */}
                                    </Col>
                                  </Row>
                                </form>
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { props, login, user, mapReducer } = state
  console.log("kljhkgjfhcgdxfgfchgvjhb", mapReducer)
  return {
    props,
    login,
    user,
    mapReducer,
  }
}

export default connect(mapStateToProps)(Settings)
// export default Settings

import "bootstrap/dist/css/bootstrap.min.css"
import "antd/dist/antd.css"
import "./index.css"
import "./scss/index.scss"
import "react-toastify/dist/ReactToastify.css"

import * as serviceWorker from "./serviceWorker"

import { Provider } from "react-redux"
import React from "react"
import ReactDOM from "react-dom"
import Routes from "./Routes"
// notification
import { ToastContainer } from "react-toastify"
import configureStore from "./redux/configureStore"

const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Routes />
      <ToastContainer />
    </React.StrictMode>
  </Provider>,

  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import { Button, Col, Form, Modal, Nav, Row } from "react-bootstrap"
import { Empty, message } from "antd"
import { Link, NavLink } from "react-router-dom"
import React, { Component } from "react"

import Loader from "../../components/common/Loader"
import Pagination from "react-js-pagination"
import Sidebar from "../../components/sidebar"
import Url from "../../services/BaseUrl"
import _ from "lodash"
import { _Api } from "../../services/Api"
import { notify } from "../../components/common/Toaster"

class popupmanagement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      farmerDetails: "",
      search: "",
      totalCount: 0,
      sortType: "",
      sortField: "",
      pageNo: 1,
      count: 10,
      loading: false,
      visible: false,
      isLoader: false,
      status: 1,
    }
  }

  componentDidMount() {
    this.getMarketDetails()
  }

  handlePageChange = (pageNo) =>
    this.setState({ loader: true, pageNo }, () => this.getMarketDetails())

  handleSearch = (e) => {
    this.setState({ search: e.target.value })

    if (e.target.value.length > 2) {
      this.setState({ search: e.target.value }, () => this.getMarketDetails())
    }
    if (e.target.value === "") {
      this.setState({ search: e.target.value }, () => this.getMarketDetails())
    }
  }
  handleSort = (sortField) =>
    this.setState(
      { loader: true, sortField, sortType: this.state.sortType === 1 ? -1 : 1 },
      () => this.getMarketDetails()
    )
  handleUserStatus = (e) =>
    this.setState(
      { loader: true, userStatus: this.state.userStatus === 1 ? 0 : 1 },
      () => this.getMarketDetails()
    )

  getMarketDetails = () => {
    let { search, count, pageNo, totalCount, sortField, sortType } = this.state
    let req = {
      search,
      sortType,
      sortField,
      pageNo,
      count,
    }

    this.setState({ isLoader: true })
    _Api(
      Url.SUPER_ADMIN_GET_MARKET.method,
      Url.SUPER_ADMIN_GET_MARKET.url,
      "",
      `?search=${req.search}&count=${req.count}&pageNo=${req.pageNo}&sortType=${req.sortType}&sortField=${req.sortField}`
    ).then((resp) => {
      if (resp.statusCode === 1) {
        this.setState({
          marketDetails: resp.responseData.marketInfo,
          totalCount: resp.responseData.totalCount,
          isLoader: false,
        })
      } else {
        this.setState({ isLoader: false })
      }
    })
  }

  // Status of user(0=active , 1=inactive , 2=delete, 3=verifyUser, 4=unVerifyUser)
  handleOnChangeStatus = (id, status) => {
    console.log(typeof status, "typeof status")
    let req = {
      marketId: id,
      status,
    }
    this.setState({ isLoader: true })

    _Api(
      Url.SUPER_ADMIN_CHANGE_MARKET_STATUS.method,
      Url.SUPER_ADMIN_CHANGE_MARKET_STATUS.url,
      req
    ).then((resp) => {
      if (resp.statusCode === 1) {
        notify("success", resp.responseData.message)
        this.setState({ visible: false, isLoader: false })
        this.getMarketDetails()
      } else {
        this.setState({ visible: false, isLoader: false })
      }
    })
  }

  //Show modal
  showModal = (id) => {
    this.setState({
      visible: true,
      selectedId: id,
    })
  }
  handleCancel = () => {
    this.setState({ visible: false })
  }

  render() {
    let {
      marketDetails,
      visible,
      loading,
      pageNo,
      count,
      search,
      totalCount,
      isLoader,
    } = this.state
    return (
      <>
        <Sidebar />

        <div className="body_container">
          <div className="body_inner_wrapper">
            {this.state.isLoader ? (
              <Loader />
            ) : (
              <>
                {" "}
                <header className="header_content">
                  {/*{isLoader && <Loader />}*/}
                  <p> Market / Pop-Up Management</p>
                  {/*{isLoader && <Loader />}*/}
                  <div className="header_search">
                    <div className="form-group has-search">
                      <span className="fa fa-search form-control-feedback"></span>
                      <Form.Control
                        type="text"
                        placeholder="Search..."
                        maxLength={30}
                        name="search"
                        value={search}
                        onChange={this.handleSearch}
                      />
                    </div>
                  </div>
                  <div className="d-flex text-nowrap header_btn">
                    <Link
                      to="/popupmanagement/addmarket"
                      className="text-light"
                    >
                      <Button variant="warning">
                        <i class="fas fa-plus-circle"></i>
                        Add Market / Pop-Up
                      </Button>
                    </Link>
                  </div>
                </header>
                <div className="main_content">
                  <div class="table-responsive">
                    <table class="table cm_bottom_space">
                      <thead>
                        <tr class="broder_top_0">
                          <th scope="col" className="ws-nowrap">
                            S.No.{" "}
                          </th>
                          <th
                            scope="col"
                            className="ws-nowrap"
                            onClick={() => this.handleSort("marketName")}
                          >
                            {" "}
                            Market / Pop-Up
                            <i class="fas fa-sort text_dark ml-2 d-inline-block"></i>{" "}
                          </th>
                          <th scope="col"> Location</th>
                          {/*<th scope="col">	Farms</th>*/}
                          <th scope="col"> Popup market/Farmer market</th>
                          <th scope="col">Description</th>
                          <th scope="col">Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {marketDetails &&
                          marketDetails.map((item, index) => (
                            <tr>
                              <td>{count * (pageNo - 1) + (index + 1)}</td>

                              <td>{item.marketName}</td>

                              <td>
                                {item.address.addressLine1 +
                                  " " +
                                  item.address.addressLine2 +
                                  " " +
                                  item.address.city +
                                  " " +
                                  item.address.state +
                                  " " +
                                  item.address.postalCode}
                              </td>
                              <td>
                                {item.category === 1
                                  ? "Popup market"
                                  : item.category === 2
                                  ? "Farmer market"
                                  : ""}
                              </td>
                              <td>{item.desc}</td>
                              <td className="ws-nowrap">
                                <div className="action_wrapper">
                                  <Link
                                    to={`/popupmanagement/editmarket/${item._id}`}
                                  >
                                    <span>
                                      <i class="fas fa-pen text-warning "></i>
                                    </span>
                                  </Link>

                                  <span
                                    onClick={() => this.showModal(item._id)}
                                  >
                                    <i class="fas fa-trash-alt text-danger"></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {marketDetails && marketDetails.length ? (
                      ""
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </div>

                  {marketDetails && marketDetails.length ? (
                    <div className="d-flex justify-content-end cm-pagination">
                      <Pagination
                        itemClassPrev={"prevClass"}
                        itemClassNext={"nextClass"}
                        activePage={this.state.pageNo}
                        itemsCountPerPage={this.state.count}
                        totalItemsCount={this.state.totalCount}
                        pageRangeDisplayed={3}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>{" "}
              </>
            )}
          </div>
          {/* <Modal
                visible={visible}
                // title="Title"
                onOk={this.handleOnChangeStatus}
                onCancel={this.handleCancel}
                footer={[
                    <Button type='button' variant="dark" onClick={this.handleCancel}>
                        NO
              </Button>,
                    <Button type="button" variant="warning" loading={loading} onClick={() => this.handleOnChangeStatus(this.state.selectedId, '2')}>
                        Yes
              </Button>,
                ]}
            >
                <img src={require('../../assets/img/Delete-Email.png')}/>
                                <br/>
                                <br/>
                                <p>Are you sure you want delete this market ?</p>
                
                </Modal> */}
        </div>
        <Modal
          show={visible}
          onHide={this.handleCancel}
          className="cm_order_view dlt_item"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img src={require("../../assets/img/Delete-Email.png")} />
            <br />
            <br />
            <p>Are you sure you want to delete this market?</p>
          </Modal.Body>
          <Modal.Footer>
            <div className="footer_btn_wrapper btn_nospace">
              <Button
                variant="warning"
                loading={loading}
                onClick={() =>
                  this.handleOnChangeStatus(this.state.selectedId, "2")
                }
              >
                Yes
              </Button>
              <Button variant="dark" onClick={this.handleCancel}>
                No
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default popupmanagement

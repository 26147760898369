import "react-phone-input-2/lib/style.css"

import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import React, { Component } from "react"

import Geocode from "react-geocode"
import { Link } from "react-router-dom"
import PhoneInput from "react-phone-input-2"
import Sidebar from "../../components/sidebar"
import Url from "../../services/BaseUrl"
import _ from "lodash"
import { _Api } from "../../services/Api"
import { isValidEmail } from "../../utils/Validation"
import { message } from "antd"
import { notify } from "../../components/common/Toaster"

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result))
  reader.readAsDataURL(img)
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg"
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!")
  }
  return isJpgOrPng
}

class Editfrmermanagement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      phone: "",
      errors: [],
      responseError: "",
      lat: "",
      long: "",
      userId: "",
      farmerDetails: "",
      commission: "",
      profileImage: "",
      profilePicPreview: "",
      profilePicErrorMsg: "",
      state: "",
      city: "",
      area: "",
      postalCode: "",
      address: "",
      addressLine1: "",
      addressLine2: "",
      logout: false,
      addressId: "",
    }
  }

  //Image OnChange
  handleFileChange = (info) => {
    let reader = new FileReader()
    if (info.file.status === "uploading") {
      this.setState({ loading: true })
      return
    }
    if (info.file.status === "done") {
      // Get this url from response in real world
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          uploadMedia: info.fileList[0].originFileObj,
          loading: false,
        })
      )
    }
  }

  componentDidMount() {
    this.getFarmerDetails()
  }

  handleOnChange = (e) => {
    let self = this

    let { addressLine1, addressLine2, city, state, postalCode } = this.state
    if (e.target.name === "addressLine1") {
      addressLine1 = e.target.value
    }
    if (e.target.name === "addressLine2") {
      addressLine2 = e.target.value
    }

    if (e.target.name === "city") {
      city = e.target.value
    }
    if (e.target.name === "state") {
      state = e.target.value
    }
    if (e.target.name === "postalCode") {
      postalCode = e.target.value
    }

    let addr =
      addressLine1 +
      " " +
      addressLine2 +
      " " +
      city +
      " " +
      state +
      " " +
      postalCode

    if (
      e.target.name === "addressLine1" ||
      e.target.name === "addressLine2" ||
      e.target.name === "city" ||
      e.target.name === "state" ||
      e.target.name === "postalCode"
    ) {
      Geocode.fromAddress(addr).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location

          self.setState({ lat: lat, long: lng })
        },
        (error) => {
          console.error(error)
        }
      )
    }
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    })
  }
  getFarmerDetails = () => {
    let { search, count, pageNo, totalCount, sortField, sortType, userId } =
      this.state
    if (this.props.match.params) {
      let req = { userId: this.props.match.params.id }
      _Api(
        Url.SUPER_ADMIN_VIEW_FARMER.method,
        Url.SUPER_ADMIN_VIEW_FARMER.url,
        "",
        `?userId=${req.userId}`
      ).then((resp) => {
        this.setState({
          farmerDetails: resp.responseData.farmersInfo,
          userId: resp.responseData.farmersInfo._id,
          firstName: resp.responseData.farmersInfo.firstName,
          lastName: resp.responseData.farmersInfo.lastName,
          countryCode: resp.responseData.farmersInfo.countryCode,
          phone: resp.responseData.farmersInfo.phone,
          email: resp.responseData.farmersInfo.email,
          commission: resp.responseData.farmersInfo.commission
            ? resp.responseData.farmersInfo.commission
            : "",
          profileImage: resp.responseData.farmersInfo.profileImage,
          profilePicPreview: resp.responseData.farmersInfo.profileImage,
          address: resp.responseData.farmersInfo.address,
          // address: !_.isEmpty(resp.responseData.farmersInfo.address) ? (resp.responseData.farmersInfo.address[0].addressLine1 ? resp.responseData.farmersInfo.address[0].addressLine1 : '') : '',
          area: !_.isEmpty(resp.responseData.farmersInfo.address)
            ? resp.responseData.farmersInfo.address[0].addressLine2
              ? resp.responseData.farmersInfo.address[0].addressLine2
              : ""
            : "",
          city: !_.isEmpty(resp.responseData.farmersInfo.address)
            ? resp.responseData.farmersInfo.address[0].city
              ? resp.responseData.farmersInfo.address[0].city
              : ""
            : "",
          state: !_.isEmpty(resp.responseData.farmersInfo.address)
            ? resp.responseData.farmersInfo.address[0].state
              ? resp.responseData.farmersInfo.address[0].state
              : ""
            : "",

          addressId:
            resp.responseData.farmersInfo.address.length > 0
              ? resp.responseData.farmersInfo.address[0]._id
                ? resp.responseData.farmersInfo.address[0]._id
                : ""
              : "",

          addressLine1: !_.isEmpty(resp.responseData.farmersInfo.address)
            ? resp.responseData.farmersInfo.address[0].addressLine1
              ? resp.responseData.farmersInfo.address[0].addressLine1
              : ""
            : "",
          addressLine2: !_.isEmpty(resp.responseData.farmersInfo.address)
            ? resp.responseData.farmersInfo.address[0].addressLine2
              ? resp.responseData.farmersInfo.address[0].addressLine2
              : ""
            : "",
          postalCode: !_.isEmpty(resp.responseData.farmersInfo.address)
            ? resp.responseData.farmersInfo.address[0].postalCode
              ? resp.responseData.farmersInfo.address[0].postalCode
              : ""
            : "",
        })
      })
    }
  }

  handleValidate = () => {
    let validate = false
    let errors = {}
    let {
      firstName,
      lastName,
      email,
      phone,
      postalCode,
      profileImage,
      countryCode,
      postalcode,
      commission,
      address,
    } = this.state

    if (profileImage) {
      if (profileImage.size > 5000000) {
        validate = true
        this.setState({
          profilePicErrorMsg: "Profile pic size should be less than 5Mb.",
        })
      } else {
        this.setState({
          profilePicErrorMsg: "",
        })
      }
    }
    if (_.isEmpty(firstName)) {
      validate = true
      errors["firstName"] = "First Name is required."
    }
    if (_.isEmpty(lastName)) {
      validate = true
      errors["lastName"] = "Last Name is required."
    }
    if (_.isEmpty(email)) {
      validate = true
      errors["email"] = "Email is required."
    } else if (!isValidEmail(email)) {
      validate = true
      errors["email"] = "Enter vaild email"
    }

    if (commission === "") {
      validate = true
      errors["commission"] = "Commission  is required."
    } else if (commission <= 0 || commission > 100) {
      validate = true
      errors["commission"] =
        "Commission must be greater than 0 and less than 100."
    }
    if (postalCode) {
      if (postalCode < 0) {
        validate = true
        errors["postalCode"] = "Postal code must be greater than 0."
      }
    }
    this.setState({ errors })
    return validate
  }

  handleEditFramer = (e) => {
    if (!this.handleValidate()) {
      let {
        addressId,
        userId,
        postalCode,
        addressLine1,
        addressLine2,
        firstName,
        lastName,
        email,
        phone,
        countryCode,
        lat,
        profileImage,
        long,
        commission,
        state,
        city,
        area,
        address,
      } = this.state
      let formData = new FormData()

      formData.append("userId", this.props.match.params.id)
      formData.append("firstName", firstName)
      formData.append("lastName", lastName)
      formData.append("email", email)
      formData.append("lat", lat ? lat : 0)
      formData.append("long", long ? long : 0)
      formData.append("phone", phone ? phone : "")
      formData.append("commission", commission)
      // formData.append("countryCode", countryCode)
      formData.append("profileImage", profileImage)

      // formData.append("addressLine1", addressLine1)
      // formData.append("addressLine2", addressLine2)
      // formData.append("postalCode", postalCode)
      // formData.append("city", city)
      // formData.append("state", state)

      let obj2 = {
        addressId: addressId,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: state,
        postalCode: postalCode ? postalCode : "",
        userId: userId,
      }

      _Api(
        Url.SUPER_ADMIN_EDIT_FARMER.method,
        Url.SUPER_ADMIN_EDIT_FARMER.url,
        formData
      ).then((resp) => {
        notify("success", resp.responseData.message)
        this.props.history.push("/farmermanagement")
      })
      if (!_.isEmpty(address)) {
        _Api(
          Url.SUPER_ADMIN_EDIT_ADDRESS.method,
          Url.SUPER_ADMIN_EDIT_ADDRESS.url,
          obj2
        ).then((resp) => {
          this.props.history.push("/farmermanagement")
        })
      } else {
        _Api(
          Url.SUPER_ADMIN_ADD_ADDRESS.method,
          Url.SUPER_ADMIN_ADD_ADDRESS.url,
          obj2
        ).then((resp) => {
          this.props.history.push("/farmermanagement")
        })
      }
    }
  }

  handlePicUpload = (event) => {
    let reader = new FileReader()
    let file = event.target.files[0]

    let { valiDate } = this.state

    reader.addEventListener(
      "load",
      () => {
        this.setState({ profilePicPreview: reader.result }, function () {})
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/gif" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml"
      ) {
        if (file.size > 5000000) {
          valiDate = false
          this.setState({
            profileImage: event.target.files[0],
            profilePicErrorMsg: "Profile pic size should be less than 5Mb.",
          })
        } else {
          this.setState({
            profileImage: event.target.files[0],
            profilePicErrorMsg: "",
          })
        }
      } else {
        valiDate = false
        this.setState({ profilePicErrorMsg: "File type not supported." })
      }
    }
  }

  handleAddressLatLong = (latValue, lngValue, address, area, city, state) => {
    this.setState({
      lat: latValue,
      long: lngValue,
      address: address,
      area: area,
      city: city,
      state: state,
    })
  }

  Closelogout = () => {
    this.setState({ logout: false })
  }
  handleLogoutAdmin = () => {
    this.setState({ logoutdeletePopup: true })
    localStorage.removeItem("accessToken")
    window.location.href = "/"
  }

  openPopup = () => {
    this.setState({ logout: true })
  }
  onKeyPress(e) {
    e = e || window.event
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which
    var charStr = String.fromCharCode(charCode)

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault()
  }

  render() {
    let userId = this.props.match.params.id
    let {
      addressLine2,
      addressLine1,
      firstName,
      lastName,
      email,
      profilePicErrorMsg,
      profileImage,
      logout,
      countryCode,
      phone,
      errors,
      commission,
      profilePicPreview,
      state,
      city,
      area,
      address,
    } = this.state
    return (
      <>
        <Sidebar />
        <div className="body_container">
          <div className="body_inner_wrapper">
            <header className="header_content">
              <div>
                <Link to="/farmermanagement">
                  <span className="mr-3 d-inline-block text_light text_12">
                    <i class="fas fa-caret-left text_dark mr-2"></i> Back{" "}
                  </span>
                </Link>
              </div>
            </header>
            <div className="page_heading d-flex">
              <h6 className=" activeheading mr-3 mr-3"> Edit Farmer</h6>
              <Link
                to={`/farmermanagement/inventorymanagement/${userId}`}
                className=" mr-3"
              >
                <h6>Inventory</h6>
              </Link>
              <Link to={`/farmermanagement/farm/${userId}`} className=" mr-3">
                <h6>Farm</h6>
              </Link>
              <Link
                to={`/farmermanagement/distributionmanagement/${userId}`}
                className=" mr-3"
              >
                <h6>Distribution</h6>
              </Link>
            </div>
            <div className="main_content farm_page ">
              <Row>
                <Col sm={3}>
                  <div className="u_img mb-5">
                    {profileImage ? (
                      <span className="profile_icon_Admin ">
                        <img src={profilePicPreview} className="logoroundimg" />
                        <span className="img_icon">
                          <span className="file_input">
                            <input
                              type="file"
                              className="cm_hide_input"
                              accept="image/x-png,image/gif,image/jpeg,image/jpg"
                              onChange={this.handlePicUpload}
                            />
                          </span>
                          <img src={require("../../assets/img/imgicon.png")} />
                        </span>
                      </span>
                    ) : (
                      //
                      // <span className="profile_icon_Admin "><img src={profilePicPreview}
                      //                                            className="logoroundimg "/>
                      //
                      // <span className="img_icon">
                      // <span className="file_input">
                      // <input type="file" className="cm_hide_input"
                      //        accept="image/x-png,image/gif,image/jpeg,image/jpg"
                      //        onChange={this.handlePicUpload}/>
                      // </span>
                      // <img src={require('../../assets/img/imgicon.png')}/>
                      // </span>
                      // </span>
                      <span className="profile_icon_Admin ">
                        <img
                          src={require("../../assets/img/adminlogo.png")}
                          className="logoroundimg "
                        />
                        <span className="img_icon">
                          <span className="file_input">
                            <input
                              type="file"
                              className="cm_hide_input"
                              accept="image/x-png,image/gif,image/jpeg,image/jpg"
                              onChange={this.handlePicUpload}
                            />
                          </span>
                          <img src={require("../../assets/img/imgicon.png")} />
                        </span>
                      </span>
                    )}
                  </div>
                  {profilePicErrorMsg ? (
                    <div className="error">{profilePicErrorMsg}</div>
                  ) : null}
                </Col>
                <Col sm={9}>
                  <Row>
                    <Col md="2">
                      <p>First Name:</p>
                    </Col>
                    <Col md="3">
                      <Form.Control
                        type="text"
                        placeholder="Enter first name"
                        maxLength={30}
                        name="firstName"
                        value={firstName}
                        onChange={this.handleOnChange}
                      />
                      <span className="error_msg">{errors["firstName"]}</span>
                    </Col>
                    <Col md="2">
                      <p>Last Name:</p>
                    </Col>
                    <Col md="3">
                      <Form.Control
                        type="text"
                        placeholder="Enter last name"
                        maxLength={30}
                        name="lastName"
                        value={lastName}
                        onChange={this.handleOnChange}
                      />
                      <span className="error_msg">{errors["lastName"]}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <p>Email:</p>
                    </Col>
                    <Col md="8">
                      <Form.Control
                        type="email"
                        placeholder="Enter email..."
                        maxLength={30}
                        readOnly="readOnly"
                        name="email"
                        value={email}
                        onChange={this.handleOnChange}
                      />
                      <span className="error_msg">{errors["email"]}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <p>Phone:</p>
                    </Col>

                    <Col md="8">
                      <div className="cm_phnnumber">
                        <PhoneInput
                          country={"us"}
                          className="form-control custom-number"
                          value={phone}
                          placeholder="Please enter phone number"
                          onChange={(phone) => this.setState({ phone })}
                        />
                      </div>
                      <span className="error_msg">{errors["phone"]}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <p>commission:</p>
                    </Col>
                    <Col md="8">
                      <Form.Control
                        type="number"
                        onKeyPress={this.onKeyPress.bind(this)}
                        placeholder="Enter commission in %"
                        maxLength={30}
                        step="any"
                        name="commission"
                        value={commission}
                        onChange={this.handleOnChange}
                      />
                      <span className="error_msg">{errors["commission"]}</span>
                    </Col>
                  </Row>
                  <Form.Group>
                    {/*<p>Location On Map:</p>*/}
                    <div>
                      <Row>
                        <Col md="2">
                          {" "}
                          <label htmlFor="">Farm Address Line 1</label>
                        </Col>
                        <Col md="8">
                          <input
                            type="text"
                            name="addressLine1"
                            className="form-control"
                            placeholder="Farm Address Line 1"
                            onChange={this.handleOnChange}
                            value={addressLine1}
                          />
                        </Col>
                      </Row>
                      {/*</div>*/}
                      <Row>
                        <Col md="2">
                          <label htmlFor="">Farm Address Line 2</label>
                        </Col>
                        <Col md="8">
                          {" "}
                          <input
                            type="text"
                            name="addressLine2"
                            className="form-control"
                            placeholder="Farm Address Line 2"
                            onChange={this.handleOnChange}
                            value={addressLine2}
                          />
                        </Col>{" "}
                      </Row>

                      <Row>
                        <Col md="2">
                          <label htmlFor="">City</label>
                        </Col>
                        <Col md="8">
                          <input
                            type="text"
                            name="city"
                            className="form-control"
                            placeholder="City"
                            onChange={this.handleOnChange}
                            value={this.state.city}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2">
                          <label htmlFor="">State</label>
                        </Col>
                        <Col md="8">
                          <input
                            type="text"
                            name="state"
                            className="form-control"
                            placeholder="State"
                            onChange={this.handleOnChange}
                            value={this.state.state}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2">
                          <label htmlFor="">Postal Code </label>
                        </Col>
                        <Col md="8">
                          <input
                            type="number"
                            onKeyPress={this.onKeyPress.bind(this)}
                            // oninput="validity.valid||(value='')"
                            name="postalCode"
                            // pattern="\d+"
                            placeholder="Postal Code"
                            className="form-control"
                            onChange={this.handleOnChange}
                            value={this.state.postalCode}
                            min="0"

                            // onkeypress="return event.charCode >= 48" min="1"
                          />
                          <span className="error_msg">
                            {errors["postalCode"]}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </Form.Group>

                  <div>
                    <Button
                      variant="dark"
                      onClick={this.props.history.goBack}
                      className="mr-3"
                    >
                      Cancel
                    </Button>
                    <Button variant="warning" onClick={this.handleEditFramer}>
                      save
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <Modal
          show={logout}
          onHide={this.Closelogout}
          className="cm_order_view dlt_item"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to logout?</p>
          </Modal.Body>
          <Modal.Footer>
            <div className="footer_btn_wrapper button_space">
              <Button variant="warning" onClick={this.handleLogoutAdmin}>
                Yes
              </Button>
              <Button variant="dark" onClick={this.Closelogout}>
                No
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default Editfrmermanagement

import "react-phone-input-2/lib/style.css"

import { Button, Col, Form, Row } from "react-bootstrap"
import React, { Component } from "react"

import Geocode from "react-geocode"
import { Link } from "react-router-dom"
import Loader from "../../components/common/Loader"
import PhoneInput from "react-phone-input-2"
import Sidebar from "../../components/sidebar"
import Url from "../../services/BaseUrl"
import _ from "lodash"
import { _Api } from "../../services/Api"
import { isValidEmail } from "../../utils/Validation"
import { notify } from "../../components/common/Toaster"

class addfarmer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      phone: "",
      errors: [],
      responseError: "",
      lat: "",
      long: "",
      commission: "",
      address: "",
      profileImage: "",
      state: "",
      profilePicErrorMsg: "",
      city: "",
      area: "",
      logout: false,
      postalCode: "",
      addressLine1: "",
      addressLine2: "",
    }
  }

  handleOnChange = (e) => {
    let self = this

    let address1, address2, city1, state1, postalCode1
    if (e.target.name === "addressLine1") {
      address1 = e.target.value
    }
    if (e.target.name === "addressLine2") {
      address2 = e.target.value
    }

    if (e.target.name === "city") {
      city1 = e.target.value
    }
    if (e.target.name === "state") {
      state1 = e.target.value
    }
    if (e.target.name === "postalCode") {
      postalCode1 = e.target.value
    }
    let addr =
      address1 + " " + address1 + " " + city1 + " " + state1 + " " + postalCode1

    if (
      e.target.name === "addressLine1" ||
      e.target.name === "addressLine2" ||
      e.target.name === "city" ||
      e.target.name === "state" ||
      e.target.name === "postalCode"
    ) {
      Geocode.fromAddress(addr).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location

          self.setState({ lat: lat, long: lng })
        },
        (error) => {
          console.error(error)
        }
      )
    }

    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    })
  }
  handleValidate = () => {
    let validate = false
    let errors = {}
    let {
      profileImage,
      firstName,
      postalCode,
      lastName,
      email,
      phone,
      countryCode,
      commission,
      address,
    } = this.state

    // if (profileImage) {
    console.log(profileImage, "profileImageprofileImageprofileImage")

    if (profileImage === "" || profileImage === undefined) {
      validate = true
      this.setState({
        profilePicErrorMsg: "Please select profile pic.",
      })
    } else {
      if (profileImage.size > 5000000) {
        validate = true
        this.setState({
          profilePicErrorMsg: "Profile pic size should be less than 5Mb.",
        })
      } else {
        this.setState({
          profilePicErrorMsg: "",
        })
      }
      // }
    }
    if (_.isEmpty(firstName)) {
      validate = true
      errors["firstName"] = "First name is required."
    }
    if (_.isEmpty(lastName)) {
      validate = true
      errors["lastName"] = "Last name is required."
    }
    if (_.isEmpty(email)) {
      validate = true
      errors["email"] = "Email is required."
    } else if (!isValidEmail(email)) {
      validate = true
      errors["email"] = "Enter vaild email"
    }
    if (postalCode) {
      if (postalCode < 0) {
        validate = true
        errors["postalCode"] = "Postal code must be greater than 0."
      }
    }

    if (phone) {
      if (phone.length > 3 && phone.length < 10) {
        validate = true
        errors["phone"] = "Please enter a valid mobile number."
      }
    }
    console.log(commission, "commissioncommissioncommissioncommission")
    if (_.isEmpty(commission)) {
      validate = true
      errors["commission"] = "commission is required."
    } else if (commission <= 0 || commission > 100) {
      validate = true
      errors["commission"] =
        "Commission must be greater than 0 and less than 100."
    }

    // if (_.isEmpty(address)) {
    //     validate = true;
    //     errors['address'] = 'Address is required.'
    // }

    this.setState({ errors })
    return validate
  }

  handleAddFramer = (e) => {
    console.log(
      !this.handleValidate(),
      "profileImage!this.handleValidate()!this.handleValidate()"
    )
    if (!this.handleValidate()) {
      let {
        addressLine1,
        addressLine2,
        postalCode,
        finalNestedDeliveryWeekDaysTimeSlot,
        firstName,
        lastName,
        email,
        phone,
        countryCode,
        profileImage,
        lat,
        address,
        long,
        commission,
        lng,
        state,
        city,
        area,
      } = this.state
      console.log("qwert", profileImage)

      let formData = new FormData()
      formData.append("firstName", firstName)
      formData.append("lastName", lastName)
      formData.append("email", email)
      formData.append("lat", lat ? lat : 0)
      formData.append("long", long ? long : 0)
      formData.append("phone", phone)
      formData.append("userType", 1)

      formData.append("commission", commission)
      // formData.append("countryCode", countryCode)
      formData.append("profileImage", profileImage)
      formData.append("addressLine1", addressLine1)
      formData.append("addressLine2", addressLine2)
      formData.append("city", city)
      formData.append("state", state)
      formData.append("postalCode", postalCode ? postalCode : "")

      // Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "",
      _Api(
        Url.SUPER_ADMIN_ADD_USER.method,
        Url.SUPER_ADMIN_ADD_USER.url,
        formData
      )
        .then((resp) => {
          {
            console.log("resp", resp)
          }
          // this.setState({ driverInfo: resp.responseData.driverInfo, isLoader: false })
          notify("success", resp.responseData.message)
          this.props.history.goBack()
          // this.closeModal()
        })
        .catch((err) => notify("error", err.data.error.responseMessage))
    }
  }
  handleAddressLatLong = (latValue, lngValue, address, area, city, state) => {
    console.log("latlong", latValue, lngValue, address)
    this.setState({
      lat: latValue,
      long: lngValue,
      address: address,
      area: area,
      city: city,
      state: state,
    })
  }
  handlePicUpload = (event) => {
    console.log(event, "eventeventevent", event.target.files[0])
    let reader = new FileReader()
    let file = event.target.files[0]

    let { valiDate } = this.state

    reader.addEventListener(
      "load",
      () => {
        this.setState({ profilePicPreview: reader.result }, function () {})
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/gif" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml"
      ) {
        if (file.size > 5000000) {
          console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")

          valiDate = false
          this.setState({
            profileImage: event.target.files[0],
            profilePicErrorMsg: "Profile pic size should be less than 5Mb.",
          })
        } else {
          this.setState({
            profileImage: event.target.files[0],
            profilePicErrorMsg: "",
          })
        }
      } else {
        valiDate = false
        this.setState({ profilePicErrorMsg: "File type not supported." })
      }
    }
  }
  // onKeyPress(event) {
  //     const keyCode = event.keyCode || event.which;
  //     const keyValue = String.fromCharCode(keyCode);
  //     if (/\+|-/.test(keyValue))
  //         event.preventDefault();
  // }
  onKeyPress(e) {
    e = e || window.event
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which
    var charStr = String.fromCharCode(charCode)

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault()
  }

  render() {
    let {
      postalCode,
      firstName,
      lastName,
      profilePicErrorMsg,
      email,
      profileImage,
      countryCode,
      logout,
      phone,
      errors,
      commission,
      profilePicPreview,
      state,
      city,
      address,
      area,
    } = this.state
    return (
      <>
        <Sidebar />
        <div className="body_container">
          <div className="body_inner_wrapper">
            {this.state.isLoader ? (
              <Loader />
            ) : (
              <>
                <header className="header_content">
                  <div>
                    <Link to="/farmermanagement">
                      <span className="mr-3 d-inline-block text_light text_12">
                        <i class="fas fa-caret-left text_dark mr-2"></i> Back{" "}
                      </span>
                    </Link>
                  </div>
                </header>

                <div className="page_heading">
                  <h6>Add Farmer</h6>
                </div>
                <div className="main_content farm_page ">
                  <Row>
                    <Col sm={3}>
                      <div className="u_img mb-5">
                        {profilePicPreview ? (
                          <span className="profile_icon_Admin ">
                            <img
                              src={profilePicPreview}
                              className="logoroundimg"
                            />
                            <span className="img_icon">
                              <span className="file_input">
                                <input
                                  type="file"
                                  className="cm_hide_input"
                                  accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                  onChange={this.handlePicUpload}
                                />
                              </span>
                              <img
                                src={require("../../assets/img/imgicon.png")}
                              />
                            </span>
                          </span>
                        ) : (
                          <span className="profile_icon_Admin ">
                            <img
                              src={require("../../assets/img/adminlogo.png")}
                              className="logoroundimg"
                            />
                            <span className="img_icon">
                              <span className="file_input">
                                <input
                                  type="file"
                                  className="cm_hide_input"
                                  accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                  onChange={this.handlePicUpload}
                                />
                              </span>
                              <img
                                src={require("../../assets/img/imgicon.png")}
                              />
                            </span>
                          </span>
                        )}
                      </div>
                      {profilePicErrorMsg ? (
                        <div className="error">{profilePicErrorMsg}</div>
                      ) : null}
                    </Col>
                    <Col sm={9}>
                      <Row>
                        <Col md="2">
                          <p>First Name:</p>
                        </Col>
                        <Col md="3">
                          <Form.Control
                            type="text"
                            placeholder="First name"
                            maxLength={30}
                            name="firstName"
                            value={firstName}
                            onChange={this.handleOnChange}
                          />
                          <span className="error_msg">
                            {errors["firstName"]}
                          </span>
                        </Col>
                        <Col md="2">
                          <p>Last Name:</p>
                        </Col>
                        <Col md="3">
                          <Form.Control
                            type="text"
                            placeholder="Last name"
                            maxLength={30}
                            name="lastName"
                            value={lastName}
                            onChange={this.handleOnChange}
                          />
                          <span className="error_msg">
                            {errors["lastName"]}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2">
                          <p>Email:</p>
                        </Col>
                        <Col md="8">
                          <Form.Control
                            type="email"
                            placeholder="Enter email"
                            maxLength={30}
                            name="email"
                            value={email}
                            onChange={this.handleOnChange}
                          />
                          <span className="error_msg">{errors["email"]}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2">
                          <p>Phone:</p>
                        </Col>
                        <Col md="8">
                          <div className="cm_phnnumber">
                            <PhoneInput
                              country={"us"}
                              className="form-control custom-number"
                              value={phone}
                              placeholder="Please enter phone number"
                              onChange={(phone) => this.setState({ phone })}
                            />
                          </div>

                          <span className="error_msg">{errors["phone"]}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2">
                          <p>Commission:</p>
                        </Col>
                        <Col md="8">
                          <Form.Control
                            type="number"
                            onKeyPress={this.onKeyPress.bind(this)}
                            name="commission"
                            step="any"
                            value={commission}
                            min={0}
                            onChange={this.handleOnChange}
                            placeholder="Enter commission in %"
                          />

                          <span className="error_msg">
                            {errors["commission"]}
                          </span>
                        </Col>
                      </Row>

                      <Form.Group>
                        {/*<p>Location On Map:</p>*/}
                        <div>
                          {console.log(
                            this.state.lat,
                            this.state.lng,
                            "xsdsddddddddddddddddd.lat"
                          )}
                          <Row>
                            <Col md="2">
                              {" "}
                              <label htmlFor="">Farm Address Line 1</label>
                            </Col>
                            <Col md="8">
                              <input
                                type="text"
                                name="addressLine1"
                                className="form-control"
                                placeholder="Farm Address Line 1"
                                onChange={this.handleOnChange}
                                value={this.state.addressLine1}
                              />
                            </Col>
                          </Row>
                          {/*</div>*/}
                          <Row>
                            <Col md="2">
                              <label htmlFor="">Farm Address Line 2</label>
                            </Col>
                            <Col md="8">
                              {" "}
                              <input
                                type="text"
                                name="addressLine2"
                                className="form-control"
                                placeholder="Farm Address Line 2"
                                onChange={this.handleOnChange}
                                value={this.state.addressLine2}
                              />
                            </Col>{" "}
                          </Row>

                          <Row>
                            <Col md="2">
                              <label htmlFor="">City</label>
                            </Col>
                            <Col md="8">
                              <input
                                type="text"
                                name="city"
                                className="form-control"
                                placeholder="City"
                                onChange={this.handleOnChange}
                                value={this.state.city}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="2">
                              <label htmlFor="">State</label>
                            </Col>
                            <Col md="8">
                              <input
                                type="text"
                                name="state"
                                className="form-control"
                                placeholder="State"
                                onChange={this.handleOnChange}
                                value={this.state.state}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="2">
                              <label htmlFor="">Postal Code </label>
                            </Col>
                            <Col md="8">
                              {/*<input type="number"*/}
                              {/*       min="0"*/}
                              {/*       name="postalCode"*/}
                              {/*       placeholder="Postal Code"*/}
                              {/*       className="form-control"*/}
                              {/*       onChange={this.handleOnChange}*/}
                              {/*       value={this.state.postalCode}/>*/}
                              <Form.Control
                                type="number"
                                onKeyPress={this.onKeyPress.bind(this)}
                                pattern="[0-9]*"
                                name="postalCode"
                                step="any"
                                value={postalCode}
                                min={0}
                                oninput="validity.valid||(value='')"
                                onChange={this.handleOnChange}
                                placeholder="Postal Code"
                              />
                            </Col>
                          </Row>
                        </div>
                      </Form.Group>

                      <div>
                        <Button
                          variant="dark"
                          onClick={this.props.history.goBack}
                          className="mr-3"
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="warning"
                          onClick={this.handleAddFramer}
                        >
                          save
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}{" "}
          </div>
        </div>
      </>
    )
  }
}

export default addfarmer

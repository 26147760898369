import { Button, Modal, Spinner } from "react-bootstrap"
import React, { Component } from "react"

import _ from "lodash"
import { connect } from "react-redux"
import { deleteInventoryItem } from "../../../redux/action/inventory"

class ItemDelete extends Component {
  componentWillUnmount() {
    this.props.dispatch({
      type: "DELETE_INVENTORY_ITEM_DISMISS",
    })
  }

  submit = () => {
    this.props.dispatch(
      deleteInventoryItem({
        inventoryId: this.props.item._id,
        userId: this.props.userId,
      })
    )
  }

  close = () => {
    if (this.props.isLoading) {
      return false
    }

    this.props.dispatch({
      type: "DELETE_INVENTORY_ITEM_DISMISS",
    })
  }

  render() {
    const { item, show, isLoading } = this.props

    if (_.isEmpty(item)) return null

    return (
      <Modal
        centered
        show={show}
        onHide={this.close}
        keyboard={!isLoading}
        className="cm_order_view dlt_item"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={require("../../../assets/img/Delete-Email.png")} />
          <br />
          <br />
          <p>Are you sure you want to delete this item?</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="footer_btn_wrapper">
            <Button
              variant="warning"
              onClick={this.submit}
              disabled={isLoading}
            >
              <span>Delete</span>{" "}
              {isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="delete status"
                  aria-hidden="true"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}
            </Button>

            <Button variant="dark" onClick={this.close} disabled={isLoading}>
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default connect((state) => ({
  item: state.inventory.item.details,
  show: state.inventory.item.isDelete,
  isLoading: state.inventory.item.isLoading,
}))(ItemDelete)

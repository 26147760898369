import { Button, Col, Container, Form, Navbar, Row } from "react-bootstrap"
import React, { Component } from "react"

import CryptoJS from "crypto-js"
import { Link } from "react-router-dom"
import _ from "lodash"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { isValidEmail } from "../utils/Validation"
import { login } from "../redux/action/Auth"

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      errors: [],
      responseError: "",
      checkBoxValue: 0,
      rememberMe: true,
    }
  }

  componentWillMount() {
    if (localStorage.getItem("hash_e") || localStorage.getItem("hash_p")) {
      let mykeyd = CryptoJS.AES.decrypt(
        localStorage.getItem("hash_p"),
        "secret123"
      )
      let mysd = mykeyd.toString(CryptoJS.enc.Utf8)

      let myEmal = localStorage.getItem("hash_e")
      console.log(mysd, myEmal, "zzzzzx")
      this.setState({ email: myEmal, password: mysd, rememberMe: true })
    } else {
      this.setState({ rememberMe: false })
    }
  }

  validateEmail(email) {
    const pattern =
      /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/
    const result = pattern.test(email)
    return result
  }
  converthash(e) {
    let { email, password } = this.state
    if (e == 0) {
      var myEml = email
      var pass = CryptoJS.AES.encrypt(password, "secret123").toString()

      sessionStorage.setItem("hash_e", myEml)
      sessionStorage.setItem("hash_p", pass)

      localStorage.setItem("hash_e", myEml)
      localStorage.setItem("hash_p", pass)
      this.setState({ checkBoxValue: 1, rememberMe: true })
    } else if (e == 1) {
      sessionStorage.removeItem("hash_e")
      sessionStorage.removeItem("hash_p")
      localStorage.removeItem("hash_e")
      localStorage.removeItem("hash_p")
      this.setState({ checkBoxValue: 0, rememberMe: false })
    }
  }

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
      responseError: "",
      errors: [],
    })
  }

  handleValidate = () => {
    let validate = false
    let errors = {}
    let { email, password } = this.state

    if (_.isEmpty(email)) {
      validate = true
      errors["email"] = "email is required."
    } else if (!isValidEmail(email)) {
      validate = true
      errors["email"] = "Enter a valid email."
    }

    if (_.isEmpty(password)) {
      validate = true
      errors["password"] = "Password is required."
    }

    this.setState({ errors })
    return validate
  }

  // Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "",
  handleOnLoginSubmit = (e) => {
    e.preventDefault()
    if (!this.handleValidate()) {
      let { email, password } = this.state
      let req = {
        email: email,
        password: password,
      }
      this.props.action.login(req)
    }
  }

  // converthash(e) {

  //     console.log(e, "gffghj");

  //     let { email, password } = this.state;
  //     if (e == 0) {

  //         var myPass = crypto.createCipher('aes-128-cbc', 'mypassword');
  //         var pass = myPass.update(password, 'utf8', 'hex')
  //         pass += myPass.final('hex');

  //         console.log(pass, "ttttttttt", myEml, "kjhgfdnnnnnnnnnnnnnnnnnnnnnnnnnnnn")

  //         var myemail = crypto.createCipher('aes-128-cbc', 'mypassword');
  //         var myEml = myemail.update(email, 'utf8', 'hex')
  //         myEml += myemail.final('hex');

  //         sessionStorage.setItem("hash_e", myEml);
  //         sessionStorage.setItem("hash_p", pass);

  //         localStorage.setItem("hash_e", myEml);
  //         localStorage.setItem("hash_p", pass);
  //         this.setState({ checkBoxValue: 1, rememberMe: true })
  //     } else if (e == 1) {
  //         sessionStorage.removeItem('hash_e');
  //         sessionStorage.removeItem('hash_p');
  //         localStorage.removeItem('hash_e');
  //         localStorage.removeItem('hash_p');
  //         this.setState({ checkBoxValue: 0, rememberMe: false })

  //     }

  //     // bcrypt.hash(confirmPassword, 10, (err, hash) => {

  //     // return res.status(500).json({
  //     // error: "Some Error at Server while parsing your request"
  //     // });
  //     // }

  //     console.log(email, password, "converthash", e);

  // }
  handleOnChangerem = (e) => {}
  render() {
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("isLoggedIn")
    )
      this.props.history.push("farmermanagement")
    let { errors, email, password } = this.state

    return (
      <div className="body_container_wrapper login_page">
        {this.props.isLoading}
        <Container>
          <h1>
            <Navbar.Brand href="#home">
              <img
                src={require("../assets/img/OFFICIAL LOGO TM WITH HEALTHY PLANEAT BLOCK.png")}
                alt="Logo"
              />
            </Navbar.Brand>
          </h1>
          <div className="login_wrapper">
            <Row>
              <Col md="4" className="mx-auto text-center">
                <div>
                  <img src={require("../assets/img/OFFICIAL LOGO.png")} />
                </div>
                <h6>Sign in</h6>
                <Form.Group>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={this.handleOnChange}
                  />
                  <span className="error_msg">{errors["email"]}</span>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={this.handleOnChange}
                  />
                  <span className="error_msg">{errors["password"]}</span>
                </Form.Group>
                <Form.Group
                  controlId="formBasicCheckbox"
                  className="forgot_wrapper"
                >
                  <div>
                    <label className="cm_checkbox">
                      <input
                        type="checkbox"
                        checked={this.state.rememberMe}
                        onChange={this.converthash.bind(
                          this,
                          this.state.checkBoxValue
                        )}
                      />
                      <span></span>
                    </label>
                    <label htmlFor="test">Remember Me</label>
                  </div>

                  <p>
                    <small>
                      <Link to="/forgotpassword">Forgot Password ?</Link>
                    </small>
                  </p>
                </Form.Group>
                <Button
                  variant="dark"
                  className="max-w-100"
                  onClick={this.handleOnLoginSubmit}
                >
                  Sign In
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="footerloginbg"></div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    isLoggedIn: state.login.isLoggedIn,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({ login }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)

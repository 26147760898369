import { combineReducers } from "redux"
import { inventory } from "./inventory"
import { item } from "./item"
import { loading } from "./Common"
import { login } from "./Auth"

export default combineReducers({
  loading,
  login,
  inventory,
  item,
})

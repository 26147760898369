import { Button, Modal, Nav } from "react-bootstrap"
import React, { Component } from "react"

import { NavLink } from "react-router-dom"
import Url from "../services/BaseUrl"
import _ from "lodash"
import { _Api } from "../services/Api"

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logout: false,
      showmobile: false,
    }
  }
  UNSAFE_componentWillReceiveProps(props) {
    let self = this
    if (props !== undefined) {
      if (!_.isEmpty(props)) {
        if (props.profilePicPreview) {
          self.setState({
            profilePicPreview: props.profilePicPreview
              ? props.profilePicPreview
              : this.state.profilePicPreview,
          })
        }
      }
    }
  }

  menushow = () => {
    this.setState({
      showmobile: !this.state.showmobile,
    })
  }

  componentDidMount() {
    this.getUserInfo()
  }

  getUserInfo = () => {
    this.setState({ errors: "" })

    this.setState({ redirect: false, loader: true })

    _Api(
      Url.SUPER_ADMIN_VIEW_PROFILE.method,
      Url.SUPER_ADMIN_VIEW_PROFILE.url,
      ""
    ).then((data) => {
      this.setState({ loader: false })
      if (data.statusCode === 1) {
        let userInfo = data.responseData.adminProfile
        if (!userInfo) return
        this.setState({
          userInfo: userInfo,

          name: userInfo.name,
          phone: userInfo.phone,
          email: userInfo.email,
          address: userInfo.address,
          profilePic: userInfo.profileImage,
          profilePicPreview: userInfo.profileImage,
        })
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }

  Closelogout = () => {
    this.setState({ logout: false })
  }
  handleLogoutAdmin = () => {
    this.setState({ logoutdeletePopup: true })
    localStorage.removeItem("accessToken")
    window.location.href = "/"
  }

  openPopup = () => {
    this.setState({ logout: true })
  }

  render() {
    let { logout, profilePicPreview, showmobile } = this.state
    return (
      <>
        <div className="mobile-trigger-wrapper" onClick={this.menushow}>
          <span className="mobile-trigger">
            <i className="fas fa-bars"></i>
          </span>
        </div>
        <div className={showmobile ? "sidebar open" : "sidebar"}>
          {/* <div className="toggle_icon_menu">
                    <button className="btn" type="button" ><i className="fas fa-bars"></i></button>
                </div> */}
          <div className="farmerlogowrapper">
            {profilePicPreview ? (
              <img className="rounded-circle" src={profilePicPreview} />
            ) : (
              <img src={require("../assets/img/adminlogo.png")} />
            )}
            <h6>Healthy PlanEat</h6>
          </div>

          <div>
            <div className="sidebar_menu">
              <Nav.Item>
                <NavLink to="/farmermanagement">
                  <div className="sidebar-item-icon">
                    <img src={require("../assets/img/farmerManagement.png")} />
                  </div>
                  Farmer Management
                </NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink to="/inventory">
                  <div className="sidebar-item-icon">
                    {/*<img src={require('../assets/img/popupManagement.png')} />*/}
                    <img src={require("../assets/img/item.png")} />
                  </div>
                  Items Management
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink to="/wholesale">
                  <div className="sidebar-item-icon">
                    <img src={require("../assets/img/inventory.png")} />
                  </div>
                  Wholesale Management
                </NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink to="/customer">
                  <div className="sidebar-item-icon">
                    <img
                      src={require("../assets/img/customerManagement.png")}
                    />
                  </div>
                  Customer Management
                </NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink to="/popupmanagement">
                  <div className="sidebar-item-icon">
                    <img src={require("../assets/img/popupManagement.png")} />
                  </div>
                  Market/Pop-Up Management
                </NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink to="/pickups">
                  <div className="sidebar-item-icon">
                    <img src={require("../assets/img/pickups.png")} />
                  </div>
                  Pick Up Host
                </NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink to="/order">
                  <div className="sidebar-item-icon">
                    <img src={require("../assets/img/orders.png")} />
                  </div>
                  Orders
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink to="/recipe">
                  <div className="sidebar-item-icon">
                    <img src={require("../assets/img/food.png")} />
                  </div>
                  Recipe
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink to="/newsletter">
                  <div className="sidebar-item-icon">
                    <img src={require("../assets/img/news-admin.png")} />
                  </div>
                  News Letter
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink to="/settings">
                  <div className="sidebar-item-icon">
                    <img src={require("../assets/img/settings.png")} />
                  </div>
                  Settings
                </NavLink>
              </Nav.Item>
            </div>
            <div className="sidebar_button">
              <Button variant="info" onClick={this.openPopup}>
                Logout
              </Button>
            </div>
          </div>
        </div>

        <Modal
          show={logout}
          onHide={this.Closelogout}
          className="cm_order_view dlt_item"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to logout?</p>
          </Modal.Body>
          <Modal.Footer>
            <div className="footer_btn_wrapper button_space">
              <Button variant="warning" onClick={this.handleLogoutAdmin}>
                Yes
              </Button>
              <Button variant="dark" onClick={this.Closelogout}>
                No
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default Sidebar

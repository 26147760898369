/**
 * @About
 *
 */

export default {
  USER_MAP_EXPLORE: { url: `admin/getMapFarmers`, method: "GET" },
  SUPER_ADMIN_LOGIN: { url: `admin/adminLogin`, method: "POST" },
  CHANGE_PASSWORD: { url: `admin/changePassword`, method: "POST" },
  SUPER_ADMIN_ADD_USER: { url: `admin/addFarmer`, method: "POST" },
  SUPER_ADMIN_GET_USER: { url: `admin/getFarmers`, method: "GET" },

  GET_WHOLESALER: { url: `admin/getWholeseller`, method: "GET" },
  GET_CUSTOMER: { url: `admin/getConsumer`, method: "GET" },
  GET_ORDER: { url: `admin/getAllOrder`, method: "GET" },
  UPDATE_HARVEST_STATUS: { url: `admin/changeHarvestStatus`, method: "POST" },

  GET_RECIPE: { url: `recipes/getRecipes`, method: "GET" },
  GET_NEWS: { url: `newsletter/getNewsletter`, method: "GET" },
  CREATE_RECIPE: { url: `recipes/create`, method: "POST" },
  DELETE_RECIPE: { url: `recipes/delete`, method: "POST" },
  UPDATE_RECIPE: { url: `recipes/update`, method: "POST" },
  UPDATE_STATUS_RECIPE: { url: `recipes/changeStatusRecipes`, method: "POST" },

  SUPER_ADMIN_ITEMS_LIST: { url: `items/getItems`, method: "GET" },
  SUPER_ADMIN_ADD_MARKET: { url: `market/addMarket`, method: "POST" },
  SUPER_ADMIN_EDIT_FARMER: { url: `user/editProfile`, method: "POST" },
  SUPER_ADMIN_EDIT_ADDRESS: { url: `user/editAddress`, method: "POST" },
  SUPER_ADMIN_ADD_ADDRESS: { url: `user/addAddress`, method: "POST" },
  SUPER_ADMIN_CHANGE_STATUS: { url: `admin/changeUserStatus`, method: "POST" },
  SUPER_ADMIN_CHANGE_INVOICE_STATUS: {
    url: `admin/changeInvoiceStatus`,
    method: "POST",
  },
  SUPER_ADMIN_GET_MARKET: { url: `market/getMarkets`, method: "GET" },
  SUPER_ADMIN_CHANGE_MARKET_STATUS: {
    url: `market/changeMarketStatus`,
    method: "POST",
  },
  SUPER_ADMIN_FORGOT_PASSWORD: { url: `admin/forgetPassword`, method: "POST" },
  SUPER_ADMIN_RESET_PASSWORD: { url: `admin/resetPassword`, method: "POST" },
  SUPER_ADMIN_EDIT_MARKET: { url: `market/editMarket`, method: "POST" },
  ADD_INVENTORY: { url: `items/create`, method: "POST" },
  EDIT_INVENTORY: { url: `items/update`, method: "POST" },
  DELETE_INVENTORY: { url: `items/delete`, method: "POST" },
  CHANGE_INVENTORY: { url: `items/changeStatusItem`, method: "POST" },

  GET_INVENTORY: { url: `inventory/getInv`, method: "GET" },
  GET_INV_LIST: { url: `items/getItems`, method: "GET" },
  ADD_INV_FARMER_ITEM: { url: `inventory/create`, method: "POST" },
  EDIT_INV_FARMER_ITEM: { url: `inventory/update`, method: "POST" },
  DELETE_INV_FARMER_ITEM: { url: `inventory/delete`, method: "POST" },
  CHANGE_INV_FARMER_STATUS: {
    url: `inventory/changeStatusInv`,
    method: "POST",
  },

  ADD_FARM: { url: `farm/create`, method: "POST" },
  EDIT_FARM: { url: `farm/update`, method: "POST" },
  EDIT_MEDIA: { url: `farm/updateMedia`, method: "POST" },
  DELETE_MEDIA: { url: `farm/deleteMedia`, method: "POST" },
  GET_FARM_INFO: { url: `farm/getFarmDetail`, method: "GET" },
  GET_ORDER_EXCEL: { url: `payment/getFarmerOrderExcelAdmin`, method: "GET" },
  GET_ALL_ORDER_EXCEL: { url: `payment/getAllOrderExcelAdmin`, method: "GET" },
  GET_ALL_NEWS_EXCEL: { url: `admin/getNewsletterExcelAdmin`, method: "GET" },
  VIEW_ORDER_ID: { url: `payment/viewOrderById`, method: "GET" },
  CHANGE_ORDER_STATUS: { url: `user/changeHarvestStatus`, method: "POST" },

  GET_DISTRIBUTION_ID: {
    url: `distribution/getFarmerDistributionDetails`,
    method: "GET",
  },
  GET_DISTRIBUTION: {
    url: `distribution/getFarmerDistribution`,
    method: "GET",
  },
  DELETE_DISTRIBUTION: { url: `distribution/delete`, method: "POST" },
  ADD_DISTRIBUTION: { url: `distribution/create`, method: "POST" },
  Edit_DISTRIBUTION: { url: `distribution/update`, method: "POST" },
  GET_MARKET_LIST: { url: `market/getMarkets`, method: "GET" },

  SUPER_ADMIN_VIEW_PROFILE: { url: `admin/viewProfile`, method: "GET" },
  SUPER_ADMIN_EDIT_PROFILE: { url: `admin/editProfile`, method: "POST" },

  SUPER_ADMIN_VIEW_FARMER: { url: `admin/viewFarmer`, method: "GET" },
  SUPER_ADMIN_VIEW_MARKET: { url: `market/viewMarket`, method: "GET" },
  SUPER_ADMIN_GET_FARM: { url: `farm/getFarm`, method: "GET" },
  SUPER_ADMIN_GET_FARM_BY_ID: { url: `farm/getFarmsById`, method: "GET" },
  PICK_UP_HOST_GET_ELIGIBLE: { url: `pick-up-host/eligible`, method: "GET" },
}

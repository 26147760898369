import * as debugFactory from "debug"
import { ActionTypes } from "../action/inventory"

const debug = debugFactory("inventory").extend("reduces")

const initialState = {
  list: [],
  inventoryType: "",
  item: {
    details: {},
    isShow: false,
    isAdd: false,
    isDelete: false,
    isLoading: false,
  },
  isLoading: false,
  error: {},
}

export const inventory = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_INVENTORY_REQUEST:
      return { ...state, isLoading: true }
    case ActionTypes.LOAD_INVENTORY_SUCCESS:
      return {
        ...state,
        list: action.payload.list,
        inventoryType: action.payload.inventoryType,
        isLoading: false,
      }
    case ActionTypes.LOAD_INVENTORY_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    case ActionTypes.SHOW_INVENTORY_ITEM:
      return {
        ...state,
        item: { ...state.item, details: action.payload, isShow: true },
      }
    case ActionTypes.HIDE_INVENTORY_ITEM:
    case ActionTypes.DELETE_INVENTORY_ITEM_DISMISS:
    case ActionTypes.ADD_INVENTORY_ITEM_DISMISS:
      return {
        ...state,
        item: initialState.item,
      }
    case ActionTypes.ADD_INVENTORY_ITEM_SHOW:
      return {
        ...state,
        item: { ...initialState.item, isAdd: true },
      }
    case ActionTypes.ADD_INVENTORY_ITEM_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.DELETE_INVENTORY_ITEM_SHOW:
      return {
        ...state,
        item: { ...state.item, details: action.payload, isDelete: true },
      }
    case ActionTypes.DELETE_INVENTORY_ITEM_REQUEST:
    case ActionTypes.UPDATE_INVENTORY_ITEM_REQUEST:
      return {
        ...state,
        item: { ...state.item, isLoading: true },
      }
    case ActionTypes.DELETE_INVENTORY_ITEM_SUCCESS:
      const removedInventory = action.payload.inventoryId
      const updatedList = state.list.filter((i) => i._id !== removedInventory)

      return {
        ...state,
        list: updatedList,
        item: initialState.item,
      }

    case ActionTypes.UPDATE_INVENTORY_ITEM_STATUS_SUCCESS:
      const { inventoryId, status } = action.payload
      const newList = state.list.map((i) => {
        if (i._id !== inventoryId) return i

        return {
          ...i,
          status,
        }
      })

      return {
        ...state,
        list: newList,
        item: initialState.item,
      }
    case ActionTypes.UPDATE_INVENTORY_ITEM_SUCCESS:
      debug("UPDATE_INVENTORY_ITEM_SUCCESS")
      return {
        ...state,
        item: initialState.item,
      }
    case ActionTypes.ADD_INVENTORY_ITEM_SUCCESS:
      debug("ADD_INVENTORY_ITEM_SUCCESS")
      return {
        ...state,
        item: initialState.item,
        isLoading: false,
      }
    case ActionTypes.RESET_INVENTORY_STATE:
      return initialState
    default:
      return state
  }
}

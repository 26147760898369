import { Button, Col, Container, Form, Navbar, Row } from "react-bootstrap"
import React, { Component } from "react"

import Url from "../services/BaseUrl"
import _ from "lodash"
import { _Api } from "../services/Api"
import { notify } from "../components/common/Toaster"

export default class ResetPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      password: "",
      confirmPassword: "",
      errors: [],
      responseError: "",
    }
  }

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    })
  }

  handleValidate = () => {
    let validate = false
    let errors = {}
    let { password, confirmPassword, desc, imageLocation, phone, countryCode } =
      this.state

    if (_.isEmpty(password)) {
      validate = true
      errors["password"] = "Password is required."
    }
    if (_.isEmpty(confirmPassword)) {
      validate = true
      errors["confirmPassword"] = "Confirm password is required."
    } else if (password !== confirmPassword) {
      validate = true
      errors["confirmPassword"] =
        "Old paswword and comfirm password must be same."
    }
    this.setState({ errors })
    return validate
  }

  // Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "",
  handleResetSubmit = (e) => {
    e.preventDefault()
    if (!this.handleValidate()) {
      let { password } = this.state
      localStorage.setItem("accessToken", this.props.match.params.id)

      let req = {
        password: password,
      }
      _Api(
        Url.SUPER_ADMIN_RESET_PASSWORD.method,
        Url.SUPER_ADMIN_RESET_PASSWORD.url,
        req
      ).then((resp) => {
        {
          console.log("resp", resp)
        }
        // this.setState({ driverInfo: resp.responseData.driverInfo, isLoader: false })
        notify("success", resp.responseData.message)
        window.location.href = "/"
        // this.closeModal()
        localStorage.clear("accessToken")
      })
    }
  }

  //sign in
  //     handleResetSubmit = e => {
  //         let { email,long, lat,password,userType} = this.state;
  //         this.setState({redirect: false, errors: ''});
  //         e.preventDefault();
  //         // let token= this.props.match.params.id;

  //         let self=this
  //         if (this.handleValidation()) {
  //             this.setState({redirect: false,loader: true});
  // let token= this.props.match.params.id;

  //             let obj = {
  //                 'newPassword': password,
  //             }

  //             let {dispatch} = this.props;
  //             dispatch(usersActions.reset(obj,token)).then(data => {

  //                 console.log(data,"Data:::::::lkjhgfdsdfghj")

  //                 this.setState({loader: false})

  //                 if (data.data.statusCode == 1) {
  //                     let success = data.data.responseData.message
  //                     this.setState({success: success})

  //                 }
  //                 else {
  //                     this.setState({errors: data.data.error.responseMessage})
  //                 }
  //             });
  //         }
  //     }

  render() {
    let {
      email,
      password,
      passwordErrorMsg,
      emailErrorMsg,
      errors,
      success,
      confirmPasswordErrorMsg,
      confirmPassword,
    } = this.state
    return (
      <div className="body_container_wrapper login_page">
        <Container>
          <h1>
            <Navbar.Brand href="#home">
              <img
                src={require(".././assets/img/OFFICIAL LOGO TM WITH HEALTHY PLANEAT BLOCK.png")}
                alt="Logo"
              />
            </Navbar.Brand>
          </h1>
          <div className="login_wrapper">
            <Row>
              <Col md="4" className="mx-auto text-center">
                <div>
                  <img src={require(".././assets/img/OFFICIAL LOGO.png")} />
                </div>
                <h6>Reset Password</h6>
                {/* {console.log(errors,"rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")}
                                <div className="error"> {errors} </div>
                                <div className="success"> {success} </div> */}
                <Form.Group>
                  <Form.Control
                    type="Password"
                    onChange={this.handleOnChange}
                    name="password"
                    value={password}
                    placeholder="New Password"
                  />
                  <span className="error_msg">{errors["password"]}</span>
                  {/* {passwordErrorMsg ? <div className="error">{passwordErrorMsg}</div> : null} */}
                </Form.Group>{" "}
                <Form.Group>
                  <Form.Control
                    type="Password"
                    onChange={this.handleOnChange}
                    name="confirmPassword"
                    value={confirmPassword}
                    placeholder="Confirm Password"
                  />
                  <span className="error_msg">{errors["confirmPassword"]}</span>
                </Form.Group>
                <Form.Group
                  controlId="formBasicCheckbox"
                  className="forgot_wrapper"
                >
                  {/*<p><small><Link to="/">Login</Link></small></p>*/}
                </Form.Group>
                <Button
                  type="submit"
                  onClick={this.handleResetSubmit}
                  variant="dark"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    )
  }
}

// export default Login

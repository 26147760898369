import { ActionTypes } from "../action/item"

const initialState = {
  list: [],
  isLoading: false,
  error: {},
}

export const item = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_ITEM_REQUEST:
      return { ...state, isLoading: true }
    case ActionTypes.LOAD_ITEM_SUCCESS:
      return {
        ...state,
        list: action.payload.list,
        isLoading: false,
      }
    case ActionTypes.LOAD_ITEM_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    default:
      return state
  }
}

import React from "react"

function Page404() {
  return (
    <div className="page_container">
      <div class="main">
        <div class="">
          <div id="outline">
            <div id="errorboxoutline">
              <div class="error-code">
                <span class="first">4</span>
                <span class="">0</span>
                <span class="last">4</span>{" "}
              </div>

              <div class="error-message">
                <div className="wrap-text">
                  <h1>Oops!</h1>
                  <h2>Page not found</h2>
                </div>
                <div id="errorboxbody">
                  <p>
                    The page you are looking for might have been removed, had
                    it's name changed or is temporarily unavailable.
                  </p>
                </div>
                <a
                  class="button-home"
                  href="/farmermanagement"
                  title="Go to the Home Page"
                >
                  Home Page
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page404

import { IS_LOADING_START, IS_LOADING_STOP } from "../action/Type"

const initalState = {
  isLoading: false,
}

// login
export const loading = (state = initalState, action) => {
  switch (action.type) {
    case IS_LOADING_START:
      return {
        ...state,
        isLoading: true,
      }

    case IS_LOADING_STOP:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}
